import React from 'react';
import {
	ControlBar,
	AudioInputControl,
	VideoInputControl,
	ContentShareControl,
	AudioOutputControl,
	useUserActivityState,
	useContentShareControls,
} from 'amazon-chime-sdk-component-library-react';

import EndMeetingControl from '../EndMeetingControl';
import { StyledControls } from './Styled.jsx';

const MeetingControls = () => {
	const { isUserActive } = useUserActivityState();
	const { paused } = useContentShareControls();

	console.log(paused);

	return (
		<StyledControls className='controls' active={!!isUserActive}>
			<ControlBar className='controls-menu' layout='undocked-horizontal' showLabels>
				<AudioInputControl />
				<VideoInputControl />
				<ContentShareControl />
				<AudioOutputControl />
				<EndMeetingControl />
			</ControlBar>
		</StyledControls>
	);
};

export default MeetingControls;
