import React, { useRef, useState } from 'react';

import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useDispatch } from 'react-redux';
import { EventEmitter } from '../../../../services/EventEmitter';

import {
	getConsultantInfo,
	postDeleteProfileInfo,
} from '../../../../store/slice/auth/signup/consultantInfoSlice';
import MenuPopover from '../../../common/MenuPopover';
import Iconify from '../../../common/Iconify';

const settings = [
	{
		title: 'Edit',
		icon: <Iconify icon='solar:pen-bold' sx={{ height: '15px', color: 'primary.main' }} />,
	},
	{
		title: 'Delete',
		icon: (
			<Iconify
				icon='solar:trash-bin-minimalistic-bold'
				sx={{ height: '16px', color: 'error.main' }}
			/>
		),
	},
];

const ShowModalInfo = (props) => {
	const { icon, title, subtitle, item, sectionName } = props;
	const anchorRef = useRef(null);
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleTask = async (data) => {
		if (data.title === 'Edit') {
			EventEmitter.dispatch(sectionName, item);
		} else if (data.title === 'Delete') {
			const params = { _id: item._id, type: sectionName };
			await dispatch(postDeleteProfileInfo(params)).unwrap();
			await dispatch(getConsultantInfo()).unwrap();
		}
		handleClose();
	};
	return (
		<>
			<Stack
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				spacing={2}
				mb={2}
			>
				<Stack direction='row' spacing={2} alignItems='center'>
					<Box component='div'>
						<Avatar
							sx={{
								backgroundColor: 'primary.lighter',
								color: 'primary.main',
							}}
						>
							{icon ? icon : <BusinessCenterOutlinedIcon fontSize='small' />}
						</Avatar>
					</Box>
					<Stack direction='column'>
						<Typography
							variant='body2'
							color='text.primary'
							fontWeight='600'
							textTransform='capitalize'
						>
							{title}
						</Typography>
						<Stack direction='row' alignItems='center' className='gap-1'>
							{subtitle && (
								<Typography variant='caption' color='text.primary'>
									{subtitle}
								</Typography>
							)}
							{/* {link ? (
								<Stack direction='row' alignItems='center' className='gap-1'>
									<Link
										href={link}
										color='text.primary'
										variant='caption'
										target='_blank'
										className='text-decoration-none'
									>
										{truncateString(link, 28)}
									</Link>
									<CopyToClipboard
										options={{ message: 'Whoa!' }}
										onCopy={() => toast.success('Copied Successfully')}
										text={link}
									>
										<IconButton color='primary' size='small' sx={{ py: 0 }}>
											<Iconify icon='eva:copy-fill' />
										</IconButton>
									</CopyToClipboard>
								</Stack>
							) : null} */}
						</Stack>
					</Stack>
				</Stack>

				{/* Profile Menu */}
				<Box>
					<IconButton ref={anchorRef} onClick={handleOpen} sx={{ p: 0 }}>
						<MoreVertIcon />
					</IconButton>
					<MenuPopover
						open={open}
						onClose={handleClose}
						anchorEl={anchorRef.current}
						sx={{ width: 150 }}
					>
						{settings.map((data, index) => (
							<MenuItem key={index} onClick={() => handleTask(data)}>
								<Typography
									variant='body2'
									fontWeight={600}
									sx={{ display: 'flex', alignItems: 'center' }}
									className='gap-2'
								>
									{data.icon}
									{data.title}
								</Typography>
							</MenuItem>
						))}
					</MenuPopover>
				</Box>
			</Stack>
		</>
	);
};

export default ShowModalInfo;
