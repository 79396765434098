/* eslint-disable no-unused-vars */
// SetCalendarDetails;

import { get, isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, Alert } from '@mui/material';
// import { Stack } from "immutable";
import React, { useCallback, useEffect, useState } from 'react';
import './Calendar.css';
import { format } from 'date-fns';
import { getCelenderFilterdEvent } from '../../../../../store/slice/dashboard/appointmentSlice';
import EventCalendar from './EventCalendar';
import Grid from '@mui/material/Grid';
// import { socket } from "../../../../../socket";
import cookie from '../../../../hooks/cookie';
import { io } from 'socket.io-client';
import Button from '@mui/material/Button';
import translate from '../../../../hooks/translate';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';
import AppointmentAdd from '../../../booking/AppointmentAdd';

const SetCalendarDetails = () => {
	const dispatch = useDispatch();
	const loading = useSelector((state) => state.appointment.loading);
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [dialogData, setDialogData] = useState([]);
	const [celenderData, setCelenderData] = useState([]);
	const [isFilter, setIsFilter] = useState(false);
	// const celenderData = useSelector((state) => state.appointment.celenderEvent);
	const celenderFilteredEvent = useSelector((state) => state.appointment.celenderFilteredEvent);

	const handleDialogOpen = useCallback(() => {
		setOpen(true);
	}, []);

	const handleDialogClose = useCallback(() => {
		setOpen(false);
	}, []);

	const handleFilterEvents = (KeyName) => {
		const payload = {
			[KeyName]: true,
		};
		if (payload && KeyName) {
			dispatch(getCelenderFilterdEvent(payload))
				.then((result) => {
					if (result?.payload?.status) {
						setIsFilter(true);
					}
				})
				.catch((err) => {
					setIsFilter(false);
				});
		}
	};

	useEffect(() => {
		const userData = cookie.get('consultant_data');
		const SOCKET_SERVER_URL = process.env.REACT_APP_API_END_POINT;
		const socket = io(SOCKET_SERVER_URL, {
			transports: ['websocket'],
			auth: {
				token: get(userData, 'token', null),
			},
		});

		socket.on('connect', () => {
			// Send a test message to the server
			socket.emit('google_calender_event', {
				token: get(userData, 'token', null),
			});
		});

		socket.on('google_calender_event', (data) => {
			console.log('Received from server:', data);
			setCelenderData(data?.text);
		});

		socket.on('disconnect', () => {
			console.log('Disconnected from the socket server');
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const events =
		celenderData?.length > 0 &&
		celenderData?.map((item, index) => {
			return {
				id: item.eventId,
				color: '#3B6ABA',
				from: item?.startDate,
				to: item?.endDate,
				title: item.eventTitle,
			};
		});

	const filterEvent =
		celenderFilteredEvent?.length > 0 &&
		celenderFilteredEvent?.map((item, index) => {
			return {
				id: item?._id,
				color: '#3B6ABA',
				from: item?.startDate,
				to: item?.endDate,
				title: item.eventTitle,
			};
		});

	// console.log('filterEvent :>> ', filterEvent);
	// console.log('isFilter :>> ', isFilter);
	// console.log("events :>> ", events);

	// useEffect(() => {
	//   dispatch(getCelenderEvent());
	// }, []);

	const eventData = isFilter ? filterEvent : events;

	// console.log('eventData :>> ', eventData);

	return (
		<div>
			<Grid container spacing={5}>
				<Grid item xs={8}>
					<Box sx={{ '& button': { m: 1 } }}>
						<Button
							size='small'
							variant='contained'
							color='info'
							type='button'
							onClick={() => handleFilterEvents('inspiremindEvent')}
						>
							{translate('consultant_calendar_events')}
						</Button>
						<Button
							size='small'
							variant='contained'
							color='warning'
							type='button'
							onClick={() => handleFilterEvents('inspiremindAppoitment')}
						>
							{translate('consultant_calendar_appointments')}
						</Button>
						<Button
							size='small'
							variant='contained'
							color='error'
							type='button'
							onClick={() => handleFilterEvents('isCalenderEvent')}
						>
							{translate('consultant_calendar_personal_events')}
						</Button>
					</Box>
					<EventCalendar events={eventData} />
				</Grid>

				<Grid item xs={4}>
					<Grid item xs>
						{/* <Calendar onClickDay={handleDateClick} /> */}
						<Box sx={{ '& button': { m: 1 } }}>
							{/* <Button
								size='small'
								variant='contained'
								color='info'
								startIcon={<AddCircleIcon />}
								onClick={() => navigate('/dashboard/event/add-event')}
							>
								{translate('common_add_event')}
							</Button> */}
							<Button
								size='small'
								variant='contained'
								color='warning'
								startIcon={<AddCircleIcon />}
								onClick={() => handleDialogOpen()}
							>
								{translate('common_add_appointment')}
							</Button>
						</Box>
					</Grid>
					<Grid item xs={12} mt={3}>
						<Typography variant='subtitle1' gutterBottom>
							{translate('consultant_calendar_upcoming_activity')}
						</Typography>
					</Grid>
					{eventData?.length > 0 ? (
						<Box className='ev-data' sx='2'>
							{isEmpty(eventData) && !loading
								? null
								: map(eventData, (item, index) => (
										<div key={index}>
											<div class='events-container'>
												<div class='events__item'>
													<span class='events__name'>{item.title}</span>
													<br />
												</div>
												<div className='datetime'>
													<div>
														<span class='events__date'>
															{' '}
															{format(new Date(item.from), 'dd-MM-yyyy')}
														</span>
														<br />
													</div>
													<div className='time'>
														<span class='events__tag'>{format(new Date(item.from), 'hh:mm a')}</span>
													</div>
												</div>
											</div>
										</div>
								  ))}
						</Box>
					) : (
						<Alert severity='info'>{translate("you_don't_have_any_upcoming_activity")}</Alert>
					)}
				</Grid>
			</Grid>
			<AppointmentAdd open={open} onClose={handleDialogClose} dialogData={dialogData} />
		</div>
	);
};

export default SetCalendarDetails;
