import AxiosDefault from "../services/AxiosDefault";

export const PostSignin = async (data) => {
  const response = await AxiosDefault({
    url: "/consultant/auth/login",
    method: "POST",
    data: data,
  });
  return response;
};

export const PostForgetPassword = async (data) => {
  const response = await AxiosDefault({
    url: "/consultant/auth/forgot-password",
    method: "POST",
    data: data,
  });
  return response;
};

export const PostForgetPasswordOtpVerify = async (data) => {
  const response = await AxiosDefault({
    url: "/consultant/auth/forgot-password-verify-otp",
    method: "POST",
    data: data,
  });
  return response;
};

export const PostForgetPasswordReset = async (data) => {
  const response = await AxiosDefault({
    url: "/consultant/auth/reset-password",
    method: "POST",
    data: data,
  });
  return response;
};