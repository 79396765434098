export function radio(theme) {
	return {
		MuiFormControlLabel: {
			styleOverrides: {
				label: {
					...theme.typography.body2,
				},
			},
		},

		MuiRadio: {
			styleOverrides: {
				root: {
					padding: theme.spacing(1),
				},
			},
		},
	};
}
