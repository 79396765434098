import { find, get, isArray, map, replace, toString } from 'lodash';

const translate = (valueKey) => {
	const languageMacros = JSON.parse(localStorage.getItem('translateLanguageMacros'));
	let finalValue = '';

	if (isArray(valueKey)) {
		const arrayValue = map(valueKey, (item) => {
			return get(find(languageMacros, { name: item }), 'value', '');
		});
		finalValue = replace(toString(arrayValue), ',', ' ');
		return finalValue ?? valueKey;
	} else {
		finalValue = get(find(languageMacros, { name: valueKey }), 'value', valueKey);
		return finalValue;
	}
};

export default translate;
