import React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

export default function ProfileContainer() {
	return (
		<Card sx={{ bgcolor: 'background.paper' }}>
			<Box p={2}>Profile</Box>
			<Divider />
		</Card>
	);
}
