import { alpha } from '@material-ui/core';

export function createGradient(color1, color2) {
	return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export const GREY = {
	50: '#F8FAFC',
	100: '#F1F5F9',
	200: '#E2E8F0',
	300: '#CBD5E1',
	400: '#94A3B8',
	500: '#64748B',
	600: '#475569',
	700: '#334155',
	800: '#1E293B',
	900: '#0F172A',
	500_8: alpha('#64748B', 0.08),
	500_12: alpha('#64748B', 0.12),
	500_16: alpha('#64748B', 0.16),
	500_24: alpha('#64748B', 0.24),
	500_32: alpha('#64748B', 0.32),
	500_48: alpha('#64748B', 0.48),
	500_56: alpha('#64748B', 0.56),
	500_80: alpha('#64748B', 0.8),
};

export const CHART_COLORS = {
	violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
	blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
	green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
	yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
	red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};
