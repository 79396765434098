import { DashboardIcon } from '../../../components/Icon';
import ConnectWithoutContactTwoToneIcon from '@mui/icons-material/ConnectWithoutContactTwoTone';
import DomainTwoToneIcon from '@mui/icons-material/DomainTwoTone';
import PersonTwoToneIcon from '@mui/icons-material/PersonTwoTone';
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import BookOnlineTwoToneIcon from '@mui/icons-material/BookOnlineTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import QuestionAnswerTwoToneIcon from '@mui/icons-material/QuestionAnswerTwoTone';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import EventNoteIcon from '@mui/icons-material/EventNote';

const MenuItemsList = [
	{
		label: 'common_dashboard',
		icon: <DashboardIcon />,
		path: '/dashboard',
		selected: 'dashboard',
		type: 'main-menu',
		subMenu: [],
	},
	{
		label: 'common_profile',
		icon: <PersonTwoToneIcon />,
		path: 'profile',
		selected: '',
		type: 'sub-menu',
		subMenu: [
			{
				label: 'consultant_profile_settings',
				icon: <ManageAccountsTwoToneIcon />,
				path: '/dashboard/profile',
				selected: '',
				type: 'main-menu',
			},
			{
				label: 'common_basic_information',
				icon: <InfoTwoToneIcon />,
				path: 'basicinfo',
				selected: '',
				type: 'main-menu',
			},
			// {
			//   label: "consultant_residential_address",
			//   icon: <PersonPinCircleTwoToneIcon />,
			//   path: "residential-address",
			//   selected: "",
			//   type: "main-menu",
			// },
			{
				label: 'consultant_professional_domain',
				icon: <DomainTwoToneIcon />,
				path: 'professional-domain',
				selected: '',
				type: 'main-menu',
			},
			{
				label: 'consultant_professional_information',
				icon: <AssignmentIndTwoToneIcon />,
				path: 'professional-info',
				selected: '',
				type: 'main-menu',
			},
			// {
			//   label: "consultant_licence_or_certification",
			//   icon: <AdminPanelSettingsTwoToneIcon />,
			//   path: "license-or-certification",
			//   selected: "",
			//   type: "main-menu",
			// },
			{
				label: 'consultant_social_media_information',
				icon: <ConnectWithoutContactTwoToneIcon />,
				path: 'social-media',
				selected: '',
				type: 'main-menu',
			},
		],
	},
	{
		label: 'InspireMind Profile',
		icon: <ManageAccountsTwoToneIcon />,
		path: 'inspire-mind-profile',
		selected: '',
		type: 'main-menu',
	},
	{
		label: 'consultant_service_plan',
		icon: <BallotTwoToneIcon />,
		path: 'service-plans',
		selected: 'user-management',
		type: 'main-menu',
	},
	{
		label: 'common_blogs',
		icon: <ArticleTwoToneIcon />,
		path: 'article-blog',
		selected: '',
		type: 'main-menu',
	},
	{
		label: 'common_content',
		icon: <ContentPasteIcon />,
		path: 'content',
		selected: '',
		type: 'main-menu',
	},
	// {
	// 	label: 'common_event',
	// 	icon: <EventIcon />,
	// 	path: 'event',
	// 	selected: '',
	// 	type: 'main-menu',
	// },

	{
		label: 'common_appointment',
		icon: <EventNoteIcon />,
		path: 'appointment',
		selected: '',
		type: 'main-menu',
	},
	// {
	//   label: "common_calendar",
	//   icon: <CalendarMonthOutlinedIcon/>,
	//   path: "calendar",
	//   selected: "",
	//   type: "main-menu",
	// },
	{
		label: 'consultant_banking',
		icon: <AccountBalanceTwoToneIcon />,
		path: 'banking',
		selected: '',
		type: 'main-menu',
	},
	{
		label: 'consultant_q_and_a_template',
		icon: <QuestionAnswerTwoToneIcon />,
		path: 'q-and-a-template',
		selected: '',
		type: 'main-menu',
	},
	{
		label: 'consultant_booking',
		icon: <BookOnlineTwoToneIcon />,
		path: 'booking',
		selected: '',
		type: 'main-menu',
	},
];

export default MenuItemsList;
