import AxiosDefault from "../../services/AxiosDefault";

export const GetQuestionList = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/consultant/questions/get`,
  });
  return response;
};

export const GetQuestionTemplate = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/consultant/questions/template/get`,
  });
  return response;
};

export const CreateQuestionTemplate = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/consultant/questions/template/create`,
    data: data,
  });
  return response;
};

export const UpdateQuestionTemplate = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/consultant/questions/template/update/${id}`,
    data: data,
  });
  return response;
};

export const StatusUpdateQuestionTemplate = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/consultant/questions/template/status-update/${id}`,
    data: data,
  });
  return response;
};

export const GetDefaultTemplate = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/consultant/questions/template-default/get`,
  });
  return response;
};

export const AddDefaultTemplate = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/consultant/questions/template-default/add`,
    data: data,
  });
  return response;
};
