import React from "react";

import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { get } from "lodash";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getBookingDetail,
  updateBookingStatus,
} from "../../../../store/slice/dashboard/bookingSlice";
import ConfirmationDialog from "../../../common/ConfirmationDialog";

const ChangeStatus = ({ data }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const handleStatus = () => {
    dispatch(updateBookingStatus({ status: "COMPLETE", id: data._id }));
    dispatch(getBookingDetail(get(params, "bookingId", "")));
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Tooltip title="Update Consultation Status Progress to complete">
        <IconButton onClick={() => setOpen(true)} size="small" color="primary">
          <ModeEditRoundedIcon />
        </IconButton>
      </Tooltip>
      <ConfirmationDialog
        dialogTitle="Consultation Update Confirmation"
        dividers={true}
        dialogOpen={open}
        dialogClose={handleClose}
        setOpen={setOpen}
        action={() => handleStatus()}
        btnColor="primary"
        buttonLabel="Confirm"
        confirmationText="Are you sure you want to Complete this Consultation ?"
      />
    </>
  );
};

export default ChangeStatus;
