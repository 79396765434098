import React from "react";

import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { get } from "lodash";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  cancelAllBookings,
  getBookingDetail
} from "../../../../store/slice/dashboard/bookingSlice";
import CMToolTip from "../../../ui-components/tooltip/CMToolTip";

const CancelConsultations = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const bookingDetail = useSelector((state) => state.booking.bookingDetail);
  const handleCancelBookings = () => {
    dispatch(cancelAllBookings(bookingDetail._id));
    dispatch(getBookingDetail(get(params, "bookingId", "")));
  };
  return (
    <>
      <Stack direction="row" alignItems="center" className="gap-2 mb-2">
        <Typography variant="subtitle2">Cancel All Consultations</Typography>
        <CMToolTip
          icon={<HelpTwoToneIcon  size="small"/>}
          title="If you want to cancel all these consultations then use this option."
        />
      </Stack>
      <Button variant="contained" onClick={handleCancelBookings}>
        Cancel All Consultations
      </Button>
    </>
  );
};

export default CancelConsultations;
