import AxiosDefault from "../../services/AxiosDefault";

export const ProfileSectionChildStatus = async (data) => {
  const response = await AxiosDefault({
    url: `/consultant/profile/section-child-status/update`,
    method: "PATCH",
    data: data,
  });
  return response;
};

export const ProfileSectionStatus = async (data) => {
  const response = await AxiosDefault({
    url: `/consultant/profile/section-status-update`,
    method: "PATCH",
    data: data,
  });
  return response;
};

export const ProfileStatusUpdate = async (data) => {
  const response = await AxiosDefault({
    url: `/consultant/profile/non-child-status/update`,
    method: "PATCH",
    data: data,
  });
  return response;
};
