import { isEmpty } from 'lodash';
import { LIGHT_MODE, THEME_NAMES_LIST, LOCALSTORAGE_THEME_TOKEN_KEY } from './theme.type';

export default function getThemeName() {
	const localTheme = localStorage.getItem(LOCALSTORAGE_THEME_TOKEN_KEY);

	if (!isEmpty(localTheme) && THEME_NAMES_LIST.includes(localTheme.toLowerCase())) {
		return localTheme.toLowerCase();
	}

	setLocalThemeMode(LIGHT_MODE);
	return LIGHT_MODE;
}

export function setLocalThemeMode(mode) {
	localStorage.setItem(LOCALSTORAGE_THEME_TOKEN_KEY, mode);
}

export function getSafeTheme(themeName, defaultThemeName = LIGHT_MODE) {
	if (THEME_NAMES_LIST.includes(themeName)) {
		return themeName;
	}

	return defaultThemeName;
}
