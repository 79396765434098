export const menuOptions = [
  {
    label: "Profile",
    icon: "eva:person-fill",
    linkTo: "/dashboard/profile",
  },
  {
    label: "Settings",
    icon: "eva:settings-2-fill",
    linkTo: "#",
  },
];
