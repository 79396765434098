import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ChatFooter from './ChatFooter';
import AxiosDefaultSetting from '../../../../../services/AxiosDefault';
import { cloneDeep, get, uniqueId } from 'lodash';
import socket from '../../../../../context/Socket';
import { extractDataFileFromName, formatChatList, formatChatMessage, getMessageStatus } from './helpers';
import MessageContainer from './MessageContainer';
import { useSelector } from 'react-redux';
import { addJob, jobTypes } from '../../../../common/JobQueue';
import EventSubject from '../../../../../services/EventSubject';
import ProfileContainer from './ProfileContainer/ProfileContainer';
import TimelineContainer from './TimelineContainer/TimelineContainer';
import NoteContainer from './NoteContainer';

export default function ChatContainer({ chatId, bookingId }) {
	const consultantId = useSelector((state) => state.auth.meInfo._id);
	const [metaData, setMetaData] = useState({});
	const [userData, setUserData] = useState({});
	const [chatHistory, setChatHistory] = useState([]);
	const [note, setNote] = useState('');
	const [timeline, setTimeline] = useState({
		list: [],
		nextToken: null,
	});

	useEffect(() => {
		socket.on('newMessage', (value) => {
			setChatHistory((prev) => [...prev, ...formatChatList([value].flat(), consultantId)]);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		async function getMetaDate() {
			const url = process.env.REACT_APP_CHAT_END_POINT;
			const response = await AxiosDefaultSetting({
				method: 'GET',
				url: `${url}conversation-list/${chatId}`,
			});

			if (response.data) {
				setNote(response.data.notes ?? '');
				setMetaData(response.data.conversation ?? {});
				setChatHistory(formatChatList(response.data.chatHistory, consultantId));

				const userId = response.data.conversation.recipientId;
				const userDetails = get(response.data.user, userId, {});
				userDetails.userName = [userDetails.firstName, userDetails.lastName].filter(Boolean).join(' ');
				setUserData(userDetails);

				setTimeline(response.data.timeline);
			}
		}

		if (chatId) {
			getMetaDate();
		}
		const subscription = EventSubject.subscribe((data) => {
			if (data.type !== 'chat_message') return;
			if (data.chatId !== chatId) return;

			setChatHistory((list) => {
				const cloneList = cloneDeep(list);
				for (let index = cloneList.length - 1; index >= 0; index--) {
					if (cloneList[index].id === data.messageId) {
						const status = getMessageStatus(data);

						cloneList[index].status = status;
						cloneList[index].url = data.url ?? cloneList[index].url;

						if (status === 'done') {
							socket.send('message', cloneList[index]);
						}

						break;
					}
				}
				return cloneList;
			});
		});

		return () => {
			subscription.unsubscribe();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chatId]);

	const onMessageSend = (message) => {
		const payload = {
			id: uniqueId(),
			chatId,
			bookingId,
			receiverId: metaData.recipientId,
			messageType: 'text',
			text: message,
			url: '',
			like: 0,
			isGroup: false,
		};

		setChatHistory((prev) => [...prev, formatChatMessage(payload, true)]);

		socket.send('message', payload, (value) => {
			console.log(value);
		});
	};

	const onFileUpload = (form, messageType, localUrl) => {
		const isReport = form.get('uploadType') === 'report';
		const fileName = form.get('fileName') ?? '';
		const meta = JSON.parse(form.get('meta') ?? {});

		const messagePayload = {
			id: uniqueId(),
			chatId,
			receiverId: metaData.recipientId,
			messageType: messageType,
			url: localUrl,
			like: 0,
			isGroup: false,
			fileName,
			fileMetaData: extractDataFileFromName(fileName, meta),
			isReport,
			title: isReport ? 'Report' : '',
		};
		const payload = {
			payload: {
				form,
				payload: messagePayload,
			},
			jobType: jobTypes.CHAT_MESSAGE_CREATE,
			title: '',
			_id: uniqueId(),
		};

		const jobId = addJob(payload);
		setChatHistory((prev) => [...prev, formatChatMessage({ ...messagePayload, status: 'uploading', jobId }, true)]);
	};

	return (
		<div className='h-100'>
			<Grid container spacing={2} className='h-100'>
				<Grid item lg={8} md={6} xs={12} className='h-100'>
					<Card className='h-100' sx={{ display: 'flex', flexDirection: 'column', maxHeight: '700px' }}>
						<CardHeader
							avatar={<Avatar alt={userData.userName} src={userData.profilePictureUR} />}
							title={<Typography fontWeight={600}>{userData.userName ?? ''}</Typography>}
							subheader=''
							sx={{ p: 2 }}
						/>
						<Divider />
						<MessageContainer chatList={chatHistory} senderId={consultantId} />
						<Divider />
						<ChatFooter disabled={!socket.isConnected} onMessageSend={onMessageSend} onFileUpload={onFileUpload} />
					</Card>
				</Grid>
				<Grid item lg={4} md={6} xs={12} display='flex' flexDirection='column' gap={2}>
					<ProfileContainer />
					<NoteContainer note={note} chatId={chatId} />
					<TimelineContainer timelineData={timeline} />
				</Grid>
			</Grid>
		</div>
	);
}
