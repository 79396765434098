import React from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";

import Iconify from "../../../components/common/Iconify";
import Logo from "../../../components/common/Logo";
import NavSection from "../../../components/common/NavSection";
import HeaderConfig from "./HeaderConfig";

const drawerWidth = 280;

const AppDrawer = (props) => {
  const { window, mobileOpen, handleDrawerToggle } = props;

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            p={1}
          >
            <Logo sx={{ width: 50, height: 50 }} />
            <Iconify
              icon="mdi:hamburger-menu-back"
              sx={{ width: 28, height: 28 }}
              onClick={handleDrawerToggle}
            />
          </Stack>
          <Divider light />
          <NavSection navConfig={HeaderConfig} />
        </Box>
      </Drawer>
    </>
  );
};

export default AppDrawer;
