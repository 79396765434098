export const OtpInputStyle = { 
	errorStyle: {
		color: '#b71c1c',
		borderColor: '#b71c1c',
	},
	inputStyle: {
		border: '1px solid #CFD3DB',
		borderRadius: '8px',
		width: '40px',
		height: '40px',
		fontSize: '16px',
		color: '#777E89',
		fontWeight: '400',
		caretColor: '#777E89',
		margin: '0 auto',
	},
	focusStyle: {
		border: '1px solid #CFD3DB',
		outline: 'none',
	},
};
