/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import { cloneDeep, get, isEmpty, map, omit, uniqueId } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { getBenefits, getContentconfig, getCountryList, getSpecialization } from '../../../../store/slice/common';
import MultiSelectSpecialization, {
	getInitSpecialization,
	getInitSubSpecialization,
	getSpecializationSubmitData,
} from '../../../common/MultiSelectSpecialization';
import translate from '../../../hooks/translate';
import useContextData from '../../../hooks/useContextData';
import ConsultantContentSection from './ConsultantContentSection';
import ContentTopSection from './ContentTopSection';
import { BEST_SUITED_EXTENSIVEEXPERIENCENECESSARY, Best_suitedArrList, COPYRIGHT_OWN } from '../../../../_mocks_/constant';
import RichTextEditor from '../../../ui-components/editor/RichTextEditor';
import { Box, Typography, alpha, useTheme } from '@mui/material';
import SwitchToggle from '../../../ui-components/switchToggle/SwitchToggle';
import PDFPicker from '../../../ui-components/pdf-picker/PDFPicker';
import EventSubject from '../../../../services/EventSubject';
import { addJob, jobTypes } from '../../../common/JobQueue';
import axios from 'axios';

const LoadingOverlay = ({ jobId, setIsFormSubmitting }) => {
	const navigate = useNavigate();
	const { palette } = useTheme();
	const [content, setContent] = useState('Saving...');
	const [showBackgroundJobBox, setShowBackgroundJobBox] = useState(false);

	useEffect(() => {
		if (!jobId) return;
		const subscription = EventSubject.subscribe((data) => {
			if (data.id !== jobId) return;

			setShowBackgroundJobBox(true);
			if (data.completed) {
				toast.success(data.content);
				navigate('/dashboard/article-blog');
				setIsFormSubmitting(false);
			}
			if (data.error) {
				toast.success(data.content);
				setIsFormSubmitting(data.content);
			}
			if (data.value === 0 || data.value === 100) {
				setContent('Saving...');
			} else {
				setContent(data.content);
			}
		});

		return () => {
			subscription.unsubscribe();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [jobId]);

	return (
		<Box
			position='absolute'
			top={0}
			left={0}
			right={0}
			bottom={0}
			display='flex'
			justifyContent='center'
			alignItems='center'
			backgroundColor={alpha(palette.primary.main, 0.3)}
			sx={{ backdropFilter: 'blur(1px)' }}
			zIndex={99999}
		>
			<Box
				width='100%'
				maxWidth='650px'
				bgcolor='white'
				padding='10px'
				display='flex'
				justifyContent='center'
				alignItems='center'
				flexDirection='column'
				gap={1}
			>
				<Typography variant='h5' fontWeight='bold'>
					{content}
				</Typography>
				{showBackgroundJobBox && (
					<Button variant='outlined' onClick={() => navigate('/dashboard/article-blog')}>
						Process in background
					</Button>
				)}
			</Box>
		</Box>
	);
};

const ContentForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const { GetLanguageList } = useContextData();

	const [jobId, setJobId] = useState(null);
	const [isFormSubmitting, setIsFormSubmitting] = useState(false);
	const [defaultLanguage, setDefaultLanguage] = useState(JSON.parse(localStorage.getItem('selectedHeaderLanguage')));

	const LanguageList = GetLanguageList();
	const contentData = get(location, 'state.item', {});
	const formType = get(location, 'state.type', '');
	const isContentCreationPending = get(contentData, 'status', '') === 'pending';

	const countryList = useSelector((state) => state.common.countryList);
	const specializationList = useSelector((state) => state.common.specializationList);
	const contentConfigList = useSelector((state) => state.common.contentConfigList);
	const benefitsList = useSelector((state) => state.common.benefitsList);

	const [checkImage, setCheckImage] = useState(true);
	const [isMonotizationPaid] = useState(false);
	const [isExtensiveExperienceNecessary, setIsExtensiveExperienceNecessary] = useState(
		formType === 'update' && contentData?.extensiveExperienceNecessary !== '' ? true : false
	);
	const [isAttachPDF, setIsAttachPDF] = useState(formType === 'update' && contentData?.attachment !== '' ? true : false);
	const selectedLanguage = localStorage.getItem('selectedHeaderLanguage');

	useEffect(() => {
		selectedLanguage && setDefaultLanguage(JSON.parse(selectedLanguage));
	}, [selectedLanguage]);

	const [isDonation, setDonation] = useState(get(contentData, 'donation', true));

	const schemaObj = Yup.object().shape({
		title: Yup.string()
			.min(3, translate('consultant_error_title_too_short'))
			.max(1000, translate('consultant_error_title_too_long'))
			.required(translate('consultant_error_title_required')),
		language: Yup.object().shape().required(translate('consultant_error_languages_required')).nullable(),
		categories: Yup.array().min(1, 'Min 1 Category').max(3, 'Max 3 Category').nullable(),
		topSection: Yup.array().of(
			Yup.object().shape({
				title: Yup.string()
					.min(3, translate('consultant_error_image_title_too_short'))
					.max(500, translate('consultant_error_image_title_too_long'))
					.required(translate('consultant_error_image_title_required')),

				imageUrl: Yup.mixed().when('imageType', {
					is: 'IMAGE_UPLOAD',
					then: Yup.array().required('Image is required'),
					otherwise: Yup.mixed()
						// .url(translate("consultant_error_enter_valid_url"))
						.required('Image link is required'),
				}),
			})
		),
		contentSection: Yup.array().of(
			Yup.object().shape({
				data: Yup.string()
					.when(['dataType', 'preferredOption'], (dataType, preferredOption) => {
						if (preferredOption === 'VIDEO' && dataType === 'VIDEO_LINK') {
							return Yup.string()
								.url(translate('consultant_error_enter_valid_url'))
								.required(translate('consultant_error_video_links_required'));
						} else if (preferredOption === 'VIDEO' && dataType === 'VIDEO_UPLOAD') {
							return Yup.mixed().required(translate('consultant_error_video_link_required'));
						}
					})
					.when(['dataType', 'preferredOption'], (dataType, preferredOption) => {
						if (preferredOption === 'AUDIO' && dataType === 'AUDIO_LINK') {
							return Yup.string()
								.url(translate('consultant_error_enter_valid_url'))
								.required(translate('consultant_error_audio_link_required'));
						} else if (preferredOption === 'AUDIO' && dataType === 'AUDIO_UPLOAD') {
							return Yup.mixed().required(translate('consultant_error_audio_required'));
						}
					})
					.when(['dataType', 'preferredOption'], (dataType, preferredOption) => {
						if (preferredOption === 'PDF' && dataType === 'PDF_LINK') {
							return Yup.string()
								.url(translate('consultant_error_enter_valid_url'))
								.required(translate('consultant_error_pdf_link_required'));
						} else if (preferredOption === 'PDF' && dataType === 'PDF_UPLOAD') {
							return Yup.mixed().required(translate('consultant_error_pdf_required'));
						}
					}),
				title: Yup.string().when(['preferredOption'], (preferredOption) => {
					if (preferredOption === 'VIDEO' || preferredOption === 'AUDIO' || preferredOption === 'PDF') {
						return Yup.string()
							.min(3, 'Title is very Short')
							.max(500, 'Title is very Long')
							.required('Title is required');
					}
				}),
				// content: Yup.object().when(['preferredOption'], (preferredOption) => {
				// 	if (preferredOption !== 'PDF') {
				// 		return Yup.object().shape().required(translate('Content is required')).nullable();
				// 	}
				// }),
			})
		),
		benefits: Yup.object().shape().required(translate('consultant_error_benefits_required')).nullable(),
		amount: isMonotizationPaid && Yup.string().required(translate('consultant_error_amount_required')),
		currencyId:
			isMonotizationPaid && Yup.object().shape().required(translate('consultant_error_currency_required')).nullable(),
		copyrightOwn: Yup.string().required(translate('consultant_error_copyrights_required')),
		details: Yup.mixed().when(['details_content_len'], (content_len) => {
			if (content_len === 0) {
				return Yup.string().required(translate('consultant_error_details_required'));
			}
			if (content_len < 80) {
				return Yup.number()
					.typeError('Too Short! Content needs to be at least 80 words.')
					.required('Too Short! Content needs to be at least 80 words.');
			}
			if (content_len > 3000) {
				return Yup.number()
					.typeError('Too Long! Content needs to be less than 3000 words.')
					.required('Too Long! Content needs to be less than 3000 words.');
			}
			return Yup.string().required(translate('consultant_error_details_required'));
		}),
		bestSuited: Yup.string().required(translate('consultant_error_bestsuited_required')),
		extensiveExperienceNecessary:
			isExtensiveExperienceNecessary && Yup.string().required(translate('common_please_select_option')),
		attachment: isAttachPDF && Yup.string().required(translate('consultant_error_pdf_required')),
	});

	const handleContent = async (values) => {
		setIsFormSubmitting(true);
		delete values.details_content_len;
		const selectedImage = values?.topSection?.filter((item) => item.selectedImageIndex === 0);
		let check = values?.topSection.some((item) => item.selectedImageIndex === 0);
		setCheckImage(check);

		const thumbnailData =
			selectedImage[0]?.imageType === 'IMAGE_UPLOAD'
				? selectedImage.map((elem) => {
						const imageObject = elem.imageUrl.filter((item) => item.type === 'horizontal')[0];

						let imageUrlType = '';
						let imageUrl = imageObject.url;
						if (imageObject.file) {
							imageUrl = imageObject.file;
							imageUrlType = 'file';
						}
						return { ...elem, imageUrl: imageUrl, imageUrlType };
				  })
				: selectedImage;

		let data = {
			...omit(values, 'subCategories'),
			language: values?.language?._id,
			thumbnail: thumbnailData,
			bestSuited: values?.bestSuited,
			donation: isDonation,
			currencyId: values?.preferredOption === 'Paid' ? values?.currencyId?._id : '62cbc02aa8ae0099a06cd197',
			amount: values?.preferredOption === 'Paid' ? values?.amount : '',
			categories: getSpecializationSubmitData(values, values.subCategories, 'categories', 'subCategories'),
			benefits: values?.benefits?._id,
			origin: values?.origin.map((item) => item?._id),
		};

		if (formType === 'update') {
			const newData = thumbnailData.map(({ _id, ...rest }) => rest);
			data = {
				...data,
				_id: get(contentData, '_id', ''),
				thumbnail: newData,
				topSection: map(get(values, 'topSection', []), (topSection) => {
					return omit(topSection, '_id');
				}),
				contentSection: map(get(values, 'contentSection', []), (contentSection) => {
					return omit(contentSection, 'mediaFile', '_id');
				}),
			};
		}

		const fileMap = {};

		if (typeof data.attachment !== 'string') {
			const id = uniqueId();
			fileMap[id] = data.attachment;
			data.attachmentType = 'file';
		}
		data.topSection.forEach((section) => {
			section.imageUrl.forEach((item) => {
				if (item.file) {
					const id = uniqueId();
					fileMap[id] = item.file;
					item.file = id;
				}
			});
		});
		data.thumbnail.forEach((item) => {
			if (item.imageUrlType === 'file') {
				const id = uniqueId();
				fileMap[id] = item.imageUrl;
				item.imageUrl = id;
				item.imageUrlType = 'file';
			}
		});
		data.contentSection.forEach((item) => {
			if (item.dataType === 'VIDEO_UPLOAD' || item.dataType === 'AUDIO_UPLOAD' || item.dataType === 'PDF_UPLOAD') {
				if (typeof item.data !== 'string') {
					const id = uniqueId();
					fileMap[id] = item.data;
					item.data = id;
					item.file = true;
				}
			}
		});

		const res = await axios.post('/consultant/content/add', { content: data });
		if (res.status === 200 && Array.isArray(res.data)) {
			const jobs = res.data
				.map((item) => {
					const file = fileMap[item.data];
					if (!file) return null;
					return { file, meta: item };
				})
				.filter(Boolean);

			const jobIds = addJob({
				payload: jobs,
				jobType: jobTypes.CONTENT_CREATE,
				title: `Uploading files for ${data.title}`,
				_id: uniqueId(),
			});
			setJobId(jobIds);
			navigate('/dashboard/content');
		}
	};

	useEffect(() => {
		dispatch(getSpecialization({ sectionName: 'SPECIALIZATION' }));
		dispatch(getBenefits());
		dispatch(getContentconfig({ sectionName: 'CONTENT' }));
	}, [dispatch]);

	const handleSwitchDonationToggleChange = (event) => {
		setDonation(event.target.checked);
	};

	useEffect(() => {
		dispatch(getCountryList());
	}, []);

	return (
		<Card>
			<CardContent>
				{specializationList.length > 0 && (
					<Formik
						initialValues={{
							title: get(contentData, 'title', ''),
							language:
								formType === 'update'
									? get(contentData, 'language', '')
									: isEmpty(defaultLanguage)
									? LanguageList[0]
									: defaultLanguage,
							categories: getInitSpecialization(contentData, specializationList, 'categories'),
							subCategories: getInitSubSpecialization(
								contentData,
								specializationList,
								'categories',
								'subCategories'
							),
							benefits: get(contentData, 'benefits', ''),
							topSection: get(contentData, 'topSection', [
								{
									imageType: 'IMAGE_UPLOAD',
									title: '',
									imageUrl: '',
									selectedImageIndex: 0,
								},
							]),
							contentSection: get(contentData, 'contentSection', [
								{
									preferredOption: 'VIDEO', //TEXT/IMAGE/VIDEO
									dataType: 'VIDEO_UPLOAD',
									data: '',
									title: '',
									content: '',
								},
							]),
							preferredOption:
								formType === 'update'
									? get(contentData, 'preferredOption', '')
									: get(contentData, 'preferredOption', 'Free'),
							currencyId: get(contentData, 'currencyId', ''),
							amount: get(contentData, 'amount', ''),
							copyrightOwn: get(contentData, 'copyrightOwn', ''),
							details: get(contentData, 'details', ''),
							bestSuited: get(contentData, 'bestSuited', ''),
							extensiveExperienceNecessary: get(contentData, 'extensiveExperienceNecessary', ''),
							attachment: get(contentData, 'attachment', ''),
							origin: get(contentData, 'origin', []),

							isPublished: get(contentData, 'isPublished', false),
							ratingStatus: get(contentData, 'ratingStatus', false),
							likeStatus: get(contentData, 'likeStatus', false),
							comments: get(contentData, 'comments', false),
						}}
						validationSchema={schemaObj}
						onSubmit={(values) => {
							handleContent(cloneDeep(values));
						}}
					>
						{(props) => (
							<form onSubmit={props.handleSubmit}>
								<Grid container spacing={2} justifyContent='center'>
									{isFormSubmitting && (
										<LoadingOverlay jobId={jobId} setIsFormSubmitting={setIsFormSubmitting} />
									)}
									<Grid item xs={12}>
										<FormControl fullWidth>
											<TextField
												label={translate('common_title')}
												name='title'
												fullWidth
												multiline
												value={props.values.title}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												error={props.errors.title && props.touched.title ? true : false}
												helperText={props.errors.title && props.touched.title ? props.errors.title : null}
											/>
										</FormControl>
									</Grid>

									<Grid item xs={12} md={6}>
										<FormControl fullWidth>
											<Autocomplete
												getOptionLabel={(option) => {
													return option.title ?? '';
												}}
												options={LanguageList}
												name='language'
												value={props.values.language}
												isOptionEqualToValue={(option, value) => {
													if (value === '' || option._id === value._id) {
														return true;
													}
												}}
												onChange={(_e, value) => {
													props.setFieldValue('language', value);
												}}
												onBlur={props.handleBlur}
												disablePortal
												autoHighlight
												disableClearable
												noOptionsText={translate('common_no_data')}
												popupIcon={<KeyboardArrowDownSharpIcon />}
												renderInput={(params) => (
													<TextField
														{...params}
														label={translate('common_language')}
														placeholder={translate('consultant_select_language')}
														name='language'
														error={props.errors.language && props.touched.language ? true : false}
														helperText={
															props.errors.language && props.touched.language
																? props.errors.language
																: null
														}
													/>
												)}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth>
											<Autocomplete
												getOptionLabel={(option) => {
													return option.title ?? '';
												}}
												options={benefitsList}
												name='benefits'
												value={props.values.benefits}
												isOptionEqualToValue={(option, value) => {
													if (value === '' || option?._id === value?._id) {
														return true;
													}
												}}
												onChange={(_e, value) => {
													props.setFieldValue('benefits', value);
												}}
												onBlur={props.handleBlur}
												disablePortal
												autoHighlight
												disableClearable
												noOptionsText={translate('common_no_data')}
												popupIcon={<KeyboardArrowDownSharpIcon />}
												renderInput={(params) => (
													<TextField
														{...params}
														label={translate('common_benefits')}
														placeholder={translate('consultant_select_benefits')}
														name='benefits'
														error={props.errors.benefits && props.touched.benefits ? true : false}
														helperText={
															props.errors.benefits && props.touched.benefits
																? props.errors.benefits
																: null
														}
													/>
												)}
											/>
										</FormControl>
									</Grid>

									<Grid item xs={12}>
										<MultiSelectSpecialization
											specialization={specializationList}
											fieldName='categories'
											subFieldName='subCategories'
											formProps={props}
											initialData={contentData}
											isSubmit={false}
										/>
									</Grid>

									{/* -----TOP SECTION----- */}
									<ContentTopSection
										formType={formType}
										formProps={props}
										checkImage={checkImage}
										setCheckImage={setCheckImage}
										isPreviewOpen={false}
									/>

									<Grid item xs={!isExtensiveExperienceNecessary ? 12 : 6}>
										<FormControl fullWidth>
											<Autocomplete
												getOptionLabel={(option) => {
													return option ?? '';
												}}
												options={Best_suitedArrList}
												name='bestSuited'
												value={props.values.bestSuited}
												isOptionEqualToValue={(option, value) => {
													if (value === '') {
														return true;
													}
												}}
												onChange={(_e, value) => {
													props.setFieldValue('bestSuited', value);
													value === Best_suitedArrList[3]
														? setIsExtensiveExperienceNecessary(true)
														: setIsExtensiveExperienceNecessary(false);

													Best_suitedArrList[3] !== value &&
														props.setFieldValue('extensiveExperienceNecessary', '');
												}}
												onBlur={props.handleBlur}
												disablePortal
												autoHighlight
												disableClearable
												noOptionsText={translate('common_no_data')}
												popupIcon={<KeyboardArrowDownSharpIcon />}
												renderInput={(params) => (
													<TextField
														{...params}
														label={translate('common_bestSuited')}
														placeholder={translate('consultant_best_suited_for_select')}
														name='bestSuited'
														error={props.errors.bestSuited && props.touched.bestSuited ? true : false}
														helperText={
															props.errors.bestSuited && props.touched.bestSuited
																? props.errors.bestSuited
																: null
														}
													/>
												)}
											/>
										</FormControl>
									</Grid>

									{(isExtensiveExperienceNecessary || props.values.extensiveExperienceNecessary !== '') && (
										<Grid item xs={6}>
											<FormControl fullWidth>
												<Autocomplete
													getOptionLabel={(option) => {
														return option ?? '';
													}}
													options={BEST_SUITED_EXTENSIVEEXPERIENCENECESSARY}
													name='extensiveExperienceNecessary'
													value={props.values.extensiveExperienceNecessary}
													isOptionEqualToValue={(option, value) => {
														if (value === '') {
															return true;
														}
													}}
													onChange={(_e, value) => {
														props.setFieldValue('extensiveExperienceNecessary', value);
													}}
													onBlur={props.handleBlur}
													disablePortal
													autoHighlight
													disableClearable
													noOptionsText={translate('common_no_data')}
													popupIcon={<KeyboardArrowDownSharpIcon />}
													renderInput={(params) => (
														<TextField
															{...params}
															placeholder={translate('select_extensive_experience_necessary')}
															name='extensiveExperienceNecessary'
															error={
																props.errors.extensiveExperienceNecessary &&
																props.touched.extensiveExperienceNecessary
																	? true
																	: false
															}
															helperText={
																props.errors.extensiveExperienceNecessary &&
																props.touched.extensiveExperienceNecessary
																	? props.errors.extensiveExperienceNecessary
																	: null
															}
														/>
													)}
												/>
											</FormControl>
										</Grid>
									)}

									<Grid item xs={12}>
										<FormControl fullWidth>
											<Autocomplete
												getOptionLabel={(option) => (option.name ? option.name : '')}
												name='origin'
												value={props.values.origin}
												options={countryList}
												isOptionEqualToValue={(option, value) => {
													if (value === '' || option._id === value._id) {
														return true;
													}
												}}
												onChange={(e, value) => {
													props.setFieldValue('origin', value);
												}}
												multiple
												autoHighlight
												disableClearable
												noOptionsText={translate('common_no_data')}
												popupIcon={<KeyboardArrowDownSharpIcon />}
												renderOption={(props, option) => (
													<Box component='li' sx={{ '& > img': { mr: 1, flexShrink: 0 } }} {...props}>
														<Box component='span' mr={1}>
															{option.flag}
														</Box>
														{option.name}
													</Box>
												)}
												renderInput={(params) => (
													<TextField
														{...params}
														label={translate('origin')}
														placeholder={translate('consultant_select_country')}
														name='origin'
														inputProps={{
															...params.inputProps,
															autoComplete: 'new-password',
														}}
														error={props.errors.origin && props.touched.origin ? true : false}
														helperText={
															props.errors.origin && props.touched.origin
																? props.errors.origin
																: null
														}
													/>
												)}
											/>
										</FormControl>
									</Grid>

									<Grid item xs={12}>
										<Divider orientation='horizontal' flexItem />
									</Grid>

									{/* -----CONTENT SECTION----- */}
									<Grid item xs={12}>
										<ConsultantContentSection formProps={props} contentConfigList={contentConfigList || []} />
									</Grid>
									<Grid item xs={12}>
										<FormControl fullWidth>
											<Autocomplete
												getOptionLabel={(option) => option ?? ''}
												options={COPYRIGHT_OWN}
												name='copyrightOwn'
												value={props.values.copyrightOwn}
												onChange={(_e, value) => {
													props.setFieldValue('copyrightOwn', value);
													value === COPYRIGHT_OWN[1] ? setIsAttachPDF(true) : setIsAttachPDF(false);

													COPYRIGHT_OWN[1] !== value && props.setFieldValue('attachment', '');
												}}
												onBlur={props.handleBlur}
												disablePortal
												autoHighlight
												disableClearable
												noOptionsText={translate('common_no_data')}
												popupIcon={<KeyboardArrowDownSharpIcon />}
												renderInput={(params) => (
													<TextField
														{...params}
														label={translate('common_copyright_own')}
														placeholder={translate('common_select_copyright_own')}
														name='copyrightOwn'
														error={
															props.errors.copyrightOwn && props.touched.copyrightOwn ? true : false
														}
														helperText={
															props.errors.copyrightOwn && props.touched.copyrightOwn
																? props.errors.copyrightOwn
																: null
														}
													/>
												)}
											/>
										</FormControl>
									</Grid>
									{/* <Grid item xs={6}>
										<ContentMonetization formProps={props} setIsMonitizationPaid={setIsMonitizationPaid} />
									</Grid> */}

									{isAttachPDF && (
										<Grid item xs={12}>
											<PDFPicker
												label={translate('common_pdf_upload')}
												id='data'
												name={'attachment'}
												value={props?.values?.attachment}
												onChange={(file) => props.setFieldValue('attachment', file)}
												error={props.errors.attachment && props.touched.attachment ? true : false}
												helperText={
													props.errors.attachment && props.touched.attachment
														? props.errors.attachment
														: null
												}
												dropBoxGridSize={6}
												previewGridSize={6}
												noPreviewImageBaseURL={true}
											/>
										</Grid>
									)}

									<Grid item xs={12}>
										<Divider orientation='horizontal' flexItem />
									</Grid>

									<Grid item xs={12}>
										<FormControl fullWidth>
											<FormLabel className='mb-2'>{translate('consultant_description')}</FormLabel>
											<RichTextEditor
												placeholder='Type Something'
												name='details'
												value={props.values?.details}
												setFieldValue={props.setFieldValue}
												error={props.errors.details && props.touched.details ? true : false}
												helperText={
													props.errors.details && props.touched.details ? props.errors.details : null
												}
											/>
										</FormControl>
									</Grid>

									<Grid item xs={12} className='donation-design'>
										<Typography variant='subtitle1'>{translate('Donation')}</Typography>
										<SwitchToggle
											checked={isDonation}
											onChange={handleSwitchDonationToggleChange}
											inputProps={{
												'aria-label': 'recording-status',
												role: 'switch',
											}}
										/>
									</Grid>
								</Grid>
								<Stack direction='row' gap={1} mt={2}>
									<Button variant='outlined' onClick={() => navigate('/dashboard/content')}>
										{translate('common_cancel')}
									</Button>
									{!isContentCreationPending && (
										<Button variant='contained' type='submit' disabled={isFormSubmitting}>
											{isFormSubmitting ? 'Saving' : 'Save'}
										</Button>
									)}
								</Stack>
							</form>
						)}
					</Formik>
				)}
			</CardContent>
		</Card>
	);
};

export default ContentForm;
