import AxiosDefault from '../../services/AxiosDefault';

export const GetEventBlog = async (data) => {
	const response = await AxiosDefault({
		url: '/consultant/event/get',
		method: 'POST',
		data: data,
	});
	return response;
};

export const EventArchive = async ({ payload, id }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/consultant/article-blog/archive/${id}`,
		data: payload,
	});
	return response;
};

export const EventPublish = async ({ payload, id }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/consultant/event/publish/${id}`,
		data: payload,
	});
	return response;
};

export const EventDelete = async (id) => {
	const response = await AxiosDefault({
		method: 'DELETE',
		url: `/consultant/event/delete/${id}`,
	});
	return response;
};

export const EventImageUpload = async (data) => {
	const response = await AxiosDefault({
		method: 'POST',
		url: `/consultant/event/image/upload`,
		data: data,
		contentType: 'multipart/form-data',
	});
	return response;
};
