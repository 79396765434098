import React from 'react';
import store from './store';
import { getJobWorkerFn } from './config';
import { Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FaUpload } from 'react-icons/fa';
import { isEmpty, omit } from 'lodash';
import { slotProps } from './JobQueueContainer.style';
import JobListItem from './components/JobListItem';
import reduxStore from './../../../store/Store';
import { addBackgroundJobId, removeBackgroundAllJob, removeBackgroundJobId } from '../../../store/slice/common';
import { socket } from '../../../socket';
import EventSubject from '../../../services/EventSubject';

const CLEAR_TIME_OUT = 2 * 1000;

const CustomIconButton = styled(IconButton)(({ theme }) => ({
	background: theme.palette.primary.main,
	padding: '16px',
	color: 'white',
	'&:hover': {
		background: theme.palette.primary.main,
		color: 'white',
	},
}));

class JobQueueContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = { jobQueue: [], isPopupOpen: false };
		this.anchorEl = null;
		this.timer = null;

		this.handlePopupClose = this.handlePopupClose.bind(this);
		this.handlePopupToggle = this.handlePopupToggle.bind(this);
		this.updateJob = this.updateJob.bind(this);
		this.remove = this.remove.bind(this);
		this.autoRemove = this.autoRemove.bind(this);
	}

	componentDidMount() {
		this.anchorEl = document.getElementById('job-btn');
		store.register({
			addJob: this.add,
			removeJob: this.remove,
			removeAllJob: this.removeAll,
		});
		socket.on('update', (data) => {
			EventSubject.next(data);
		});
	}

	autoRemove = async () => {
		if (this.timer) return;

		this.timer = setTimeout(() => {
			this.timer = null;

			this.setState((prevState) => {
				return {
					...omit(prevState, 'jobQueue'),
					jobQueue: prevState.jobQueue.filter((e) => {
						const currentTime = new Date().getTime();
						const diff = currentTime - e.createdTime;
						if (diff < CLEAR_TIME_OUT) return false;

						if (e.completed) {
							reduxStore.dispatch(removeBackgroundJobId({ id: isEmpty(e._id) ? e.id : e._id }));
							return false;
						}
						return true;
					}),
				};
			});
		}, CLEAR_TIME_OUT);
	};

	updateJob = (jobId, payload) => {
		this.setState((prev) => {
			const jobIndex = prev.jobQueue.findIndex((e) => e.id === jobId);
			if (jobIndex === -1) return prev;

			const newJobQueue = [...prev.jobQueue];
			newJobQueue[jobIndex] = { ...newJobQueue[jobIndex], ...payload };

			return {
				...prev,
				jobQueue: newJobQueue,
			};
		});

		// add auto remove on complete
		this.autoRemove();
	};

	runJob = async (newJob) => {
		const workerFn = getJobWorkerFn(newJob.jobType);
		workerFn(newJob.payload, newJob.id, this.updateJob);
	};

	add = (newJob) => {
		this.setState(({ jobQueue, ...rest }) => ({
			...rest,
			jobQueue: [...jobQueue, omit(newJob, 'payload')],
		}));
		this.runJob(newJob);
		reduxStore.dispatch(addBackgroundJobId({ id: isEmpty(newJob._id) ? newJob.id : newJob._id, value: true }));

		return newJob.id;
	};

	remove = (id) => {
		reduxStore.dispatch(removeBackgroundJobId({ id }));
		this.setState(({ jobQueue, ...rest }) => {
			const newJobQueue = jobQueue.filter((job) => job.id !== id);
			return { ...rest, jobQueue: newJobQueue };
		});
	};

	removeAll = () => {
		reduxStore.dispatch(removeBackgroundAllJob());
		this.setState((prev) => ({ ...prev, jobQueue: [] }));
	};

	handlePopupClose = () => {
		this.setState((prev) => ({ ...prev, isPopupOpen: false }));
	};

	handlePopupToggle = () => {
		this.anchorEl = document.getElementById('job-btn');
		this.setState((prev) => ({ ...prev, isPopupOpen: !prev.isPopupOpen }));
	};

	render() {
		if (!this.state.jobQueue.length) return <></>;
		return (
			<Box zIndex={999999} position='fixed' bottom={8} right={16}>
				<Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
					<Tooltip title=''>
						<CustomIconButton onClick={this.handlePopupToggle} size='small' aria-haspopup='true' id='job-btn'>
							<FaUpload fontSize={24} />
						</CustomIconButton>
					</Tooltip>
				</Box>
				<Menu
					anchorEl={this.anchorEl}
					open={this.state.isPopupOpen}
					onClose={this.handlePopupClose}
					transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
					slotProps={slotProps}
				>
					{this.state.jobQueue.map((job) => (
						<MenuItem sx={{ px: 0 }} disableRipple key={job.id}>
							<JobListItem data={job} removeJob={this.remove} />
						</MenuItem>
					))}
				</Menu>
			</Box>
		);
	}
}

export default JobQueueContainer;
