import axios from 'axios';
import EventSubject from '../../../../../services/EventSubject';

const uploadImage = async (data, id, updateJob, totalSize, completedSize) => {
	const formData = new FormData();
	formData.append('file', data.file, `file.${data.file.type?.split('/').pop()}`);
	const content = {
		contentId: data.meta.contentId,
		logID: data.meta.logID,
		type: data.meta.type,
		subSection: data.meta.subSection,
		imageType: data.meta.imageType,
		sectionType: data.meta.sectionType,
	};
	formData.append('content', JSON.stringify(content));

	const response = await axios.post('/consultant/upload', formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		onUploadProgress: (progressEvent) => {
			const percentCompleted = Math.round(((completedSize + progressEvent.loaded) * 100) / totalSize);
			const value = Math.min(percentCompleted, 100);
			const content = `Uploading files: ${value}%`;

			EventSubject.next({ id, content, value });
			updateJob(id, { value, content });
		},
	});

	return response;
};

export default uploadImage;
