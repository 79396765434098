import AxiosDefault from "../../services/AxiosDefault";

export const PostServicePlanAdd = async (data) => {
  const response = await AxiosDefault({
    url: "/consultant/consultation/service-plan/add-update",
    method: "POST",
    data: data,
  });
  return response;
};

export const GetServicePlan = async () => {
  const response = await AxiosDefault({
    url: "/consultant/consultation/service-plan/get",
    method: "GET",
  });
  return response;
};

export const DeleteServicePlan = async (id) => {
  const response = await AxiosDefault({
    url: `/consultant/consultation/service-plan/delete`,
    method: "DELETE",
    data: { isDeleted: true, _id: id },
  });
  return response;
};

export const ServicePlanStatus = async (data) => {
  const response = await AxiosDefault({
    url: `/consultant/consultation/service-plan/status-update`,
    method: "PATCH",
    data: data,
  });
  return response;
};

export const GetServiceType = async () => {
  const response = await AxiosDefault({
    url: "/consultant/consultation/services/type",
    method: "GET",
  });
  return response;
};

export const PostRefundPolicyAdd = async (data) => {
  const response = await AxiosDefault({
    url: "/consultant/consultation/term-condition/add-update",
    method: "POST",
    data: data,
  });
  return response;
};

export const GetRefundPolicy = async () => {
  const response = await AxiosDefault({
    url: "/consultant/consultation/term-condition/get",
    method: "GET",
  });
  return response;
};

export const ChangeServicePlanTypeStatus = async (data) => {
  const response = await AxiosDefault({
    url: `/consultant/consultation/services/mode-update`,
    method: "PATCH",
    data: data,
  });
  return response;
};

export const ServicePlanPDFDownload = async () => {
  const response = await AxiosDefault({
    url: "/consultant/consultation/service-plan/download-pdf",
    method: "GET",
  });
  return response;
};

