import React from 'react';

import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useNavigate } from 'react-router-dom';

import { Autocomplete, Chip } from '@mui/material';
import translate from '../../../hooks/translate';
import CMToolTip from '../../../ui-components/tooltip/CMToolTip';

const SeoSection = (props) => {
	const { formProps, isContentCreationPending, isFormSubmitting } = props;
	const navigate = useNavigate();

	return (
		<>
			<Grid item xs={12}>
				<Stack direction='row' className='gap-1'>
					<Typography variant='h4' className='mb-2'>
						{translate('consultant_seo_section')}
					</Typography>
					<CMToolTip
						icon={<HelpTwoToneIcon size='small' />}
						title=' Add optional SEO information to enhance outreach and visibility.'
					/>
				</Stack>
			</Grid>
			<Grid item xs={12}>
				<TextField
					label={`${translate('common_title')} (Optional)`}
					name='seoTitle'
					multiline
					value={formProps.values.seoTitle}
					onChange={formProps.handleChange}
					onBlur={formProps.handleBlur}
					error={formProps.errors.seoTitle && formProps.touched.seoTitle ? true : false}
					helperText={formProps.errors.seoTitle && formProps.touched.seoTitle ? formProps.errors.seoTitle : null}
				/>
			</Grid>

			<Grid item xs={12}>
				<TextField
					label={`${translate('consultant_description')} (Optional)`}
					name='seoDescription'
					multiline
					rows={4}
					value={formProps.values.seoDescription}
					onChange={formProps.handleChange}
					onBlur={formProps.handleBlur}
					error={formProps.errors.seoDescription && formProps.touched.seoDescription ? true : false}
					helperText={
						formProps.errors.seoDescription && formProps.touched.seoDescription
							? formProps.errors.seoDescription
							: null
					}
				/>
			</Grid>

			<Grid item xs={12}>
				<Autocomplete
					options={[]}
					name='seoMetaTags'
					value={formProps.values.seoMetaTags}
					onChange={(e, value) => {
						console.log('value :>> ', value);
						formProps.setFieldValue('seoMetaTags', value);
					}}
					onBlur={formProps.handleBlur}
					multiple
					freeSolo
					renderTags={(value, getTagProps) =>
						value.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)
					}
					renderInput={(params) => (
						<TextField
							{...params}
							label={`${translate('consultant_meta_tags')} (Optional)`}
							error={formProps.errors.seoMetaTags && formProps.touched.seoMetaTags ? true : false}
							helperText={
								formProps.errors.seoMetaTags && formProps.touched.seoMetaTags
									? formProps.errors.seoMetaTags
									: null
							}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Stack direction='row' spacing={1}>
					<Button variant='outlined' onClick={() => navigate('/dashboard/article-blog')}>
						{translate('common_cancel')}
					</Button>
					{!isContentCreationPending && (
						<Button variant='contained' type='submit' disabled={isFormSubmitting}>
							{isFormSubmitting ? 'Saving' : 'Save'}
						</Button>
					)}
				</Stack>
			</Grid>
		</>
	);
};

export default SeoSection;
