import React, { useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';

const CustomPagination = (props) => {
	const { setFilterOptions, filterOptions, getArticleData } = props;

	const TotalArticle = useSelector((state) => state.articleAndBlog.totalArticle);
	var TotalPages = Math.ceil(TotalArticle / 8);

	const [currentPageNo, setCurrentPageNo] = useState(1);

	const handleChange = (event, value) => {
		setFilterOptions({ ...filterOptions, pageNo: value - 1 });
		getArticleData({ ...filterOptions, pageNo: value - 1 });
		setCurrentPageNo(value);
	};

	return (
		<Stack spacing={2} my={3}>
			<Pagination
				count={TotalPages}
				color='primary'
				style={{ margin: 'auto' }}
				page={currentPageNo}
				onChange={handleChange}
			/>
		</Stack>
	);
};

export default CustomPagination;
