import React, { useEffect, useState } from 'react';

import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { alpha, styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';

import { isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, NavLink, useNavigate } from 'react-router-dom';

import Logo from '../../../assets/images/logo/logo.png';

import translate from '../../../components/hooks/translate';
import { getMeInfo } from '../../../store/slice/auth/authentication/authSlice';
import ComponentStyle from '../../../styles/ComponentStyle.module.css';
import AccountPopover from '../../components/AccountPopover';
import AppDrawer from './AppDrawer';
import HeaderConfig, { menuOptions } from './HeaderConfig';

const HeaderMain = (props) => {
	const { window, headerRef } = props;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

	const [mobileOpen, setMobileOpen] = useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	useEffect(() => {
		isLoggedIn && dispatch(getMeInfo());
	}, [dispatch, isLoggedIn]);

	const AppBar = styled(MuiAppBar)(({ theme }) => ({
		boxShadow: 'none',
		backdropFilter: 'blur(6px)',
		WebkitBackdropFilter: 'blur(6px)',
		backgroundColor: alpha(theme.palette.background.default, 0.72),
	}));

	return (
		<>
			<AppBar position='fixed' ref={headerRef}>
				<Toolbar>
					<RouterLink to='/'>
						<Box
							component='img'
							src={Logo}
							alt='logo'
							sx={{
								maxWidth: { sm: '30px', xs: '30px' },
								marginTop: '11px',
								marginBottom: '11px',
								marginLeft: '12px',
							}}
						/>
					</RouterLink>

					<Stack direction='row' sx={{ flexGrow: 1 }} justifyContent='end' alignItems='center'>
						{!isEmpty(HeaderConfig) ? (
							<Box sx={{ display: { md: 'none' } }}>
								<IconButton aria-label='open drawer' edge='start' onClick={handleDrawerToggle} sx={{ mr: 2 }}>
									<MenuOpenIcon />
								</IconButton>
							</Box>
						) : null}

						<Box className='me-3' sx={{ display: { md: 'block', xs: 'none' } }}>
							<MenuList className='d-flex gap-0 align-items-center'>
								{map(HeaderConfig, (page, index) => {
									return (
										<NavLink to={page?.path} key={index}>
											{({ isActive }) => (
												<MenuItem
													sx={{
														color: isActive ? 'primary.main' : 'grey.600',
														fontWeight: 500,
													}}
												>
													{page.title}
												</MenuItem>
											)}
										</NavLink>
									);
								})}
							</MenuList>
						</Box>

						{isLoggedIn ? (
							<AccountPopover menuOptions={menuOptions} />
						) : (
							<>
								<Button
									variant='contained'
									color='primary'
									onClick={() => {
										navigate('/signup');
									}}
									className={ComponentStyle.header_btn}
									sx={{
										mr: 1,
									}}
								>
									{translate('common_sign_up')}
								</Button>

								<Button
									variant='outlined'
									color='primary'
									onClick={() => {
										navigate('/login');
									}}
									className={ComponentStyle.header_btn}
								>
									{translate('common_login')}
								</Button>
							</>
						)}
					</Stack>
				</Toolbar>
			</AppBar>
			<AppDrawer window={window} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
		</>
	);
};

export default HeaderMain;
