import React from 'react';

import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import translate from '../../../hooks/translate';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	width: '100%',
	maxWidth: '700px',
	margin: 'auto',
	borderRadius: theme.spacing(2),
	paddingLeft: theme.spacing(2.5),
	border: `2px solid ${theme.palette.grey[500]}`,
	animation: 'all 0.3s ease-in-out',
	'& .MuiInputBase-input': {
		minHeight: theme.spacing(4),
	},
	'& .MuiInputAdornment-root': {
		minHeight: theme.spacing(5),
		borderLeft: `2px solid ${theme.palette.grey[500]}`,
	},
	'& .MuiIconButton-root': {
		padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
		borderRadius: '0 50% 50% 0',
		'& .MuiTouchRipple-child': {
			borderRadius: '0 50% 50% 0',
		},
	},
	'&.Mui-focused': {
		borderColor: theme.palette.secondary.main,
		'& .MuiInputAdornment-root': {
			borderColor: theme.palette.secondary.main,
		},
	},
}));

const SearchBar = (props) => {
	const { setFilterOptions, filterOptions, getContentData } = props;

	const articleBlogLoading = useSelector((state) => state.articleAndBlog.loading);

	return (
		<Formik
			initialValues={{
				search: '',
			}}
			onSubmit={(values) => {
				setFilterOptions({ ...filterOptions, pageNo: 0, ...values });
				getContentData({ ...filterOptions, pageNo: 0, ...values });
			}}
		>
			{(props) => (
				<form onSubmit={props.handleSubmit} className='text-center'>
					<StyledInputBase
						placeholder={translate('common_search')}
						name='search'
						value={props.values.search}
						onChange={props.handleChange}
						fullWidth={true}
						inputProps={{ 'aria-label': 'search articles' }}
						endAdornment={
							<InputAdornment position='end'>
								{props.values.search && (
									<IconButton
										color='secondary'
										size='medium'
										onClick={() => {
											props.setFieldValue('search', '');
											setFilterOptions({
												...filterOptions,
												pageNo: 0,
												search: '',
											});
											getContentData({
												...filterOptions,
												pageNo: 0,
												search: '',
											});
										}}
									>
										<ClearIcon fontSize='inherit' />
									</IconButton>
								)}

								<IconButton
									color='secondary'
									size='medium'
									aria-label='search article button'
									type='submit'
									className='border-left'
									disabled={props.isSubmitting && articleBlogLoading}
								>
									<SearchIcon fontSize='medium' />
								</IconButton>
							</InputAdornment>
						}
					/>
				</form>
			)}
		</Formik>
	);
};

export default SearchBar;
