import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { isNumber } from 'lodash';
import { alpha } from '@mui/material';

const getProgress = (processCompleted, totalProcessCount) => {
	if (!processCompleted || !totalProcessCount) return 0;

	const percentage = parseInt((processCompleted / totalProcessCount) * 100);
	if (isNumber(percentage)) return percentage;
	return 0;
};
const CardProgressLoadingOverlay = ({ inBackgroundJob, isPending, processCompleted, totalProcessCount }) => {
	const progress = getProgress(processCompleted, totalProcessCount);
	if (!inBackgroundJob && !isPending) return <></>;
	return (
		<Box
			position='absolute'
			top={0}
			left={0}
			right={0}
			bottom={0}
			display='flex'
			justifyContent='center'
			alignItems='center'
			sx={{ backdropFilter: 'blur(1px)', bgcolor: ({ palette }) => alpha(palette.primary.main, 0.3) }}
			zIndex={99999}
		>
			<Box
				width='100%'
				maxWidth='650px'
				bgcolor='white'
				padding='10px'
				display='flex'
				justifyContent='center'
				alignItems='center'
				flexDirection='column'
				gap={1}
			>
				<Typography variant='h5' fontWeight='bold'>
					{inBackgroundJob ? 'Saving...' : `Processing... ${progress}%`}
				</Typography>
			</Box>
		</Box>
	);
};

export default CardProgressLoadingOverlay;
