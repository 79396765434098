import React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { Outlet } from 'react-router-dom';
import PageHeader from '../../components/common/PageHeader';
import Page from '../../components/common/Page';

const ContentPage = () => {
	return (
		<>
			<Page title='Contents | InspireMind'>
				<Box component='section' className='w-100'>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<PageHeader title='common_content' variant='outlined' elevation={0} />
						</Grid>
						<Grid item xs={12}>
							<Outlet />
						</Grid>
					</Grid>
				</Box>
			</Page>
		</>
	);
};

export default ContentPage;
