import React, { useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';

const ContentCustomPagination = (props) => {
	const { setFilterOptions, filterOptions, getContentData } = props;

	const TotalArticle = useSelector((state) => state.articleAndBlog.totalArticle);
	const TotalPages = Math.ceil(TotalArticle / 8);

	const [currentPageNo, setCurrentPageNo] = useState(1);

	const handleChange = (event, value) => {
		setFilterOptions({ ...filterOptions, pageNo: value - 1 });
		getContentData({ ...filterOptions, pageNo: value - 1 });
		setCurrentPageNo(value);
	};

	return (
		<Stack spacing={2} my={3}>
			<Pagination count={TotalPages} color='primary' sx={{ margin: 'auto' }} page={currentPageNo} onChange={handleChange} />
		</Stack>
	);
};

export default ContentCustomPagination;
