import { get } from 'lodash';
import React, { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import { ButtonBox } from '../../component/FilePickerComponents';
import { renderFileStyle } from '../FilePickerAppointmentPreview.styles';
import ImageViewRenderer from './ImageViewRenderer';
import PdfViewRenderer from './PdfViewRenderer';

function RenderFile({ previewFileSize, handleRemoveFile, file, filePreviewSize, theme, previewImageStyle, fileLabel }) {
	useEffect(() => {
		return () => {
			if (file?.preview) {
				URL.revokeObjectURL(file.preview);
			}
		};
	}, [file?.preview]);

	return (
		<Grid item sm={previewFileSize ? previewFileSize : 12} xs={12} key={get(file, 'path')}>
			<ListItem disablePadding sx={renderFileStyle.ListItem} className='position-relative border'>
				<ButtonBox className='position-absolute zIndex-fixed'>
					<IconButton
						aria-label='Remove Item'
						size='small'
						onClick={() => handleRemoveFile(file)}
						sx={renderFileStyle.IconButton}
					>
						<CloseIcon fontSize='small' />
					</IconButton>
				</ButtonBox>
				{file?.type === 'application/pdf' ? (
					<PdfViewRenderer
						file={file}
						filePreviewSize={filePreviewSize}
						previewImageStyle={previewImageStyle}
						theme={theme}
						fileLabel={fileLabel}
					/>
				) : (
					<ImageViewRenderer
						file={file}
						filePreviewSize={filePreviewSize}
						previewImageStyle={previewImageStyle}
						theme={theme}
						fileLabel={fileLabel}
					/>
				)}
			</ListItem>
		</Grid>
	);
}

export default RenderFile;
