import React from "react";

import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const ArticleCardSkeleton = () => {
  return (
    <>
      <Paper variant="outlined" className="w-100">
        <CardContent>
          <Skeleton animation="wave" variant="rectangular" height={150} />
          <Skeleton animation="wave" width={160} height={20} />
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={7} />
          <Skeleton animation="wave" height={7} />
          <Skeleton animation="wave" height={7} />

          <Stack direction="row" spacing={2}>
            <Skeleton
              animation="wave"
              width={70}
              height={40}
              sx={{ borderRadius: "10px" }}
            />
            <Skeleton
              animation="wave"
              width={70}
              height={40}
              sx={{ borderRadius: "10px" }}
            />
            <Skeleton
              animation="wave"
              width={70}
              height={40}
              sx={{ borderRadius: "10px" }}
            />
          </Stack>
          <Skeleton
            animation="wave"
            width={80}
            height={20}
            sx={{ marginTop: "1rem" }}
          />
        </CardContent>
      </Paper>
    </>
  );
};

export default ArticleCardSkeleton;
