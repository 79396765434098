/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import { DialogActions, DialogContent, Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Drawer } from '@mui/material';
import { Formik } from 'formik';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import CMTextButton from '../../../../../components/ui-components/buttons/CMTextButton';
import { EventEmitter } from '../../../../../services/EventEmitter';
import { getConsultantInfo, postAddSocialMediaLinks } from '../../../../../store/slice/auth/signup/consultantInfoSlice';
import translate from '../../../../hooks/translate';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';

const AddSocialMediaLinksModal = (props) => {
	const { showButton, socialMediaInfo, onClose } = props;
	const [open, setOpen] = useState(false);
	const [data, setData] = useState();
	const dispatch = useDispatch();
	const [socialMediaPlatform, setSocialMediaPlatform] = useState('');
	const [isLinkInput, setIsLinkInput] = useState('');

	const socialMediaChanelList = useSelector((state) => state.common.socialMediaChanelList);

	let _socialMediaPlatform = [];
	_socialMediaPlatform =
		socialMediaChanelList?.length > 0 &&
		socialMediaChanelList?.filter((el) => {
			return !socialMediaInfo?.find((element) => {
				return element?.socialMediaChanel === el?.title;
			});
		});

	useEffect(() => {
		EventEmitter.subscribe('socialMediaInfo', async (data) => {
			setData(data);
			setOpen(true);
		});
		EventEmitter.subscribe('addProfileSocialLinks', () => {
			setOpen(true);
		});
	}, []);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setData({});
		setOpen(false);
	};

	const isInputLink = isLinkInput.includes('https://');

	const schemaObj = Yup.object().shape({
		socialMediaChanel: Yup.string().min(2, 'Social Media Chanel is required!').required('Social Media Chanel is required!'),
		chanelLink: Yup.string()
			.matches(
				isInputLink
					? /^(?=.{4,2048}$)((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]{1,63}(\.[a-zA-Z]{1,63}){1,5}(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/
					: '',
				'Enter correct url!'
			)
			.required('consultant_error_links_required'),
	});

	const handleAddSocialLinks = async (payload) => {
		const chanelLink = payload?.chanelLink;
		const socialMediaChanel = payload?.socialMediaChanel;
		const isLink = chanelLink.includes('https://');

		let channel;
		if (socialMediaChanel === 'Twitter') {
			channel = isLink ? chanelLink : `https://twitter.com/${chanelLink}`;
		} else if (socialMediaChanel === 'Facebook') {
			channel = isLink ? chanelLink : `https://www.facebook.com/${chanelLink}`;
		} else if (socialMediaChanel === 'Instagram') {
			channel = isLink ? chanelLink : `https://www.instagram.com/${chanelLink}`;
		} else if (socialMediaChanel === 'LinkedIn') {
			channel = isLink ? chanelLink : '';
		} else {
			channel = socialMediaChanel === 'YouTube' && isLink ? chanelLink : '';
		}

		const _payload = {
			chanelLink: channel,
			socialMediaChanel: socialMediaChanel,
		};

		if (data) {
			_payload['_id'] = data._id;
		}

		const res = await dispatch(postAddSocialMediaLinks(_payload))
			.unwrap()
			.then((result) => {
				if (result.status) {
					dispatch(getConsultantInfo());
					handleClose();
				}
			})
			.catch((err) => {
				console.log(' postAddPublicationLinks err', err);
			});
		return res;
	};

	return (
		<>
			{showButton && (
				<CMTextButton onClick={handleClickOpen} startIcon={<AddCircleOutlineOutlinedIcon />} color='primary'>
					{translate('consultant_add_social_media_links')}
				</CMTextButton>
			)}
			<Drawer anchor='right' open={open} onClose={onClose} className='university-details-design'>
				<div>
					<Stack
						direction='row'
						gap={1}
						py={2}
						px={{ xs: 2, md: 3 }}
						alignItems='center'
						justifyContent='space-between'
					>
						<IconButton size='small' color='primary' onClick={handleClose} sx={{ minWidth: 0 }}>
							<KeyboardArrowLeftRoundedIcon />
						</IconButton>

						<Typography variant='h6'>{translate('consultant_add_social_media_links')}</Typography>
					</Stack>
					<Divider sx={{ borderStyle: 'dashed' }} />
				</div>
				{_socialMediaPlatform && (
					<Formik
						initialValues={{
							socialMediaChanel: get(data, 'socialMediaChanel', ''),
							chanelLink: get(data, 'chanelLink', ''),
						}}
						validationSchema={schemaObj}
						onSubmit={(values, { resetForm }) => {
							const payload = {
								socialMediaChanel: get(values, 'socialMediaChanel', ''),
								chanelLink: get(values, 'chanelLink', ''),
							};
							handleAddSocialLinks(payload);
							resetForm();
						}}
					>
						{(props) => (
							<form onSubmit={props.handleSubmit} className='d-flex flex-column overflow-auto'>
								<DialogContent dividers sx={{ px: { xs: 2, md: 3 }, pt: 2 }}>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<Autocomplete
												options={_socialMediaPlatform || []}
												getOptionLabel={(option) => option.title ?? option}
												name='socialMediaChanel'
												value={props.values.socialMediaChanel}
												isOptionEqualToValue={(option, value) => {
													return option.title === value;
												}}
												onChange={(e, value) => {
													props.setFieldValue('socialMediaChanel', value.title);
													setSocialMediaPlatform(value.title);
												}}
												autoHighlight
												disableClearable
												noOptionsText={translate('common_no_data')}
												popupIcon={<KeyboardArrowDownSharpIcon />}
												renderOption={(props, option) => (
													<Box
														key={option._id}
														component='li'
														sx={{ '& > img': { mr: 1, flexShrink: 0 } }}
														{...props}
													>
														{option.title}
													</Box>
												)}
												renderInput={(params) => (
													<TextField
														{...params}
														label={translate('consultant_social_media_channels')}
														name='socialMediaChanel'
														inputProps={{
															...params.inputProps,
															autoComplete: 'new-password',
														}}
														error={
															props.errors.socialMediaChanel && props.touched.socialMediaChanel
																? true
																: false
														}
														helperText={
															props.errors.socialMediaChanel && props.touched.socialMediaChanel
																? props.errors.socialMediaChanel
																: null
														}
													/>
												)}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												type={
													socialMediaPlatform === 'LinkedIn' || socialMediaPlatform === 'YouTube'
														? 'url'
														: 'text'
												}
												label={
													socialMediaPlatform === 'LinkedIn' || socialMediaPlatform === 'YouTube'
														? 'Links'
														: translate('consultant_username_or_links')
												}
												name='chanelLink'
												value={props.values.chanelLink}
												onChangeText={setIsLinkInput(props.values.chanelLink)}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												fullWidth
												error={props.errors.chanelLink && props.touched.chanelLink ? true : false}
												helperText={
													props.errors.chanelLink && props.touched.chanelLink
														? props.errors.chanelLink
														: null
												}
											/>
										</Grid>
									</Grid>
								</DialogContent>

								<DialogActions style={{ marginBottom: '10px', marginRight: '20px' }}>
									<Button variant='outlined' onClick={handleClose}>
										{translate('common_cancel')}
									</Button>
									<Button variant='contained' type='submit'>
										{translate('common_btn_submit')}
									</Button>
								</DialogActions>
							</form>
						)}
					</Formik>
				)}
			</Drawer>
		</>
	);
};

export default AddSocialMediaLinksModal;
