import React from "react";
import { map } from "lodash";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

import numToArray from "../../hooks/numToArray";

const QAndASkeleton = (props) => {
  const { skeletons, ...rest } = props;

  const arrayValue = skeletons ? numToArray(skeletons) : [1, 2, 3, 4, 5];

  return (
    <Box>
      {map(arrayValue, (item) => (
        <Stack
          direction="row"
          spacing={2}
          key={item}
          className="mb-3"
          {...rest}
        >
          <Box>
            <Stack direction="row" spacing={2} alignItems="center">
              <Skeleton
                variant="text"
                animation="wave"
                width="24px"
                height="40px"
              />
              <Skeleton
                variant="text"
                animation="wave"
                width="24px"
                height="20px"
              />
            </Stack>
          </Box>
          <Box className="w-100">
            <Box className="w-100">
              <Stack direction="row" spacing={2} alignItems="center">
                <Skeleton
                  variant="text"
                  animation="wave"
                  width="24px"
                  height="40px"
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  width="95%"
                  height="40px"
                />
              </Stack>
            </Box>
            <Box className="w-100">
              <Stack direction="row" spacing={2} alignItems="center">
                <Skeleton
                  variant="text"
                  animation="wave"
                  width="24px"
                  height="40px"
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  width="50%"
                  height="40px"
                />
              </Stack>
            </Box>
          </Box>
        </Stack>
      ))}
    </Box>
  );
};

export default QAndASkeleton;
