import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import CMCardHeader from "../../../../ui-components/cards/CMCardHeader";
import translate from "../../../../hooks/translate";
import ChatBox from "./chatBox";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import { get, isEmpty } from "lodash";
import ShortProfile from "../../../../../pages/short-profile/ShortProfile";
import { Formik } from "formik";
import { toast } from "react-toastify";
import CMDialog from "../../../../ui-components/dialog/CMDialog";
import FilePicker from "../../../../ui-components/file-picker/FilePicker";
import {
  getChatMessages,
  postFileUpload,
  postMessageAdd,
} from "../../../../../store/slice/dashboard/chatSlice";

import {
  Button,
  DialogActions,
  DialogContent,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import { useParams } from "react-router-dom";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import * as React from 'react';
import AttendMeeting from "../../AttendMeeting";

const BookingManagementPage = () => {
  const [open, setOpen] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(1);
  const params = useParams();
  const dispatch = useDispatch();
  const [value, setValue] = useState('1');
  const [attendMeeting, setAttendMeeting] = useState(false);

  const getChatMessagesData = useCallback(() => {
    setInterval(async () => {
      dispatch(getChatMessages(get(params, "bookingId", "")));
    }, 40000);
    dispatch(getChatMessages(get(params, "bookingId", "")));
  }, [dispatch, params]);

  useEffect(() => {
    getChatMessagesData();
  }, [getChatMessagesData]);

  const chatData = useSelector((state) => state.chat.chatMessages);

  const handleMessageAdd = async (data) => {
    const payload = {
      bookingId: get(params, "bookingId", ""),
      message: get(data, "message", ""),
    };
    dispatch(postMessageAdd(payload))
      .then((result) => {
        if (result.payload.status) {
          data.resetForm({ message: "" });
          getChatMessagesData();
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleFileUpload = async (data) => {
    setFileUploadLoading("0");
    const payload = {
      bookingId: get(params, "bookingId", "1"),
      file: get(data, "file", ""),
    };
    dispatch(postFileUpload(payload))
      .then((result) => {
        console.log("Result ===>", result);
        if (result.payload.status) {
          setFileUploadLoading("");
          setOpen(false);
          toast.success(result.payload.message);
          getChatMessagesData();
        } else {
          setFileUploadLoading("1");
          toast.error(result.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid id item md={8}>
        <Paper elevation={0} className="h-100">
          {/* <CMCardHeader title={translate("chat_window")} /> */}
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', display:'flex',justifyContent:'center' }} >
                <TabList onChange={handleChange} aria-label="lab API tabs example" >
                  <Tab className="pe-2" label={translate("chat_window")} value="1" />
                  <Tab className="pe-2" label="Meeting" value="2" sx={{margin: '0px 25px'}} />
                  <Tab className="pe-2" label="Pree Q&A" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <CardContent>
                  <Stack className="px-1" rowGap={2}>
                    <ChatBox chatData={chatData} />
                    <Stack direction="row" columnGap={1}>
                      <Formik
                        initialValues={{
                          message: "",
                        }}
                        onSubmit={(values) => {
                          console.log("values", values);
                        }}
                      >
                        {(props) => (
                          <form
                            onSubmit={props.handleSubmit}
                            className="rounded bg-primary-light p-2 d-flex gap-2 w-100"
                          >
                            <TextField
                              name="message"
                              value={props.values.message}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              fullWidth
                              size="small"
                              className="bg-white"
                              placeholder="Type here..."
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={handleClickOpen}>
                                      <DescriptionIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <CMDialog
                              dialogTitle={translate("Select Your File")}
                              dialogOpen={open}
                              maxWidth="md"
                              dialogClose={handleClose}
                            >
                              <DialogContent dividers>
                                <Grid item xs={12}>
                                  <FormLabel
                                    className="mb-1"
                                    sx={{ color: "text.textDark" }}
                                  ></FormLabel>
                                  <FilePicker
                                    className="mt-3"
                                    id="file"
                                    name="file"
                                    label={translate("Add File")}
                                    value={props.values.file}
                                    setFieldValue={props.setFieldValue}
                                    error={
                                      props.errors.file && props.touched.file
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      props.errors.file && props.touched.file
                                        ? props.errors.file
                                        : false
                                    }
                                    dropBoxGridSize={12}
                                    previewGridSize={12}
                                    loading={Boolean(fileUploadLoading === "0")}
                                  />
                                </Grid>
                              </DialogContent>
                              <DialogActions>
                                <Button variant="outlined" onClick={handleClose}>
                                  {translate("common_cancel")}
                                </Button>
                                <Button
                                  variant="contained"
                                  type="submit"
                                  onClick={() =>
                                    handleFileUpload({
                                      file: props.values.file,
                                    })
                                  }
                                >
                                  {translate("common_btn_submit")}
                                </Button>
                              </DialogActions>
                            </CMDialog>
                            <IconButton>
                              <SendRoundedIcon
                                type="submit"
                                color={
                                  isEmpty(props.values.message)
                                    ? "disabled"
                                    : "primary"
                                }
                                onClick={() =>
                                  handleMessageAdd({
                                    message: props.values.message,
                                    resetForm: props.resetForm,
                                  })
                                }
                                disabled={
                                  isEmpty(props.values.message) ? true : false
                                }
                              />
                            </IconButton>
                          </form>
                        )}
                      </Formik>
                    </Stack>
                  </Stack>
                </CardContent>
              </TabPanel>
              <TabPanel value="2">
                {
                  !attendMeeting &&
                  <Button
                    variant='contained'
                    onClick={(() => setAttendMeeting(true))}
                  >
                    Attend Meeting
                  </Button>
                }
                {
                  attendMeeting &&
                  <AttendMeeting bookingManagement={attendMeeting} />
                }
                {/* <CardContent className="border">
                  <Box>
                    <Stack className="px-1" rowGap={2}>
                    </Stack>
                  </Box>
                </CardContent> */}
              </TabPanel>
              <TabPanel value="3">Item Three</TabPanel>
            </TabContext>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={4}>
        <Paper elevation={0} className="h-100">
          {/* <CMCardHeader title={translate("booking_management")} /> */}
          <CardContent>
            <Stack className="px-1">
              <Box>
                {/* <ShortProfile/> */}
                <ShortProfile shortProfileData={""} type="chat" />
              </Box>
            </Stack>
          </CardContent>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default BookingManagementPage;
