import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import themeBuilder, { getThemeName } from './themeBuilder';
import { getSafeTheme, setLocalThemeMode } from './config/theme.service';
import { ThemeContext } from './config/themeContext';

export default function ThemeConfig({ children }) {
	const [themeMode, setThemeMode] = useState(getThemeName());

	const updateTheme = useCallback((newThemMode) => {
		const safeNewThemeMode = getSafeTheme(newThemMode);
		setLocalThemeMode(safeNewThemeMode);
		setThemeMode(safeNewThemeMode);
	}, []);

	const theme = useMemo(() => {
		const finalTHeme = themeBuilder();
		return finalTHeme;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [themeMode]);

	const memoizedValue = useMemo(() => ({ themeMode, updateTheme }), [themeMode, updateTheme]);

	return (
		<ThemeContext.Provider value={memoizedValue}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</ThemeContext.Provider>
	);
}

ThemeConfig.propTypes = {
	children: PropTypes.node,
};
