import AxiosDefaultSetting from '../../../../services/AxiosDefault';
import EventSubject from '../../../../services/EventSubject';

const url = process.env.REACT_APP_CHAT_END_POINT;
const create = async (data, jobId, updateJob) => {
	try {
		const response = await AxiosDefaultSetting({
			url: `${url}conversation-list/upload`,
			method: 'POST',
			data: data.form,
			contentType: 'multipart/form-data',
			onUploadProgress: function (progressEvent) {
				const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				const content = `Uploading: ${percentCompleted}%`;
				const value = parseInt(percentCompleted);

				EventSubject.next({
					id: jobId,
					messageId: data.payload.id,
					progress: value,
				});
				updateJob(jobId, {
					value,
					content,
				});
			},
		});
		const result = response.data;
		if (result) {
			EventSubject.next({
				id: jobId,
				value: 100,
				completed: true,
				type: 'chat_message',
				chatId: data.payload.chatId,
				messageId: data.payload.id,
				url: result,
			});
			updateJob(jobId, { value: 100, completed: true, content: result.message ?? 'Uploaded!' });
		} else {
			EventSubject.next({
				id: jobId,
				value: 100,
				completed: false,
				error: true,
				type: 'chat_message',
				chatId: data.payload.chatId,
				messageId: data.payload.id,
			});
			updateJob(jobId, { completed: false, error: true, content: result.message ?? 'Not Uploaded!' });
		}
	} catch (error) {
		EventSubject.next({
			id: jobId,
			value: 100,
			completed: false,
			error: true,
			type: 'chat_message',
			chatId: data.payload.chatId,
			messageId: data.payload.id,
		});
		updateJob(jobId, { completed: false, error: true, content: error.response?.data?.message ?? 'Not Uploaded!' });
	}
};

export default create;
