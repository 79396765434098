import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { getIn } from 'formik';
import translate from '../../../../hooks/translate';
import { generateSlots, validateStartTime } from '../helper';

function RenderInput({ params, hasError, handleBlur, errorText }) {
	return (
		<>
			<TextField {...params} size='small' onBlur={handleBlur} error={hasError} />
			{hasError && (
				<FormHelperText error sx={{ mt: 0 }}>
					{errorText}
				</FormHelperText>
			)}
		</>
	);
}

export default function AvailabilityTimeZoneForm({ index, subIndex, props, slots, setSlots, time, remove, item, disableForm }) {
	const startTime = `availability.${index}.time[${subIndex}].startTime`;
	const endTime = `availability.${index}.time[${subIndex}].endTime`;
	const startTimeError = getIn(props.errors, startTime);
	const startTimeTouched = getIn(props.touched, startTime);
	const endTimeError = getIn(props.errors, endTime);
	const endTimeTouched = getIn(props.touched, endTime);

	return (
		<Box>
			<Stack ml={5} direction='row' spacing={1} alignItems='center' justifyContent='space-between' mb={1}>
				<Box display='flex' direction='row' gap={1}>
					<Autocomplete
						options={slots}
						sx={{ width: '120px' }}
						name={startTime}
						value={time.startTime}
						getOptionLabel={(option) => option.id ?? option}
						onFocus={() => {
							setSlots(generateSlots(15, 'startTime', props.values.availability[index].time, subIndex));
						}}
						onChange={(_, value) => {
							props.setFieldValue(endTime, '');
							validateStartTime(props.values.availability[index].time, value, true, subIndex)
								? props.setFieldValue(startTime, value.id)
								: props.setFieldValue(startTime, '');
						}}
						onBlur={props.handleBlur}
						forcePopupIcon={false}
						autoHighlight={true}
						disableClearable={true}
						disabled={disableForm}
						autoComplete={true}
						noOptionsText={translate('common_no_data')}
						renderInput={(params) => (
							<RenderInput
								params={params}
								hasError={Boolean(startTimeTouched && startTimeError)}
								handleBlur={props.handleBlur}
								errorText={startTimeError}
							/>
						)}
					/>

					<Typography variant='body1' size='small' mt={1}>
						{translate('common_to')}
					</Typography>

					<Autocomplete
						options={slots}
						sx={{ width: '120px' }}
						name={endTime}
						value={time.endTime}
						getOptionLabel={(option) => option.id ?? option}
						onFocus={() => {
							setSlots(generateSlots(15, 'endTime', props.values.availability[index].time, subIndex));
						}}
						onChange={(_, value) => {
							validateStartTime(props.values.availability[index].time, value, false, subIndex)
								? props.setFieldValue(endTime, value.id)
								: props.setFieldValue(endTime, '');
						}}
						forcePopupIcon={false}
						autoHighlight={true}
						disableClearable={true}
						disabled={disableForm}
						autoComplete={true}
						noOptionsText={translate('common_no_data')}
						renderInput={(params) => (
							<RenderInput
								params={params}
								hasError={Boolean(endTimeTouched && endTimeError)}
								handleBlur={props.handleBlur}
								errorText={endTimeError}
							/>
						)}
					/>
				</Box>

				<IconButton aria-label='delete' disabled={disableForm || item.time.length === 1} onClick={() => remove(subIndex)}>
					<DeleteIcon />
				</IconButton>
			</Stack>
		</Box>
	);
}
