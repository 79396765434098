import AxiosDefault from '../services/AxiosDefault';

export const GetMeInfo = async () => {
	const response = await AxiosDefault({
		url: `/consultant/auth/me`,
		method: 'GET',
	});
	return response;
};

export const GetGeneralConfiguration = async ({ sectionName, level }) => {
	const levelNo = level ? level : 1;

	const response = await AxiosDefault({
		url: `/consultant/general-configuration?sectionName=${sectionName}&level=${levelNo}`,
		method: 'GET',
	});
	return response;
};

export const GetPhoneCode = async () => {
	const response = await AxiosDefault({
		url: 'public/phone-code',
		method: 'GET',
	});
	return response;
};

export const GetCountyList = async () => {
	const response = await AxiosDefault({
		url: `/public/country-profile/permitted-country-list`,
		method: 'GET',
	});
	return response;
};

export const GetLanguageList = async () => {
	const response = await AxiosDefault({
		url: `/public/languages`,
		method: 'GET',
	});
	return response;
};

export const GetTimeZone = async () => {
	const response = await AxiosDefault({
		url: `/public/timezone`,
		method: 'GET',
	});
	return response;
};

export const GetCurrencyList = async () => {
	const response = await AxiosDefault({
		url: '/public/currency',
		method: 'GET',
	});
	return response;
};

export const ProfileImageUpload = async (data) => {
	const response = await AxiosDefault({
		url: '/consultant/profile/image/upload',
		method: 'POST',
		data: data,
		contentType: 'multipart/form-data',
	});
	return response;
};

export const GetCountryPolicies = async (countryId) => {
	const response = await AxiosDefault({
		url: `/public/country-profile/policies/get?countryId=${countryId}&userType=system_2`,
		method: 'GET',
	});

	return response;
};

export const GetBenefits = async () => {
	const response = await AxiosDefault({
		url: `/consultant/general-configuration?sectionName=BENEFITS&level=1`,
		method: 'GET',
	});

	return response;
};
