import { createContext, useState } from 'react';
import { Cookies } from 'react-cookie';
import { get, isEmpty, find, isArray, map, replace, toString } from 'lodash';
import { GetTranslateLanguageMacros, GetTranslateLanguages } from '../api/AppLanguageConfig';

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
	const [LanguageLoader, setLanguageLoader] = useState(() => {
		return isEmpty(localStorage.getItem('translateLanguageMacros'));
	});
	const cookie = new Cookies();
	const selectedLanguage = cookie.get('selectedLanguage');
	const [LanguageSelected, setLanguageSelected] = useState(() => {
		return !isEmpty(selectedLanguage) && selectedLanguage !== undefined ? selectedLanguage : {};
	});
	const [LanguageMacros, setLanguageMacros] = useState(() => {
		return JSON.parse(localStorage.getItem('translateLanguageMacros'));
	});

	const setLanguage = (Language) => {
		const expireTime = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
		cookie.set('selectedLanguage', Language, {
			path: '/',
			expires: expireTime,
		});
		setLanguageSelected(Language);
		SetLanguageMacros(Language);
	};

	const SetLanguageList = async () => {
		await GetTranslateLanguages()
			.then((result) => {
				if (get(result, 'status', false)) {
					localStorage.setItem('LanguageList', JSON.stringify(get(result.data, 'data', [])));
				}
			})
			.catch((err) => {
				// throw err;
			});

		return true;
	};

	const GetLanguageList = () => {
		return JSON.parse(localStorage.getItem('LanguageList'));
	};

	const SetLanguageMacros = async (languageValue) => {
		const languageId = !isEmpty(languageValue) ? get(languageValue, '_id', '') : get(LanguageSelected, '_id', '');

		await GetTranslateLanguageMacros(languageId)
			.then((result) => {
				if (get(result, 'status', false)) {
					localStorage.setItem('translateLanguageMacros', JSON.stringify(get(result.data, 'data', [])));
					setLanguageMacros(get(result.data, 'data', []));
					const expireTime = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
					cookie.set('LanguageMacro', true, {
						path: '/',
						expires: expireTime,
					});
				}
				setLanguageLoader(false);
				// window.location.reload();
			})
			.catch((err) => {
				// window.location.reload();
				// throw err;
			});

		return true;
	};

	const translateMacro = (valueKey) => {
		// const LanguageMacros = JSON.parse(
		//   localStorage.getItem("translateLanguageMacros")
		// );
		// const { LanguageMacros } = useContextData();
		let finalValue = '';

		if (isArray(valueKey)) {
			const arrayValue = map(valueKey, (item) => {
				return get(find(LanguageMacros, { name: item }), 'value', '');
			});
			finalValue = replace(toString(arrayValue), ',', ' ');
			return finalValue ?? valueKey;
		} else {
			finalValue = get(find(LanguageMacros, { name: valueKey }), 'value', valueKey);
			return finalValue;
		}
	};

	return (
		<DataContext.Provider
			value={{
				LanguageLoader,
				SetLanguageList,
				GetLanguageList,
				translateMacro,
				LanguageMacros,
				LanguageSelected,
				SetLanguageMacros,
				setLanguage,
			}}
		>
			{children}
		</DataContext.Provider>
	);
};

export default DataContext;
