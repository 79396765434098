import { alpha } from '@mui/material';
import { DARK_MODE } from '../../config/theme.type';
import { CHART_COLORS, GREY } from './common';
import { PRIMARY_DARK, SECONDARY_DARK, INFO_DARK, SUCCESS_DARK, WARNING_DARK, ERROR_DARK, GRADIENTS_DARK } from './dark';
import { PRIMARY_LIGHT, SECONDARY_LIGHT, INFO_LIGHT, SUCCESS_LIGHT, WARNING_LIGHT, ERROR_LIGHT, GRADIENTS_LIGHT } from './light';

const getDarkPalette = () => ({
	mode: 'dark',
	common: { black: '#000', white: '#fff' },
	primary: PRIMARY_DARK,
	secondary: SECONDARY_DARK,
	info: INFO_DARK,
	success: SUCCESS_DARK,
	warning: WARNING_DARK,
	error: ERROR_DARK,
	grey: GREY,
	gradients: GRADIENTS_DARK,
	chart: CHART_COLORS,
	divider: alpha(GREY[100], 0.08),
	text: {
		primary: '#FFFFFF',
		secondary: GREY[500],
		disabled: GREY[600],
	},
	background: {
		paper: GREY[800],
		default: GREY[900],
		neutral: alpha(GREY[500], 0.12),
	},
	action: {
		active: GREY[500],
		hover: alpha(GREY[500], 0.08),
		selected: alpha(GREY[500], 0.16),
		disabled: alpha(GREY[500], 0.8),
		disabledBackground: alpha(GREY[500], 0.24),
		focus: alpha(GREY[500], 0.24),
		hoverOpacity: 0.08,
		disabledOpacity: 0.48,
	},
});

const getLightPalette = () => ({
	mode: 'light',
	common: { black: '#000', white: '#fff' },
	primary: PRIMARY_LIGHT,
	secondary: SECONDARY_LIGHT,
	info: INFO_LIGHT,
	success: SUCCESS_LIGHT,
	warning: WARNING_LIGHT,
	error: ERROR_LIGHT,
	grey: GREY,
	gradients: GRADIENTS_LIGHT,
	chart: CHART_COLORS,
	divider: GREY[500_32],
	text: {
		primary: GREY[800],
		secondary: GREY[600],
		disabled: GREY[500],
		default: '#000000',
	},
	background: {
		paper: '#FFFFFF',
		default: '#FFFFFF',
		neutral: GREY[100],
	},
	action: {
		active: GREY[600],
		hover: GREY[500_8],
		selected: GREY[500_16],
		disabled: GREY[500_80],
		disabledBackground: GREY[500_24],
		focus: GREY[500_24],
		hoverOpacity: 0.08,
		disabledOpacity: 0.48,
	},
});

export default function getPalette(themeType) {
	if (themeType === DARK_MODE) return getDarkPalette();

	return getLightPalette();
}
