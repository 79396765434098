import React, { useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Formik } from 'formik';
import { filter, get, isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getGoalType } from '../../../store/slice/common';
import {
	addDefaultTemplate,
	getDefaultTemplate,
	getQuestionsTemplateList,
} from '../../../store/slice/dashboard/QAndATemplateSlice';
import translate from '../../hooks/translate';

const CONSULTATION_TYPES = ['pre', 'post'];

const SelectDefaultTemplate = () => {
	const dispatch = useDispatch();
	const [dataIsEmpty, setDataIsEmpty] = useState(false);

	const isLoading = useSelector((state) => state.QAndATemplate.loading);
	const goalTypeList = useSelector((state) => state.common.goalType);
	const questionsTemplateList = useSelector((state) => state.QAndATemplate.questionsTemplateList);
	const defaultTemplateList = useSelector((state) => state.QAndATemplate.defaultTemplateList);

	const initialFormValue = useMemo(
		() =>
			map(goalTypeList, (goal) => {
				const goalId = get(goal, '_id', null);
				if (!goalId) return [];

				return CONSULTATION_TYPES.map((consultationType) => {
					const filterValue = filter(defaultTemplateList, (defaultValue) => {
						if (!defaultValue) return null;
						if (defaultValue.goalType === goalId && defaultValue.consultationType === consultationType) {
							return defaultValue;
						}
					})?.[0];
					const specializationList = goal?.categories?.map((e) => e.foreignId);
					return {
						goalType: goal?._id,
						templateId: get(filterValue, 'templateId', ''),
						status: get(filterValue, 'status', false),
						specializationList: specializationList ? specializationList.flat() : [],
						goalTypeTitle: consultationType === 'pre' ? goal?.macroName : '',
						consultationType,
					};
				});
			}).flat(),
		[goalTypeList, defaultTemplateList]
	);
	const templateListMap = useMemo(() => {
		const listMap = {};

		const isIn = (template, specializationList) => {
			if (!template.categories) return false;

			for (let index = 0; index < template.length; index++) {
				const categoryId = template[index].foreignData;
				if (specializationList.includes(categoryId)) {
					return true;
				}
			}
			return false;
		};

		initialFormValue?.forEach((form) => {
			if (!form.goalType) return;

			listMap[form.goalType] = [];
			questionsTemplateList.forEach((template) => {
				if (template.createdBy === 'MASTER_ADMIN' || isIn(template, form.specializationList)) {
					listMap[form.goalType].push(template);
				}
			});
		});
		return listMap;
	}, [initialFormValue, questionsTemplateList]);

	const handleSubmit = (values) => {
		const data = filter(values, (val) => val?.templateId !== '');
		dispatch(
			addDefaultTemplate(
				data.map((e) => ({
					templateId: e.templateId,
					goalType: e.goalType,
					consultationType: e.consultationType,
					status: e.status,
				}))
			)
		)
			.unwrap()
			.then((result) => {
				if (get(result, 'status', false)) {
					toast.success(get(result, 'message', 'Default template set successfully.'));
					dispatch(getDefaultTemplate());
				} else {
					toast.error(get(result, 'message', 'Something went wrong.'));
				}
			})
			.catch((err) => {});
	};

	useEffect(() => {
		dispatch(getGoalType({ sectionName: 'GOAL_TYPE' }));
		dispatch(getQuestionsTemplateList());
		dispatch(getDefaultTemplate())
			.unwrap()
			.then((result) => {
				if (get(result, 'status', false)) {
					setDataIsEmpty(isEmpty(get(result, 'data', [])));
				}
			})
			.catch((err) => {
				throw err;
			});
	}, [dispatch]);

	return (
		<Paper variant='outlined' className='h-100'>
			<CardContent>
				<Grid container spacing={2} justifyContent='center'>
					<Grid item xs={12}>
						<Box className='border-bottom pb-3'>
							<Stack
								direction='row'
								justifyContent='space-between'
								alignItems='center'
								flexWrap='wrap'
								className='gap-3'
							>
								<Typography variant='h5' fontWeight={600}>
									{translate('consultant_select_default_template')}
								</Typography>
								<Button variant='contained' component={Link} to='list'>
									{translate('consultant_manage_template')}
								</Button>
							</Stack>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Box className={isEmpty(defaultTemplateList) ? '' : 'mt-3'} overflow='auto'>
							{isEmpty(initialFormValue) || isLoading ? (
								dataIsEmpty ? (
									<Typography variant='subtitle1'>{translate('consultant_no_data_available')}</Typography>
								) : (
									<LinearProgress />
								)
							) : (
								<Formik
									initialValues={initialFormValue}
									enableReinitialize
									onSubmit={(values) => handleSubmit(values)}
								>
									{(props) => (
										<Box component='form' onSubmit={props.handleSubmit} minWidth='650px'>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<Box className='border-bottom pb-1 mb-3'>
														<Grid container spacing={2} className='align-items-center'>
															<Grid item xs={4}>
																<Typography variant='body1' fontWeight={500} color='grey.800'>
																	Goal
																</Typography>
															</Grid>
															<Grid item xs={2}>
																<Typography variant='body1' fontWeight={500} color='grey.800'>
																	Type
																</Typography>
															</Grid>
															<Grid item xs={4}>
																<Typography variant='body1' fontWeight={500} color='grey.800'>
																	Template Name
																</Typography>
															</Grid>
															<Grid item xs={2} sx={{ textAlign: 'center' }}>
																<Typography variant='body1' fontWeight={500} color='grey.800'>
																	Status
																</Typography>
															</Grid>
														</Grid>
													</Box>
												</Grid>

												<Grid item xs={12}>
													{map(props.values, (field, fieldIndex) => {
														const templateId = `[${fieldIndex}].templateId`;
														const fieldStatus = `[${fieldIndex}].status`;

														return (
															<Box key={fieldIndex} className='mb-3'>
																<Grid container spacing={2} alignItems='stretch'>
																	<Grid
																		item
																		xs={4}
																		display='flex'
																		alignItems='flex-end'
																		className={
																			field.consultationType === 'post'
																				? 'border-bottom'
																				: ''
																		}
																	>
																		<Typography
																			variant='body1'
																			fontWeight={500}
																			color='grey.800'
																			mb='-14px'
																		>
																			{field?.goalTypeTitle
																				? translate(field?.goalTypeTitle)
																				: ''}
																		</Typography>
																	</Grid>
																	<Grid
																		item
																		xs={2}
																		className='border-bottom pb-3'
																		display='flex'
																		alignItems='center'
																	>
																		<Typography
																			variant='body1'
																			fontWeight={500}
																			color='grey.800'
																		>
																			{field?.consultationType}
																		</Typography>
																	</Grid>
																	<Grid
																		item
																		xs={4}
																		className='border-bottom pb-3'
																		display='flex'
																		alignItems='center'
																	>
																		<TextField
																			id={`template-select-${fieldIndex}`}
																			label={translate('consultant_select_template')}
																			name={templateId}
																			value={field.templateId}
																			onChange={props.handleChange}
																			onBlur={props.handleBlur}
																			fullWidth={true}
																			select={true}
																			size='small'
																		>
																			<MenuItem value=''>
																				{translate('common_none')}
																			</MenuItem>
																			{map(
																				templateListMap[field?.goalType],
																				(option, index) => {
																					return (
																						<MenuItem key={index} value={option?._id}>
																							{option?.templateName}
																						</MenuItem>
																					);
																				}
																			)}
																		</TextField>
																	</Grid>
																	<Grid
																		item
																		xs={2}
																		className='border-bottom pb-3'
																		sx={{ textAlign: 'center' }}
																		display='flex'
																		alignItems='center'
																	>
																		<Box width='100%'>
																			<FormControlLabel
																				name={fieldStatus}
																				value={field.status}
																				checked={field.status}
																				onChange={(e) => {
																					props.setFieldValue(
																						fieldStatus,
																						e.target.checked
																					);
																				}}
																				control={<Checkbox />}
																				label={translate('consultant_active')}
																			/>
																		</Box>
																	</Grid>
																</Grid>
															</Box>
														);
													})}
												</Grid>
												<Grid item xs={12}>
													<Box className='mt-2 text-right'>
														<Button variant='contained' type='submit'>
															{translate('common_btn_submit')}
														</Button>
													</Box>
												</Grid>
											</Grid>
										</Box>
									)}
								</Formik>
							)}
						</Box>
					</Grid>
				</Grid>
			</CardContent>
		</Paper>
	);
};

export default SelectDefaultTemplate;
