import React from 'react';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { Link } from 'react-router-dom';

import logo from '../../../assets/images/logo/logo.png';

import ComponentStyle from '../../../styles/ComponentStyle.module.css';
import translate from '../../../components/hooks/translate';
import LanguageSwitcherModal from '../../../components/language-switcher/LanguageSwitcherModel';

const Footer = (props) => {
	const { footerRef } = props;
	return (
		<Box component='div' className={ComponentStyle.main_footer} ref={footerRef}>
			<Container maxWidth='xl'>
				<Grid container spacing={1} justifyContent='center'>
					<Grid item xs={12} sm={2} md={2} pt={0}>
						<Box
							component='img'
							src={logo}
							alt='logo'
							sx={{
								maxWidth: { sm: '30px', xs: '30px' },
								marginTop: '11px',
								marginBottom: '11px',
								marginLeft: '12px',
							}}
						/>
					</Grid>
					<Grid container item xs={12} sm={10} md={10}>
						<Grid item xs={12} sm={12} md={5} lg={4}>
							<Stack
								direction='row'
								spacing={1}
								justifyContent={{ xs: 'center', sm: 'flex-end' }}
								alignItems='center'
							>
								<IconButton aria-label='facebook'>
									<FacebookIcon />
								</IconButton>
								<IconButton aria-label='twitter'>
									<TwitterIcon />
								</IconButton>
								<IconButton aria-label='instagram'>
									<InstagramIcon />
								</IconButton>
								<IconButton aria-label='Linkdin'>
									<LinkedInIcon />
								</IconButton>
							</Stack>
						</Grid>
						<Grid item xs={12} sm={12} md={7} lg={8} sx={{ textAlign: { sm: 'end', xs: 'start' } }}>
							<Stack
								// direction="row"
								direction={{ xs: 'column', sm: 'row' }}
								divider={<Divider orientation='vertical' flexItem />}
								spacing={2}
								alignItems='center'
								justifyContent='end'
								py={1}
							>
								<Box>
									<LanguageSwitcherModal sx={{ padding: '0 !important' }} />
								</Box>
								<Box>
									<Typography variant='subtitle1' color='text.secondary'>
										&copy; 2022
										<Link to='/' className='fw-bold'>
											<Typography variant='subtitle1' component='span' className='ms-2'>
												InspireMind
											</Typography>
										</Link>
									</Typography>
								</Box>
								<Box>
									<Link to='/terms-and-conditions'>{translate('common_terms_and_conditions')}</Link>
								</Box>
							</Stack>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Footer;
