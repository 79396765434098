import { GREY, createGradient } from './common';

export const PRIMARY_DARK = {
	lighter: '#ccd7fe', // 0.75
	light: '#99affe', // 0.5
	main: '#3460fe',
	dark: '#2443b1',
	darker: '#122158',
	contrastText: '#fff',
};
export const SECONDARY_DARK = {
	lighter: '#E3EBFB',
	light: '#A6BAE9',
	main: '#6076B8',
	dark: '#304184',
	darker: '#121C58',
	contrastText: '#fff',
};
export const INFO_DARK = {
	lighter: '#DEEAFF',
	light: '#9EBBFF',
	main: '#5E86FF',
	dark: '#2F4AB7',
	darker: '#12217A',
	contrastText: '#fff',
};
export const SUCCESS_DARK = {
	lighter: '#EDFBD9',
	light: '#B6EC8C',
	main: '#67C13E',
	dark: '#328A1F',
	darker: '#0F5C0B',
	contrastText: GREY[800],
};
export const WARNING_DARK = {
	lighter: '#FEF6CE',
	light: '#FDDA6C',
	main: '#F9B20C',
	dark: '#B37406',
	darker: '#774502',
	contrastText: GREY[800],
};
export const ERROR_DARK = {
	lighter: '#FFE9D5',
	light: '#FFAC82',
	main: '#FF4842',
	dark: '#B71D18',
	darker: '#7A0916',
	contrastText: '#fff',
};
export const GRADIENTS_DARK = {
	primary: createGradient(PRIMARY_DARK.light, PRIMARY_DARK.main),
	info: createGradient(INFO_DARK.light, INFO_DARK.main),
	success: createGradient(SUCCESS_DARK.light, SUCCESS_DARK.main),
	warning: createGradient(WARNING_DARK.light, WARNING_DARK.main),
	error: createGradient(ERROR_DARK.light, ERROR_DARK.main),
};
