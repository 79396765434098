import axios from 'axios';
import EventSubject from '../services/EventSubject';

export class FileUploader {
	constructor(fileData, initiateUrl, uploadUrl, completeUrl, jobID, updateJob, totalSize, completedSize) {
		this.file = fileData.file;
		this.meta = fileData.meta;
		this.initiateUrl = initiateUrl;
		this.uploadUrl = uploadUrl;
		this.completeUrl = completeUrl;
		this.jobID = jobID;
		this.totalSize = totalSize;
		this.completedSize = completedSize;
		this.updateJob = updateJob;
		this.uploadId = null;
		this.parts = [];
		this.totalParts = 0;
		this.status = 0;
		this.partSizeInBytes = 5 * 1024 * 1024;
	}

	async initiateUpload() {
		this.status = 0;
		const content = {
			contentId: this.meta.contentId,
			logID: this.meta.logID,
			type: this.meta.type,
			subSection: this.meta.subSection,
			imageType: this.meta.imageType,
			filename: this.file.name,
		};

		try {
			const response = await axios.post(this.initiateUrl, { content });
			if (response.status === 200) {
				this.uploadId = response.data.uploadId;

				// Calculate total number of parts based on file size and part size
				const fileSizeInBytes = this.file.size;
				this.totalParts = Math.ceil(fileSizeInBytes / this.partSizeInBytes);

				// Upload all parts automatically
				await this.uploadAllParts();
			}
		} catch (error) {
			console.error('Error initiating upload:', error);
		}
	}

	async uploadPartWithRetry(partNumber, retries = 10, resumeUpload = false) {
		const formData = new FormData();
		const content = {
			contentId: this.meta.contentId,
			logID: this.meta.logID,
			type: this.meta.type,
			subSection: this.meta.subSection,
			imageType: this.meta.imageType,
			uploadId: this.uploadId,
			partNumber: partNumber,
		};
		const filePartBuffer = this.file.slice((partNumber - 1) * this.partSizeInBytes, partNumber * this.partSizeInBytes);
		formData.append('file', filePartBuffer, this.file.name);
		formData.append('content', JSON.stringify(content));

		const response = await axios.post(this.uploadUrl, formData);
		if (response?.status === 200) {
			this.parts.push({ PartNumber: partNumber, ETag: response.data.ETag });

			const percentCompleted = Math.round(
				((this.completedSize + partNumber * this.partSizeInBytes) / this.totalSize) * 100
			);
			const value = Math.min(percentCompleted, 100);
			const content = `Uploading files: ${value}%`;

			EventSubject.next({ id: this.jobID, content, value });
			this.updateJob(this.jobID, { value, content });
		} else {
			console.error(`Error uploading part ${partNumber}:`, response);

			if (resumeUpload && response?.status === 409) {
				return;
			}

			if (retries > 0) {
				await new Promise((resolve) => setTimeout(resolve, 2000));
				// Retry with one less attempt
				await this.uploadPartWithRetry(partNumber, retries - 1, true);
			} else {
				console.error('Max retries reached. Upload failed.');
				// Handle error or notify the user accordingly
			}
		}
	}

	async uploadPart(partNumber) {
		await this.uploadPartWithRetry(partNumber, 3, false);
	}

	async uploadAllParts() {
		// Upload all parts automatically
		for (let partNumber = 1; partNumber <= this.totalParts; partNumber++) {
			await this.uploadPart(partNumber);
		}

		// Once all parts are uploaded, trigger complete upload
		await this.completeUpload();
	}

	async completeUpload() {
		const content = {
			contentId: this.meta.contentId,
			logID: this.meta.logID,
			type: this.meta.type,
			subSection: this.meta.subSection,
			imageType: this.meta.imageType,
			uploadId: this.uploadId,
			parts: this.parts,
			filename: this.file.name,
		};

		try {
			const response = await axios.post(this.completeUrl, { content });
			this.status = response.status;
		} catch (error) {
			console.error('Error completing upload:', error);
		}
	}

	getStatus() {
		return this.status;
	}
}
