import React from 'react';
import { styled } from '@mui/material/styles';
import AccordionSummary from '@mui/material/AccordionSummary';

const MuiAccordionSummary = styled(AccordionSummary)(({ other, theme }) => ({
	backgroundColor: theme.palette.primary.contrastText,
	paddingRight: 16,
	margin: '0 0',
	borderRadius: 'inherit',
	'& .MuiAccordionSummary-root.Mui-expanded': {
		borderRadius: '2px 2px 0 0',
	},
	...other,
}));
const reverseStyle = {
	flexDirection: 'row-reverse',
	'&.Mui-expanded': {
		minHeight: 48,
	},
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},

	'& .MuiAccordionSummary-content': {
		margin: '0',
		marginLeft: '16px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	'.MuiAccordionSummary-content.Mui-expanded': {
		margin: '0',
		marginLeft: '16px',
	},
};

const CMAccordionSummary = (props) => {
	const { children, reverse, ...rest } = props;
	return (
		<MuiAccordionSummary other={reverse === true ? reverseStyle : null} {...rest}>
			{children}
		</MuiAccordionSummary>
	);
};

export default CMAccordionSummary;
