import React, { useEffect } from 'react';
import {
	VideoTileGrid,
	UserActivityProvider,
	useMeetingManager,
	useMeetingStatus,
	// useRosterState,
} from 'amazon-chime-sdk-component-library-react';
import Box from '@mui/material/Box';

import { StyledLayout, StyledContent } from './Styled.js';
import { useNavigation } from '../../providers/NavigationProvider.jsx';

import MeetingControls from '../../containers/MeetingControls';
import useMeetingEndRedirect from '../../hooks/useMeetingEndRedirect';
import MeetingMetrics from '../../containers/MeetingMetrics';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import StatusViewerScreen from './StatusViewerScreen';

const MeetingView = ({ meetingData }) => {
	// const tiles = useRosterState();
	const meetingStatus = useMeetingStatus();
	const meetingManager = useMeetingManager();
	useMeetingEndRedirect();
	const { showNavbar, showRoster } = useNavigation();

	useEffect(() => {
		const joinMeeting = async (data) => {
			// Initialize the `MeetingSessionConfiguration`
			const meetingSessionConfiguration = new MeetingSessionConfiguration(
				data.Meeting_Response,
				data.Attendee_Response.Attendees[0]
			);

			// Create a `MeetingSession` using `join()` function with the `MeetingSessionConfiguration`
			await meetingManager.join(meetingSessionConfiguration);

			// Start the `MeetingSession` to join the meeting
			await meetingManager.start();
		};

		if (!meetingData || !meetingManager) return;
		joinMeeting(meetingData);
	}, [meetingData, meetingManager]);

	if (meetingStatus === 3) {
		return (
			<Box position='relative' width='100%' display='flex' justifyContent='center' alignItems='center'>
				<Box>The meeting was ended.</Box>
			</Box>
		);
	}
	return (
		<UserActivityProvider>
			<StyledLayout showNav={showNavbar} showRoster={showRoster}>
				<StatusViewerScreen status={meetingStatus} />
				<StyledContent>
					<MeetingMetrics />
					<VideoTileGrid layout='standard' className='videos' noRemoteVideoView={<div>No Remote Video</div>} />
					<MeetingControls />
				</StyledContent>
			</StyledLayout>
		</UserActivityProvider>
	);
};

export default MeetingView;
