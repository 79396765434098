export const TAB_TYPE = {
	UPCOMING: 'UPCOMING',
	PAST: 'PAST',
	HISTORY: 'HISTORY',
	CONSULTANT: 'CONSULTANT',
};
export const TAB_LIST = [
	{
		title: 'Upcoming Consultation',
		type: TAB_TYPE.UPCOMING,
		id: TAB_TYPE.UPCOMING,
	},
	{
		title: 'Past Consultation',
		type: TAB_TYPE.PAST,
		id: TAB_TYPE.PAST,
	},
	{
		title: 'Consultation History',
		type: TAB_TYPE.HISTORY,
		id: TAB_TYPE.HISTORY,
	},
	{
		title: 'Created Appointment',
		type: TAB_TYPE.CONSULTANT,
		id: TAB_TYPE.CONSULTANT,
	},
];
