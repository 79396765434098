import { useDispatch } from 'react-redux';
import { contentArchive, contentDelete, contentpublish, getContent } from '../../../../store/slice/dashboard/contentSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const useContentAction = (item) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleArchive = (id, checked) => {
		const data = {
			id: id,
			payload: {
				status: checked,
			},
		};
		dispatch(contentArchive(data))
			.unwrap()
			.then((result) => {
				if (result.status) {
					dispatch(getContent({ pageNo: 0, type: 'ALL' }));
					toast.success(result.message);
				} else {
					toast.error(result.message);
				}
			})
			.catch((error) => {
				console.log('Article blog error========>', error);
			});
	};

	const handlePublish = async (id, checked) => {
		const data = {
			id: id,
			payload: {
				status: checked,
			},
		};
		const res = await dispatch(contentpublish(data))
			.unwrap()
			.then((result) => {
				if (result.status) {
					toast.success(result.message);
				} else {
					toast.error(result.message);
				}
			})
			.catch((error) => {
				console.log('Article blog error========>', error);
			});
		return res;
	};

	const handleDelete = async (id) => {
		const res = await dispatch(contentDelete(id))
			.unwrap()
			.then((result) => {
				if (result.status) {
					toast.success(result.message);
					dispatch(getContent({ pageNo: 0, type: 'ALL' }));
				}
			})
			.catch((error) => {});
		return res;
	};

	const handleEdit = () => {
		navigate('/dashboard/content/add-content', { state: { item: item, type: 'update' } });
	};

	return {
		handleArchive,
		handlePublish,
		handleDelete,
		handleEdit,
	};
};
