import AxiosDefault from "../../services/AxiosDefault";

export const PostOnlineServiceAdd = async (data) => {
  const response = await AxiosDefault({
    url: "/consultant/consultation/services/online/add-update",
    method: "POST",
    data: data,
  });
  return response;
};

export const AddOfflineService = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/consultant/consultation/services/offline/add-update`,
    data: data,
    contentType: "multipart/form-data",
  });
  return response;
};

export const GetOfflineService = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/consultant/consultation/services/offline/get`,
  });
  return response;
};
export const GetCelenderEvent = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/consultant/consultation/services/celender-sync`,
  });
  return response;
};

export const GetCelenderFilteredEvent = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/consultant/consultation/services/get-all`,
    data: data,
  });
  return response;
};

export const AddPhoneService = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/consultant/consultation/services/phone/add-update`,
    data: data,
  });
  return response;
};

export const OtpSendService = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/consultant/consultation/services/phone/otp`,
    data: data,
  });
  return response;
};

export const OtpVerifyService = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/consultant/consultation/services/phone/otp-verify`,
    data: data,
  });
  return response;
};

export const GetPhoneService = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/consultant/consultation/services/phone/get`,
  });
  return response;
};

export const AddUnavailability = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/consultant/consultation/unavailability/add`,
    data: data,
  });
  return response;
};


export const GetUnavailability = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/consultant/consultation/unavailability/get`,
  });
  return response;
};

export const RemoveUnavailability = async (id) => {
  const response = await AxiosDefault({
    method: "DELETE",
    url: `/consultant/consultation/unavailability/delete/${id}`,
  });
  return response;
};

export const AddAvailability = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/consultant/consultation/availability/add`,
    data: data,
  });
  return response;
};

export const GetAvailability = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/consultant/consultation/availability/get`,
  });
  return response;
};

export const PauseAvailability = async (data) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/consultant/consultation/availability/pause`,
    data: data,
  });
  return response;
};

export const PostAppointmentAdd = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/customer/consultation/booking/consultant-create`,
    data: data,
  });
  return response;
};


