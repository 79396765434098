import React from "react";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import CMDialog from "../ui-components/dialog/CMDialog";
import CMTextButton from "../ui-components/buttons/CMTextButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationDialog = (props) => {
  const {
    dialogTitle,
    dialogOpen,
    dialogClose,
    action,
    buttonLabel,
    confirmationText,
    btnColor,
  } = props;
  return (
    <>
      <CMDialog
        dialogTitle={dialogTitle}
        dialogOpen={dialogOpen}
        dialogClose={dialogClose}
        TransitionComponent={Transition}
        maxWidth="md"
      >
        <DialogContent dividers>
          <Typography variant="subtitle1">
            {confirmationText
              ? confirmationText
              : "Are you sure you want to Delete ?"}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="end"
          >
            <CMTextButton onClick={() => dialogClose()}>Cancel</CMTextButton>
            <Button
              variant="contained"
              color={btnColor ? btnColor : "error"}
              onClick={action}
            >
              {buttonLabel ? buttonLabel : "Delete"}
            </Button>
          </Stack>
        </DialogActions>
      </CMDialog>
    </>
  );
};

export default ConfirmationDialog;
