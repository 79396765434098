import React, { lazy, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { isEmpty } from 'lodash';

import Loadable from './components/ui-components/loader/Loadable';
import { getMeInfo } from './store/slice/auth/authentication/authSlice';

// Layouts
import PrivateLimitedAccess from './layout/private/PrivateLimitedAccess';
import Public from './layout/public';
import Private from './layout/private';

import ProfileDetails from './components/dashboard/profile/ProfileDetails';
import ProfileBasicInfo from './components/dashboard/profile/sub-pages/basic-info';
import ProfileLicenseCertificate from './components/dashboard/profile/sub-pages/license-certificate';
import ProfileProfessionalDomain from './components/dashboard/profile/sub-pages/professional-domain';
import ProfileProfessionalInfo from './components/dashboard/profile/sub-pages/professional-info';
import ProfileResidentialAddress from './components/dashboard/profile/sub-pages/residentialAddress';
import ProfileSocialMedia from './components/dashboard/profile/sub-pages/social-media';
import ArticleBlogForm from './components/dashboard/article-blog/create-article';
import ArticleBlogPage from './components/dashboard/article-blog';
import QAndATemplate from './pages/dashboard/QAndATemplate';
import QAndATemplateList from './components/dashboard/q-and-a-template/QAndATemplateList';
import QAndATemplateForm from './components/dashboard/q-and-a-template/QAndATemplateForm';
import SelectDefaultTemplate from './components/dashboard/q-and-a-template/SelectDefaultTemplate';
import Appointment from './components/dashboard/appointment/Appointment';
import SetServiceTypes from './components/dashboard/appointment/SetServiceTypes';
import Booking from './pages/dashboard/Booking';
import BookingList from './components/dashboard/booking/BookingList';
import QAndADetail from './components/dashboard/booking/QAndADetail';
import BookingDetail from './components/dashboard/booking/BookingDetail';
import BankingSetup from './components/dashboard/banking';
import LogoOnlyLayout from './layout/private/LogoOnlyLayout';
import BookingManagement from './components/dashboard/booking/components/booking-management/index';
import ContentForm from './components/dashboard/content/create-content';
import ContentPage from './pages/dashboard/ContentPage';
import ContentBlogPage from './components/dashboard/content';
// import EventPage from './pages/dashboard/EventPage';
// import EventPageData from './components/dashboard/event';
// import EventForm from './components/dashboard/event/create-event';
import ContentDetails from './components/dashboard/content/content-cards/ContentDetails';
import ArticleDeatils from './components/dashboard/article-blog/components/ArticleDetails';
// import EventDetails from './components/dashboard/event/create-event/EventDetails';
import SetCalendarDetails from './components/dashboard/appointment/service-type/calendar/SetCalendarDetails';
import CalendarPage from './pages/dashboard/CalendarPage';
import AttendMeeting from './components/dashboard/booking/AttendMeeting';
import PublicLayoutWrapper from './layout/public/PublicLayoutWrapper';

// const Home = Loadable(lazy(() => import('./pages/public/Home')));
const SignUp = Loadable(lazy(() => import('./pages/auth/signup/SignUp')));
const OtpVerify = Loadable(lazy(() => import('./pages/auth/signup/OtpVerify')));
const ConsultantInfo = Loadable(lazy(() => import('./pages/auth/signup/ConsultantInfo')));
const SignIn = Loadable(lazy(() => import('./pages/auth/signin/SignIn')));
const ForgetPasswordSource = Loadable(lazy(() => import('./pages/auth/forget-password/ForgetPasswordSource')));
const ForgetPasswordReset = Loadable(lazy(() => import('./pages/auth/forget-password/ForgetPasswordReset')));
const ForgetPasswordOtpVerify = Loadable(lazy(() => import('./pages/auth/forget-password/ForgetPasswordOtpVerify')));
const TermsAndConditions = Loadable(lazy(() => import('./pages/policies/TermsAndConditions')));
const DataPolicy = Loadable(lazy(() => import('./pages/policies/DataPolicy')));
const PrivacyPolicy = Loadable(lazy(() => import('./pages/policies/PrivacyPolicy')));
const AppointmentTermsAndConditions = Loadable(lazy(() => import('./pages/policies/AppointmentTermsAndConditions')));
const PageNotFound = Loadable(lazy(() => import('./pages/common/Page404')));

// Dashboard
const Dashboard = Loadable(lazy(() => import('./pages/dashboard/Dashboard')));
const Profile = Loadable(lazy(() => import('./pages/dashboard/Profile')));
const InspiremindProfile = Loadable(lazy(() => import('./pages/dashboard/InspiremindProfile')));
const ServiceAndPlans = Loadable(lazy(() => import('./pages/dashboard/ServiceAndPlans')));
const ArticleAndBlog = Loadable(lazy(() => import('./pages/dashboard/ArticleAndBlog')));
const AppointmentPage = Loadable(lazy(() => import('./pages/dashboard/AppointmentPage')));
const BankingPage = Loadable(lazy(() => import('./pages/dashboard/Banking')));

const AppRoute = () => {
	const dispatch = useDispatch();

	const token = useSelector((state) => state.auth.authData.token);
	const isSignupCompleted = useSelector((state) => state.auth.isSignupCompleted);

	useEffect(() => {
		!isEmpty(token) && !isSignupCompleted && dispatch(getMeInfo());
	}, [token, isSignupCompleted, dispatch]);

	return (
		<>
			<Routes>
				<Route path='dashboard/' element={<Private />}>
					<Route index element={<Dashboard />} />

					<Route path='profile' element={<Profile />}>
						<Route index element={<ProfileDetails />} />
						<Route path='basicinfo' element={<ProfileBasicInfo />} />
						<Route path='residential-address' element={<ProfileResidentialAddress />} />
						<Route path='professional-domain' element={<ProfileProfessionalDomain />} />
						<Route path='professional-info' element={<ProfileProfessionalInfo />} />
						<Route path='license-or-certification' element={<ProfileLicenseCertificate />} />
						<Route path='social-media' element={<ProfileSocialMedia />} />
					</Route>

					<Route path='inspire-mind-profile' element={<Outlet />}>
						<Route index element={<InspiremindProfile />} />
						<Route path='links' element={<InspiremindProfile />} />
						<Route path='courses' element={<InspiremindProfile />} />
						<Route path='blog' element={<InspiremindProfile />} />
						<Route path='*' element={<Navigate to='' replace />} />
					</Route>
					<Route path='service-plans' element={<ServiceAndPlans />} />

					<Route path='article-blog' element={<ArticleAndBlog />}>
						<Route index element={<ArticleBlogPage />} />
						<Route path='add-blog' element={<ArticleBlogForm />} />
						<Route path='blog-details' element={<ArticleDeatils />} />
					</Route>

					<Route path='content' element={<ContentPage />}>
						<Route index element={<ContentBlogPage />} />
						<Route path='add-content' element={<ContentForm />} />
						<Route path='content-details' element={<ContentDetails />} />
					</Route>

					{/* <Route path='event' element={<EventPage />}>
						<Route index element={<EventPageData />} />
						<Route path='add-event' element={<EventForm />} />
						<Route path='event-deatils' element={<EventDetails />} />
					</Route> */}

					<Route path='appointment' element={<AppointmentPage />}>
						<Route index element={<Appointment />} />
						<Route path='service-type' element={<SetServiceTypes />} />
					</Route>

					<Route path='calendar' element={<CalendarPage />}>
						<Route index element={<SetCalendarDetails />} />
					</Route>

					<Route path='banking' element={<BankingPage />}>
						<Route index element={<BankingSetup />} />
					</Route>

					<Route path='q-and-a-template' element={<QAndATemplate />}>
						<Route index element={<SelectDefaultTemplate />} />
						<Route path='list' element={<QAndATemplateList />} />
						<Route path='create' element={<QAndATemplateForm />} />
						<Route path='update' element={<QAndATemplateForm />} />
					</Route>

					<Route path='booking' element={<Booking />}>
						<Route index element={<BookingList />} />
						<Route path='detail/:bookingId' element={<BookingDetail />} />
						<Route path='detail/:bookingId/:appointmentId' element={<BookingDetail />} />
						<Route path='q-and-a-detail' element={<QAndADetail />} />
						<Route path='booking-management/:bookingId' element={<BookingManagement />} />
						<Route path='attend-meeting/:bookingId' element={<AttendMeeting />} />
					</Route>

					{/* <Route path="settings" element={<Setting />} /> */}
				</Route>

				<Route path='/' element={<PrivateLimitedAccess />}>
					<Route index path='/consultant-info' element={<ConsultantInfo />} />
				</Route>

				<Route path='/' element={<Public />}>
					<Route path='terms-and-conditions' element={<TermsAndConditions />} />
					<Route path='data-policy' element={<DataPolicy />} />
					<Route path='privacy-policy' element={<PrivacyPolicy />} />
					<Route path='appointment-terms-and-conditions' element={<AppointmentTermsAndConditions />} />
				</Route>

				<Route path='/' element={<PublicLayoutWrapper />}>
					<Route index element={<SignIn />} />
					<Route path='login' element={<SignIn />} />
					<Route path='signup' element={<SignUp />} />
					<Route path='recover/account' element={<ForgetPasswordSource />} />
					<Route path='otp-verify' element={<OtpVerify />} />
					<Route path='forget-password-otp-verify' element={<ForgetPasswordOtpVerify />} />
					<Route path='forget-password-reset' element={<ForgetPasswordReset />} />
				</Route>

				<Route path='/' element={<LogoOnlyLayout />}>
					<Route path='*' element={<PageNotFound path='/' />} />
				</Route>
			</Routes>
		</>
	);
};

export default AppRoute;
