import { useEffect, useMemo, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import '../../../../styles/video.css';
import { GetBookingDetail } from '../../../../api/dashboard/BookingAPI';
import ChatContainer from './chat/ChatContainer';
import MeetingContainer from './meeting';
import { getCurrentMeetingFromList, getMeetingMetaData } from './helpers';

const TabPanel = ({ activeTab, tabIndex, children }) => {
	const isActive = activeTab === tabIndex;

	return (
		<Box display={isActive ? 'block' : 'none'} pt={3} flex={1}>
			{children}
		</Box>
	);
};

export default function AttendMeeting() {
	const [bookingData, setBookingData] = useState(null);
	const [appointmentData, setAppointmentData] = useState({});
	const [meetingMetaData, setMeetingMetaData] = useState({});
	const [activeTab, setActiveTab] = useState(0);
	const params = useParams();
	const bookingId = get(params, 'bookingId', '');
	const timer = useRef();

	useEffect(() => {
		async function getDetails() {
			try {
				const response = await GetBookingDetail(bookingId);
				const data = get(response, 'data.data', null);
				if (!data) return;
				setBookingData(data);

				// check for meeting
				const currentMeeting = getCurrentMeetingFromList(data.upcomingBookings);
				if (!currentMeeting?.startTime) return;
				setAppointmentData(currentMeeting);
				const meetingTimeConf = getMeetingMetaData(currentMeeting.startTime, currentMeeting.endTime);
				setMeetingMetaData(meetingTimeConf);

				// has upcoming meeting
				if (meetingTimeConf < 0) return;
				timer.current = setTimeout(() => {}, meetingTimeConf.nextMeeting);
			} catch (_) {}
		}
		if (bookingId) {
			getDetails();
		}

		return () => {
			if (!timer.current) return;
			clearTimeout(timer.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleTabChange = (_, newValue) => {
		setActiveTab(newValue);
	};

	if (!bookingData) return <></>;
	return (
		<Box height='100%' display='flex' flexDirection='column'>
			<Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
				<Tabs value={activeTab} onChange={handleTabChange} centered>
					<Tab label='Chat' sx={{ px: 2 }} />
					<Tab label='Meeting' sx={{ px: 2 }} />
				</Tabs>
			</Box>
			<TabPanel activeTab={activeTab} tabIndex={0}>
				<ChatContainer chatId={bookingData.chatId} bookingId={bookingId} />
			</TabPanel>
			<TabPanel activeTab={activeTab} tabIndex={1}>
				<MeetingContainer meetingData={bookingData.meeting?.[0]} appointmentData={appointmentData} />
			</TabPanel>
		</Box>
	);
}
