import React, { useEffect, useState } from 'react';

import { get, isEmpty, map } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { getBookingDetail } from '../../../store/slice/dashboard/bookingSlice';
import NoDataOverlay from '../../common/NoDataOverlay';
import createMarkup from '../../hooks/createMarkup';
import translate from '../../hooks/translate';
import CMCardHeader from '../../ui-components/cards/CMCardHeader';
import DetailCardSkeleton from '../../ui-components/skeleton/DetailCardSkeleton';
import BookingDetailBox, { BookingDetailTitle, BookingDetailValue } from './components/BookingDetailBox';
import ConsultationDetailCard from './consultation/ConsultationDetailCard';
import CancelConsultations from './consultation/CancelConsultations';
import CMToolTip from '../../ui-components/tooltip/CMToolTip';
import CopyToClipboard from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const BookingDetail = () => {
	const params = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const isLoading = useSelector((state) => state.booking.loading);
	const bookingDetail = useSelector((state) => state.booking.bookingDetail);
	const [emptyData, setEmptyData] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [isCopied, setIsCopied] = useState(false);

	useEffect(() => {
		dispatch(getBookingDetail(get(params, 'bookingId', '')))
			.unwrap()
			.then((result) => {
				if (get(result, 'status', false)) {
					if (isEmpty(get(result, 'data', {}))) {
						setEmptyData(true);
					}
				} else {
					setEmptyData(true);
				}
			})
			.catch((err) => {
				throw err;
			});
	}, [dispatch, params]);
	console.log(bookingDetail);

	return (
		<Paper elevation={0} className='w-100 h-100'>
			<CMCardHeader title={translate('common_appointment_booking_details')} />
			<CardContent className='w-100 h-100'>
				{isLoading ? (
					<DetailCardSkeleton disableHeaderButton={true} skeletons={3} cardProps={{ variant: 'outlined' }} />
				) : emptyData ? (
					<NoDataOverlay />
				) : (
					<Stack direction='column' spacing={1}>
						<Paper variant='outlined' className='w-100 rounded-0'>
							<CMCardHeader
								title={translate('common_booking_details')}
								titleSize='1rem'
								titleColor='grey'
								titleColorVariant='700'
							/>
							<CardContent>
								<Stack
									direction={{ sm: 'row', xs: 'column' }}
									flexWrap='wrap'
									justifyContent='space-between'
									sx={{ rowGap: 2, columnGap: 2 }}
								>
									<BookingDetailBox>
										<BookingDetailTitle title={translate('common_booking_id')} />
										<BookingDetailValue>{get(bookingDetail, 'bookingId', 'NA')}</BookingDetailValue>
									</BookingDetailBox>
									<BookingDetailBox>
										<BookingDetailTitle title={translate('common_booking_status')} />
										<BookingDetailValue>
											{get(bookingDetail, 'bookingStatus', false) ? (
												<CheckCircleIcon color='success' className='align-middle' />
											) : (
												<CancelIcon color='error' className='align-middle' />
											)}
										</BookingDetailValue>
									</BookingDetailBox>
									<BookingDetailBox>
										<BookingDetailTitle title={translate('common_booked_on')} />
										<BookingDetailValue highlight='true'>
											{moment(get(bookingDetail, 'createdAt', 'NA'), ['MM-DD-YYYY', 'YYYY-MM-DD']).format(
												'MM/DD/YYYY'
											)}
										</BookingDetailValue>
									</BookingDetailBox>
									<BookingDetailBox>
										<BookingDetailTitle title={translate('common_consultation_date')} />
										<BookingDetailValue highlight='true'>
											{moment(get(bookingDetail, 'bookingTime', 'NA')).format('MM/DD/YYYY')}
										</BookingDetailValue>
									</BookingDetailBox>
									<BookingDetailBox>
										<BookingDetailTitle title={translate('common_consultation_time')} />
										<BookingDetailValue highlight='true'>
											{moment(get(bookingDetail, 'bookingTime', 'NA')).format('LT')}
										</BookingDetailValue>
									</BookingDetailBox>

									<BookingDetailBox>
										<BookingDetailTitle title={translate('Deep Link')} />
										<CMToolTip
											icon={
												<CopyToClipboard
													text={get(bookingDetail, 'deepLink', 'NA')}
													onCopy={() => setIsCopied(true)}
												>
													<ContentCopyIcon fontSize='small' color='secondary' />
												</CopyToClipboard>
											}
											title='Copied!!!'
										/>
									</BookingDetailBox>

									<BookingDetailBox>
										<BookingDetailTitle title={translate('common_goal_type')} />
										<BookingDetailValue highlight='true'>
											{get(bookingDetail, 'goalType.title', 'NA')}
										</BookingDetailValue>
									</BookingDetailBox>

									<BookingDetailBox>
										<BookingDetailTitle title={translate('common_service_type')} />
										<BookingDetailValue highlight='true'>
											{get(bookingDetail, 'serviceType', 'NA')}
										</BookingDetailValue>
									</BookingDetailBox>
									{get(bookingDetail, 'zipCode', null) ? (
										<BookingDetailBox>
											<BookingDetailTitle title={translate('consultant_zip_code_or_postal_code')} />
											<BookingDetailValue>{bookingDetail.zipCode}</BookingDetailValue>
										</BookingDetailBox>
									) : null}
									<BookingDetailBox>
										<BookingDetailTitle title={translate('common_payment_type')} />
										<BookingDetailValue>{get(bookingDetail, 'paymentMethodType', 'NA')}</BookingDetailValue>
									</BookingDetailBox>
									<BookingDetailBox>
										<BookingDetailTitle title={translate('common_payment_status')} />
										<BookingDetailValue
											highlight='true'
											color={get(bookingDetail, 'paymentStatus', 'NA') === 'UNPAID' ? 'warning' : 'success'}
										>
											{get(bookingDetail, 'paymentStatus', 'NA')}
										</BookingDetailValue>
									</BookingDetailBox>
									<BookingDetailBox>
										<BookingDetailTitle title='View Answered Q&A' />
										<Button
											variant='contained'
											onClick={() =>
												navigate('/dashboard/booking/q-and-a-detail', {
													state: { id: get(bookingDetail, '_id', '') },
												})
											}
										>
											{translate('consultant_filled_q_and_a_detail')}
										</Button>
									</BookingDetailBox>
									<Button
										variant='contained'
										onClick={() => window.open(`/dashboard/booking/attend-meeting/${bookingDetail._id}`)}
									>
										Attend Meeting
									</Button>
								</Stack>
							</CardContent>
						</Paper>
						<Paper variant='outlined' className='w-100 rounded-0'>
							<CMCardHeader
								title={translate('common_user_details')}
								titleSize='1rem'
								titleColor='grey'
								titleColorVariant='700'
							/>
							<CardContent>
								<Stack
									direction={{ sm: 'row', xs: 'column' }}
									flexWrap='wrap'
									justifyContent='space-between'
									sx={{ rowGap: 2, columnGap: 2 }}
								>
									{bookingDetail?.firstName && bookingDetail?.lastName && (
										<BookingDetailBox>
											<BookingDetailTitle title={translate('common_name')} />
											<BookingDetailValue>
												{get(bookingDetail, 'firstName', 'NA') +
													' ' +
													get(bookingDetail, 'lastName', 'NA')}
											</BookingDetailValue>
										</BookingDetailBox>
									)}
									{bookingDetail?.gender && (
										<BookingDetailBox>
											<BookingDetailTitle title={translate('common_gender')} />
											<BookingDetailValue>
												{get(bookingDetail, 'gender', 'NA') === 'M' ? 'Male' : 'Female'}
											</BookingDetailValue>
										</BookingDetailBox>
									)}
									{bookingDetail?.email && (
										<BookingDetailBox>
											<BookingDetailTitle title={translate('common_email')} />
											<BookingDetailValue>{get(bookingDetail, 'email', 'NA')}</BookingDetailValue>
										</BookingDetailBox>
									)}
								</Stack>
							</CardContent>
						</Paper>
						<Paper variant='outlined' className='w-100 rounded-0'>
							<CMCardHeader
								title={translate('common_plan_details')}
								titleSize='1rem'
								titleColor='grey'
								titleColorVariant='700'
							/>
							<CardContent>
								<Stack
									direction={{ sm: 'row', xs: 'column' }}
									flexWrap='wrap'
									justifyContent='space-between'
									sx={{ rowGap: 2, columnGap: 2 }}
								>
									<BookingDetailBox>
										<BookingDetailTitle title={translate('common_plan_name')} />
										<BookingDetailValue>
											{get(bookingDetail, 'rawPlanData.planName', 'NA')}
										</BookingDetailValue>
									</BookingDetailBox>
									<BookingDetailBox>
										<BookingDetailTitle title={translate('common_session_type')} />
										<BookingDetailValue>
											{get(bookingDetail, 'rawPlanData.sessionName', 'NA')}
										</BookingDetailValue>
									</BookingDetailBox>
									<BookingDetailBox>
										<BookingDetailTitle title={translate('common_time_unit')} />
										<BookingDetailValue highlight='true'>
											{get(bookingDetail, 'rawPlanData.timeUnit', 'NA')} Min.
										</BookingDetailValue>
									</BookingDetailBox>
									<BookingDetailBox>
										<BookingDetailTitle title={translate('common_amount')} />
										<BookingDetailValue highlight='true'>
											{get(bookingDetail, 'rawPlanData.currencyId.currencySymbol', 'NA') +
												' ' +
												get(bookingDetail, 'rawPlanData.amount', 'NA')}
										</BookingDetailValue>
									</BookingDetailBox>
									<BookingDetailBox>
										<BookingDetailTitle title={translate('common_number_of_consultation')} />
										<BookingDetailValue>
											{get(bookingDetail, 'rawPlanData.numberOfConsultant', 'NA')}
										</BookingDetailValue>
									</BookingDetailBox>
									<BookingDetailBox>
										<BookingDetailTitle title={translate('common_diet_plan')} />
										<BookingDetailValue>
											{get(bookingDetail, 'rawPlanData.dietPlan', false) ? (
												<CheckCircleIcon color='success' className='align-middle' />
											) : (
												<CancelIcon color='error' className='align-middle' />
											)}
										</BookingDetailValue>
									</BookingDetailBox>
									{get(bookingDetail, 'rawPlanData.onlineConsultation', false) ? (
										<BookingDetailBox>
											<BookingDetailTitle title={translate('common_online_consultation')} />
											<BookingDetailValue>
												{get(bookingDetail, 'rawPlanData.onlineConsultation', false) ? (
													<CheckCircleIcon color='success' className='align-middle' />
												) : (
													<CancelIcon color='error' className='align-middle' />
												)}
											</BookingDetailValue>
										</BookingDetailBox>
									) : null}

									{get(bookingDetail, 'rawPlanData.offlineConsultation', false) ? (
										<BookingDetailBox>
											<BookingDetailTitle title={translate('common_offline_consultation')} />
											<BookingDetailValue>
												{get(bookingDetail, 'rawPlanData.offlineConsultation', false) ? (
													<CheckCircleIcon color='success' className='align-middle' />
												) : (
													<CancelIcon color='error' className='align-middle' />
												)}
											</BookingDetailValue>
										</BookingDetailBox>
									) : null}

									{get(bookingDetail, 'rawPlanData.phoneConsultation', false) ? (
										<BookingDetailBox>
											<BookingDetailTitle title={translate('common_phone_consultation')} />
											<BookingDetailValue>
												{get(bookingDetail, 'rawPlanData.phoneConsultation', false) ? (
													<CheckCircleIcon color='success' className='align-middle' />
												) : (
													<CancelIcon color='error' className='align-middle' />
												)}
											</BookingDetailValue>
										</BookingDetailBox>
									) : null}
								</Stack>
								<BookingDetailBox
									sx={{
										marginTop: 2,
										gridTemplateColumns: '240px calc(100% - 240px)',
									}}
								>
									<BookingDetailTitle title={translate('common_details')} />
									<BookingDetailValue
										dangerouslySetInnerHTML={createMarkup(get(bookingDetail, 'rawPlanData.details', 'NA'))}
										sx={{
											'&>p': {
												margin: 0,
											},
										}}
									/>
								</BookingDetailBox>
							</CardContent>
						</Paper>
						<Paper variant='outlined' className='w-100 rounded-0'>
							<CMCardHeader
								title='Consultation Details'
								titleSize='1rem'
								titleColor='grey'
								titleColorVariant='700'
								border='1px'
							/>
							<CardContent>
								<Grid container spacing={2}>
									{map(get(bookingDetail, 'upcomingBookings', []), (data, index) => (
										<Grid item xs={12} sm={6} lg={4} xl={3} key={index}>
											<ConsultationDetailCard data={data} />
										</Grid>
									))}
									{bookingDetail.bookingStatus === 'IN_PROCESS' ? (
										<Grid item xs={12}>
											<CancelConsultations />
										</Grid>
									) : null}
								</Grid>
							</CardContent>
						</Paper>
					</Stack>
				)}
			</CardContent>
		</Paper>
	);
};

export default BookingDetail;
