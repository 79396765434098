import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  GetChatMessages,
  PostFileUpload,
  PostMessageAdd,
} from "../../../api/dashboard/ChatAPI";

const initialState = {
  chatMessages: [],
  loading: false,
};

export const getChatMessages = createAsyncThunk(
  "chat/getChatMessages",
  async (data) => {
    try {
      const response = await GetChatMessages(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const postMessageAdd = createAsyncThunk(
  "chat/postMessageAdd",
  async (data) => {
    try {
      const response = await PostMessageAdd(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const postFileUpload = createAsyncThunk(
  "chat/postFileUpload",
  async (data) => {
    try {
      const response = await PostFileUpload(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getChatMessages.pending, (state) => {
        state.loading = true;
      })
      .addCase(getChatMessages.fulfilled, (state, { payload }) => {
        state.chatMessages = get(payload, "data.message", []);
        state.loading = false;
      })
      .addCase(getChatMessages.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postMessageAdd.pending, (state) => {
        state.loading = true;
      })
      .addCase(postMessageAdd.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postMessageAdd.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postFileUpload.pending, (state) => {
        state.loading = true;
      })
      .addCase(postFileUpload.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postFileUpload.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default chatSlice.reducer;
