import React, { useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import style from './style.module.css';
import classNames from 'classnames';
import TextMessageUI from './TextMessageUI';
import ImageMessageUI from './ImageMessageUI';
import FileMessageUI from './FileMessageUI';

export default function MessageContainer({ chatList, senderId }) {
	const messagesContainerRef = useRef(null);

	useEffect(() => {
		messagesContainerRef.current?.scrollTo({
			top: messagesContainerRef.current.scrollHeight,
		});
	}, []);

	useEffect(() => {
		messagesContainerRef.current?.scrollTo({
			top: messagesContainerRef.current.scrollHeight,
		});
	}, [chatList]);

	return (
		<Box p={1} flex={1} sx={{ overflowX: 'hidden', overflowY: 'auto' }} ref={messagesContainerRef}>
			<Grid container className={style.root}>
				{chatList?.map((item) => {
					const messageClass = item.receiverId === senderId ? 'messageIn' : 'messageOut';

					return (
						<div className={classNames(style.container, style[messageClass])} key={item.id}>
							{item.messageType === 'text' && (
								<TextMessageUI message={item.text} messageClass={messageClass} time={item.createdAt} />
							)}
							{item.messageType === 'image' && <ImageMessageUI messageClass={messageClass} data={item} />}
							{item.messageType === 'file' && <FileMessageUI messageClass={messageClass} data={item} />}
						</div>
					);
				})}
				<div />
			</Grid>
		</Box>
	);
}
