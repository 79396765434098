import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PostSignin } from "../../../../api/signin";

const initialState = {
  loading: false,
};

export const postSignin = createAsyncThunk(
  "signin/postSignin",
  async (data) => {
    try {
      const response = await PostSignin(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const signinSlice = createSlice({
  name: "signin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postSignin.pending, (state) => {
        state.loading = true;
      })
      .addCase(postSignin.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postSignin.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const getUserData = (state) => state.signin.userData;
export default signinSlice.reducer;
