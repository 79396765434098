// component
// import Iconify from "../../../components/common/Iconify";

// ----------------------------------------------------------------------

// const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const HeaderConfig = [
  // {
  //   title: "Home",
  //   path: "/",
  //   icon: getIcon("eva:home-fill"),
  // },
  // {
  //   title: "About",
  //   path: "/about",
  //   icon: getIcon("brandico:blogger-rect"),
  // },
  // {
  //   title: "Contact",
  //   path: "/contact",
  //   icon: getIcon("eva:phone-call-fill"),
  // },
];

export const menuOptions = [
  {
    label: "Account",
    icon: "eva:person-fill",
    linkTo: "/dashboard",
  },
  {
    label: "Settings",
    icon: "eva:settings-2-fill",
    linkTo: "#",
  },
];

export default HeaderConfig;
