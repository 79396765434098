import React from 'react';
import { isEmpty, map } from 'lodash';

import VisibilityIcon from '@mui/icons-material/Visibility';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AvatarInnerButtonsWrapper } from '../../../ui-components/file-picker/component/FilePickerComponents';
import RenderFile from './RenderFile';

const REACT_APP_BUCKET_END_POINT = process.env.REACT_APP_BUCKET_END_POINT;

const FilePickerAppointmentPreview = (props) => {
	const {
		files,
		fileLabel,
		filePreviewSize,
		handleRemoveFile,
		value,
		name,
		theme,
		previewImageStyle,
		previewFileSize,
		noPreviewImageBaseURL,
	} = props;

	const { palette } = theme;
	const imageURL = noPreviewImageBaseURL ? value : REACT_APP_BUCKET_END_POINT + value;

	return (
		<Grid container spacing={2}>
			{!isEmpty(files) ? (
				map(files, (file) => (
					<RenderFile
						previewFileSize={previewFileSize}
						handleRemoveFile={handleRemoveFile}
						file={file}
						filePreviewSize={filePreviewSize}
						theme={theme}
						previewImageStyle={previewImageStyle}
						fileLabel={fileLabel}
					/>
				))
			) : value ? (
				<Grid item sm={previewFileSize ? previewFileSize : 12} xs={12}>
					<ListItem
						disablePadding
						sx={{
							columnGap: 2,
							padding: '4px',
							borderRadius: '6px',
							flexWrap: 'wrap',
						}}
						className='position-relative border'
					>
						<ListItemIcon
							className='position-relative'
							sx={{
								'&:hover > .avatar-hover-buttons': {
									display: 'inline-flex',
									transition: 'all 3000ms ease',
								},
							}}
						>
							<Avatar
								src={imageURL}
								alt={name + '_image'}
								variant='rounded'
								sx={{
									width: filePreviewSize ? filePreviewSize : 128,
									height: filePreviewSize ? filePreviewSize : 96,
									aspectRatio: '4/3',
									border: `2px solid ${palette.grey[200]}`,
									cursor: 'pointer',
									previewImageStyle,
								}}
							/>
							<AvatarInnerButtonsWrapper className='avatar-hover-buttons'>
								<Button
									aria-label='view-file'
									size='small'
									className='min-w-auto p-1'
									component={'a'}
									href={imageURL}
									target='_blank'
								>
									<VisibilityIcon sx={{ color: 'grey.200' }} />
								</Button>
								{/* <IconButton
                      aria-label="download-file"
                      size="small"
                      className="min-w-auto p-1"
                      component={"a"}
                      href={imageURL}
                      target="_blank"
                      value="download"
                      download={value.split("/").slice(-1).pop()}
                    >
                      <DownloadIcon sx={{ color: "grey.200" }} />
                    </IconButton> */}
							</AvatarInnerButtonsWrapper>
						</ListItemIcon>
						{fileLabel ? (
							<ListItemText
								primary={value.split('/').pop()}
								primaryTypographyProps={{ fontSize: '14px' }}
								sx={{ wordWrap: 'break-word' }}
							/>
						) : null}
					</ListItem>
				</Grid>
			) : null}
		</Grid>
	);
};

export default FilePickerAppointmentPreview;
