import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { get, isEmpty, map } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ShortProfileImg from '../../assets/images/men-employee-img.jpg';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';

import Box from '@mui/material/Box';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Label from '../common/Label';
import { getBookingDetail } from '../../store/slice/dashboard/bookingSlice';

const ShortProfile = (props) => {
	const { profileData, type } = props;

	const sortProfileTitle = type === 'chat' ? 'Customer Profile' : 'Undefine';

	const params = useParams();
	const dispatch = useDispatch();

	// const isLoading = useSelector((state) => state.booking.loading);
	// const bookingDetail = useSelector((state) => state.booking.bookingDetail);
	const [emptyData, setEmptyData] = useState(false);
	// eslint-disable-next-line no-unused-vars
	// const [isCopied, setIsCopied] = useState(false);
	const bookingDetail = useSelector((state) => state.booking.bookingDetail);
	const customerId = get(bookingDetail, 'userId._id', '');
	console.log('customerId :>> ', customerId);

	useEffect(() => {
		dispatch(getBookingDetail(get(params, 'bookingId', '')))
			.unwrap()
			.then((result) => {
				if (get(result, 'status', false)) {
					if (isEmpty(get(result, 'data', {}))) {
						setEmptyData(true);
					}
				} else {
					setEmptyData(true);
				}
			})
			.catch((err) => {
				throw err;
			});
	}, [dispatch, params]);

	return (
		<div>
			<>
				<Typography variant='h4' className='mb-3'>
					{sortProfileTitle}
				</Typography>
				<Box
					sx={{
						border: '1px solid #ebebeb',
						padding: '10px',
						borderRadius: '10px',
						position: 'relative',
					}}
				>
					<Label
						variant='ghost'
						color='secondary'
						className='position-absolute top-0 right-0 rounded-1 p-3'
						sx={{ fontSize: '16px' }}
					>
						{bookingDetail.createdBy === 'MASTER_ADMIN' ? 'InspireMind' : 'Customer'}
					</Label>
					<Stack direction='row' spacing={2} alignItems='flex-end' mb={1}>
						<Box
							sx={{
								width: '100px',
								height: '100px',
								border: '3px solid',
								borderColor: 'primary.contrastText',
							}}
						>
							<Box
								component='img'
								src={
									bookingDetail.createdBy === 'MASTER_ADMIN'
										? get(bookingDetail, 'userId.profilePictureURL', ShortProfileImg)
										: get(profileData, 'consultantId.profilePictureURL', ShortProfileImg)
								}
								alt='short profile image'
								className='img-cover w-100 h-100 rounded-2'
							/>
						</Box>
						<Box>
							<Typography variant='h5'>
								{bookingDetail.createdBy === 'MASTER_ADMIN'
									? get(bookingDetail, 'firstName', '') + ' ' + get(bookingDetail, 'lastName')
									: get(bookingDetail, 'firstName', '') + ' ' + get(bookingDetail, 'lastName', '')}
								{get(bookingDetail, 'userId.firstName', 'NA') + ' ' + get(bookingDetail, 'userId.lastName', 'NA')}
							</Typography>
						</Box>
					</Stack>

					{/* <Typography variant="body2" color="grey.600">
          {truncate(get(bookingDetail, 'consultantId.bio', "No bio available"), {
            length: 100,
          })}
        </Typography> */}

					<Box>
						<Divider className='mt-2' />
						<List className='d-flex flex-wrap'>
							<ListItem className='py-1 px-0'>
								<ListItemIcon>
									<MailIcon fontSize='small' />
								</ListItemIcon>
								<ListItemText>
									<Typography variant='body2'>{get(bookingDetail, 'userId.email', 'NA')}</Typography>
								</ListItemText>
							</ListItem>
							<ListItem className='py-1 px-0'>
								<ListItemIcon>
									<PhoneIcon fontSize='small' />
								</ListItemIcon>
								<ListItemText>
									<Typography variant='body2'>
										{get(bookingDetail, 'consultantId.code', '') +
											' ' +
											get(bookingDetail, 'consultantId.mobile', 'NA')}
									</Typography>
								</ListItemText>
							</ListItem>
							{/* <ListItem className="py-1 px-0">
              <ListItemIcon>
                <LocationOnIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">
                  {" "}
                  {get(bookingDetail, "consultantId.state", "NA") +
                    " " +
                    get(bookingDetail, "consultantId.country.name", "")}
                </Typography>
              </ListItemText>
            </ListItem> */}
						</List>

						{/* <Stack direction="row" alignItems="center" spacing={2}>
              {map(
                get(profileData, "consultantId.socialMediaInfo"),
                (item, index) => (
                  <Box key={index}>
                    <Link href={item.chanelLink} target="_blank"></Link>
                  </Box>
                )
              )}
            </Stack> */}
					</Box>
					{/* // ) : null} */}
				</Box>

				{/* <BookingBasicInfo /> */}
			</>
		</div>
	);
};

export default ShortProfile;
