import AxiosDefault from '../services/AxiosDefault';

export const PostUserExist = async (data) => {
	const response = await AxiosDefault({
		url: '/consultant/auth/signup-email-available',
		method: 'POST',
		data: data,
	});
	return response;
};

export const PostSignup = async (data) => {
	const response = await AxiosDefault({
		url: '/consultant/auth/signup',
		method: 'POST',
		data: data,
	});
	return response;
};

export const PostResendOtp = async (data) => {
	const response = await AxiosDefault({
		url: '/consultant/auth/resend-otp',
		method: 'POST',
		data: data,
	});
	return response;
};

export const PostOtpVerification = async (data) => {
	const response = await AxiosDefault({
		url: '/consultant/auth/verify-otp',
		method: 'POST',
		data: data,
	});
	return response;
};

// Consultant
export const PostConsultantInfoIdentityProofUpload = async (data) => {
	const response = await AxiosDefault({
		url: '/consultant/profile/identity-proof/upload',
		method: 'POST',
		data: data,
		contentType: 'multipart/form-data',
	});
	return response;
};

export const PostConsultantInfo = async (data) => {
	const response = await AxiosDefault({
		url: '/consultant/profile/info',
		method: 'POST',
		data: data,
	});
	return response;
};

export const GetConsultantInfo = async () => {
	const response = await AxiosDefault({
		url: `/consultant/profile/info`,
		method: 'GET',
	});
	return response;
};

export const GetConsultantMobileUIInfo = async (consultant_id) => {
	const response = await AxiosDefault({
		url: `/public/consultant/details/${consultant_id}`,
		method: 'GET',
	});
	return response;
};

export const GetIdentityProofType = async (id) => {
	const response = await AxiosDefault({
		url: `/consultant/identity-proof/${id}`,
		method: 'GET',
	});
	return response;
};

export const PostAddWork = async (data) => {
	const response = await AxiosDefault({
		url: '/consultant/profile/work/update',
		method: 'POST',
		data: data,
		contentType: 'multipart/form-data',
	});
	return response;
};

export const PostAddHighSchool = async (data) => {
	const response = await AxiosDefault({
		url: '/consultant/profile/education/high-school/update',
		method: 'POST',
		data: data,
		contentType: 'multipart/form-data',
	});
	return response;
};

export const PostAddUniversity = async (data) => {
	const response = await AxiosDefault({
		url: '/consultant/profile/education/university/update',
		method: 'POST',
		data: data,
		contentType: 'multipart/form-data',
	});
	return response;
};

export const PostAddLicenseCertificate = async (data) => {
	const response = await AxiosDefault({
		url: '/consultant/profile/license-certification/update',
		method: 'POST',
		data: data,
	});
	return response;
};

export const PostAddPublicationLinks = async (data) => {
	const response = await AxiosDefault({
		url: '/consultant/profile/publication-links/update',
		method: 'POST',
		data: data,
	});
	return response;
};

export const PostAddSocialMediaLinks = async (data) => {
	const response = await AxiosDefault({
		url: '/consultant/profile/social-media-info/update',
		method: 'POST',
		data: data,
	});
	return response;
};

export const PostDeleteProfileInfo = async ({ _id, type }) => {
	const response = await AxiosDefault({
		url: `/consultant/profile/info?_id=${_id}&type=${type}`,
		method: 'DELETE',
	});
	return response;
};

export const GetFaqInfo = async (type) => {
	const response = await AxiosDefault({
		url: `/consultant/faq?type=${type}`,
		method: 'GET',
	});
	return response;
};

export const SendOtp = async (data) => {
	const response = await AxiosDefault({
		url: `/consultant/profile/phone/otp`,
		method: 'POST',
		data: data,
	});
	return response;
};

export const VerifyOtp = async (data) => {
	const response = await AxiosDefault({
		url: `/consultant/profile/phone/otp-verify`,
		method: 'POST',
		data: data,
	});
	return response;
};

export const DeleteVerification = async (data) => {
	const response = await AxiosDefault({
		url: `/consultant/profile/phone/delete`,
		method: 'DELETE',
	});
	return response;
};

export const EmailChange = async (data) => {
	const response = await AxiosDefault({
		url: `/consultant/profile/profile/update`,
		method: 'PATCH',
		data: data,
	});
	return response;
};

export const EmailOTPVerify = async (data) => {
	const response = await AxiosDefault({
		url: `/consultant/profile/email/otp-verify`,
		method: 'POST',
		data: data,
	});
	return response;
};
