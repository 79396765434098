import React from 'react';

import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PublicLayoutWrapper = () => {
	const location = useLocation();
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

	return isLoggedIn ? <Navigate to='/dashboard' state={{ from: location }} replace /> : <Outlet />;
};

export default PublicLayoutWrapper;
