import { useEffect, useRef, useState } from 'react';

import { noCase } from 'change-case';
import { map } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
	Avatar,
	Badge,
	Box,
	Button,
	Divider,
	IconButton,
	List,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Tooltip,
	Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

// components
import Iconify from '../../../components/common/Iconify';
import MenuPopover from '../../../components/common/MenuPopover';
import Scrollbar from '../../../components/common/Scrollbar';
import AxiosDefault from '../../../services/AxiosDefault';
import { NOTIFICATIONS_LIST } from '../../../constant/api';
import { addNotification } from '../../../store/slice/notification/NotificationSlice';

NotificationItem.propTypes = {
	notification: PropTypes.object.isRequired,
};

function NotificationItem({ notification }) {
	return (
		<ListItemButton
			to={notification.routeURL}
			disableGutters
			component={RouterLink}
			sx={{
				py: 1.5,
				px: 2.5,
				mt: '1px',
				...(!notification.read && {
					bgcolor: 'action.selected',
				}),
			}}
		>
			<ListItemAvatar>
				<Avatar sx={{ bgcolor: 'background.neutral' }} alt='Remy Sharp' src={notification.icon} />
			</ListItemAvatar>
			<ListItemText
				primary={
					<Typography variant='subtitle2'>
						{notification.title}
						<Typography component='span' variant='body2' sx={{ color: 'text.secondary' }}>
							&nbsp; {noCase(notification.subTitle)}
						</Typography>
					</Typography>
				}
				secondary={
					<Typography
						variant='caption'
						sx={{
							mt: 0.5,
							display: 'flex',
							alignItems: 'center',
							color: 'text.disabled',
						}}
					>
						<Iconify icon='eva:clock-fill' sx={{ mr: 0.5, width: 16, height: 16 }} />
						{moment(new Date(notification.createdAt)).fromNow()}
					</Typography>
				}
			/>
		</ListItemButton>
	);
}

export default function NotificationsPopover() {
	const anchorRef = useRef(null);
	const dispatch = useDispatch();
	const notificationList = useSelector((state) => state.notification.notificationList);
	const totalUnRead = useSelector((state) => state.notification.unreadCount);
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		async function getList() {
			try {
				const response = await AxiosDefault({
					url: NOTIFICATIONS_LIST,
					method: 'GET',
				});
				dispatch(addNotification(response.data));
			} catch (error) {}
		}
		getList();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<IconButton
				ref={anchorRef}
				size='large'
				color={open ? 'primary' : 'default'}
				onClick={handleOpen}
				sx={{
					...(open && {
						bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
					}),
				}}
			>
				<Badge badgeContent={totalUnRead} color='error'>
					<Iconify icon='eva:bell-fill' width={20} height={20} />
				</Badge>
			</IconButton>

			<MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
				<Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
					<Box sx={{ flexGrow: 1 }}>
						<Typography variant='subtitle1'>Notifications</Typography>
						<Typography variant='body2' sx={{ color: 'text.secondary' }}>
							You have {totalUnRead} unread messages
						</Typography>
					</Box>

					{totalUnRead > 0 && (
						<Tooltip title=' Mark all as read'>
							<IconButton color='primary'>
								<Iconify icon='eva:done-all-fill' width={20} height={20} />
							</IconButton>
						</Tooltip>
					)}
				</Box>

				<Divider />

				<Scrollbar sx={{ height: { xs: 340 } }}>
					<List disablePadding>
						{map(notificationList, (notification) => (
							<NotificationItem key={notification._id} notification={notification} setOpen={setOpen} />
						))}
					</List>
				</Scrollbar>

				<Divider />

				<Box sx={{ p: 1 }}>
					<Button fullWidth disableRipple component={RouterLink} to='#'>
						View All
					</Button>
				</Box>
			</MenuPopover>
		</>
	);
}
