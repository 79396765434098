import React, { useEffect, useState } from 'react';

import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { Formik } from 'formik';
import { filter, get, isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { getConsultantData, getIdentityProofType } from '../../../../../store/slice/auth/signup/consultantInfoSlice';
import { addOfflineService, getOfflineService } from '../../../../../store/slice/dashboard/appointmentSlice';
import translate from '../../../../hooks/translate';
import CMRadio from '../../../../ui-components/form-components/CMRadio';
import FIlePickerAppoinment from '../../../../ui-components/file-picker/file-picker-appointment/FIlePickerAppoinment';
import FormLabel from '@mui/material/FormLabel';
import { changeServicePlanTypeStatus } from '../../../../../store/slice/dashboard/servicePlanSlice';

const Schema = Yup.object().shape({
	country: Yup.string().required('Country is required.'),
	state: Yup.string().required('State is required.'),
	city: Yup.string().required('City is required.'),
	addressLine: Yup.string().required('Address is required.'),
	zipCode: Yup.string().required('Zip Code is required.'),
	fileFront: Yup.string().required('front identity is Required'),
	fileBack: Yup.string().required('Back identity is Required'),
	proofOfIdentity: Yup.string().required('Identity Proof is Required'),
});

const SetOfflineDetails = ({ refreshData }) => {
	const dispatch = useDispatch();

	const offlineServiceData = useSelector((state) => state.appointment.offlineServiceData);
	const loading = useSelector((state) => state.appointment.loading);
	const countryLoading = useSelector((state) => state.common.countryLoading);
	const store = useSelector(getConsultantData);
	const identityType = get(store, 'identityType', []);
	const countryList = useSelector((state) => state.common.countryList);
	const serviceTypes = useSelector((state) => state.servicePlan.serviceTypes);

	const [countryId, setCountryId] = useState(
		get(
			filter(countryList, (country) => country.name === get(offlineServiceData, 'country', '')),
			'[0]._id',
			''
		)
	);
	useEffect(() => {
		if (!isEmpty(countryId)) {
			dispatch(getIdentityProofType(countryId));
		}
	}, [countryId, dispatch]);

	const [stateList, setStateList] = useState([]);
	const handleCountryChange = (data) => {
		setStateList(get(data, 'states', []));
		setCityList([]);
	};

	const [cityList, setCityList] = useState([]);
	const handleStateChange = (data) => {
		setCityList(get(data, 'cities', []));
	};

	const handleService = async (payload) => {
		const res = await dispatch(addOfflineService(payload))
			.unwrap()
			.then((result) => {
				if (result.status) {
					toast.success(result.message);
					dispatch(getOfflineService());
				} else {
					toast.error(result.message);
				}
			})
			.catch((error) => {
				console.log('Offline service error========>', error);
			});
		return res;
	};

	const _serviceTypeOffline = serviceTypes?.serviceTypeOffline;

	const handleOfflineStatus = (e) => {
		const payload = {
			serviceTypeOffline: !_serviceTypeOffline,
		};
		if (payload) {
			dispatch(changeServicePlanTypeStatus(payload))
				.unwrap()
				.then((result) => {
					if (result.status) {
						toast.success(result.message);
						refreshData();
					}
				})
				.catch((error) => {
					console.log('Service plan update error=======>', error);
				});
		}
	};

	if (loading || countryLoading) {
		return <LinearProgress />;
	}
	return (
		<Formik
			initialValues={{
				country: get(offlineServiceData, 'country', ''),
				state: get(offlineServiceData, 'state', ''),
				city: get(offlineServiceData, 'city', ''),
				addressLine: get(offlineServiceData, 'addressLine', ''),
				zipCode: get(offlineServiceData, 'zipCode', ''),
				proofOfIdentity: get(offlineServiceData, 'proofOfIdentity', ''),
				fileFront: get(offlineServiceData, 'identityFrontUrl', ''),
				fileBack: get(offlineServiceData, 'identityBackUrl', ''),
			}}
			validationSchema={Schema}
			onSubmit={(values) => handleService(values)}
		>
			{(props) => (
				<form onSubmit={props.handleSubmit}>
					{console.log(props)}
					<DialogContent dividers sx={{ px: { xs: 2, md: 3 }, py: 2 }}>
						<Grid item container spacing={2} xs={12}>
							<Grid item xs={12} md={6}>
								<Autocomplete
									disabled={offlineServiceData?._id ? (_serviceTypeOffline === true ? false : true) : false}
									getOptionLabel={(option) => option.name ?? option}
									name='country'
									value={props.values.country}
									options={countryList}
									isOptionEqualToValue={(option, value) => option.name === value}
									onChange={(e, value) => {
										props.setFieldValue('country', value.name);
										setCountryId(value._id);
										props.setFieldValue('proofOfIdentity', '');
										handleCountryChange(value);
									}}
									autoHighlight
									disableClearable
									noOptionsText={translate('common_no_data')}
									popupIcon={<KeyboardArrowDownSharpIcon />}
									renderOption={(props, option) => (
										<Box component='li' sx={{ '& > img': { mr: 1, flexShrink: 0 } }} {...props}>
											<Box component='span' mr={1}>
												{option.flag}
											</Box>
											{option.name}
										</Box>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											name='country'
											label={translate('consultant_select_country')}
											inputProps={{
												...params.inputProps,
												autoComplete: 'new-password',
											}}
											error={props.errors.country && props.touched.country ? true : false}
											helperText={
												props.errors.country && props.touched.country ? props.errors.country : null
											}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Autocomplete
									options={stateList}
									key={stateList}
									name='state'
									disabled={offlineServiceData?._id ? (_serviceTypeOffline === true ? false : true) : false}
									value={props.values.state}
									getOptionLabel={(option) => option.name ?? option}
									isOptionEqualToValue={(option, value) => option.name === value}
									onChange={(e, value) => {
										props.setFieldValue('state', value.name);
										handleStateChange(value);
									}}
									autoHighlight
									disableClearable
									noOptionsText={translate('common_no_data')}
									popupIcon={<KeyboardArrowDownSharpIcon />}
									renderOption={(props, option) => (
										<Box component='li' {...props}>
											{option.name}
										</Box>
									)}
									renderInput={(params) => (
										<TextField
											name='state'
											{...params}
											label={translate('consultant_select_state')}
											inputProps={{
												...params.inputProps,
												autoComplete: 'new-password',
											}}
											error={props.errors.state && props.touched.state ? true : false}
											helperText={props.errors.state && props.touched.state ? props.errors.state : null}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Autocomplete
									getOptionLabel={(option) => option.name ?? option}
									options={cityList}
									key={cityList}
									name='city'
									disabled={offlineServiceData?._id ? (_serviceTypeOffline === true ? false : true) : false}
									value={props.values.city}
									isOptionEqualToValue={(option, value) => option.name === value}
									onChange={(e, value) => {
										props.setFieldValue('city', value.name);
									}}
									autoHighlight
									disableClearable
									noOptionsText={translate('common_no_data')}
									popupIcon={<KeyboardArrowDownSharpIcon />}
									renderOption={(props, option) => (
										<Box component='li' {...props}>
											{option.name}
										</Box>
									)}
									renderInput={(params) => (
										<TextField
											name='city'
											{...params}
											label={translate('consultant_select_city')}
											inputProps={{
												...params.inputProps,
												autoComplete: 'new-password',
											}}
											error={props.errors.city && props.touched.city ? true : false}
											helperText={props.errors.city && props.touched.city ? props.errors.city : null}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									label={translate('consultant_zip_code_or_postal_code')}
									name='zipCode'
									disabled={offlineServiceData?._id ? (_serviceTypeOffline === true ? false : true) : false}
									value={props.values.zipCode}
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									error={props.errors.zipCode && props.touched.zipCode ? true : false}
									helperText={props.errors.zipCode && props.touched.zipCode ? props.errors.zipCode : null}
								/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									label={translate('consultant_address_line')}
									name='addressLine'
									disabled={offlineServiceData?._id ? (_serviceTypeOffline === true ? false : true) : false}
									value={props.values.addressLine}
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									multiline
									rows={3}
									error={props.errors.addressLine && props.touched.addressLine ? true : false}
									helperText={
										props.errors.addressLine && props.touched.addressLine ? props.errors.addressLine : null
									}
								/>
							</Grid>

							{identityType.length > 0 && (
								<Grid item xs={12}>
									<FormControl>
										<FormLabel className='mb-1'>{translate('Documents')}</FormLabel>
										<RadioGroup
											name='proofOfIdentity'
											value={props.values.proofOfIdentity}
											onChange={(e, value) => {
												props.setFieldValue('proofOfIdentity', value);
											}}
											row
											sx={{ marginLeft: '10px', marginTop: '10px' }}
										>
											{map(identityType, (item, index) => (
												<FormControlLabel
													key={index}
													disabled={
														offlineServiceData?._id
															? _serviceTypeOffline === true
																? false
																: true
															: false
													}
													checked={item.title === props.values.proofOfIdentity}
													value={item.title}
													control={<CMRadio />}
													label={translate(item?.titleMacro)}
												/>
											))}
										</RadioGroup>
									</FormControl>
									{props.errors.proofOfIdentity && props.touched.proofOfIdentity && (
										<FormHelperText error>{props.errors.proofOfIdentity}</FormHelperText>
									)}
								</Grid>
							)}

							<Grid container spacing={2} item xs={12}>
								<Grid item xs={12} md={6}>
									<FIlePickerAppoinment
										label={translate('consultant_front_file')}
										id='fileFront'
										name='fileFront'
										value={props.values.fileFront}
										setFieldValue={props.setFieldValue}
										disabled={offlineServiceData?._id ? (_serviceTypeOffline === true ? false : true) : false}
										error={props.errors.fileFront && props.touched.fileFront ? true : false}
										helperText={
											props.errors.fileFront && props.touched.fileFront ? props.errors.fileFront : false
										}
										dropBoxGridSize={12}
										previewGridSize={12}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<FIlePickerAppoinment
										label={translate('consultant_back_file')}
										id='fileBack'
										name='fileBack'
										disabled={offlineServiceData?._id ? (_serviceTypeOffline === true ? false : true) : false}
										value={props.values.fileBack}
										setFieldValue={props.setFieldValue}
										error={props.errors.fileBack && props.touched.fileBack ? true : false}
										helperText={
											props.errors.fileBack && props.touched.fileBack ? props.errors.fileBack : false
										}
										dropBoxGridSize={12}
										previewGridSize={12}
									/>
								</Grid>
							</Grid>
						</Grid>
					</DialogContent>

					<Divider sx={{ borderStyle: 'dashed' }} />

					<DialogActions sx={{ px: { xs: 3, md: 3 }, py: 2 }}>
						<Box>
							<Button
								variant='contained'
								type='submit'
								disabled={offlineServiceData?._id ? (_serviceTypeOffline === true ? false : true) : false}
							>
								{offlineServiceData?._id ? (
									translate('consulant_update_details')
								) : (
									<>
										{translate('common_btn_submit')} {translate('common_details')}
									</>
								)}
							</Button>

							{offlineServiceData?._id && (
								<Button
									variant='contained'
									color='error'
									style={{ marginLeft: '10px' }}
									onClick={handleOfflineStatus}
								>
									{_serviceTypeOffline
										? translate('consultant_offline_disable')
										: translate('consultant_offline_enable')}
								</Button>
							)}
						</Box>
					</DialogActions>
				</form>
			)}
		</Formik>
	);
};

export default SetOfflineDetails;
