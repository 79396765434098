import React, { useCallback, useEffect, useMemo, useState } from "react";

import NorthEastIcon from "@mui/icons-material/NorthEast";
import PreviewIcon from "@mui/icons-material/Preview";
import SouthWestIcon from "@mui/icons-material/SouthWest";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { get } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

import AvatarImg from "../../../../assets/images/user.svg";
import { getTransactionsList } from "../../../../store/slice/dashboard/bankingSlice";
import Label from "../../../common/Label";
import CMCardHeader from "../../../ui-components/cards/CMCardHeader";
import CMDataGrid from "../../../ui-components/data-grid/CMDataGrid";
import CMDataGridToolbar from "../../../ui-components/data-grid/CMDataGridToolbar";
import EmptyTableRowOverlay from "../../../ui-components/data-grid/EmptyTableRowOverlay";
import translate from "../../../hooks/translate";

const TransactionList = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.banking.loading);
  const transactionList = useSelector((state) => state.banking.transactionList);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowPerPage, setRowPerPage] = useState([10]);
  const [rowCountState, setRowCountState] = useState(0);

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 18,
    height: 18,
    fontSize: "12px",
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  const getTransactionsListData = useCallback(
    (pageNo) => {
      dispatch(getTransactionsList({ pageNo: pageNo }))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false)) {
            const totalTransactions = get(result, "data.totalTransactions", 0);
            const bookingPerPage = get(result, "data.OnThisPage", 0);
            setRowCountState(totalTransactions);
            setRowPerPage([bookingPerPage]);
          }
        })
        .catch((err) => {
          throw err;
        });
    },
    [dispatch]
  );

  useEffect(() => {
    getTransactionsListData(page);
  }, [dispatch, getTransactionsListData, page]);

  const columnsData = useMemo(
    () => [
      {
        field: "customerId",
        headerName: translate("consultant_customer_id"),
        headerClassName: "super-app-theme--header",
        width: 250,
        editable: false,
        renderCell: ({ row }) => (
          <Stack direction="row" spacing={2} alignItems="center">
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <SmallAvatar
                  sx={{
                    backgroundColor:
                      row.transactionType === "CREDIT"
                        ? "success.dark"
                        : "error.dark",
                  }}
                >
                  {true ? (
                    <SouthWestIcon fontSize="inherit" />
                  ) : (
                    <NorthEastIcon fontSize="inherit" />
                  )}
                </SmallAvatar>
              }
            >
              <Avatar
                sx={{ bgcolor: "success.dark", width: 38, height: 38 }}
                alt="Travis Howard"
                src={AvatarImg}
              />
            </Badge>
            <Stack direction="column">
              <Typography variant="subtitle2">
                {row.transactionType === "CREDIT"
                  ? "Receive money from"
                  : "Payment for"}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {get(row, "customerId.name", "NA")}
              </Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        field: "_id",
        headerName: translate("consultant_transaction_id"),
        width: 220,
        editable: false,
      },
      {
        field: "transactionType",
        headerName: translate("consultant_transaction_type"),
        width: 200,
        editable: false,
      },
      {
        field: "createdAt",
        headerName: translate("consultant_created_at"),
        type: "dateTime",
        width: 150,
        editable: false,
        renderCell: ({ row }) => (
          <Box>
            <Typography variant="subtitle2" color="text.secondary">
              {moment(get(row, "createdAt", "NA"), [
                "MM-DD-YYYY",
                "YYYY-MM-DD",
              ]).format("MM/DD/YYYY")}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {moment(get(row, "createdAt", "NA"), [
                "MM-DD-YYYY",
                "YYYY-MM-DD",
              ]).format("LT")}
            </Typography>
          </Box>
        ),
      },
      {
        field: "consultantAmount",
        headerName: translate("consultant_amount"),
        type: "number",
        width: 120,
        editable: false,
        renderCell: ({ row }) => (
          <Stack direction="row" spacing={0.5}>
            <Typography variant="subtitle2" color="text.secondary">
              {get(row, "consultantAmount", "NA")}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {get(row, "currencyId.currencySymbol", "")}
            </Typography>
          </Stack>
        ),
      },
      {
        field: "paymentStatus",
        headerName: translate("consultant_payment_status"),
        width: 150,
        editable: false,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row }) => (
          <Label
            color={
              row.transactionStatus === "PAID"
                ? "success"
                : row.transactionStatus === "UNPAID"
                ? "warning"
                : "error"
            }
          >
            {get(row, "transactionStatus", "NA")}
          </Label>
        ),
      },
      {
        field: "actions",
        headerName: translate("common_actions"),
        width: 120,
        headerAlign: "center",
        renderCell: ({ row }) => (
          <Box className="w-100 text-center">
            <Tooltip title="View All Booking Detail" arrow>
              <IconButton
                aria-label={`${row._id}-view`}
                color="primary"
                // onClick={() =>
                //   navigate("/dashboard/booking/booking-detail", {
                //     state: { id: row?._id },
                //   })
                // }
              >
                <PreviewIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  const CustomToolbar = () => {
    return <CMDataGridToolbar ToolbarQuickFilter={true} />;
  };

  return (
    <Card className="h-100">
      <CMCardHeader
        title={translate("consultant_payment_transaction_listing")}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CMDataGrid
              rows={transactionList ?? []}
              rowCount={rowCountState}
              columns={columnsData}
              getRowId={(row) => row._id}
              pagination
              pageSize={pageSize}
              page={page}
              rowsPerPageOptions={rowPerPage}
              paginationMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              disableSelectionOnClick={true}
              loading={isLoading}
              components={{
                Toolbar: CustomToolbar,
                NoRowsOverlay: EmptyTableRowOverlay,
                LoadingOverlay: LinearProgress,
              }}
              autoHeight
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TransactionList;
