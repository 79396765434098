import styled from '@emotion/styled';
import InputLabel from '@mui/material/InputLabel';
import { styled as MUIStyled, alpha } from '@mui/material/styles';

export const getBorderWidth = (props) => {
	if (props?.nativeProps?.isDragAccept || props?.nativeProps?.isDragReject || props?.nativeProps?.isFocused) {
		return '2px';
	}
	return '1px';
};

export const getBorderColor = (props, palette) => {
	if (props?.nativeProps?.isDragAccept) {
		return palette.success.main;
	}
	if (props?.nativeProps?.isDragReject) {
		return palette.error.main;
	}
	if (props?.nativeProps?.isFocused) {
		return palette.primary.main;
	}
	if (props?.error) {
		return palette.error.main;
	}
	return '#919eab52';
};

export const getLabelColor = (props, palette) => {
	if (props?.nativeProps?.isDragAccept) {
		return palette.success.main;
	}
	if (props?.nativeProps?.isDragReject) {
		return palette.error.main;
	}
	if (props?.nativeProps?.isFocused) {
		return palette.primary.main;
	}
	if (props?.error) {
		return palette.error.main;
	}
	return palette.text.secondary;
};

export const CustomDropzone = styled.div(({ props, theme }) => ({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	minHeight: 110,
	padding: '20px',
	borderWidth: '0',
	borderRadius: '8px',
	borderColor: getBorderColor(props, theme.palette),
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: 'inherit',
	outline: 'none',
	transition: 'border 0.24s ease-in-out',
	position: 'relative',
}));

export const CustomDropzoneLabel = MUIStyled(InputLabel)(({ props, theme }) => ({
	color: getLabelColor(props, theme.palette),
}));

export const CustomDropzoneLabelFieldset = styled.fieldset(({ props, theme }) => ({
	textAlign: 'left',
	position: 'absolute',
	bottom: 0,
	right: 0,
	top: '-5px',
	left: 0,
	margin: 0,
	padding: '0 8px',
	pointerEvents: 'none',
	borderRadius: 'inherit',
	borderStyle: 'solid',
	borderWidth: getBorderWidth(props),
	borderColor: getBorderColor(props, theme.palette),
	overflow: 'hidden',
	minWidth: '0%',
}));

export const CustomDropzoneLabelLegend = styled.legend(({ props, theme }) => ({
	float: 'unset',
	width: 'auto',
	overflow: 'hidden',
	display: 'block',
	padding: 0,
	height: '11px',
	fontSize: '0.75em',
	visibility: 'hidden',
	maxWidth: '100%',
	transition: 'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms',
	whiteSpace: 'nowrap',
}));

export const CustomDropzoneLabelText = styled.span(({ props, theme }) => ({
	paddingLeft: '5px',
	paddingRight: '5px',
	display: 'inline-block',
	opacity: 0,
	visibility: 'visible',
}));

export const ButtonBox = styled.div(({ theme }) => ({
	top: 0,
	right: 0,
	backgroundColor: alpha(theme.palette.grey[100], 0.75),
	transition: theme.transitions.easing.sharp,
}));

export const AvatarInnerButtonsWrapper = styled.div(({ theme, sx }) => ({
	position: 'absolute',
	zIndex: 'inherit',
	width: '100%',
	height: '100%',
	display: 'none',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: theme.spacing(1),
	gap: theme.spacing(2.5),
	backgroundColor: alpha(theme.palette.grey[900], 0.5),
	transition: theme.transitions.easing.sharp,
	...sx,
}));
