import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, omit } from 'lodash';

import { GetEventBlog, EventArchive, EventPublish, EventDelete, EventImageUpload } from '../../../api/dashboard/EventAPI';

const initialState = {
	eventList: [],
	totalArticle: 0,
	loading: false,
};

export const getEventBlog = createAsyncThunk('event/getEventBlog', async (data) => {
	try {
		const response = await GetEventBlog(omit(data, 'silentFetch'));
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const eventArchive = createAsyncThunk('event/eventArchive', async (data) => {
	try {
		const response = await EventArchive(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const eventPublish = createAsyncThunk('event/eventPublish', async (data) => {
	try {
		const response = await EventPublish(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const eventDelete = createAsyncThunk('event/eventDelete', async (id) => {
	try {
		const response = await EventDelete(id);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const eventImageUpload = createAsyncThunk('event/eventImageUpload', async (id) => {
	try {
		const response = await EventImageUpload(id);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const eventSlice = createSlice({
	name: 'event',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getEventBlog.pending, (state, action) => {
				const updateLoadingStatus = get(action, 'meta.arg.silentFetch', false);
				if (!updateLoadingStatus) {
					state.loading = true;
				}
			})
			.addCase(getEventBlog.fulfilled, (state, { payload }) => {
				state.totalArticle = get(payload, 'data.totalArticle', 0);
				state.eventList = get(payload, 'data.list', []);
				state.loading = false;
			})
			.addCase(getEventBlog.rejected, (state) => {
				state.loading = false;
			})
			.addCase(eventImageUpload.pending, (state) => {
				state.loading = true;
			})
			.addCase(eventImageUpload.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(eventImageUpload.rejected, (state) => {
				state.loading = false;
			})
			.addCase(eventDelete.pending, (state) => {
				state.loading = true;
			})
			.addCase(eventDelete.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(eventDelete.rejected, (state) => {
				state.loading = false;
			});
	},
});

export default eventSlice.reducer;
