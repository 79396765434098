import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import translate from '../../../components/hooks/translate';

const DashboardFooter = () => {
	return (
		<>
			<Box component='div' sx={{ backgroundColor: grey[200] }} className='p-2'>
				<Grid container alignItems='center'>
					<Grid item md={6} xs={6}>
						<Typography variant='subtitle1' color='text.secondary'>
							&copy; 2022
							<Link to='/' className='fw-bold'>
								<Typography variant='subtitle1' component='span' className='ms-2'>
									InspireMind
								</Typography>
							</Link>
						</Typography>
					</Grid>
					<Grid item md={6} xs={6} sx={{ textAlign: { sm: 'end', xs: 'end' } }}>
						<Link to='/terms-and-conditions' className='nav-links'>
							{translate('common_terms_and_conditions')}
						</Link>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default DashboardFooter;
