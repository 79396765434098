import React, { useEffect, useState } from 'react';

import { map } from 'lodash';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { TAB_LIST, TAB_TYPE } from './data';

export default function BookingTab({ filterOptions, setFilterOptions, getBookingsData }) {
	const [value, setValue] = useState(TAB_TYPE.UPCOMING);
	const { palette } = useTheme();

	useEffect(() => {
		const payload = {
			pageNo: 0,
			type: TAB_TYPE.UPCOMING,
		};
		getBookingsData(payload);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (_, newValue) => {
		setValue(newValue);
		setFilterOptions({ ...filterOptions, type: newValue });
		const payload = {
			pageNo: 0,
			type: newValue,
		};
		getBookingsData(payload);
	};

	return (
		<Box sx={{ backgroundColor: palette.primary.lighter }}>
			<Tabs value={value} onChange={handleChange} variant='scrollable' scrollButtons='auto'>
				{map(TAB_LIST, (item) => (
					<Tab label={item.title} value={item.type} key={item.id} sx={{ px: 2 }} />
				))}
			</Tabs>
		</Box>
	);
}
