import React, { useState, useEffect } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { Formik } from 'formik';
import { get, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import CMTextButton from '../../../../../components/ui-components/buttons/CMTextButton';
import { Drawer, FormHelperText, MenuItem, Select } from '@mui/material';
import { EventEmitter } from '../../../../../services/EventEmitter';
import {
	getConsultantInfo,
	postAddUniversity,
} from '../../../../../store/slice/auth/signup/consultantInfoSlice';
import translate from '../../../../hooks/translate';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';

const specialCharacter = /^[aA-zZ\s]+$/;
const schemaObj = Yup.object().shape({
	universityName: Yup.string()
		.matches(specialCharacter, translate('common_error_special_characters_not_allowed'))
		.required(translate('consultant_error_university_name_required')),
	title: Yup.string()
		.matches(specialCharacter, translate('common_error_special_characters_not_allowed'))
		.required(translate('consultant_error_degree_required')),
	fieldOfStudy: Yup.string()
		.matches(specialCharacter, translate('common_error_special_characters_not_allowed'))
		.required(translate('consultant_error_field_of_study_required')),
	// startDate: Yup.date()
	//   .nullable()
	//   .required(translate('consultant_error_start_date_required')),
	// endDate: Yup.date()
	//   .nullable()
	//   .required(translate('consultant_error_end_date_required')),
	// file: Yup.string().required(translate('consultant_error_file_required')),
});

const AddUniversityModal = (props) => {
	const { showButton } = props;
	const [open, setOpen] = useState(false);
	const [data, setData] = useState();
	const dispatch = useDispatch();

	useEffect(() => {
		EventEmitter.subscribe('educationUniversity', async (data) => {
			setData(data);
			setOpen(true);
		});
		EventEmitter.subscribe('addProfileUniversity', () => {
			setOpen(true);
		});
	}, []);

	const handleClickOpen = () => {
		setData({});
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleAddUniversity = async (payload) => {
		if (!isEmpty(data)) {
			payload._id = data._id;
			if (typeof payload.file === 'string') {
				payload.file = '';
			}
		}
		const res = await dispatch(postAddUniversity(payload))
			.unwrap()
			.then((result) => {
				if (get(result, 'status', false)) {
					dispatch(getConsultantInfo());
					handleClose();
				}
			})
			.catch((err) => {
				console.log(' handleAddUniversity err', err);
			});
		return res;
	};

	return (
		<>
			{showButton && (
				<CMTextButton
					onClick={handleClickOpen}
					startIcon={<AddCircleOutlineOutlinedIcon fontSize='small' />}
					color='primary'
				>
					{translate('consultant_add_university')}
				</CMTextButton>
			)}
			<Drawer
				anchor='right'
				open={open}
				onClose={handleClose}
				className='university-details-design'
			>
				<div>
					<Stack
						direction='row'
						gap={1}
						py={2}
						px={{ xs: 2, md: 3 }}
						alignItems='center'
						justifyContent='space-between'
					>
						<IconButton
							size='small'
							color='primary'
							onClick={handleClose}
							sx={{ minWidth: 0 }}
						>
							<KeyboardArrowLeftRoundedIcon />
						</IconButton>

						<Typography variant='h6'>
							{translate('consultant_add_university')}
						</Typography>
					</Stack>
					<Divider sx={{ borderStyle: 'dashed' }} />
				</div>
				<Formik
					initialValues={{
						universityName: get(data, 'universityName', ''),
						title: get(data, 'title', ''),
						fieldOfStudy: get(data, 'fieldOfStudy', ''),
						// degree: get(data, "degree", ""),
						// startDate: get(data, "startDate", ""),
						// endDate: get(data, "endDate", ""),
						// file: get(data, "documentUrl", ""),
					}}
					validationSchema={schemaObj}
					onSubmit={async (values, { resetForm }) => {
						await handleAddUniversity(values);
						resetForm();
					}}
				>
					{(props) => (
						<form
							onSubmit={props.handleSubmit}
							className='d-flex flex-column overflow-auto'
						>
							<DialogContent dividers sx={{ px: { xs: 2, md: 3 }, pt: 2 }}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6}>
										<TextField
											label={translate('consultant_university_name')}
											name='universityName'
											value={props.values.universityName}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											error={
												props.errors.universityName &&
												props.touched.universityName
													? true
													: false
											}
											helperText={
												props.errors.universityName &&
												props.touched.universityName
													? props.errors.universityName
													: null
											}
										/>
									</Grid>

									{/* <Grid item xs={12} sm={6}>
                    <TextField
                      label={translate("common_location")}
                      name="location"
                      value={props.values.location}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={
                        props.errors.location && props.touched.location
                          ? true
                          : false
                      }
                      helperText={
                        props.errors.location && props.touched.location
                          ? props.errors.location
                          : null
                      }
                    />
                  </Grid> */}

									{/* <Grid xs={12} sm={6}>
                    <FormControl fullWidth error={props.errors.degree && props.touched.degree}>
                      <Select
                        label={translate("consultant_degree")}
                        name="degree"
                        value={props.values.degree}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      error={props.errors.degree && props.touched.degree ? true : false}
                      helperText={props.errors.degree && props.touched.degree ? props.errors.degree : null}
                      >

                        <MenuItem value="bachelor">{translate("Bachelor")}</MenuItem>
                        <MenuItem value="master">{translate("Master")}</MenuItem>
                        <MenuItem value="doctorate">{translate("Doctorate")}</MenuItem>
                        <MenuItem value="other">{translate("Other")}</MenuItem>
                      </Select>

                      {props.errors.degree && props.touched.degree && (
                        <FormHelperText>{props.errors.degree}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid> */}

									<Grid item xs={6}>
										<FormControl
											fullWidth
											error={props.errors.title && props.touched.title}
										>
											{/* <FormLabel component="legend">{translate("consultant_degree")}</FormLabel> */}
											<Select
												name='title'
												value={props.values.title}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												displayEmpty
											>
												<MenuItem value={props.values.title} disabled>
													{translate('consultant_select_degrees')}
												</MenuItem>
												<MenuItem value='Bachelor'>
													{translate('Bachelor')}
												</MenuItem>
												<MenuItem value='Master'>
													{translate('Master')}
												</MenuItem>
												<MenuItem value='Doctorate'>
													{translate('Doctorate')}
												</MenuItem>
												<MenuItem value='Other'>
													{translate('Other')}
												</MenuItem>
											</Select>
											{props.errors.title && props.touched.title && (
												<FormHelperText>
													{props.errors.title}
												</FormHelperText>
											)}
										</FormControl>
									</Grid>

									<Grid item xs={12}>
										<TextField
											label={translate('consultant_field_of_study')}
											name='fieldOfStudy'
											value={props.values.fieldOfStudy}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											error={
												props.errors.fieldOfStudy &&
												props.touched.fieldOfStudy
													? true
													: false
											}
											helperText={
												props.errors.fieldOfStudy &&
												props.touched.fieldOfStudy
													? props.errors.fieldOfStudy
													: null
											}
										/>
									</Grid>

									{/* <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={1}
                      >
                        <MobileDatePicker
                          label={translate("consultant_start_date")}
                          name="startDate"
                          value={props.values.startDate}
                          onChange={(newValue) => {
                            const dateValue = newValue.format("YYYY/MM/DD");
                            props.setFieldValue("startDate", dateValue);
                          }}
                          onBlur={props.handleBlur}
                          inputFormat="DD/MM/YYYY"
                          toolbarTitle="Select Start Date"
                          disableFuture
                          toolbarPlaceholder=""
                          closeOnSelect={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              name="startDate"
                              error={
                                props.errors.startDate &&
                                props.touched.startDate
                                  ? true
                                  : false
                              }
                              helperText={
                                props.errors.startDate &&
                                props.touched.startDate
                                  ? props.errors.startDate
                                  : null
                              }
                            />
                          )}
                        />
                      </Stack>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={1}
                      >
                        <MobileDatePicker
                          label={translate("consultant_end_date")}
                          name="endDate"
                          value={props.values.endDate}
                          onChange={(newValue) => {
                            const dateValue = newValue.format("YYYY/MM/DD");
                            props.setFieldValue("endDate", dateValue);
                          }}
                          onBlur={props.handleBlur}
                          inputFormat="DD/MM/YYYY"
                          toolbarTitle="Select End Date"
                          disableFuture
                          toolbarPlaceholder=""
                          closeOnSelect={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              name="endDate"
                              error={
                                props.errors.endDate && props.touched.endDate
                                  ? true
                                  : false
                              }
                              helperText={
                                props.errors.endDate && props.touched.endDate
                                  ? props.errors.endDate
                                  : null
                              }
                            />
                          )}
                        />
                      </Stack>
                    </FormControl>
                  </Grid> */}
								</Grid>
							</DialogContent>

							<DialogActions
								style={{
									marginBottom: '10px',
									paddingTop: '16px',
									marginRight: '20px',
								}}
							>
								<Button variant='outlined' onClick={handleClose}>
									{translate('common_cancel')}
								</Button>
								<Button variant='contained' type='submit'>
									{translate('common_btn_submit')}
								</Button>
							</DialogActions>
						</form>
					)}
				</Formik>
			</Drawer>
		</>
	);
};

export default AddUniversityModal;
