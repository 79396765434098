import React from "react";

import { Stack, Paper, Typography } from "@mui/material";
import PublishedWithChangesRoundedIcon from "@mui/icons-material/PublishedWithChangesRounded";

import moment from "moment";

import Iconify from "../../../common/Iconify";
import Label from "../../../common/Label";
import ChangeStatus from "./ChangeStatus";

const InProgressCard = ({ data }) => {
  const consultationStatus = data.consultationStatus;
  return (
    <>
      <Paper
        className={`p-2 px-3 rounded h-100 ${
          consultationStatus === "IN_PROGRESS" &&
          data.appointmentSetup === "CLOSE" &&
          moment(data.startDate).isBefore(moment(), "second")
            ? "bounce"
            : ""
        }`}
        sx={{
          boxShadow: (theme) => theme.shadows[3],
          borderTop: (theme) => `4px solid ${theme.palette.primary.main}`,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="gap-3 mb-2"
        >
          <Label variant="ghost" color="primary">
            {consultationStatus}
          </Label>
          {consultationStatus === "IN_PROGRESS" &&
          data.appointmentSetup === "CLOSE" &&
          moment(data.startDate).isBefore(moment(),"second") ? (
            <ChangeStatus data={data} />
          ) : (
            <PublishedWithChangesRoundedIcon color="primary" />
          )}
        </Stack>
        <Typography variant="subtitle1" gutterBottom>
          {data.consultationNumber} Consultation
        </Typography>
        {data.appointmentSetup === "CLOSE" ? (
          <Stack direction="row" alignItems="center" className="gap-1">
            <Iconify icon="ic:round-access-time" color="text.secondary" />
            <Typography variant="caption" color="text.secondary">
              {`${moment(data.startDate).format("ddd, ll")} at ${
                data.startTime
              } to ${data.endTime}`}
            </Typography>
          </Stack>
        ) : null}
      </Paper>
      
    </>
  );
};

export default InProgressCard;
