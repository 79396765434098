import React, { useEffect, useState } from 'react';

import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import LocationCityTwoToneIcon from '@mui/icons-material/LocationCityTwoTone';
import PhoneAndroidTwoToneIcon from '@mui/icons-material/PhoneAndroidTwoTone';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import { grey } from '@mui/material/colors';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getCountryList, getPhoneCode } from '../../../store/slice/common';
import { getOfflineService, getPhoneService } from '../../../store/slice/dashboard/appointmentSlice';
import SetOfflineDetails from './service-type/offline/SetOfflineDetails';
import GoogleCalendar from './service-type/online/GoogleCalendar';
import SetPhoneDetails from './service-type/phone/SetPhoneDetails';
import translate from '../../hooks/translate';
import SetCalendarDetails from './service-type/calendar/SetCalendarDetails';
import { getServiceTypes } from '../../../store/slice/dashboard/servicePlanSlice';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 2 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const SetServiceTypes = () => {
	const dispatch = useDispatch();
	const { state } = useLocation();

	const [value, setValue] = useState(get(state, 'tanIndex', 0));

	const loading = useSelector((state) => state.common.countryLoading);

	const handleChange = (_event, newValue) => {
		setValue(newValue);
	};

	const [isRefresh, setIsRefresh] = useState(false);

	useEffect(() => {
		dispatch(getCountryList());
		dispatch(getPhoneCode());
		dispatch(getOfflineService());
		dispatch(getPhoneService());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(getServiceTypes());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isRefresh]);

	return (
		<>
			<Paper variant='outlined'>
				<CardContent>
					<Box
						sx={{
							borderBottom: 1,
							borderColor: grey[300],
						}}
					>
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label='basic tabs example'
							variant='scrollable'
							scrollButtons='auto'
						>
							<Tab
								icon={<CalendarMonthTwoToneIcon />}
								iconPosition='start'
								label={translate('common_online')}
								sx={{ minHeight: '45px' }}
								{...a11yProps(0)}
							/>
							<Tab
								icon={<LocationCityTwoToneIcon />}
								iconPosition='start'
								label={translate('common_offline')}
								sx={{ minHeight: '45px' }}
								{...a11yProps(1)}
							/>
							<Tab
								icon={<PhoneAndroidTwoToneIcon />}
								iconPosition='start'
								label={translate('common_phone')}
								sx={{ minHeight: '45px' }}
								{...a11yProps(2)}
							/>
							{/* <Tab
                icon={<EditCalendarIcon />}
                iconPosition="start"
                label={translate("Calendar")}
                sx={{ minHeight: "45px" }}
                {...a11yProps(2)}
              /> */}
						</Tabs>
					</Box>
					<TabPanel value={value} index={0}>
						<GoogleCalendar setIsRefresh={setIsRefresh} />
					</TabPanel>
					<TabPanel value={value} index={1}>
						{loading ? <LinearProgress /> : <SetOfflineDetails setIsRefresh={setIsRefresh} />}
					</TabPanel>
					<TabPanel value={value} index={2}>
						<SetPhoneDetails setIsRefresh={setIsRefresh} />
					</TabPanel>
					<TabPanel value={value} index={3}>
						<SetCalendarDetails />
					</TabPanel>
				</CardContent>
			</Paper>
		</>
	);
};

export default SetServiceTypes;
