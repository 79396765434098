import React from 'react';
import { get } from 'lodash';
import { renderFileStyle } from '../FilePickerAppointmentPreview.styles';
import { BiSolidFilePdf } from 'react-icons/bi';
import { Box, Typography } from '@mui/material';

export default function PdfViewRenderer({ file, filePreviewSize, previewImageStyle, theme, fileLabel }) {
	return (
		<Box className='position-relative' sx={renderFileStyle.ListItemIcon} width='100%' textAlign='center' padding={2}>
			<Box display='flex' justifyContent='center' alignItems='center'>
				<Box padding={1.5} borderRadius='50%' bgcolor='rgba(255,0,0,0.15)' width='48px' height='48px'>
					<BiSolidFilePdf color='red' fontSize={24} />
				</Box>
			</Box>

			{fileLabel ? (
				<Box mt={2}>
					<Typography variant='body1' fontWeight={600} lineHeight={1}>
						{get(file, 'path', '')}
					</Typography>
					<Typography variant='body2'>{(get(file, 'size', '') / 1024).toFixed(2) + ' kb'}</Typography>
					<Typography variant='caption' lineHeight={1} component='a' href={get(file, 'preview')} target='_blank'>
						Preview
					</Typography>
				</Box>
			) : null}
		</Box>
	);
}
