import { io } from 'socket.io-client';

class BaseSocket {
	constructor() {
		this._socket = null;
		this._connected = false;
		this._pendingListeners = [];
	}

	connect(token) {
		if (this._socket) {
			this._socket.disconnect();
			this._connected = false;
		}

		this._socket = io(process.env.REACT_APP_SOCKET_END_POINT, {
			extraHeaders: {
				auth: token,
			},
		});

		this._socket.on('connect_error', () => {
			this._connected = false;
		});
		this._socket.on('connect', () => {
			this._connected = true;
			this._pendingListeners.forEach((listener) => {
				this.on(listener.eventName, listener.cb);
			});
			this._pendingListeners = [];
		});
	}

	disconnect() {
		if (!this._socket) return;

		this._socket.disconnect();
		this._socket = null;
		this._connected = false;
	}

	send(event, data, cb) {
		if (!this._connected) return;

		this._socket.emit(event, data, (value) => {
			if (cb) {
				cb(value);
			}
		});
	}

	on(eventName, cb) {
		if (!this._connected) {
			this._pendingListeners.push({ eventName, cb });
			return;
		}

		this._socket.on(eventName, cb);
	}

	off(eventName, cb) {
		if (!this._socket) return;

		this._socket.off(eventName, cb);
	}

	// getters
	get isConnected() {
		return this._connected;
	}
}

const socket = new BaseSocket();
export default socket;
