import React, { useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { ImAttachment } from 'react-icons/im';
import { CgFileDocument } from 'react-icons/cg';
import { HiOutlineDocumentReport } from 'react-icons/hi';
// import { LiaFileVideo } from 'react-icons/lia';
import { getReadableFileSizeString } from '../../../../../../utils/formatNumber';
import { ALLOWED_EXT } from '../config';
import { toast } from 'react-toastify';
import { cleanUp } from './helper';
import { getFileType } from '../helpers';

const getAllowedExt = (uploadType) => {
	if (uploadType === 'report') {
		return '.pdf,.docx,.csv,.xlsx';
	}

	return '.pdf,.docx,.csv,.xlsx,.png,.jpeg,.jpg,.webp,.svg,.gif';
};

export default function UploadAttachment({ disabled, onFileUpload }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [uploadType, setUploadType] = React.useState('');
	const inputRef = useRef();
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleUploadBtnClick = (e) => {
		const type = e.target.dataset.type;
		setUploadType(type);

		inputRef.current.click();
		handleClose();
	};

	const handleUpload = (e) => {
		const file = e.target.files[0];
		if (!file) return;

		const ext = file.name.split('.').pop();
		if (!ALLOWED_EXT.includes(ext)) {
			toast.error('Invalid file type!');
			cleanUp(inputRef.current);
			return;
		}

		const form = new FormData();
		form.append('file', file);
		form.append('fileName', file.name);
		form.append('uploadType', uploadType);
		form.append(
			'meta',
			JSON.stringify({
				size: getReadableFileSizeString(file.size),
			})
		);
		onFileUpload(form, getFileType(ext), URL.createObjectURL(file));
	};

	return (
		<Box display='flex' alignItems='flex-end'>
			<input type='file' accept={getAllowedExt(uploadType)} className='d-none' ref={inputRef} onChange={handleUpload} />

			<IconButton
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				disabled={disabled}
			>
				<ImAttachment fontSize={17} color={disabled ? 'inherit' : 'gray'} />
			</IconButton>

			<Menu
				anchorEl={anchorEl}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				sx={{ transform: 'translate(-13px, -43px)' }}
				open={open}
				onClose={handleClose}
				MenuListProps={{ 'aria-labelledby': 'basic-button' }}
			>
				<MenuItem data-type='report' onClick={handleUploadBtnClick} sx={{ gap: 1 }}>
					<HiOutlineDocumentReport fontSize={16} />
					Report
				</MenuItem>
				<MenuItem data-type='document' onClick={handleUploadBtnClick} sx={{ gap: 1 }}>
					<CgFileDocument fontSize={16} />
					Document
				</MenuItem>
			</Menu>
		</Box>
	);
}
