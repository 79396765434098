import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Iconify from '../../common/Iconify';
import ListIcon from '@mui/icons-material/List';

import { debounce, get, isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getArticleBlog } from '../../../store/slice/dashboard/articleAndBlogSlice';
import DataNotFound from '../../common/DataNotFound';
import ArticleCardSkeleton from '../../ui-components/skeleton/ArticleCardSkeleton';
import ArticleFilter from './components/ArticleFilter';
import ArticleSearch from './components/ArticleSearch';
import ArticleTypes from './components/ArticleTypes';
import CustomPagination from './components/CustomPagination';
import translate from '../../hooks/translate';
import TableSkeleton from '../../ui-components/skeleton/TableSkeleton';
import BlogListRender from './BlogListRender';
import EventSubject from '../../../services/EventSubject';

const ArticleBlogPage = () => {
	const [viewType, setViewType] = useState('gallery');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [filterOptions, setFilterOptions] = useState({
		pageNo: 0,
		type: 'ALL',
		search: '',
		sortBy: '',
	});

	const articleBlogList = useSelector((state) => state.articleAndBlog.articleBlogList);
	const articleBlogLoading = useSelector((state) => state.articleAndBlog.loading);
	const userId = useSelector((state) => state.auth.meInfo._id);

	const getArticleData = useCallback(
		async (data) => {
			const payload = {
				pageNo: get(data, 'pageNo', 0),
				type: get(data, 'type', ''),
				sortBy: get(data, 'sortBy', ''),
				search: get(data, 'search', ''),
				silentFetch: get(data, 'silentFetch', false),
			};
			dispatch(getArticleBlog(payload));
		},
		[dispatch]
	);
	const getArticleDataDebounce = useMemo(() => debounce(getArticleData, 1000), [getArticleData]);
	useEffect(() => {
		const payload = {
			pageNo: 0,
			type: 'ALL',
			search: '',
			sortBy: '',
		};
		getArticleData(payload);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChangeView = (_, newView) => {
		if (newView !== null) {
			setViewType(newView);
		}
	};

	useEffect(() => {
		const subscription = EventSubject.subscribe((data) => {
			if (data.tag !== userId) return;
			if (data.target !== 'article') return;
			if (data.action === 'update-list') {
				getArticleDataDebounce({
					...filterOptions,
					silentFetch: true,
				});
			}
		});

		return () => {
			subscription.unsubscribe();
		};
	}, [userId, getArticleDataDebounce, filterOptions]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<ArticleSearch
					getArticleData={getArticleData}
					setFilterOptions={setFilterOptions}
					filterOptions={filterOptions}
				/>
			</Grid>

			<Grid item xs={12}>
				<ArticleTypes getArticleData={getArticleData} setFilterOptions={setFilterOptions} filterOptions={filterOptions} />
			</Grid>

			<Grid item xs={12}>
				<Stack
					direction={{ sm: 'row', xs: 'column' }}
					spacing={1}
					sx={{ justifyContent: { xs: 'end', sm: 'space-between' }, alignItems: { xs: 'end', sm: 'center' } }}
				>
					<Button
						variant='contained'
						color='primary'
						startIcon={<AddCircleIcon />}
						onClick={() => navigate('/dashboard/article-blog/add-blog')}
					>
						{translate('consultant_add_blog')}
					</Button>
					<Stack
						width={{ xs: '100%', sm: 'auto' }}
						gap={1}
						flexDirection='row'
						alignItems='center'
						justifyContent='flex-end'
					>
						<ArticleFilter
							getArticleData={getArticleData}
							setFilterOptions={setFilterOptions}
							filterOptions={filterOptions}
						/>

						<ToggleButtonGroup size='small' value={viewType} exclusive onChange={handleChangeView}>
							<ToggleButton value='list'>
								<ListIcon fontSize='small' />
							</ToggleButton>

							<ToggleButton value='gallery'>
								<Iconify icon='mingcute:dot-grid-fill' width={18} sx={{ height: 18 }} />
							</ToggleButton>
						</ToggleButtonGroup>
					</Stack>
				</Stack>
			</Grid>

			{articleBlogLoading ? (
				viewType === 'list' ? (
					<Grid item xs={12}>
						<TableSkeleton />
					</Grid>
				) : (
					map([1, 2, 3, 4], (data) => (
						<Grid item xs={12} md={6} sm={6} lg={4} xl={3} key={data}>
							<ArticleCardSkeleton />
						</Grid>
					))
				)
			) : null}

			{!articleBlogLoading && isEmpty(articleBlogList) && <DataNotFound />}
			{!articleBlogLoading && <BlogListRender list={articleBlogList} viewType={viewType} />}

			{viewType === 'gallery' && (
				<Grid item xs={12} lg={12}>
					{isEmpty(articleBlogList) && !articleBlogLoading ? null : (
						<CustomPagination
							getArticleData={getArticleData}
							setFilterOptions={setFilterOptions}
							filterOptions={filterOptions}
						/>
					)}
				</Grid>
			)}
		</Grid>
	);
};

export default ArticleBlogPage;
