import React, { useCallback, useState } from 'react';

const ErrorContext = React.createContext({
	errorMessage: '',
	updateErrorMessage: (_) => {},
});

export default function ErrorProvider({ children }) {
	const [errorMessage, setErrorMessage] = useState('');

	const updateErrorMessage = useCallback((message) => {
		setErrorMessage(message);
	}, []);

	const providerValue = {
		errorMessage,
		updateErrorMessage,
	};
	return <ErrorContext.Provider value={providerValue}>{children}</ErrorContext.Provider>;
}
