export const slotProps = {
	paper: {
		elevation: 0,
		sx: {
			overflow: 'visible',
			filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.2))',
			mt: '-10px',
			ml: '-10px',
			'&:before': {
				content: '""',
				display: 'block',
				position: 'absolute',
				bottom: -10,
				right: 14,
				width: 10,
				height: 10,
				bgcolor: 'background.paper',
				transform: 'translateY(-50%) rotate(45deg)',
				zIndex: 0,
			},
		},
	},
};
