/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Formik } from 'formik';
import { isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { getTimeZone } from '../../../../store/slice/common';
import { addAvailability, getAvailability } from '../../../../store/slice/dashboard/appointmentSlice';
import translate from '../../../hooks/translate';
import { getConsultantData } from '../../../../store/slice/auth/signup/consultantInfoSlice';
import { Schema } from './SetAvailability.validatiors';
import { DAYS_LIST, getDefaultTimeZone } from './helper';
import AvailabilityForm from './components/AvailabilityForm';

const SetAvailability = ({ disableForm }) => {
	const dispatch = useDispatch();
	const consultantData = useSelector(getConsultantData).data;
	const timeZoneList = useSelector((state) => state.common.timeZoneList);
	const availabilityData = useSelector((state) => state.appointment.availabilityList);
	const loading = useSelector((state) => state.appointment.loading);
	const [slots, setSlots] = useState([]);

	useEffect(() => {
		dispatch(getTimeZone());
		dispatch(getAvailability());
	}, []);

	const handleSubmitTime = async (data) => {
		const res = await dispatch(addAvailability(data))
			.unwrap()
			.then((result) => {
				if (result.status) {
					toast.success(result.message);
					dispatch(getAvailability());
				} else {
					toast.error(result.message);
				}
			})
			.catch((error) => {});
		return res;
	};

	return (
		<Card>
			<CardContent>
				{loading && isEmpty(availabilityData) && isEmpty(timeZoneList) ? (
					<LinearProgress />
				) : (
					<Formik
						enableReinitialize
						initialValues={{
							availability: availabilityData?.availability ? availabilityData?.availability : DAYS_LIST,
							timezone: getDefaultTimeZone(availabilityData, consultantData),
						}}
						validationSchema={Schema}
						onSubmit={(values, { resetForm }) => {
							handleSubmitTime(values);
							resetForm({ values });
						}}
					>
						{(props) => (
							<form onSubmit={props.handleSubmit}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6}>
										<Autocomplete
											options={timeZoneList}
											name='timezone'
											value={props.values.timezone}
											getOptionLabel={(option) => option.zoneName ?? option}
											isOptionEqualToValue={(option, value) => option.zoneName === value}
											onChange={(_, value) => {
												props.setFieldValue('timezone', value.zoneName);
											}}
											autoHighlight={true}
											disableClearable={true}
											disabled={disableForm}
											noOptionsText={translate('common_no_data')}
											popupIcon={<KeyboardArrowDownSharpIcon />}
											renderInput={(params) => (
												<TextField
													{...params}
													name='timezone'
													label={translate('consultant_select_time_zone')}
													size='small'
													error={props.errors.timezone && props.touched.timezone ? true : false}
													helperText={
														props.errors.timezone && props.touched.timezone
															? props.errors.timezone
															: null
													}
												/>
											)}
										/>
										<Typography
											variant='body1'
											sx={{ fontWeight: '500', color: 'text.primary' }}
											gutterBottom
											className='mt-3'
										>
											{translate('consultant_set_your_weekly_hours')}
										</Typography>
									</Grid>
								</Grid>
								<Grid container spacing={2}>
									{map(props?.values?.availability, (item, index) => (
										<AvailabilityForm
											item={item}
											index={index}
											props={props}
											slots={slots}
											setSlots={setSlots}
											key={index}
											disableForm={disableForm}
										/>
									))}

									<Grid item xs={12}>
										<Button type='submit' variant='contained' sx={{ float: 'right' }} disabled={disableForm}>
											{translate('common_btn_submit')}
										</Button>
									</Grid>
								</Grid>
							</form>
						)}
					</Formik>
				)}
			</CardContent>
		</Card>
	);
};

export default SetAvailability;
