export function getFormattedDate(date) {
	let dateObj;

	if (date) {
		dateObj = new Date(date);
	} else {
		dateObj = new Date();
	}

	return dateObj.toLocaleString('en-US', {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	});
}
export function formatChatMessage(item, isMine) {
	return {
		...item,
		id: item.id ?? item._id,
		isMine,
		createdAt: getFormattedDate(item.createdAt),
	};
}
export function formatChatList(list, recipientId) {
	return list.map((item) => formatChatMessage(item, item.senderID === recipientId));
}

export function getMessageStatus(data) {
	if (data.error) {
		return 'error';
	}
	if (data.completed) {
		return 'done';
	}
	return 'uploading';
}

export function extractDataFileFromName(fileName, meta) {
	const metaData = meta ?? {};
	const ext = fileName.split('.').pop();

	switch (ext) {
		case 'pdf':
			metaData.fileType = 'pdf';
			metaData.fileIconName = 'bi:file-pdf';
			metaData.fileIconColor = 'red';
			break;

		case 'docx':
		case 'csv':
		case 'xlsx':
			metaData.fileType = 'docx';
			metaData.fileIconName = 'vscode-icons:file-type-word';
			metaData.fileIconColor = 'blue';
			break;

		default:
			break;
	}

	return metaData;
}

export function getFileType(ext) {
	if (['png', 'jpeg', 'jpg', 'webp', 'svg', 'gif'].includes(ext)) {
		return 'image';
	}

	return 'file';
}
