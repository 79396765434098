import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

const initialState = {
	list: [],
	unreadCount: 0,
	loading: false,
};

export const NotificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		add(state, action) {
			state.list = get(action.payload, 'data.list', []);
			state.unreadCount = get(action.payload, 'data.unreadCount', 0);
		},
	},
});

export const NotificationSliceActions = NotificationSlice.actions;
export const addNotification = NotificationSliceActions.add;

export default NotificationSlice.reducer;
