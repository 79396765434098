import React, { useState, useEffect, useRef } from 'react';
import OtpInput from 'react-otp-input';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import ComponentStyle from '../../../../../../src/styles/ComponentStyle.module.css';

import { Formik } from 'formik';
import { get, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import {
	emailChange,
	emailOTPVerify,
	getConsultantInfo,
	postConsultantInfo,
} from '../../../../../store/slice/auth/signup/consultantInfoSlice';
import translate from '../../../../hooks/translate';
import { toast } from 'react-toastify';
import { FormHelperText, MenuItem, Select } from '@mui/material';
import { getPhoneCode, getTimeZone } from '../../../../../store/slice/common';
import Timer from '../../../../common/Timer';
import { ZIPMap } from '../../../../../utils/regex';

const ResidentialAddress = (props) => {
	const { handleNext, consultantInfo, buttonLabel, isEdit } = props;
	const dispatch = useDispatch();
	const store = consultantInfo;
	const consultantData = store.data;
	const initialDate = moment().subtract(21, 'years');
	const countryList = useSelector((state) => state.common.countryList);
	const phoneCode = useSelector((state) => state.common.phoneCode);
	const isLoading = useSelector((state) => state.consultantInfo.loading);
	// const [otpSent, setOtpSent] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [formSubmitted, setFormSubmitted] = useState(false);
	// const [otpVerified, setOtpVerified] = useState(false);
	// const [verify, setIsVerify] = useState(localStorage.getItem('verify') === true);
	// const [mobileOTP, setMobileOTP] = useState('');
	// const [mobileOTPSent, setMobileOTPSent] = useState(false);
	const [emailOTPSent, setEmailOTPSent] = useState(false);
	// const [canResendMobileOTP, setCanResendMobileOTP] = useState(false);
	const [canResendEmailOTP, setCanResendEmailOTP] = useState(false);
	const [emailOTP, setEmailOTP] = useState('');
	const [stateList, setStateList] = useState([]);
	const [country, setCountry] = useState();
	const [timeZone, setTimeZone] = useState();
	const timeZoneList = useSelector((state) => state.common.timeZoneList);
	const [countryId, setCountryId] = useState();
	const [countryTimeZone, setCountryTimeZone] = useState('');
	const [cityList, setCityList] = useState([]);
	const phoneRegExp = /^\d{10}$/;
	const [isEmailOTPSent, setIsEmailOTPSent] = useState(false);
	const [isEmailOTPVerified, setIsEmailOTPVerified] = useState(true);
	// eslint-disable-next-line no-unused-vars
	const [erorrAlert, setErorrAlert] = useState(false);
	// const mobileOTPTimer = useRef();
	const emailOTPTimer = useRef();

	// const [isVerified, setIsVerified] = useState(false);
	// const tempData = JSON.parse(localStorage?.getItem('tempData'));

	const handleCountryChange = (data) => {
		setCountryTimeZone(data?.timezone[0]?.zoneName);
		setStateList(get(data, 'states', []));
		setCountryId(data._id);
		setCityList([]);
	};

	const handleStateChange = (data) => {
		setCityList(get(data, 'cities', []));
	};

	// const handleMobileOTPChange = (code) => {
	// 	setMobileOTP(code);
	// };

	const handleEmailOTPChange = (code) => {
		setEmailOTP(code);
	};

	useEffect(() => {
		dispatch(getTimeZone());
		dispatch(getPhoneCode());
		let phoneCodeCountryWise;
		let timeZoneContryWise;
		timeZoneContryWise = timeZoneList?.filter((item) => item?.zoneName === countryTimeZone);
		phoneCodeCountryWise = phoneCode?.filter((item) => item._id === countryId);
		setCountry(phoneCodeCountryWise);
		setTimeZone(timeZoneContryWise);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryId, countryTimeZone]);

	const Schema = Yup.object().shape({
		country: Yup.object().required(translate('consultant_error_country_required')),
		// state: Yup.string().required(translate("consultant_error_state_required")),
		// city: Yup.string().required(translate("consultant_error_city_required")),
		otp: Yup.string().when('formSubmitted', {
			is: true,
			then: Yup.string().required(translate('consultant_error_otp_required')),
			otherwise: Yup.string().notRequired(),
		}),

		emailOTP: Yup.string().when('formSubmitted', {
			is: true,
			then: Yup.string().required(translate('consultant_error_otp_required')),
			otherwise: Yup.string().notRequired(),
		}),

		zipCode: Yup.object().when(['country'], (country) => {
			let regx = ZIPMap.IN;
			console.log('country', country);
			if (country?.name === 'United Kingdom') {
				regx = ZIPMap.UK;
			}
			return Yup.string()
				.matches(regx, 'Please enter Valid zip code / postal code')
				.required(translate('consultant_error_zipcode_or_postcode_required'));
		}),

		code: (country?.length === 0 || undefined) && Yup.string().required(translate('consultant_error_dialling_code_required')),
		mobile: Yup.string()
			.max(10, translate('consultant_error_phone_number_too_long'))
			.length(10, translate('consultant_error_please_enter_a_valid_phone_number'))
			.matches(phoneRegExp, translate('consultant_error_please_enter_a_valid_phone_number'))
			.required(translate('consultant_error_phone_number_required')),
		timeZoneProfile:
			(timeZone?.length === 0 || undefined) && Yup.string().required(translate('consultant_error_time_zone_required')),
		// language: Yup.array()
		//   .min(1, translate("consultant_error_languages_required"))
		//   .max(3, translate("consultant_error_languages_max_3")),
		gender: Yup.string().required(translate('consultant_error_gender_required')),
		DOB: Yup.date()
			.nullable()
			.required(translate('consultant_error_date_of_birth_required'))
			.min(moment().subtract(80, 'years').toDate(), 'You must be at least 80 years')
			.max(new Date(Date.now() - 662695990000), 'You must be at least 21 years'),
		email:
			isEdit &&
			Yup.string().email(translate('common_error_email_valid_email')).required(translate('common_error_email_required')),
	});

	// const sendOtpf = (values) => {
	// 	if (values.mobile === '') {
	// 		toast.error('Please enter mobile number!');
	// 		return;
	// 	}
	// 	if (values.code === '') {
	// 		toast.error('Please select code!');
	// 		return;
	// 	}

	// 	setMobileOTP('');
	// 	setOtpSent(true);

	// 	return new Promise((resolve) => {
	// 		const payload = { mobile: values.mobile, code: values.code };
	// 		dispatch(sendOtp(payload))
	// 			.unwrap()
	// 			.then((result) => {
	// 				if (result.status) {
	// 					toast.success(result.message);
	// 					// getBasicInformation()
	// 					setOtpSent(true); // Setting otpSent to true after sending OTP
	// 					setMobileOTPSent(true);
	// 					setCanResendMobileOTP(false);
	// 					resolve();

	// 					mobileOTPTimer.current = setTimeout(() => {
	// 						setCanResendMobileOTP(true);
	// 					}, 30000);
	// 				}
	// 			})
	// 			.catch((err) => {
	// 				console.log('sendOtp err', err);
	// 				resolve();
	// 			});
	// 	});
	// };

	// const handleAddressInfo = (values) => {
	// 	setErorrAlert(false);
	// 	const payload = { ...values, country: get(values, 'country._id', '') };
	// 	dispatch(postConsultantInfo(payload))
	// 		.unwrap()
	// 		.then((result) => {
	// 			if (result.status) {
	// 				!isEdit && handleNext(2);
	// 				toast.success(result.message);
	// 				getBasicInformation();
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			console.log(' handleAddressInfo err', err);
	// 			setErorrAlert(true);
	// 		});
	// };

	// const verifyOtpf = (values) => {
	// 	return new Promise((resolve) => {
	// 		const payload = { code: mobileOTP };
	// 		if (mobileOTP) {
	// 			dispatch(verifyOtp(payload))
	// 				.unwrap()
	// 				.then((result, values1) => {
	// 					if (result.status) {
	// 						if (result.message === 'Verify sucessfully') {
	// 							toast.success(result.message);
	// 							setOtpVerified(true);
	// 							resolve();
	// 							const newIsVerifyMode = !verify;
	// 							setIsVerify(newIsVerifyMode);
	// 							localStorage.setItem('verify', newIsVerifyMode.toString());
	// 							localStorage.setItem('mobile-verify', 1);
	// 							getBasicInformation();
	// 							// setIsVerified(true)
	// 							if (mobileOTPTimer.current) {
	// 								clearTimeout(mobileOTPTimer.current);
	// 							}
	// 							setCanResendMobileOTP(false);
	// 						} else {
	// 							toast.error(result.message);
	// 							resolve();
	// 						}
	// 					}
	// 				})
	// 				.catch((err) => {
	// 					console.log('verifyOtp err', err);
	// 				});
	// 		}
	// 	});
	// };

	const handleBasicInfo = (values) => {
		const payloadData = {
			gender: get(values, 'gender', ''),
			DOB: get(values, 'DOB', ''),
			timeZoneProfile: get(values, 'timeZoneProfile', ''),
			mobile: get(values, 'mobile', ''),
			// email:get(values, "email", ""),
			code: get(values, 'code', ''),
			zipCode: get(values, 'zipCode', ''),
			// city: get(values, "city", ""),
			// state: get(values, "state", ""),
			country: get(values, 'country._id', ''),
			// otp: get(values, "otp", "")
		};

		if (values?.city !== '') {
			payloadData['city'] = values?.city;
		}
		if (values?.state !== '') {
			payloadData['state'] = values?.state;
		}

		dispatch(postConsultantInfo(payloadData))
			.unwrap()
			.then((result) => {
				if (result.status) {
					// const step = otpVerified ? 3 : 2;
					!isEdit && handleNext(1);
					getBasicInformation();
					// console.log(result, "164");
					if (isEdit) {
						toast.success(result.message);
					}
				}
			})
			.catch((err) => {
				console.log(' basic err', err);
			});
	};

	// const handleVerificationDelete = () => {
	// 	dispatch(deleteVerification())
	// 		.unwrap()
	// 		.then((result) => {
	// 			const newIsVerifyMode = !verify || !consultantData?.isMobileNumberVerified;
	// 			setIsVerify(newIsVerifyMode);
	// 			localStorage.setItem('verify', newIsVerifyMode.toString());
	// 			setOtpSent(false);
	// 			setMobileOTPSent(false);
	// 			setOtpVerified(false);
	// 			getBasicInformation();
	// 		})
	// 		.catch((err) => {
	// 			console.log('err', err);
	// 		});
	// };

	const getBasicInformation = () => {
		dispatch(getConsultantInfo());
	};

	const handleChangeEmail = (payload) => {
		setEmailOTP('');
		if (isEdit && payload?.email) {
			dispatch(emailChange(payload))
				.unwrap()
				.then((result) => {
					if (result.status) {
						toast.success('OTP sent to your email!');
						setIsEmailOTPSent(true);
						setIsEmailOTPVerified(false);
						setCanResendEmailOTP(false);
						setEmailOTPSent(true);

						emailOTPTimer.current = setTimeout(() => {
							setCanResendEmailOTP(true);
						}, 30000);
					}
				})
				.catch((err) => {
					console.log('sendOtp err', err);
				});
		}
	};

	const emailOTPVerification = (payload) => {
		if (isEmpty(payload.code) || payload.code.length !== 6) {
			return;
		}

		if (isEdit) {
			dispatch(emailOTPVerify(payload))
				.unwrap()
				.then((result) => {
					if (result.status) {
						toast.success(result.message);
						setIsEmailOTPSent(false);
						setIsEmailOTPVerified(true);
						getBasicInformation();
						if (emailOTPTimer.current) {
							clearTimeout(emailOTPTimer.current);
						}
						setCanResendEmailOTP(false);
						setEmailOTPSent(false);
					} else {
						toast.error(result.message);
					}
				})
				.catch((err) => {
					console.log('verifyOtp err', err);
				});
		}
	};

	return (
		<Box>
			<Formik
				initialValues={{
					country: get(consultantData, 'country', ''),
					state: get(consultantData, 'state', ''),
					city: get(consultantData, 'city', ''),
					zipCode: get(consultantData, 'zipCode', ''),
					code: get(consultantData, 'code', ''),
					mobile: get(consultantData, 'mobile', ''),
					email: get(consultantData, 'email', ''),
					// language: get(consultantData, "language", []),
					// otp: get(consultantData, "otp", ""),
					// emailOTP: get(consultantData, "emailOTP", ""),
					timeZoneProfile: get(consultantData, 'timeZoneProfile', ''),
					gender: get(consultantData, 'gender', ''),
					DOB: get(consultantData, 'DOB', initialDate),
				}}
				// enableReinitialize
				validationSchema={Schema}
				onSubmit={(values) => {
					setFormSubmitted(true);
					if (isEmailOTPVerified) {
						const payload = {
							...values,
							code:
								country?.length === 0 || undefined
									? values.code
									: country && country[0]?.dialingcode
									? country[0]?.dialingcode
									: '',
							timeZoneProfile:
								timeZone?.length === 0 || undefined
									? values.timeZoneProfile
									: timeZone && timeZone[0]?.zoneName
									? timeZone[0]?.zoneName
									: '',
							step: isEdit ? get(consultantData, 'step', 1) : 1,
						};
						handleBasicInfo(payload);
					} else {
						toast.error('Please verify OTP');
					}
				}}
			>
				{(props) => (
					<form onSubmit={props.handleSubmit}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<FormControl fullWidth error={props.errors.gender && props.touched.gender}>
									<InputLabel id='gender-input-label'>{translate('consultant_gender')}</InputLabel>
									<Select
										labelId='gender-input-label'
										label={translate('consultant_gender')}
										name='gender'
										value={props.values.gender}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										displayEmpty
										sx={{ textTransform: 'capitalize' }}
									>
										<MenuItem sx={{ textTransform: 'capitalize' }} value='male'>
											{translate('male')}
										</MenuItem>
										<MenuItem sx={{ textTransform: 'capitalize' }} value='female'>
											{translate('female')}
										</MenuItem>
										<MenuItem sx={{ textTransform: 'capitalize' }} value='non-binary'>
											{translate('non_binary')}
										</MenuItem>
										<MenuItem sx={{ textTransform: 'capitalize' }} value='I prefer not to say'>
											{translate('i_prefer_not_to_say')}
										</MenuItem>
										<MenuItem sx={{ textTransform: 'capitalize' }} value='other'>
											{translate('other')}
										</MenuItem>
									</Select>
									{props.errors.gender && props.touched.gender && (
										<FormHelperText>{props.errors.gender}</FormHelperText>
									)}
								</FormControl>
							</Grid>

							<Grid item xs={12}>
								<FormControl fullWidth>
									<Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
										<MobileDatePicker
											label={translate('consultant_select_birth_date')}
											name='DOB'
											value={props.values.DOB}
											onChange={(newValue) => {
												const newDate = newValue.format('YYYY/MM/DD');
												props.setFieldValue('DOB', newDate);
											}}
											onBlur={props.handleBlur}
											inputFormat='DD/MM/YYYY'
											toolbarTitle='Select Birth Date'
											// disableOpenPicker={true}
											// ToolbarComponent='DatePickerToolbar'
											toolbarPlaceholder=''
											disableFuture
											closeOnSelect={true}
											minDate={moment().subtract(80, 'years').toDate()}
											maxDate={moment().subtract(21, 'years').toDate()}
											renderInput={(params) => (
												<TextField
													{...params}
													fullWidth
													name='DOB'
													error={props.errors.DOB && props.touched.DOB ? true : false}
													helperText={props.errors.DOB && props.touched.DOB ? props.errors.DOB : null}
												/>
											)}
										/>
									</Stack>
								</FormControl>
							</Grid>

							{/* =====PHONE VERIFICATION====== */}

							<Grid container item xs={12} spacing={1.5}>
								<Grid item xs={3}>
									<Autocomplete
										fullWidth
										getOptionLabel={(option) => option.dialingcode ?? option}
										options={phoneCode}
										isOptionEqualToValue={(option, value) => option.dialingcode === value}
										onChange={(event, value) => {
											props.setFieldValue(
												'code',
												country[0]?.dialingcode ? country[0]?.dialingcode : value?.dialingcode
											);
										}}
										name='code'
										value={
											country?.length === 0 || undefined
												? props.values.code
												: country && country[0]?.dialingcode
												? country[0]?.dialingcode
												: ''
										}
										autoHighlight
										disableClearable
										noOptionsText={translate('common_no_data')}
										popupIcon={<KeyboardArrowDownSharpIcon />}
										renderOption={(props, option) => (
											<Box component='li' sx={{ '& > img': { mr: 1, flexShrink: 0 } }} {...props}>
												<Box component='span' mr={1}>
													{option.flag}
												</Box>
												{option.dialingcode}
											</Box>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												label={translate('common_code')}
												name='code'
												inputProps={{
													...params.inputProps,
													autoComplete: 'new-password',
												}}
												error={props.errors.code && props.touched.code ? true : false}
												helperText={props.errors.code && props.touched.code ? props.errors.code : null}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={9} sm>
									<TextField
										fullWidth
										label={translate('consultant_phone_number')}
										name='mobile'
										value={props.values.mobile}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										type='text'
										inputProps={{ maxLength: 10 }}
										error={props.errors.mobile && props.touched.mobile ? true : false}
										helperText={props.errors.mobile && props.touched.mobile ? props.errors.mobile : null}
									/>
								</Grid>
								{/* 
								<Grid item xs={12} sm='auto' display='flex' alignItems='center'>
									{consultantData?.isMobileNumberVerified ? (
										<Stack
											pt={'10px'}
											width='max-content'
											direction='row'
											spacing={1}
											sx={{ ml: 'auto' }}
											style={{ color: 'green' }}
										>
											Verified!{' '}
											<span>
												<DeleteIcon
													style={{
														color: '#e65f5f',
														marginLeft: '3px',
													}}
													onClick={() => {
														handleVerificationDelete();
														props.setFieldValue('mobile', '');
														props.setFieldValue('otp', '');
													}}
												/>
											</span>
										</Stack>
									) : (
										<Stack width='max-content' direction='row' spacing={1} sx={{ ml: 'auto' }}>
											{mobileOTPSent && !canResendMobileOTP ? (
												<Timer time={30} />
											) : (
												<Button
													type='button'
													variant='contained'
													size='medium'
													onClick={() => {
														const data = {
															mobile: props.values.mobile,
															code: country[0]?.dialingcode || props.values.code,
														};

														sendOtpf(data);
													}}
													disabled={
														(props.isSubmitting && isLoading) ||
														(mobileOTPSent && !canResendMobileOTP)
													}
												>
													{mobileOTPSent ? 'Resend OTP' : 'Send OTP'}
												</Button>
											)}
										</Stack>
									)}
								</Grid> */}
							</Grid>

							{/* {otpSent &&
								(!otpVerified ? (
									<>
										<Grid xs={3} display={{ xs: 'none', sm: 'block' }} />
										<Grid item xs='auto'>
											<OtpInput
												name='otp'
												value={mobileOTP}
												onChange={handleMobileOTPChange}
												// onBlur={props.handleBlur}
												numInputs={4}
												separator={<span style={{ width: '8px' }}></span>}
												isInputNum={true}
												shouldAutoFocus={true}
												className={ComponentStyle.otp_inputs}
												containerStyle={
													{
														//   alignItems: "center",
														//   justifyContent: "center",
													}
												}
												hasErrored={erorrAlert}
												errorStyle={{
													color: '#b71c1c',
													borderColor: '#b71c1c',
												}}
												inputStyle={{
													border: '1px solid #CFD3DB',
													borderRadius: '8px',
													width: '40px',
													height: '40px',
													fontSize: '16px',
													color: '#777E89',
													fontWeight: '400',
													caretColor: '#777E89',
													margin: '0 auto',
												}}
												focusStyle={{
													border: '1px solid #CFD3DB',
													outline: 'none',
												}}
											/>
											<Button
												type='button'
												variant='contained'
												size='medium'
												onClick={() => {
													const data = {
														otp: props.values.otp,
													};
													verifyOtpf(data);
													// setOtpVerified(true);
												}}
												disabled={props.isSubmitting && isLoading}
											>
												Verify OTP
											</Button>
											<TextField
												label='Enter OTP'
												variant='outlined'
												name='otp'
												value={props.values.otp}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												type='text'
												error={
													(props.errors.otp && props.touched.otp) || // Show the error if it was already touched
													(formSubmitted && otpSent) // Show the error when form is submitted and OTP is not entered
												}
												helperText={
													(props.errors.otp && props.touched.otp) ||
													(formSubmitted && otpSent && !props.values.otp)
														? props.errors.otp || 'OTP is required'
														: null
												}
											/>
										</Grid>
										<Grid item xs='auto'>
											<Stack width='max-content'>
												<Button
													type='button'
													variant='contained'
													size='medium'
													onClick={() => {
														const data = {
															otp: props.values.otp,
														};
														verifyOtpf(data);
														// setOtpVerified(true);
													}}
													disabled={props.isSubmitting && isLoading}
												>
													Verify OTP
												</Button>
											</Stack>
										</Grid>
									</>
								) : null)} */}

							{/* =====EMAIL VERIFITION====== */}

							{isEdit && (
								<>
									<Grid item xs={12}>
										<Stack direction='row' spacing={1}>
											<TextField
												label='Email'
												name='email'
												color='secondary'
												value={props.values.email}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												error={props.errors.email && props.touched.email ? true : false}
												helperText={props.errors.email && props.touched.email ? props.errors.email : null}
											/>
											{consultantData?.email !== props.values.email ? (
												<Grid item xs={3} p={1}>
													<Stack
														width='max-content'
														direction='row'
														height='100%'
														alignItems='center'
														spacing={1}
														sx={{ ml: 'auto' }}
													>
														{emailOTPSent && !canResendEmailOTP ? (
															<Timer time={30} />
														) : (
															<Button
																type='button'
																variant='contained'
																size='medium'
																onClick={() => {
																	const data = {
																		email: props.values.email,
																	};
																	handleChangeEmail(data);
																}}
																disabled={
																	(props.isSubmitting && isLoading) ||
																	(emailOTPSent && !canResendEmailOTP)
																}
															>
																Change Email
															</Button>
														)}
													</Stack>
												</Grid>
											) : null}
										</Stack>
									</Grid>
								</>
							)}

							{isEdit && isEmailOTPSent && !isEmailOTPVerified && (
								<>
									<Grid item xs={9}>
										<OtpInput
											name='emailOTP'
											value={emailOTP}
											onChange={handleEmailOTPChange}
											// onBlur={props.handleBlur}
											numInputs={6}
											separator={<span style={{ width: '8px' }}></span>}
											isInputNum={true}
											shouldAutoFocus={true}
											className={ComponentStyle.otp_inputs}
											containerStyle={{ alignItems: 'center' }}
											hasErrored={erorrAlert}
											errorStyle={{
												color: '#b71c1c',
												borderColor: '#b71c1c',
											}}
											inputStyle={{
												border: '1px solid #CFD3DB',
												borderRadius: '8px',
												width: '40px',
												height: '40px',
												fontSize: '16px',
												color: '#777E89',
												fontWeight: '400',
												caretColor: '#777E89',
												margin: '0 auto',
											}}
											focusStyle={{
												border: '1px solid #CFD3DB',
												outline: 'none',
											}}
										/>
										{/* <TextField
                        label="Enter OTP"
                        variant="outlined"
                        name="emailOTP"
                        value={props.values.emailOTP}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        type="text"
                        error={
                          (props.errors.emailOTP && props.touched.emailOTP) || // Show the error if it was already touched
                          (formSubmitted && isEmailOTPSent) // Show the error when form is submitted and OTP is not entered
                        }
                        helperText={
                          (props.errors.emailOTP && props.touched.emailOTP) ||
                          (formSubmitted &&
                            isEmailOTPSent &&
                            !props.values.emailOTP)
                            ? props.errors.emailOTP || "OTP is required"
                            : null
                        }
                      /> */}
									</Grid>

									<Grid item xs={3} p={1}>
										<Stack width='max-content' direction='row' spacing={1} sx={{ ml: 'auto' }}>
											<Button
												type='button'
												variant='contained'
												size='medium'
												onClick={() => {
													const data = {
														code: emailOTP,
													};
													emailOTPVerification(data);
												}}
												disabled={props.isSubmitting && isLoading}
											>
												Verify OTP
											</Button>
										</Stack>
									</Grid>
								</>
							)}

							{/* ======================================================================= */}

							<Grid item xs={12}>
								<FormLabel htmlFor='age' className='mb-1'>
									Address
								</FormLabel>
							</Grid>

							<Grid item xs={12} sm={6}>
								<FormControl fullWidth>
									<Box>
										<Autocomplete
											getOptionLabel={(option) => (option.name ? option.name : '')}
											name='country'
											value={props.values.country}
											options={countryList}
											isOptionEqualToValue={(option, value) => option._id === value._id}
											onChange={(e, value) => {
												props.setFieldValue('country', value);
												handleCountryChange(value);
											}}
											autoHighlight
											disableClearable
											noOptionsText={translate('common_no_data')}
											popupIcon={<KeyboardArrowDownSharpIcon />}
											renderOption={(props, option) => (
												<Box component='li' sx={{ '& > img': { mr: 1, flexShrink: 0 } }} {...props}>
													<Box component='span' mr={1}>
														{option.flag}
													</Box>
													{option.name}
												</Box>
											)}
											renderInput={(params) => (
												<TextField
													{...params}
													placeholder={translate('consultant_select_country')}
													name='country'
													inputProps={{
														...params.inputProps,
														autoComplete: 'new-password',
													}}
													error={props.errors.country && props.touched.country ? true : false}
													helperText={
														props.errors.country && props.touched.country
															? props.errors.country
															: null
													}
												/>
											)}
										/>
									</Box>
								</FormControl>
							</Grid>

							<Grid item xs={12} sm={6}>
								<Autocomplete
									options={stateList}
									key={stateList}
									name='state'
									value={props.values.state}
									getOptionLabel={(option) => option.name ?? option}
									isOptionEqualToValue={(option, value) => option.name === value}
									onChange={(e, value) => {
										props.setFieldValue('state', value.name);
										handleStateChange(value);
									}}
									autoHighlight
									disableClearable
									noOptionsText={translate('common_no_data')}
									popupIcon={<KeyboardArrowDownSharpIcon />}
									renderOption={(props, option) => (
										<Box component='li' {...props}>
											{option.name}
										</Box>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											placeholder={translate('consultant_select_state')}
											name='state'
											inputProps={{
												...params.inputProps,
												autoComplete: 'new-password',
											}}
											error={props.errors.state && props.touched.state ? true : false}
											helperText={props.errors.state && props.touched.state ? props.errors.state : null}
										/>
									)}
								/>
							</Grid>

							<Grid item xs={12} sm={6}>
								<Autocomplete
									getOptionLabel={(option) => option.name ?? option}
									options={cityList}
									key={cityList}
									name='city'
									value={props.values.city}
									isOptionEqualToValue={(option, value) => option.name === value}
									onChange={(e, value) => {
										props.setFieldValue('city', value.name);
									}}
									autoHighlight
									disableClearable
									noOptionsText={translate('common_no_data')}
									popupIcon={<KeyboardArrowDownSharpIcon />}
									renderOption={(props, option) => (
										<Box component='li' {...props}>
											{option.name}
										</Box>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											placeholder={translate('consultant_select_city')}
											name='city'
											inputProps={{
												...params.inputProps,
												autoComplete: 'new-password',
											}}
											error={props.errors.city && props.touched.city ? true : false}
											helperText={props.errors.city && props.touched.city ? props.errors.city : null}
										/>
									)}
								/>
							</Grid>

							<Grid item xs={12} sm={6}>
								<TextField
									label={translate('consultant_zip_code_or_postal_code')}
									variant='outlined'
									name='zipCode'
									value={props.values.zipCode}
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									error={props.errors.zipCode && props.touched.zipCode ? true : false}
									helperText={props.errors.zipCode && props.touched.zipCode ? props.errors.zipCode : null}
								/>
							</Grid>

							<Grid item xs={12}>
								<Autocomplete
									options={timeZoneList}
									name='timeZoneProfile'
									getOptionLabel={(option) => {
										return option.zoneName ?? option;
									}}
									isOptionEqualToValue={(option, value) => option.zoneName === value}
									onChange={(e, value) => {
										props.setFieldValue(
											'timeZoneProfile',
											timeZone[0]?.zoneName ? timeZone[0]?.zoneName : value.zoneName
										);
									}}
									value={
										timeZone?.length === 0 || undefined
											? props.values.timeZoneProfile
											: timeZone && timeZone[0]?.zoneName
											? timeZone[0]?.zoneName
											: ''
									}
									autoHighlight={true}
									disableClearable={true}
									noOptionsText={translate('common_no_data')}
									popupIcon={<KeyboardArrowDownSharpIcon />}
									renderInput={(params) => (
										<TextField
											{...params}
											name='timeZoneProfile'
											label={translate('consultant_select_time_zone')}
											inputProps={{
												...params.inputProps,
												autoComplete: 'new-password',
											}}
											error={props.errors.timeZoneProfile && props.touched.timeZoneProfile ? true : false}
											helperText={
												props.errors.timeZoneProfile && props.touched.timeZoneProfile
													? props.errors.timeZoneProfile
													: null
											}
										/>
									)}
								/>
							</Grid>

							<Grid item xs={12}>
								<Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
									{/* {!isEdit && (
                      <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={handleBack}
                      >
                        {translate("common_btn_back")}
                      </Button>
                    )} */}
									<Stack direction='row' spacing={1} sx={{ ml: 'auto' }}>
										{/* Step 3: Update the label of the submit button */}
										<Button type='submit' variant='contained' disabled={props.isSubmitting && isLoading}>
											{buttonLabel}
										</Button>
										{/* <Button
                        variant="text"
                        onClick={() => {
                          handleNext(2);
                        }}
                      >
                        Skip
                      </Button> */}
									</Stack>
								</Stack>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	);
};

export default ResidentialAddress;
