import React, { useEffect, useState } from 'react';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import { cloneDeep, get, isEmpty, map, omit, uniqueId } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { getSpecialization } from '../../../../store/slice/common';
import MultiSelectSpecialization, {
	getInitSpecialization,
	getInitSubSpecialization,
	getSpecializationSubmitData,
} from '../../../common/MultiSelectSpecialization';
import translate from '../../../hooks/translate';
import useContextData from '../../../hooks/useContextData';
import ContentSection from './ContentSection';
import SeoSection from './SeoSection';
import TopSection from './TopSection';
import 'react-image-crop/dist/ReactCrop.css';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material';
import EventSubject from '../../../../services/EventSubject';
import { addJob, jobTypes } from '../../../common/JobQueue';
import { toast } from 'react-toastify';
import axios from 'axios';

const schemaObj = Yup.object().shape({
	title: Yup.string()
		.min(3, translate('consultant_error_title_too_short'))
		.max(1000, translate('consultant_error_title_too_long'))
		.required(translate('consultant_error_title_required')),

	subTitle: Yup.string()
		.min(3, translate('consultant_error_subtitle_too_short'))
		.max(1000, translate('consultant_error_subtitle_too_long')),
	language: Yup.object().shape().required(translate('consultant_error_languages_required')).nullable(),
	categories: Yup.array().min(1, 'Min 1 Category').max(3, 'Max 3 Category').nullable(),
	topSection: Yup.array().of(
		Yup.object().shape({
			title: Yup.string()
				.min(3, translate('consultant_error_image_title_too_short'))
				.max(500, translate('consultant_error_image_title_too_long'))
				.required(translate('consultant_error_image_title_required')),
			imageUrl: Yup.mixed().when('imageType', {
				is: 'IMAGE_UPLOAD',
				then: Yup.array().required(translate('consultant_error_image_required')),
				otherwise: Yup.mixed()
					// .url(translate("consultant_error_enter_valid_url"))
					.required(translate('consultant_error_image_link_required')),
			}),
		})
	),
	contentSection: Yup.array().of(
		Yup.object().shape({
			data: Yup.mixed()
				.when(['preferredOption', 'data_content_len'], (preferredOption, data_content_len) => {
					if (preferredOption === 'TEXT') {
						if (!data_content_len) return Yup.string().required(translate('consultant_error_content_required'));
					}
				})
				.when(['dataType', 'preferredOption'], (dataType, preferredOption) => {
					if (preferredOption === 'IMAGE' && dataType === 'IMAGE_LINK') {
						return (
							Yup.mixed()
								// .url(translate("consultant_error_enter_valid_url"))
								.required(translate('consultant_error_image_link_required'))
						);
					} else if (preferredOption === 'IMAGE' && dataType === 'IMAGE_UPLOAD') {
						return Yup.mixed().required(translate('consultant_error_image_required'));
					}
				})
				.when(['dataType', 'preferredOption'], (dataType, preferredOption, _, data) => {
					if (preferredOption === 'VIDEO') {
						if (typeof data.originalValue === 'string') {
							return Yup.string()
								.url(translate('consultant_error_enter_valid_url'))
								.required(translate('consultant_error_video_links_required'));
						}

						if (isEmpty(data.originalValue)) {
							return Yup.string()
								.url(translate('consultant_error_enter_valid_url'))
								.required(translate('consultant_error_video_links_required'));
						}
					}
				}),

			title: Yup.string().when(['preferredOption'], (preferredOption) => {
				if (
					preferredOption === 'IMAGE' ||
					preferredOption === 'VIDEO' ||
					preferredOption === 'AUDIO' ||
					preferredOption === 'PDF'
				) {
					return Yup.string()
						.min(3, 'Title is very Short')
						.max(500, 'Title is very Long')
						.required('Title is required');
				}
			}),
		})
	),
	// seoTitle: Yup.string()
	// 	.min(40, translate('consultant_error_seo_title_too_short'))
	// 	.max(60, translate('consultant_error_seo_title_too_long'))
	// 	.required(translate('consultant_error_seo_title_required')),
	// seoDescription: Yup.string()
	// 	.min(80, translate('consultant_error_seo_description_too_short'))
	// 	.max(155, translate('consultant_error_seo_description_too_long'))
	// 	.required(translate('consultant_error_seo_description_required')),
	// seoMetaTags: Yup.array()
	// 	.min(3, translate('consultant_error_seo_meta_tags_too_short'))
	// 	.max(10, translate('consultant_error_seo_meta_tags_too_long'))
	// 	.required(translate('consultant_error_seo_meta_tags_required')),
});

const LoadingOverlay = ({ jobId, setIsFormSubmitting }) => {
	const navigate = useNavigate();
	const { palette } = useTheme();
	const [content, setContent] = useState('Saving...');
	const [showBackgroundJobBox, setShowBackgroundJobBox] = useState(false);

	useEffect(() => {
		if (!jobId) return;

		const subscription = EventSubject.subscribe((data) => {
			if (!jobId.includes(data.id)) return;

			setShowBackgroundJobBox(true);
			if (data.completed) {
				toast.success(data.content);
				navigate('/dashboard/article-blog');
				setIsFormSubmitting(false);
			}
			if (data.error) {
				toast.success(data.content);
				setIsFormSubmitting(data.content);
			}
			if (data.value === 0 || data.value === 100) {
				setContent('Saving...');
			} else {
				setContent(data.content);
			}
		});

		return () => {
			subscription.unsubscribe();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [jobId]);

	return (
		<Box
			position='absolute'
			top={0}
			left={0}
			right={0}
			bottom={0}
			display='flex'
			justifyContent='center'
			alignItems='center'
			backgroundColor={alpha(palette.primary.main, 0.3)}
			sx={{ backdropFilter: 'blur(1px)' }}
			zIndex={99999}
		>
			<Box
				width='100%'
				maxWidth='650px'
				bgcolor='white'
				padding='10px'
				display='flex'
				justifyContent='center'
				alignItems='center'
				flexDirection='column'
				gap={1}
			>
				<Typography variant='h5' fontWeight='bold'>
					{content}
				</Typography>
				{showBackgroundJobBox && (
					<Button variant='outlined' onClick={() => navigate('/dashboard/article-blog')}>
						Process in background
					</Button>
				)}
			</Box>
		</Box>
	);
};

const ArticleBlogForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const { GetLanguageList } = useContextData();

	const [jobId, setJobId] = useState(null);
	const [checkImage, setCheckImage] = useState(true);
	const [isFormSubmitting, setIsFormSubmitting] = useState(false);
	const [defaultLanguage, setDefaultLanguage] = useState(JSON.parse(localStorage.getItem('selectedHeaderLanguage')));

	const LanguageList = GetLanguageList();
	const articleData = get(location, 'state.item', {});
	const formType = get(location, 'state.type', '');
	const isContentCreationPending = get(articleData, 'status', '') === 'pending';
	const specializationList = useSelector((state) => state.common.specializationList);

	useEffect(() => {
		const selectedLanguage = localStorage.getItem('selectedHeaderLanguage');
		selectedLanguage && setDefaultLanguage(JSON.parse(selectedLanguage));
	}, []);

	const handleArticle = async (values, formikHelpers) => {
		const totalContentLength = get(values, 'contentSection', []).reduce((accumulator, currentValue) => {
			if (currentValue.preferredOption !== 'TEXT') return accumulator;

			const contentLength = currentValue.data_content_len;
			if (contentLength) return accumulator + contentLength;

			const textLength = get(currentValue, 'data', '').split(' ').length;
			return accumulator + textLength;
		}, 0);
		if (totalContentLength < 500) {
			toast.error('Content should be more than 500 words!');
			return;
		}
		if (totalContentLength > 3500) {
			toast.error('Content should be less than 3500 words!');
			return;
		}

		setIsFormSubmitting(true);
		const selectedImage = values?.topSection?.filter((item) => item.selectedImageIndex === 0);
		let check = values?.topSection.some((item) => item.selectedImageIndex === 0);
		setCheckImage(check);

		const thumbnailData =
			selectedImage[0]?.imageType === 'IMAGE_UPLOAD'
				? selectedImage.map((elem) => {
						const imageObject = elem.imageUrl.filter((item) => item.type === 'horizontal')[0];

						let imageUrlType = '';
						let imageUrl = imageObject.url;
						if (imageObject.file) {
							imageUrl = imageObject.file;
							imageUrlType = 'file';
						}
						return { ...elem, imageUrl: imageUrl, imageUrlType };
				  })
				: selectedImage;

		const data = {
			...omit(values, 'subCategories', 'contentSection'),
			language: get(values, 'language._id', ''),
			thumbnail: thumbnailData,
			categories: getSpecializationSubmitData(values, values.subCategories, 'categories', 'subCategories'),
			contentSection: map(get(values, 'contentSection', []), (contentSection) => {
				return omit(contentSection, 'mediaFile', '_id', 'data_content_len');
			}),
		};
		if (formType === 'update') {
			const newData = thumbnailData.map(({ _id, ...rest }) => rest);
			data._id = get(articleData, '_id', '');
			data.thumbnail = newData;
			data.topSection = map(get(values, 'topSection', []), (topSection) => omit(topSection, '_id'));
		}

		const fileMap = {};

		data.topSection.forEach((section) => {
			section.imageUrl.forEach((item) => {
				if (item.file) {
					const id = uniqueId();
					fileMap[id] = item.file;
					item.file = id;
				}
			});
		});
		data.thumbnail.forEach((item) => {
			if (item.imageUrlType === 'file') {
				const id = uniqueId();
				fileMap[id] = item.imageUrl;
				item.imageUrl = id;
				item.imageUrlType = 'file';
			}
		});
		data.contentSection.forEach((item) => {
			if (item.dataType === 'VIDEO_UPLOAD') {
				if (typeof item.data !== 'string') {
					const id = uniqueId();
					fileMap[id] = item.data;
					item.data = id;
					item.file = true;
				}
			} else if (item.dataType === 'IMAGE_UPLOAD') {
				item.data.forEach((item) => {
					if (item.file) {
						const id = uniqueId();
						fileMap[id] = item.file;
						item.file = id;
					}
				});
			}
		});

		if (!check) {
			setIsFormSubmitting(false);
			return;
		}
		const res = await axios.post('/consultant/article-blog/add', { content: data });
		if (res.status === 200 && Array.isArray(res.data)) {
			const jobs = res.data
				.map((item) => {
					const file = fileMap[item.data];
					if (!file) return null;
					return { file, meta: item };
				})
				.filter(Boolean);

			const jobIds = addJob({
				payload: jobs,
				jobType: jobTypes.BLOG_CREATE,
				title: `Uploading files for ${data.title}`,
				_id: uniqueId(),
			});
			setJobId(jobIds);
			navigate('/dashboard/article-blog');
		}
	};

	useEffect(() => {
		dispatch(getSpecialization({ sectionName: 'SPECIALIZATION' }));
	}, [dispatch]);

	return (
		<Card>
			<CardContent>
				{specializationList.length > 0 && (
					<Formik
						initialValues={{
							title: get(articleData, 'title', ''),
							subTitle: get(articleData, 'subTitle', ''),
							language:
								formType === 'update'
									? get(articleData, 'language[0]', '')
									: isEmpty(defaultLanguage)
									? LanguageList[0]
									: defaultLanguage,
							categories: getInitSpecialization(articleData, specializationList, 'categories'),
							subCategories: getInitSubSpecialization(
								articleData,
								specializationList,
								'categories',
								'subCategories'
							),
							topSection:
								articleData?.topSection?.imageType === 'IMAGE_LINK'
									? get(articleData, 'topSection', [
											{
												imageType: 'IMAGE_LINK',
												title: '',
												imageUrl: '',
												selectedImageIndex: 0,
											},
									  ])
									: get(articleData, 'topSection', [
											{
												imageType: 'IMAGE_UPLOAD',
												title: '',
												imageUrl: '',
												selectedImageIndex: 0,
											},
									  ]),

							contentSection: get(articleData, 'contentSection', [
								{
									preferredOption: 'TEXT', //TEXT/IMAGE/VIDEO
									dataType: 'TEXT',
									data: '',
									title: '',
									data_content_len: 0,
								},
							]),

							isPublished: get(articleData, 'isPublished', false),
							ratingStatus: get(articleData, 'ratingStatus', false),
							likeStatus: get(articleData, 'likeStatus', false),
							comments: get(articleData, 'comments', false),
							seoTitle: get(articleData, 'seoTitle', ''),
							seoDescription: get(articleData, 'seoDescription', ''),
							seoMetaTags: get(articleData, 'seoMetaTags', []),
						}}
						validationSchema={schemaObj}
						onSubmit={(values, formikHelpers) => {
							handleArticle(cloneDeep(values), formikHelpers);
						}}
					>
						{(props) => (
							<form onSubmit={props.handleSubmit}>
								<Grid container spacing={2} justifyContent='center'>
									{isFormSubmitting && (
										<LoadingOverlay jobId={jobId} setIsFormSubmitting={setIsFormSubmitting} />
									)}
									<Grid item xs={12}>
										<TextField
											label={translate('common_title')}
											name='title'
											fullWidth
											multiline
											value={props.values.title}
											onChange={(e, value) => {
												props.setFieldValue('title', e.target.value);
												props.setFieldValue('seoTitle', e.target.value);
											}}
											onBlur={props.handleBlur}
											error={props.errors.title && props.touched.title ? true : false}
											helperText={props.errors.title && props.touched.title ? props.errors.title : null}
										/>
									</Grid>

									<Grid item xs={12}>
										<TextField
											label={translate('common_subtitle')}
											name='subTitle'
											fullWidth
											multiline
											value={props.values.subTitle}
											onChange={(e) => {
												props.setFieldValue('subTitle', e.target.value);
											}}
											onBlur={props.handleBlur}
											error={props.errors.subTitle && props.touched.subTitle ? true : false}
											helperText={
												props.errors.subTitle && props.touched.subTitle ? props.errors.subTitle : null
											}
										/>
									</Grid>

									<Grid item xs={12}>
										<FormControl fullWidth>
											<Autocomplete
												getOptionLabel={(option) => {
													return option.title ?? '';
												}}
												options={LanguageList}
												name='language'
												value={props.values.language}
												isOptionEqualToValue={(option, value) => {
													if (value === '' || option._id === value._id) {
														return true;
													}
												}}
												onChange={(_e, value) => {
													props.setFieldValue('language', value);
												}}
												onBlur={props.handleBlur}
												disablePortal
												autoHighlight
												disableClearable
												noOptionsText={translate('common_no_data')}
												popupIcon={<KeyboardArrowDownSharpIcon />}
												renderInput={(params) => (
													<TextField
														{...params}
														label={translate('common_language')}
														placeholder={translate('consultant_select_language')}
														name='language'
														error={props.errors.language && props.touched.language ? true : false}
														helperText={
															props.errors.language && props.touched.language
																? props.errors.language
																: null
														}
													/>
												)}
											/>
										</FormControl>
									</Grid>

									<Grid item xs={12}>
										<MultiSelectSpecialization
											isDefaultSEOmetaTag={true}
											specialization={specializationList}
											fieldName='categories'
											subFieldName='subCategories'
											formProps={props}
											initialData={articleData}
											isSubmit={false}
										/>
									</Grid>

									<Grid item xs={12}>
										<Divider orientation='horizontal' flexItem />
									</Grid>

									{/* -----TOP SECTION----- */}

									<TopSection
										formType={formType}
										formProps={props}
										checkImage={checkImage}
										setCheckImage={setCheckImage}
										isPreviewOpen={false}
									/>

									<Grid item xs={12}>
										<Divider orientation='horizontal' flexItem />
									</Grid>

									{/* -----CONTENT SECTION----- */}

									<ContentSection formProps={props} formType={formType} isPreviewOpen={false} />

									{/* -----CONFIGURATION----- */}
									{/* <Grid item xs={12}>
										<Stack direction='row' className='gap-1'>
											<Typography variant='h4' className='mb-2'>
												{translate('consultant_configuration_section')}
											</Typography>{' '}
											<CMToolTip
												icon={<HelpTwoToneIcon size='small' />}
												title=''
											/>
										</Stack>
									</Grid>
									<Grid item xs={12} sm={6} lg={3}>
										<Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
											<Typography variant='body1'>{translate('consultant_publish')}</Typography>
											<Switch
												color='primary'
												checked={props.values.isPublished}
												onChange={(e) => props.setFieldValue('isPublished', e.target.checked)}
											/>
										</Stack>
									</Grid>
									<Grid item xs={12} sm={6} lg={3}>
										<Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
											<Typography variant='body1'>{translate('consultant_enabled_comments')}</Typography>
											<Switch
												color='primary'
												checked={props.values.comments}
												onChange={(e) => props.setFieldValue('comments', e.target.checked)}
											/>
										</Stack>
									</Grid>
									<Grid item xs={12} sm={6} lg={3}>
										<Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
											<Typography variant='body1'>{translate('consultant_enabled_rating')}</Typography>
											<Switch
												color='primary'
												checked={props.values.ratingStatus}
												onChange={(e) => props.setFieldValue('ratingStatus', e.target.checked)}
											/>
										</Stack>
									</Grid>
									<Grid item xs={12} sm={6} lg={3}>
										<Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
											<Typography variant='body1'>{translate('consultant_enabled_like')}</Typography>
											<Switch
												color='primary'
												checked={props.values.likeStatus}
												onChange={(e) => props.setFieldValue('likeStatus', e.target.checked)}
											/>
										</Stack>
									</Grid> */}

									<Grid item xs={12}>
										<Divider orientation='horizontal' flexItem />
									</Grid>

									{/* -----SEO SECTION----- */}

									<SeoSection
										isContentCreationPending={isContentCreationPending}
										formProps={props}
										isFormSubmitting={isFormSubmitting}
									/>
								</Grid>
							</form>
						)}
					</Formik>
				)}
			</CardContent>
		</Card>
	);
};

export default ArticleBlogForm;
