import React from 'react';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { FieldArray, getIn } from 'formik';
import { get, size } from 'lodash';

import translate from '../../../hooks/translate';
import FilePicker from '../../../ui-components/file-picker/FilePicker';
import { useTheme } from '@emotion/react';
import FilePickerPreview from '../../../ui-components/file-picker/component/FilePickerPreview';

const ContentTopSection = (props) => {
	const { formProps } = props;
	const theme = useTheme();

	const handleRemove = (index) => {
		formProps.values.topSection.splice(index, 1);
		const check = formProps.values.topSection.some((image) => image.selectedImageIndex === 0);
		if (!check) {
			formProps.values.topSection[0].selectedImageIndex = 0;
		}

		const topSection = formProps.values.topSection;
		formProps.setFieldValue('topSection', topSection);
	};

	return (
		<>
			<Grid item xs={12}>
				<FieldArray name='topSection'>
					{() => (
						<Box>
							{formProps.values.topSection.length >= 0 &&
								formProps.values.topSection.map((item, index) => {
									const title = `topSection[${index}].title`;
									const errorImgTitle = getIn(formProps.errors, title);
									const touchedImgTitle = getIn(formProps.touched, title);

									const imageUrl = `topSection[${index}].imageUrl`;
									const errorImageUrl = getIn(formProps.errors, imageUrl);
									const touchedImageUrl = getIn(formProps.touched, imageUrl);

									const isLargeTopSection = size(get(formProps, 'values.topSection')) > 1;

									const imageUploaded = Array.isArray(item?.imageUrl);
									const imageTypeUpload = item.imageType === 'IMAGE_UPLOAD';

									return (
										<Box key={index}>
											{index !== 0 ? (
												<Box>
													<Stack
														direction='row'
														justifyContent='flex-start'
														alignItems='center'
														spacing={1}
													>
														<Divider
															orientation='horizontal'
															flexItem={true}
															light={true}
															sx={{
																width: `calc(100% - 48px)`,
																margin: 'auto 0 !important',
															}}
														/>
														{isLargeTopSection && (
															<IconButton
																color='error'
																size='small'
																onClick={(event) => {
																	handleRemove(index);
																}}
															>
																<ClearOutlinedIcon />
															</IconButton>
														)}
													</Stack>
												</Box>
											) : null}

											<Grid container spacing={2}>
												<Grid item xs={12}>
													<Box mb={-1}>
														<FormControl fullWidth>
															<FormLabel sx={{ fontWeight: 'bold' }}>Thumbnail</FormLabel>
														</FormControl>
													</Box>
												</Grid>

												{imageTypeUpload && !imageUploaded && (
													<Grid item xs={12} md={6}>
														<FilePicker
															isPreviewOpen={false}
															label={translate('common_image_upload')}
															id='imageUrl'
															name={imageUrl}
															value={item?.imageUrl}
															onChange={(file) => formProps.setFieldValue(imageUrl, file)}
															formProps={formProps}
															error={errorImageUrl && touchedImageUrl ? true : false}
															helperText={errorImageUrl && touchedImageUrl ? errorImageUrl : false}
															dropBoxGridSize={12}
															previewGridSize={6}
															noPreviewImageBaseURL={true}
															uploadEventId={imageUrl}
														/>
													</Grid>
												)}
												{!imageTypeUpload && (
													<Grid item xs={12}>
														<TextField
															type='url'
															label={translate('consultant_image_link')}
															name={imageUrl}
															value={item.imageUrl[0]?.url ? item.imageUrl[0]?.url : item.imageUrl}
															onChange={formProps.handleChange}
															onBlur={formProps.handleBlur}
															error={errorImageUrl && touchedImageUrl ? true : false}
															helperText={errorImageUrl && touchedImageUrl ? errorImageUrl : null}
														/>
													</Grid>
												)}

												<Grid item xs={12} md={imageTypeUpload && imageUploaded ? 12 : 6}>
													<TextField
														label={translate('consultant_image_title')}
														name={title}
														value={item.title}
														onChange={formProps.handleChange}
														onBlur={formProps.handleBlur}
														error={errorImgTitle && touchedImgTitle ? true : false}
														helperText={errorImgTitle && touchedImgTitle ? errorImgTitle : null}
													/>
												</Grid>
											</Grid>

											{imageTypeUpload && (
												<Grid item xs={6}>
													<Box>
														<FilePickerPreview
															fileLabel={false}
															name={imageUrl}
															value={item?.imageUrl}
															theme={theme}
															noPreviewImageBaseURL={true}
														/>
													</Box>
													<Box display='flex' gap={1} mt={2}>
														{imageUploaded && (
															<Button
																color='error'
																variant='outlined'
																onClick={() => formProps.setFieldValue(imageUrl, '')}
															>
																Replace image
															</Button>
														)}
													</Box>
												</Grid>
											)}
										</Box>
									);
								})}
						</Box>
					)}
				</FieldArray>
			</Grid>
		</>
	);
};

export default React.memo(ContentTopSection);
