import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  DeleteVerification,
  EmailChange,
  EmailOTPVerify,
  GetConsultantInfo,
  GetConsultantMobileUIInfo,
  GetIdentityProofType,
  PostAddHighSchool,
  PostAddLicenseCertificate,
  PostAddPublicationLinks,
  PostAddSocialMediaLinks,
  PostAddUniversity,
  PostAddWork,
  PostConsultantInfo,
  PostConsultantInfoIdentityProofUpload,
  PostDeleteProfileInfo,
  SendOtp,
  VerifyOtp
} from "../../../../api/signup";

const initialState = {
  data: [],
  mobileUIData : [],
  identityType: [],
  loading: false,
  infoLoading: false,
  fileUploadLoading: false,
};

export const postConsultantInfo = createAsyncThunk(
  "consultantInfo/postConsultantInfo",
  async (data) => {
    try {
      const response = await PostConsultantInfo(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const postConsultantInfoIdentityProofUpload = createAsyncThunk(
  "consultantInfo/postConsultantInfoIdentityProofUpload",
  async (data) => {
    try {
      const response = await PostConsultantInfoIdentityProofUpload(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const getIdentityProofType = createAsyncThunk(
  "consultantInfo/getIdentityProofType",
  async (id) => {
    try {
      const response = await GetIdentityProofType(id);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const postAddWork = createAsyncThunk(
  "consultantInfo/postAddWork",
  async (data) => {
    try {
      const response = await PostAddWork(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const postAddHighSchool = createAsyncThunk(
  "consultantInfo/postAddHighSchool",
  async (data) => {
    try {
      const response = await PostAddHighSchool(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const postAddUniversity = createAsyncThunk(
  "consultantInfo/postAddUniversity",
  async (data) => {
    try {
      const response = await PostAddUniversity(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const postAddLicenseCertificate = createAsyncThunk(
  "consultantInfo/postAddLicenseCertificate",
  async (data) => {
    try {
      const response = await PostAddLicenseCertificate(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const postAddPublicationLinks = createAsyncThunk(
  "consultantInfo/postAddPublicationLinks",
  async (data) => {
    try {
      const response = await PostAddPublicationLinks(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const postAddSocialMediaLinks = createAsyncThunk(
  "consultantInfo/postAddSocialMediaLinks",
  async (data) => {
    try {
      const response = await PostAddSocialMediaLinks(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const getConsultantInfo = createAsyncThunk(
  "consultantInfo/getConsultantInfo",
  async (data) => {
    try {
      const response = await GetConsultantInfo(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);


export const getConsultantMobileUIInfo = createAsyncThunk(
  "consultantInfo/getConsultantMobileUIInfo",
  async (data) => {
    try {
      const response = await GetConsultantMobileUIInfo(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const postDeleteProfileInfo = createAsyncThunk(
  "consultantInfo/postDeleteProfileInfo",
  async (param) => {
    try {
      const response = await PostDeleteProfileInfo(param);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const sendOtp = createAsyncThunk(
  'consultant/profile/phone/otp',
  async (data) => {
    try {
      const response = await SendOtp(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
)

export const verifyOtp = createAsyncThunk(
  'consultant/profile/phone/otp-verify',
  async (data) => {
    try {
      const response = await VerifyOtp(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
)

export const deleteVerification = createAsyncThunk(
  'consultant/profile/phone/delete',
  async () => {
    try {
      const response = await DeleteVerification();
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
)

export const emailChange = createAsyncThunk(
  '/consultant/profile/profile/update',
  async (data) => {
    try {
      const response = await EmailChange(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
)

export const emailOTPVerify = createAsyncThunk(
  'consultant/profile/email/otp-verify',
  async (data) => {
    try {
      const response = await EmailOTPVerify(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
)



export const consultantInfo = createSlice({
  name: "consultantInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postConsultantInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(postConsultantInfo.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postConsultantInfo.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postConsultantInfoIdentityProofUpload.pending, (state) => {
        state.fileUploadLoading = true;
      })
      .addCase(postConsultantInfoIdentityProofUpload.fulfilled, (state) => {
        state.fileUploadLoading = false;
      })
      .addCase(postConsultantInfoIdentityProofUpload.rejected, (state) => {
        state.fileUploadLoading = false;
      })
      .addCase(getConsultantInfo.pending, (state) => {
        state.infoLoading = true;
      })
      .addCase(getConsultantInfo.fulfilled, (state, { payload }) => {
        state.data = get(payload, "data", []);
        state.infoLoading = false;
      })
      .addCase(getConsultantInfo.rejected, (state) => {
        state.infoLoading = false;
      })
      .addCase(getConsultantMobileUIInfo.pending, (state) => {
        state.infoLoading = true;
      })
      .addCase(getConsultantMobileUIInfo.fulfilled, (state, { payload }) => {
        state.mobileUIData = get(payload, "data", []);
        state.infoLoading = false;
      })
      .addCase(getConsultantMobileUIInfo.rejected, (state) => {
        state.infoLoading = false;
      })
      .addCase(getIdentityProofType.pending, (state) => {
        state.loading = true;
      })
      .addCase(getIdentityProofType.fulfilled, (state, { payload }) => {
        state.identityType = get(payload, "data", []);
        state.loading = false;
      })
      .addCase(getIdentityProofType.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postDeleteProfileInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(postDeleteProfileInfo.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postDeleteProfileInfo.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const getConsultantData = (state) => state.consultantInfo;
export default consultantInfo.reducer;
