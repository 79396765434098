/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const SidebarMenuItem = (props) => {
	const { isActive, drawerOpen, icon, label, sx, setDrawerOpen } = props;
	const theme = useTheme();
	const matchUpSm = useMediaQuery(theme.breakpoints.down('sm'));

	const bgColor = useMemo(() => alpha(theme.palette.primary.light, 0.3), []);

	return (
		<ListItemButton
			id='nbar'
			selected={isActive}
			sx={{
				justifyContent: drawerOpen ? 'initial' : 'center',
				px: 1,
				py: 1.2,
				color: 'text.secondary',
				borderRadius: '8px',
				my: 1,
				'&:hover': {
					color: 'text.primary',
					backgroundColor: bgColor,
				},
				'&.Mui-selected': {
					color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.light,
					backgroundColor: bgColor,
				},
				...sx,
			}}
			onClick={() => matchUpSm && setDrawerOpen(false)}
		>
			<ListItemIcon sx={{ minWidth: 0, mr: drawerOpen ? 2 : 'auto', justifyContent: 'center' }}>{icon}</ListItemIcon>

			<ListItemText
				primary={label}
				sx={{ opacity: drawerOpen ? 1 : 0, my: 0 }}
				primaryTypographyProps={{
					noWrap: true,
					typography: 'body2',
					textTransform: 'capitalize',
					fontWeight: isActive ? 'fontWeightSemiBold' : 'fontWeightMedium',
				}}
			/>
		</ListItemButton>
	);
};

export default SidebarMenuItem;
