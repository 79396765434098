import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { useDispatch } from 'react-redux';

import DashboardFooter from './footer';
import DashboardHeader from './header';
import DashboardSidebar from './sidebar/Sidebar';
import { getConsultantInfo } from '../../store/slice/auth/signup/consultantInfoSlice';
import { getMeInfo } from '../../store/slice/auth/authentication/authSlice';

const PrivateLayout = ({ children }) => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const dispatch = useDispatch();

	const handleDrawerToggle = () => {
		setDrawerOpen(!drawerOpen);
	};

	useEffect(() => {
		dispatch(getConsultantInfo());
		dispatch(getMeInfo());
	}, [dispatch]);

	return (
		<>
			<Stack direction='row'>
				<DashboardSidebar drawerOpen={drawerOpen} handleDrawerToggle={handleDrawerToggle} setDrawerOpen={setDrawerOpen} />
				<DashboardHeader drawerOpen={drawerOpen} handleDrawerToggle={handleDrawerToggle} />

				<Box flex={1} display='flex' flexDirection='column' pt={8.6} minHeight='100%' sx={{ overflowX: 'hidden' }}>
					<Box
						component='main'
						sx={{
							width: '100%',
							padding: '16px',
							backgroundColor: 'secondary.50',
							flexGrow: 1,
							overflow: 'auto',
							display: 'flex',
						}}
					>
						{children}
					</Box>
					<DashboardFooter />
				</Box>
			</Stack>
		</>
	);
};

export default PrivateLayout;
