import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

// import { getConsultantData } from "../../../../../store/slice/auth/signup/consultantInfoSlice";
import { getCountryList, getLanguageList, getPhoneCode } from '../../../../../store/slice/common';
// import ResidentialAddress from "../../../../auth/signup/consultant-info/residential-address/ResidentialAddress";

const ProfileResidentialAddress = () => {
	const dispatch = useDispatch();
	// const consultantData = useSelector(getConsultantData);
	// const consultantInfoLoading = useSelector(
	//   (state) => state.consultantInfo.loading
	// );
	// const loading = useSelector((state) => state.common.loading);

	useEffect(() => {
		dispatch(getPhoneCode());
		dispatch(getCountryList());
		dispatch(getLanguageList());
	}, [dispatch]);

	return (
		<>
			{/* <Paper variant="outlined">
        <CardContent>
          {consultantInfoLoading || loading ? (
            <LinearProgress />
          ) : (
            <ResidentialAddress
              consultantInfo={consultantData}
              buttonLabel="Submit"
              isEdit={true}
            />
          )}
        </CardContent>
      </Paper> */}
		</>
	);
};

export default ProfileResidentialAddress;
