import React from 'react';
import Typography from '@mui/material/Typography';

export default function Text({ line = 1, children, ...props }) {
	return (
		<Typography
			variant='subtitle1'
			color='text.primary'
			display='-webkit-box'
			overflow='hidden'
			sx={{ WebkitLineClamp: line, WebkitBoxOrient: 'vertical' }}
			{...props}
		>
			{children}
		</Typography>
	);
}
