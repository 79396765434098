import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box } from '@mui/material';

import logoImg from "../../assets/images/logo/single_nutra_plus_logo.svg";

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <RouterLink to="/">
      <Box component="img" src={logoImg} sx={{ width: 60, height: 60, ...sx }} />
    </RouterLink>
  );
}
