import React from "react";

import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import PublicLayout from "../public/PublicLayout";

const PrivateLimitedAccess = () => {
  const location = useLocation();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isSignupCompleted = useSelector(
    (state) => state.auth.isSignupCompleted
  );

  return isLoggedIn && !isSignupCompleted ? (
    <PublicLayout>
      <Outlet />
    </PublicLayout>
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default PrivateLimitedAccess;
