import React from 'react';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

export default function TableSkeleton() {
	return (
		<Paper variant='outlined' className='w-100'>
			<Skeleton animation='wave' variant='rectangular' height={56} />
			<Skeleton animation='wave' variant='rectangular' height={200} sx={{ my: 1, borderRadius: 0 }} />
			<Skeleton animation='wave' variant='rectangular' height={50} />
		</Paper>
	);
}
