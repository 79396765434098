import React from 'react';
// import { get, map } from 'lodash';
import { useLocation } from 'react-router-dom';
// import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
// import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
// import Box from '@mui/material/Box';
// import Chip from '@mui/material/Chip';
// import Divider from '@mui/material/Divider';
// import Grid from '@mui/material/Grid';
// import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// import Card from '@mui/material/Card';
// Other
import ReactPlayer from 'react-player/lazy';
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { extractTopContentImageUrl } from '../../../../utils/content';
import './style.css';

const SWIPER_MODULES_LIST = [Pagination, Autoplay];

function RenderContent({ content }) {
	if (content.preferredOption === 'TEXT') {
		return (
			<div
				key={content._id}
				dangerouslySetInnerHTML={{ __html: content.data }}
				className='blog-content-wrapper blog-image'
			/>
		);
	}

	if (content.preferredOption === 'IMAGE') {
		return (
			<div>
				<img
					src={extractTopContentImageUrl(content, 'horizontal')}
					width={780}
					height={487}
					alt={content.title}
					className='w-full h-full object-cover'
				/>
			</div>
		);
	}

	if (content.preferredOption === 'VIDEO') {
		return (
			<div className='my-4 p-4 md:p-5 rounded-xl shadow-2xl bg-[#f5f6fa] blog-content-wrapper'>
				<h3 className='text-base md:text-xl font-semibold text-neutral-900 mb-5'>{content.title}</h3>
				<div className='player-wrapper rounded-lg overflow-hidden mx-auto'>
					<ReactPlayer url={content.data} controls={true} width='100%' className='react-player' />
				</div>
			</div>
		);
	}
	return <></>;
}

const ArticleDeatils = () => {
	const location = useLocation();
	const { ArticleData } = location.state;
	const articleDetails = ArticleData;

	return (
		<>
			{/* <Box>
				<Typography fontWeight={800} mb={1}>
					MetaData
				</Typography>
				<Card sx={{ p: 2, mb: 2 }}>
					<Typography fontSize='16px' color='#929292' fontWeight={600}>
						Categories
					</Typography>
					<Stack direction='row' flexWrap='wrap' className='gap-2'>
						{map(get(ArticleData, 'categories', []), (category, categoryIndex) => {
							return (
								<React.Fragment key={categoryIndex}>
									<Chip label={category?.foreignData?.title || 'NA'} size='small' />
									{map(category?.subCategories || [], (subCategory, subCategoryIndex) => {
										return (
											<Chip
												key={subCategoryIndex}
												label={subCategory?.foreignData?.title || 'NA'}
												size='small'
											/>
										);
									})}
								</React.Fragment>
							);
						})}
					</Stack>
				</Card>
				<Grid container gap='30px' mt='10px' px='4px' width={{ xs: '100%', md: '100%', lg: '100%' }}>
					<Grid item md='12' lg='7'>
						<Card sx={{ p: 2, mb: 2 }}>
							<Typography fontSize='16px' color='#929292' fontWeight={600}>
								SEO Title
							</Typography>
							<Typography fontSize='13px' fontWeight={400}>
								{get(ArticleData, 'seoTitle', false)}
							</Typography>
						</Card>
						<Card sx={{ p: 2 }}>
							<Typography fontSize='16px' color='#929292' fontWeight={600}>
								SEO Description
							</Typography>
							<Typography fontSize='13px' fontWeight={400}>
								{get(ArticleData, 'seoDescription', false)}
							</Typography>
						</Card>
					</Grid>
					<Grid item md='12' lg='4'>
						<Grid container>
							<Stack item width='100%'>
								<Stack mx='auto' direction='row' height='50px' gap='15px'>
									<Stack alignItems={'center'} gap='3px'>
										<Typography fontSize={'12px'} color='#929292' fontWeight={600}>
											Publish
										</Typography>
										{get(ArticleData, 'isPublished', false) ? (
											<TaskAltOutlinedIcon className='align-middle' />
										) : (
											<HighlightOffOutlinedIcon className='align-middle' />
										)}
									</Stack>
									<Divider flexItem orientation='vertical'></Divider>
									<Stack alignItems={'center'} gap='3px'>
										<Typography fontSize={'12px'} color='#929292' fontWeight={600}>
											Comments
										</Typography>
										{get(ArticleData, 'comments', false) ? (
											<TaskAltOutlinedIcon className='align-middle' />
										) : (
											<HighlightOffOutlinedIcon className='align-middle' />
										)}
									</Stack>
									<Divider flexItem orientation='vertical'></Divider>
									<Stack alignItems={'center'} gap='3px'>
										<Typography fontSize={'12px'} color='#929292' fontWeight={600}>
											Likes
										</Typography>
										{get(ArticleData, 'likeStatus', false) ? (
											<TaskAltOutlinedIcon className='align-middle' />
										) : (
											<HighlightOffOutlinedIcon className='align-middle' />
										)}
									</Stack>
									<Divider flexItem orientation='vertical'></Divider>
									<Stack alignItems={'center'} gap='3px'>
										<Typography fontSize={'12px'} color='#929292' fontWeight={600}>
											Ratings
										</Typography>
										{get(ArticleData, 'ratingStatus', false) ? (
											<TaskAltOutlinedIcon className='align-middle' />
										) : (
											<HighlightOffOutlinedIcon className='align-middle' />
										)}
									</Stack>
								</Stack>
							</Stack>
							<Stack padding='24px' mt='10px' width='100%'>
								<Typography fontSize={'14px'} fontWeight={600}>
									SEO Meta Tags
								</Typography>
								<Divider />
								<Box display='flex' flexWrap={'wrap'} gap='10px' mt='8px'>
									{map(get(ArticleData, 'seoMetaTags', false), (tags, tagsIndex) => (
										<Chip key={tagsIndex} label={tags} size='medium' fontSize='12px' />
									))}
								</Box>
							</Stack>
						</Grid>
					</Grid>
				</Grid>
			</Box>
			<Divider sx={{ my: 2 }} /> */}
			<div>
				<Typography fontWeight={800} mb={1}>
					Blog Preview
				</Typography>
				<div className='blog-container' style={{ maxWidth: '990px', margin: 'auto' }}>
					<div className='blog-content'>
						<h1 className='text-2xl md:text-3xl font-semibold mb-4 md:mb-2'>{articleDetails.title}</h1>

						<div className='mb-3 md:mb-4 w-full relative'>
							<div className='relative'>
								<Swiper
									modules={SWIPER_MODULES_LIST}
									centeredSlides={true}
									autoplay={{
										delay: 3000,
										disableOnInteraction: false,
									}}
									pagination={{ dynamicBullets: true }}
									spaceBetween={20}
									slidesPerView={1}
								>
									{articleDetails.topSection?.map((topContent, index) => (
										<SwiperSlide key={topContent._id} virtualIndex={index}>
											{/* <div> */}
											<img
												src={extractTopContentImageUrl(topContent, 'horizontal')}
												width={780}
												height={487}
												alt={topContent.title}
												className='w-full h-full object-cover rounded-2xl'
											/>
											{/* </div> */}
										</SwiperSlide>
									))}
								</Swiper>
								<div className='absolute top-0 z-10 bottom-0 rounded-2xl w-full bg-black-gradient1 flex flex-col pointer-events-none'>
									<div className='p-4'>
										<div className='flex flex-wrap gap-2 justify-end'>
											{articleDetails.language?.map((language) => (
												<div
													className='bg-white py-2 px-4 rounded-full text-xs font-medium text-neutral-900 leading-none'
													key={language._id}
												>
													{language.title}
												</div>
											))}
										</div>
									</div>
									<div className='p-4 pt-0 mt-auto'>
										{/* <p className="text-base md:text-xl font-semibold text-white mb-3">Specialization</p> */}
										<div className='flex flex-wrap gap-2'>
											{articleDetails.categories.map((category) => (
												<div
													className='bg-white py-2 px-3 rounded-full text-xs font-medium text-neutral-900 leading-none'
													key={category._id}
												>
													{category.foreignData.title}
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>

						{articleDetails?.subTitle && (
							<div className='w-full border-left-4 pt-1 pb-1 mt-4 mb-4'>
								<h2 className='text-sm md:text-base font-medium text-neutral-800'>{articleDetails.subTitle}</h2>
							</div>
						)}

						{articleDetails.contentSection.map((content) => (
							<RenderContent content={content} key={content._id} />
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default ArticleDeatils;
