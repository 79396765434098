import React from "react";

import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import PublicLayout from "./PublicLayout";

const Public = () => {
  const location = useLocation();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isSignupCompleted = useSelector(
    (state) => state.auth.isSignupCompleted
  );

  return isLoggedIn && !isSignupCompleted ? (
    <Navigate to="/consultant-info" state={{ from: location }} replace />
  ) : (
    <PublicLayout>
      <Outlet />
    </PublicLayout>
  );
};

export default Public;
