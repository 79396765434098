import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  PostConnectStripeAccount,
  PostConnectStripeAccountStatus,
  GetTransactionsList,
} from "../../../api/dashboard/BankingAPI";

const initialState = {
  loginUrl: "",
  transactionList: [],
  loading: false,
};

export const postConnectStripeAccount = createAsyncThunk(
  "banking/postConnectStripeAccount",
  async (data) => {
    try {
      const response = await PostConnectStripeAccount(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const postConnectStripeAccountStatus = createAsyncThunk(
  "banking/postConnectStripeAccountStatus",
  async (data) => {
    try {
      const response = await PostConnectStripeAccountStatus(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const getTransactionsList = createAsyncThunk(
  "banking/getTransactionsList",
  async (data) => {
    try {
      const response = await GetTransactionsList(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const bankingSlice = createSlice({
  name: "banking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postConnectStripeAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(postConnectStripeAccount.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postConnectStripeAccount.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postConnectStripeAccountStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        postConnectStripeAccountStatus.fulfilled,
        (state, { payload }) => {
          state.loginUrl = get(payload, "data.loginLink", "");
          state.loading = false;
        }
      )
      .addCase(postConnectStripeAccountStatus.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTransactionsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTransactionsList.fulfilled, (state, { payload }) => {
        state.transactionList = get(payload, "data.list", []);
        state.loading = false;
      })
      .addCase(getTransactionsList.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default bankingSlice.reducer;
