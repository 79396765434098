import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";

import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import TranslateIcon from "@mui/icons-material/Translate";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import { Formik } from "formik";
import { isEmpty } from "lodash";

// import cookie from "../hooks/cookie";
import translate from "../hooks/translate";
import CMTextButton from "../ui-components/buttons/CMTextButton";
import CMDialog from "../ui-components/dialog/CMDialog";

// import useGetAppLanguageMacros from "../hooks/useGetAppLanguageMacros";
// import useGetAppLanguages from "../hooks/useGetAppLanguages";
import useContextData from "../hooks/useContextData";
// import { getTranslateLanguages } from "../../store/slice/language/AppLanguageConfigSlice";

const LanguageSwitcherModal = () => {
  const [open, setOpen] = useState(false);
  const { LanguageSelected, setLanguage, GetLanguageList } = useContextData();
  const LanguageList = GetLanguageList();
  // const { LanguageSelected,setLanguage } = useContextData();
  // const dispatch = useDispatch();
  // // const selectedLanguage = cookie.get("selectedLanguage");

  // const translateLanguages = useSelector(
  //   (state) => state.appLanguageConfig.translateLanguages
  // );

  // console.log('translateLanguages',translateLanguages);

  // const [languages] = useGetAppLanguages();

  // const [languageMacros, setValue] = useGetAppLanguageMacros(selectedLanguage);
  const handleChangeLanguage = (value) => {
    setLanguage(value);
    handleClose();
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const handleGetLanguages = useCallback(() => {
  //   dispatch(getTranslateLanguages())
  //     .unwrap()
  //     .catch((err) => {
  //       throw err;
  //     });
  // }, [dispatch]);

  // useEffect(() => {
  //   if (isEmpty(translateLanguages)) {
  //     handleGetLanguages();
  //   }
  // }, [handleGetLanguages, translateLanguages]);

  return (
    <>
      <CMTextButton onClick={handleOpen} startIcon={<TranslateIcon />}>
        {translate("common_language")}
      </CMTextButton>
      <CMDialog
        dialogTitle="Select Language"
        dialogOpen={open}
        maxWidth="md"
        dialogClose={handleClose}
      >
        <Formik
          initialValues={{
            selectedLanguage: LanguageSelected ?? {},
          }}
          onSubmit={(values) => {
            handleChangeLanguage(values?.selectedLanguage);
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <DialogContent dividers>
                <Grid container>
                  <Grid item xs={10}>
                    <Autocomplete
                      name="selectedLanguage"
                      options={LanguageList || []}
                      getOptionLabel={(option) =>
                        option?.displayTitle ? option?.displayTitle : ""
                      }
                      isOptionEqualToValue={(option, value) => {
                        if (
                          isEmpty(value) ||
                          option === {} ||
                          option?._id === value?._id
                        ) {
                          return true;
                        }
                      }}
                      value={props.values.selectedLanguage}
                      onChange={(_event, value) => {
                        props.setFieldValue("selectedLanguage", value);
                      }}
                      autoHighlight
                      disableClearable
                      noOptionsText={translate("common_no_data")}
                      popupIcon={<KeyboardArrowDownSharpIcon />}
                      sx={{ width: "300px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="language"
                          name="selectedLanguage"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          error={
                            props.errors.selectedLanguage &&
                            props.touched.selectedLanguage
                              ? true
                              : false
                          }
                          helperText={
                            props.errors.selectedLanguage &&
                            props.touched.selectedLanguage
                              ? props.errors.selectedLanguage
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button type="submit" variant="contained">
                  Select
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </CMDialog>
    </>
  );
};

export default LanguageSwitcherModal;
