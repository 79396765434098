import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Outlet } from 'react-router-dom';

import Page from '../../components/common/Page';
import PageHeader from '../../components/common/PageHeader';

const QAndATemplate = () => {
	return (
		<Page title='QAndATemplate | InspireMind'>
			<Box component='section' className='w-100'>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<PageHeader title='consultant_q_and_a_template' variant='outlined' elevation={0} />
					</Grid>
					<Grid item xs={12}>
						<Box>
							<Outlet />
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Page>
	);
};

export default QAndATemplate;
