import axios from 'axios';
import cookie from '../../components/hooks/cookie';
import { get } from 'lodash';

const BASE_URL = process.env.REACT_APP_API_END_POINT;

export function jwtInterceptor() {
	axios.interceptors.request.use((request) => {
		// add auth header with jwt if account is logged in and request is to the api url

		const isApiUrl = request.baseURL?.startsWith(BASE_URL);

		const userData = cookie.get('consultant_data');
		const token = get(userData, 'token', null);

		if (token && isApiUrl && !request.headers.authorization) {
			request.headers.authorization = token;
		}

		return request;
	});
}
