import React, { useCallback, useState } from 'react';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';

import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import translate from '../../hooks/translate';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';

const DEFAULT_IMG_STATE = {
	image: null,
	scale: 1,
	borderRadius: 2000,
	rotate: 0,
	backgroundColor: 'transparent',
	position: { x: 0, y: 0 },
	editImage: '',
};

const AvatarPicker = React.forwardRef((props, ref) => {
	const theme = useTheme();
	const { selectedImage } = props;
	const [imageSetting, setImageSetting] = useState({
		...cloneDeep(DEFAULT_IMG_STATE),
		image: selectedImage ? selectedImage : null,
	});

	const handleDrop = (dropped) => {
		if (!dropped?.[0]?.name) {
			return;
		}

		const ext = dropped[0].name.split('.').pop().toLowerCase();
		if (!['png', 'jpg', 'jpeg'].includes(ext)) {
			toast.error('Invalid file!');
			return;
		}

		setImageSetting({
			...imageSetting,
			image: dropped[0],
		});
	};

	const handleScale = (event) => {
		setImageSetting({
			...imageSetting,
			scale: event.target.value,
		});
	};
	const handleRotate = (event) => {
		setImageSetting({
			...imageSetting,
			rotate: event.target.value,
		});
	};
	const handlePositionChange = (position) => {
		setImageSetting({ ...imageSetting, position: position });
	};

	const removeImage = useCallback(() => {
		setImageSetting(cloneDeep(DEFAULT_IMG_STATE));
	}, [setImageSetting]);

	return (
		<Box sx={{ width: { sm: '380px', xs: '100%' }, p: { sm: 1 } }}>
			<Grid container spacing={{ sm: 2, xs: 1 }}>
				<Grid item xs={12}>
					<Box sx={{ mb: 1, py: 1, borderBottom: `1px solid ${theme.palette.grey[300]}` }}>
						<Dropzone onDrop={handleDrop} noKeyboard>
							{({ getRootProps, getInputProps }) => (
								<Box sx={{ width: 'max-content', margin: 'auto', position: 'relative', textAlign: 'center' }}>
									<AvatarEditor
										image={imageSetting.image}
										scale={parseFloat(imageSetting.scale)}
										rotate={parseFloat(imageSetting.rotate)}
										position={imageSetting.position}
										onPositionChange={handlePositionChange}
										borderRadius={imageSetting.borderRadius}
										disableHiDPIScaling={true}
										border={0}
										className='avatar-upload-canvas'
										ref={ref}
										color={[255, 255, 255, 1]}
									/>
									<input {...getInputProps()} />
									{imageSetting.image === null ? (
										<Button
											{...getRootProps()}
											fullWidth
											sx={{
												position: 'absolute',
												zIndex: 109999,
												top: '50%',
												left: '50%',
												transform: 'translate(-50%,-50%)',
												gap: 1,
											}}
											variant='contained'
										>
											<AiOutlineCloudUpload fontSize={20} />
											Select Image
										</Button>
									) : (
										<Box mt={1} display='flex' gap={1}>
											<Button {...getRootProps()} sx={{ gap: 1 }} variant='outlined'>
												<AiOutlineCloudUpload fontSize={20} />
												Select Image
											</Button>
											<Button sx={{ gap: 1 }} variant='outlined' color='error' onClick={removeImage}>
												<MdDelete fontSize={20} />
												Remove
											</Button>
										</Box>
									)}
								</Box>
							)}
						</Dropzone>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: { sm: '1.5fr 2fr' },
							gridGap: { sm: '4px 16px', xs: '4px 8px' },
						}}
					>
						<Typography>{translate('common_zoom')} :</Typography>
						<Box sx={{ px: 1 }}>
							<Slider
								aria-label='Zoom'
								valueLabelDisplay='auto'
								step={0.1}
								min={1}
								max={10}
								value={imageSetting.scale}
								onChange={handleScale}
							/>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: { sm: '1.5fr 2fr' },
							gridGap: '4px 16px',
						}}
					>
						<Typography>{translate('common_rotate')} :</Typography>
						<Box sx={{ px: 1 }}>
							<Slider
								aria-label='Rotation'
								valueLabelDisplay='auto'
								min={0}
								max={360}
								value={imageSetting.rotate}
								onChange={handleRotate}
							/>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
});

export default AvatarPicker;
