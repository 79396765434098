import getPalette from './base/palette';
import { createCustomShadow, createShadow } from './base/shadows';
import getThemeName from './config/theme.service';
import typography from './base/typography';
import { createTheme } from '@mui/material/styles';

import * as coreLocale from '@mui/material/locale';
import * as dataGridLocale from '@mui/x-data-grid/locales';
import * as datePickerLocale from '@mui/x-date-pickers/locales';
import componentsOverrides from './overrides';

export default function themeBuilder(themeName) {
	let themeMode = '';

	if (themeName) {
		themeMode = themeName;
	} else {
		themeMode = getThemeName();
	}

	const palette = getPalette(themeMode);
	const shadows = createShadow(palette.grey[500]);
	const customShadows = createCustomShadow(palette);

	const themeOptions = {
		palette,
		shape: { borderRadius: 8 },
		typography,
		shadows,
		customShadows,
	};
	const theme = createTheme(
		themeOptions,
		coreLocale['enUS'], // core translations
		dataGridLocale['enUS'], // x-data-grid translations
		datePickerLocale['enUS'] // x-date-pickers translations
	);
	theme.components = componentsOverrides(theme);

	return theme;
}

export { getThemeName };
