import React from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import DataNotFoundImg from "../../assets/images/no-data-found.png";

const DataNotFound = () => {
  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={6}>
          <Box
            component="img"
            sx={{
              height: "100%",
              width: "100%",
            }}
            alt=""
            src={DataNotFoundImg}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DataNotFound;
