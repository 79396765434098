import React from "react";

import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";


const CheckStatus = (props) => {
  const { url } = props;
  const handleLogin = () => {
    console.log("url", url);
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <>
      <Paper variant="outlined">
        <CardContent>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Stripe Dashboard</Typography>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleLogin()}
            >
              Go To Stripe Dashboard
            </Button>
          </Stack>
        </CardContent>
      </Paper>
    </>
  );
};

export default CheckStatus;
