import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { filter, find, get, isEmpty, map } from 'lodash';
// MUI
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// Custom
import CMAccordion from '../ui-components/accordion/CMAccordion';
import CMAccordionDetails from '../ui-components/accordion/CMAccordionDetails';
import CMAccordionSummary from '../ui-components/accordion/CMAccordionSummary';
import translate from '../hooks/translate';
import { FormControl } from '@mui/material';

const RenderTags = (value, getTagProps) =>
	value.map((option, index, { color }) => <Chip color={color ?? 'primary'} label={option.title} {...getTagProps({ index })} />);

const MultiSelectSpecialization = (props) => {
	const { specialization, formProps, fieldName, subFieldName, isDefaultSEOmetaTag = false } = props;

	// const [metaTag, setMetaTag] = useState();
	// const [childTag, setChildTag] = useState([]);

	const [expanded, setExpanded] = useState(false);
	const handleChange = (panel) => (_event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const handleSubSpecialization = (child, checked, subFieldName, formProps) => {
		if (checked) {
			formProps.setFieldValue(subFieldName, [
				...formProps.values[subFieldName],
				{
					foreignId: get(child, '_id', ''),
					foreignData: get(child, '_id', ''),
					parentId: get(child, 'parentId', ''),
				},
			]);
		} else {
			formProps.setFieldValue(
				subFieldName,
				filter(formProps.values[subFieldName], (item) => get(item, 'foreignId', '') !== get(child, '_id', ''))
			);
		}
	};
	const subSpecExist = (arr, id) => {
		if (!isEmpty(arr)) {
			return arr.some(function (el) {
				return get(el, 'foreignId', '') === id;
			});
		}
		return false;
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<FormControl fullWidth>
					<Autocomplete
						options={specialization || []}
						getOptionLabel={(option) => translate(get(option, 'macroName', '')) ?? ''}
						isOptionEqualToValue={(option, value) => {
							if (get(option, '_id', '') === get(value, '_id', '')) {
								return true;
							}
						}}
						id='specialization'
						name={fieldName}
						value={formProps.values[fieldName]}
						onChange={(_event, value) => {
							formProps.setFieldValue(fieldName, value);
							isDefaultSEOmetaTag &&
								formProps.setFieldValue(
									'seoMetaTags',
									value?.map((item) => item?.title)
								);
						}}
						fullWidth={true}
						autoHighlight={true}
						multiple={true}
						renderInput={(params) => (
							<TextField
								{...params}
								label={translate('common_category')}
								error={formProps.errors[fieldName] && formProps.touched[fieldName] ? true : false}
								helperText={
									formProps.errors[fieldName] && formProps.touched[fieldName]
										? formProps.errors[fieldName]
										: null
								}
								onBlur={formProps.handleBlur}
								inputProps={{
									...params.inputProps,
									autoComplete: [fieldName],
								}}
								sx={{ '& .MuiFormHelperText-root': { mx: 1 } }}
							/>
						)}
						renderTags={RenderTags}
					/>
				</FormControl>
			</Grid>
			{!isEmpty(formProps.values[fieldName]) && (
				<Grid item xs={12} container spacing={0.5}>
					{map(
						filter(formProps.values[fieldName], (e) => e?.child?.length),
						(item, index) => {
							return (
								<Grid item xs={12} key={index}>
									<CMAccordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
										<CMAccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls={`panel${index}bh-content`}
											id={`panel${index}bh-header`}
										>
											<Typography variant='body1' fontWeight={600}>
												{translate(get(item, 'macroName', ''))}
											</Typography>
										</CMAccordionSummary>
										<CMAccordionDetails>
											{map(get(item, 'child'), (subItem, index) => {
												return (
													<FormGroup key={index}>
														<FormControlLabel
															control={
																<Checkbox
																	value={formProps.values[subFieldName].includes(
																		get(subItem, '_id', '')
																	)}
																	checked={subSpecExist(
																		formProps.values[subFieldName],
																		get(subItem, '_id', '')
																	)}
																	onChange={(e, value) => {
																		// console.log('value :>> ', value);
																		// let title = []
																		// title.push(subItem.title)
																		// console.log('title :>> ', title);
																		// setChildTag((prev) => [...prev, subItem.title])
																		handleSubSpecialization(
																			subItem,
																			e.target.checked,
																			subFieldName,
																			formProps
																		);
																	}}
																	sx={{
																		'&.Mui-checked': {
																			'&, & + .MuiFormControlLabel-label': {
																				color: 'primary.main',
																			},
																		},
																	}}
																/>
															}
															label={translate(get(subItem, 'macroName', ''))}
														/>
													</FormGroup>
												);
											})}
										</CMAccordionDetails>
									</CMAccordion>
								</Grid>
							);
						}
					)}
					{formProps.errors[subFieldName] && formProps.touched[subFieldName] ? (
						<Grid item xs={12}>
							<FormHelperText error sx={{ mx: 2 }}>
								{formProps.errors[subFieldName]}
							</FormHelperText>
						</Grid>
					) : null}
				</Grid>
			)}
		</Grid>
	);
};

MultiSelectSpecialization.propTypes = {
	specialization: PropTypes.array.isRequired,
	initialData: PropTypes.object.isRequired,
	formProps: PropTypes.object.isRequired,
	fieldName: PropTypes.string.isRequired,
};

export default MultiSelectSpecialization;

export const getInitSpecialization = (initialData, specialization, fieldName) => {
	const list = map(get(initialData, fieldName, []), (item) => {
		return find(specialization, {
			_id: get(item, 'foreignId', ''),
		});
	});
	const filteredList = filter(list, (item) => Boolean(item));
	return filteredList;
};

export const getInitSubSpecialization = (initialData, specialization, fieldName, subFieldName) => {
	let subSpec = [];
	map(getInitSpecialization(initialData, specialization, fieldName), (spec) => {
		map(get(initialData, fieldName, []), (initSpec) => {
			if (get(initSpec, 'foreignId', '') === get(spec, '_id', '')) {
				if (!isEmpty(initSpec)) {
					subSpec.push(...get(initSpec, subFieldName, ''));
				}
			}
			return;
		});
		return;
	});
	return subSpec;
};

export const getSpecializationSubmitData = (values, subSpecializationData, fieldName, subFieldName) => {
	const spc = [];
	const data = [];
	map(values[fieldName], (item) => {
		spc.push({
			foreignId: get(item, '_id', ''),
			foreignData: get(item, '_id', ''),
			child: [],
		});
	});
	if (!isEmpty(subSpecializationData)) {
		map(spc, (item) => {
			const subEl = [];
			map(subSpecializationData, (subItem) => {
				if (get(subItem, 'parentId', '') === get(item, 'foreignId', '')) {
					subEl.push({
						foreignId: subItem.foreignId,
						foreignData: subItem.foreignId,
						parentId: subItem.parentId,
					});
				}
			});
			data.push({
				...item,
				[subFieldName]: !isEmpty(subEl) ? subEl : [],
			});
		});
		return data;
	} else {
		return spc;
	}
};

export const getOnlySpecializationSubmitData = (values, fieldName) => {
	const spc = [];
	map(values[fieldName], (item) => {
		spc.push({
			foreignId: get(item, '_id', ''),
			foreignData: get(item, '_id', ''),
		});
	});
	return spc;
};
