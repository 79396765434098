import React from "react";

import { Stack, Paper, Typography } from "@mui/material";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";

import moment from "moment";

import Iconify from "../../../common/Iconify";
import Label from "../../../common/Label";

const SuccessCard = ({ data }) => {
  const consultationStatus = data.consultationStatus;
  return (
    <>
      <Paper
        className="p-2 px-3 rounded h-100"
        sx={{
          boxShadow: (theme) => theme.customShadows.success,
          border: (theme) => `2px solid ${theme.palette.success.main}`,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="gap-3 mb-2"
        >
          <Label variant="ghost" color="success">
            {consultationStatus}
          </Label>
          {consultationStatus === "COMPLETE" ? (
            <TaskAltRoundedIcon color="success" />
          ) : null}
        </Stack>
        <Typography variant="subtitle1" gutterBottom>
          {data.consultationNumber} Consultation
        </Typography>
        <Stack direction="row" alignItems="center" className="gap-1">
          <Iconify icon="ic:round-access-time" color="text.secondary" />
          <Typography variant="caption" color="text.secondary">
            {data.startDate
              ? `${moment(data.startDate).format("ddd, ll")} at ${
                  data.startTime
                } to ${data.endTime}`
              : ""}
          </Typography>
        </Stack>
      </Paper>
    </>
  );
};

export default SuccessCard;
