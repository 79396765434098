import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, omit } from 'lodash';

import { GetContent, ContentArchive, ContentPublish, ContentDelete } from '../../../api/dashboard/content';

const initialState = {
	contentList: [],
	totalArticle: 0,
	loading: false,
};

export const getContent = createAsyncThunk('content/getContent', async (data) => {
	try {
		const response = await GetContent(omit(data, 'silentFetch'));
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const contentArchive = createAsyncThunk('content/contentArchive', async (data) => {
	try {
		const response = await ContentArchive(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const contentpublish = createAsyncThunk('content/contentpublish', async (data) => {
	try {
		const response = await ContentPublish(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const contentDelete = createAsyncThunk('content/contentDelete', async (id) => {
	try {
		const response = await ContentDelete(id);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const contentSlice = createSlice({
	name: 'content',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getContent.pending, (state, action) => {
				const updateLoadingStatus = get(action, 'meta.arg.silentFetch', false);
				if (!updateLoadingStatus) {
					state.loading = true;
				}
			})
			.addCase(getContent.fulfilled, (state, { payload }) => {
				state.totalArticle = get(payload, 'data.totalArticle', 0);
				state.contentList = get(payload, 'data.list', []);
				state.loading = false;
			})
			.addCase(getContent.rejected, (state) => {
				state.loading = false;
			})
			.addCase(contentDelete.pending, (state) => {
				state.loading = true;
			})
			.addCase(contentDelete.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(contentDelete.rejected, (state) => {
				state.loading = false;
			});
	},
});

export default contentSlice.reducer;
