import React from 'react';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { FieldArray, getIn } from 'formik';
import { get, size } from 'lodash';

import translate from '../../../hooks/translate';

import CMRadio from '../../../ui-components/form-components/CMRadio';
import CMToolTip from '../../../ui-components/tooltip/CMToolTip';
import Checkbox from '@mui/material/Checkbox';
import FilePicker from '../../../ui-components/file-picker/FilePicker';
import FilePickerPreview from '../../../ui-components/file-picker/component/FilePickerPreview';
import { useTheme } from '@emotion/react';

const TopSection = (props) => {
	const theme = useTheme();
	const { formProps, checkImage, setCheckImage } = props;

	const handleCheckboxChange = (index) => {
		const topSection = formProps.values.topSection.map((item, i) => ({
			...item,
			selectedImageIndex: i === index ? (item.selectedImageIndex === -1 ? 0 : -1) : -1,
		}));

		setCheckImage(!checkImage);

		formProps.setFieldValue('topSection', topSection);
	};

	const handleRemove = (index) => {
		formProps.values.topSection.splice(index, 1);
		const check = formProps.values.topSection.some((image) => image.selectedImageIndex === 0);
		if (!check) {
			formProps.values.topSection[0].selectedImageIndex = 0;
		}

		const topSection = formProps.values.topSection;
		formProps.setFieldValue('topSection', topSection);
	};

	return (
		<Grid item xs={12} className='top-image-section'>
			<Stack direction='row' className='gap-1'>
				<Typography variant='h4' className='mb-2'>
					{translate('consultant_top_section')}
				</Typography>
				<CMToolTip
					icon={<HelpTwoToneIcon size='small' />}
					title=' Include the blog image and title for the image you wish to set for the blog.'
				/>
			</Stack>
			<FieldArray name='topSection'>
				{({ push }) => (
					<Box>
						{formProps.values.topSection.length >= 0 &&
							formProps.values.topSection.map((item, index) => {
								const imageType = `topSection[${index}].imageType`;

								const title = `topSection[${index}].title`;
								const errorImgTitle = getIn(formProps.errors, title);
								const touchedImgTitle = getIn(formProps.touched, title);

								const imageUrl = `topSection[${index}].imageUrl`;
								const errorImageUrl = getIn(formProps.errors, imageUrl);
								const touchedImageUrl = getIn(formProps.touched, imageUrl);

								const isLargeTopSection = size(get(formProps, 'values.topSection')) > 1;

								return (
									<Box key={index} className='mt-3'>
										{index !== 0 ? (
											<Box>
												<Stack
													direction='row'
													justifyContent='flex-start'
													alignItems='center'
													spacing={1}
												>
													<Divider
														orientation='horizontal'
														flexItem={true}
														light={true}
														sx={{
															width: `calc(100% - 48px)`,
															margin: 'auto 0 !important',
														}}
													/>
													{isLargeTopSection && (
														<IconButton
															color='error'
															size='small'
															onClick={(event) => handleRemove(index)}
														>
															<ClearOutlinedIcon />
														</IconButton>
													)}
												</Stack>
											</Box>
										) : null}

										<Grid container spacing={2}>
											{!Array.isArray(item?.imageUrl) && (
												<Grid item xs={12}>
													<Box>
														<FormControl fullWidth>
															<FormLabel>{translate('consultant_featured_image')}</FormLabel>
															<RadioGroup
																color='primary'
																name={imageType}
																value={item.imageType}
																onChange={(_e, value) => {
																	formProps.setFieldValue(imageType, value);
																	formProps.setFieldValue(title, '');
																}}
																row
																sx={{
																	marginLeft: '10px',
																	marginTop: '10px',
																}}
															>
																<FormControlLabel
																	value='IMAGE_UPLOAD'
																	control={<CMRadio />}
																	label={translate('consultant_image_upload')}
																/>
																<FormControlLabel
																	value='IMAGE_LINK'
																	control={<CMRadio />}
																	label={translate('consultant_image_link')}
																/>
															</RadioGroup>
														</FormControl>
													</Box>
												</Grid>
											)}

											{item.imageType === 'IMAGE_UPLOAD' && !Array.isArray(item?.imageUrl) && (
												<Grid item xs={12} md={6}>
													<FilePicker
														isPreviewOpen={false}
														label={translate('common_image_upload')}
														id='imageUrl'
														name={imageUrl}
														value={item?.imageUrl}
														onChange={(file) => formProps.setFieldValue(imageUrl, file)}
														error={errorImageUrl && touchedImageUrl ? true : false}
														helperText={errorImageUrl && touchedImageUrl ? errorImageUrl : false}
														dropBoxGridSize={12}
														previewGridSize={6}
														noPreviewImageBaseURL={true}
														uploadEventId={imageType}
													/>
												</Grid>
											)}
											{item.imageType !== 'IMAGE_UPLOAD' && (
												<Grid item xs={12}>
													<TextField
														label={translate('consultant_image_link')}
														name={imageUrl}
														type='url'
														value={item.imageUrl[0]?.url ? item.imageUrl[0]?.url : item.imageUrl}
														onChange={formProps.handleChange}
														onBlur={formProps.handleBlur}
														error={errorImageUrl && touchedImageUrl ? true : false}
														helperText={errorImageUrl && touchedImageUrl ? errorImageUrl : null}
													/>
												</Grid>
											)}

											<Grid
												container
												item
												xs={12}
												md={item.imageType === 'IMAGE_UPLOAD' && Array.isArray(item?.imageUrl) ? 12 : 6}
											>
												<Grid
													item
													xs={12}
													md={
														item.imageType === 'IMAGE_UPLOAD' && Array.isArray(item?.imageUrl)
															? 6
															: 12
													}
												>
													<TextField
														label={translate('consultant_image_title')}
														name={title}
														value={item.title}
														onChange={formProps.handleChange}
														onBlur={formProps.handleBlur}
														error={errorImgTitle && touchedImgTitle ? true : false}
														helperText={errorImgTitle && touchedImgTitle ? errorImgTitle : null}
													/>
												</Grid>
												{item.imageType === 'IMAGE_UPLOAD' && (
													<Grid item xs={12} md={Array.isArray(item?.imageUrl) ? 6 : 12} pl={2}>
														<FormControlLabel
															value={index}
															control={
																<Checkbox
																	checked={item.selectedImageIndex !== -1}
																	onClick={() => handleCheckboxChange(index)}
																	disabled={item.selectedImageIndex !== -1}
																/>
															}
															label='Select Image'
														/>
													</Grid>
												)}
											</Grid>

											{item.imageType === 'IMAGE_LINK' && (
												<Grid item xs={3} md={3}>
													<FormControlLabel
														value={index}
														control={
															<Checkbox
																checked={item.selectedImageIndex !== -1}
																onClick={() => handleCheckboxChange(index)}
																disabled={item.selectedImageIndex !== -1}
															/>
														}
														label='Select Image'
													/>
												</Grid>
											)}
											{item.imageType === 'IMAGE_UPLOAD' && (
												<Grid item xs={12}>
													<Box>
														<FilePickerPreview
															name={imageUrl}
															value={item?.imageUrl}
															theme={theme}
															noPreviewImageBaseURL={true}
														/>
														<Box display='flex' gap={1} mt={2}>
															{Array.isArray(item?.imageUrl) && (
																<Button
																	color='error'
																	variant='outlined'
																	onClick={() => formProps.setFieldValue(imageUrl, '')}
																>
																	Replace image
																</Button>
															)}
															{formProps.values.topSection.length - 1 === index && (
																<Button
																	variant='outlined'
																	onClick={() =>
																		push({
																			imageUrl: '',
																			title: '',
																			imageType: 'IMAGE_UPLOAD',
																			selectedImageIndex:
																				formProps.values.topSection.length === 0 ? 0 : -1,
																		})
																	}
																	disabled={formProps.values.topSection.length >= 3}
																>
																	{translate('common_add')}
																</Button>
															)}
														</Box>
													</Box>
												</Grid>
											)}
										</Grid>
									</Box>
								);
							})}
					</Box>
				)}
			</FieldArray>
		</Grid>
	);
};

export default React.memo(TopSection);
