import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const SwitchToggle = (props) => {
	return (
		<FormGroup>
			<FormControlLabel sx={{ margin: '0' }} control={<Switch {...props} />} />
		</FormGroup>
	);
};

export default SwitchToggle;
