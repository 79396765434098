import React, { useCallback, useContext, useEffect, useState } from 'react';

const MeetingStateContext = React.createContext(null);

export function useMeetingState() {
	const state = useContext(MeetingStateContext);
	return state;
}

export function MeetingStateProvider({ value, children }) {
	const [state, setState] = useState({
		meetingId: 'b458fdf1-2d1a-4219-abe9-413e1bb90706',
		region: 'us-east-1',
	});

	useEffect(() => {
		setState((pre) => ({ ...pre, ...value }));
	}, [value]);

	const updateMeetingConfig = useCallback((data) => {
		setState(data);
	}, []);

	const providerValue = {
		...state,
		updateMeetingConfig,
	};

	return <MeetingStateContext.Provider value={providerValue}>{children}</MeetingStateContext.Provider>;
}
