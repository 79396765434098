import React, { useState } from 'react';
import {
	ControlBarButton,
	Phone,
	Modal,
	ModalBody,
	ModalHeader,
	ModalButton,
	ModalButtonGroup,
	useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';

import { StyledP } from './Styled';
import { useMeetingState } from '../../providers/MeetingStateProvider';

const EndMeetingControl = () => {
	const meetingManager = useMeetingManager();
	const [showModal, setShowModal] = useState(false);
	const toggleModal = () => setShowModal(!showModal);
	const { meetingId } = useMeetingState();

	const leaveMeeting = async () => {};

	const endMeetingForAll = async () => {
		try {
			if (meetingId) {
				await meetingManager.leave();
			}
		} catch (e) {
			console.log('Could not end meeting', e);
		}
	};

	return (
		<>
			<ControlBarButton icon={<Phone />} onClick={toggleModal} label='Leave' />
			{showModal && (
				<Modal size='md' onClose={toggleModal} rootId='modal-root'>
					<ModalHeader title='End Meeting' />
					<ModalBody>
						<StyledP>
							Leave meeting or you can end the meeting for all. The meeting cannot be used once it ends.
						</StyledP>
					</ModalBody>
					<ModalButtonGroup
						primaryButtons={[
							<ModalButton onClick={endMeetingForAll} variant='primary' label='End meeting for all' closesModal />,
							<ModalButton onClick={leaveMeeting} variant='primary' label='Leave Meeting' closesModal />,
							<ModalButton variant='secondary' label='Cancel' closesModal />,
						]}
					/>
				</Modal>
			)}
		</>
	);
};

export default EndMeetingControl;
