import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PostSignup, PostUserExist } from "../../../../api/signup";

const initialState = {
  userData: {
    userId: "",
    email: "",
  },
  loading: false,
};

export const postSignup = createAsyncThunk(
  "signup/postSignup",
  async (data) => {
    try {
      const response = await PostSignup(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const postUserExist = createAsyncThunk(
  "signup/postUserExist",
  async (data) => {
    try {
      const response = await PostUserExist(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postSignup.pending, (state) => {
        state.loading = true;
      })
      .addCase(postSignup.fulfilled, (state, action) => {
        state.userData = action.payload.data;
        state.loading = false;
      })
      .addCase(postSignup.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const getSignupData = (state) => state.signup;
export const getUserData = (state) => state.signup.userData;
export const getUserloading = (state) => state.signup.loading;
export default signupSlice.reducer;
