import React, { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import AddLicenseModal from '../license-and-publish/AddLicenseModal';

import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';

import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Formik } from 'formik';
import { get, map, size, upperCase } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { getConsultantInfo, postConsultantInfo } from '../../../../../store/slice/auth/signup/consultantInfoSlice';
import translate from '../../../../hooks/translate';
// import CMTextButton from '../../../../ui-components/buttons/CMTextButton';
import ShowModalInfo from '../ShowModalInfo';
import AddProfileImage from './AddProfileImage';
// import AddSchoolModal from './AddSchoolModal';
import AddUniversityModal from './AddUniversityModal';
// import WorkplaceModal from './AddWorkplaceModal';
import Autocomplete from '@mui/material/Autocomplete';
import { getLanguageList } from '../../../../../store/slice/common';

const ProfessionalInfo = (props) => {
	const { handleNext, handleBack, consultantInfo, buttonLabel, isEdit } = props;

	const dispatch = useDispatch();

	const [isSubmit, setIsSubmit] = useState(false);
	const [isRefresh, setIsRefresh] = useState(true);

	const store = consultantInfo;
	const consultantData = store.data;

	// const workDataLenght = size(get(consultantData, 'work', []));
	// const schoolDataLenght = size(get(consultantData, 'educationHighSchool', []));
	const universityDataLenght = size(get(consultantData, 'educationUniversity', []));
	const languageList = useSelector((state) => state.common.languageList);

	// const [workModalOpen, setWorkModalOpen] = useState(false);
	const [open, setOpen] = useState(false);

	const handleDialogOpen = () => {
		setOpen(true);
	};

	const licenseDataLenght = size(get(consultantData, 'licenseCertification', []));

	const handleLicenseAndPublication = async (payload) => {
		const res = await dispatch(postConsultantInfo(payload))
			.unwrap()
			.then((result) => {
				if (result.status) {
					!isEdit && handleNext(3);
					dispatch(getConsultantInfo());
					if (isEdit) {
						toast.success(result.message);
					}
				}
			})
			.catch((err) => {
				console.log(' handleProfessionalInfo err', err);
			});
		return res;
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		dispatch(getLanguageList());
	}, [dispatch]);

	const Schema = Yup.object().shape({
		bio: Yup.string()
			.min(120, translate('Too Short! Bio needs to be at least 120 characters. '))
			.max(320, translate('Too Long! Bio needs to be less than 320 characters.'))
			.required(translate('consultant_error_bio_info_required')),
		language: Yup.array()
			.min(1, translate('consultant_error_languages_required'))
			.max(5, translate('consultant_error_languages_max_5')),
	});

	const handleProfessionalInfo = async (payload) => {
		const res = await dispatch(postConsultantInfo(payload))
			.unwrap()
			.then((result) => {
				if (result.status) {
					!isEdit && handleNext(3);
					dispatch(getConsultantInfo());
					if (isEdit) {
						toast.success(result.message);
					}
				}
			})
			.catch((err) => {
				console.log(' handleProfessionalInfo err', err);
			});
		return res;
	};

	return (
		<>
			{isRefresh && (
				<Box>
					<Formik
						initialValues={{
							bio: get(consultantData, 'bio', ''),
							language: get(consultantData, 'language', []),
							profilePictureURL: get(consultantData, 'profilePictureURL', 'NA'),
						}}
						enableReinitialize
						validationSchema={Schema}
						onSubmit={async (values) => {
							setIsSubmit(true);
							const payload = {
								bio: values.bio,
								language: map(values.language, (l) => {
									return { name: l.name };
								}),
								step: isEdit ? get(consultantData, 'step', 3) : 3,
							};
							// workDataLenght > 0 &&
							//   schoolDataLenght > 0 &&
							universityDataLenght > 0 && (await handleProfessionalInfo(payload));

							const payload1 = {
								step: isEdit ? get(consultantData, 'step', 3) : 3,
							};
							licenseDataLenght > 0 &&
								// publicationDataLenght > 0 &&
								(await handleLicenseAndPublication(payload1));
						}}
					>
						{(props) => (
							<form onSubmit={props.handleSubmit}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={3}>
										<FormControl fullWidth>
											<FormLabel htmlFor='age' className='mb-1'>
												{translate('Upload Profile')}
											</FormLabel>
											<Stack direction='row' alignItems='center' spacing={2}>
												<Box className='position-relative'>
													<Avatar
														name='profilePictureURL'
														src={props.values.profilePictureURL}
														alt={upperCase(get(consultantData, 'firstName', 'U'))}
														sx={{
															width: 96,
															height: 96,
															bgcolor: 'primary.main',
														}}
													/>
													<IconButton
														onClick={handleDialogOpen}
														className='position-absolute right-0 bottom-0'
														sx={{
															backgroundColor: 'common.white',
															'&:hover': {
																backgroundColor: 'grey.200',
															},
														}}
													>
														<EditIcon fontSize='small' />
													</IconButton>
												</Box>
												<AddProfileImage
													open={open}
													setOpen={setOpen}
													handleClose={handleClose}
													setIsRefresh={setIsRefresh}
												/>
											</Stack>
										</FormControl>
									</Grid>

									<Grid item xs={12} sm={9}>
										<FormControl fullWidth>
											{/* <FormLabel htmlFor="about-me-field" className="mb-1">
											{translate("consultant_bio")}
										</FormLabel> */}
											<TextField
												id='about-me-field'
												placeholder={translate('consultant_bio')}
												name='bio'
												value={props.values.bio}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												error={props.errors.bio && props.touched.bio ? true : false}
												helperText={props.errors.bio && props.touched.bio ? props.errors.bio : null}
												multiline
												rows={4}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<FormControl fullWidth>
											<FormLabel htmlFor='language' className='mb-2'>
												{translate('consultant_spoken_languages')}
											</FormLabel>

											<Autocomplete
												options={languageList}
												name='language'
												value={props.values.language}
												getOptionLabel={(option) => `${option.name}`}
												isOptionEqualToValue={(option, value) => {
													if (option.name === value.name) {
														return true;
													}
												}}
												onChange={(e, value) => {
													props.setFieldValue('language', value);
												}}
												onBlur={props.handleBlur}
												multiple
												getOptionDisabled={() => (props.values.language?.length > 4 ? true : false)}
												disableCloseOnSelect
												autoHighlight
												filterSelectedOptions={true}
												noOptionsText={translate('common_no_data')}
												popupIcon={<KeyboardArrowDownSharpIcon />}
												renderOption={(props, option) => <li {...props}>{option.name}</li>}
												renderInput={(params) => (
													<TextField
														{...params}
														// label="Languages"
														name='languages'
														placeholder={translate('common_language')}
														error={props.errors.language && props.touched.language ? true : false}
														helperText={
															props.errors.language && props.touched.language
																? props.errors.language
																: null
														}
													/>
												)}
											/>
										</FormControl>
									</Grid>

									<Stack direction='row' spacing={1} sx={{ ml: 'auto' }} mt='10px'>
										{isEdit && (
											<Button type='submit' variant='contained' disabled={props.isSubmitting}>
												{buttonLabel}
											</Button>
										)}
										{/* <Button
                        variant="text"
                        onClick={() => {
                          handleNext(4);
                        }}
                      >
                        Skip
                      </Button> */}
									</Stack>

									{/* <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    {translate("consultant_work")}
                  </Typography>
                  <CMTextButton
                    onClick={() => setWorkModalOpen(true)}
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    color="secondary"
                  >
                    {translate("consultant_add_workplace")}
                  </CMTextButton>
                  <WorkplaceModal
                    workModalOpen={workModalOpen}
                    setWorkModalOpen={setWorkModalOpen}
                  />
                  {size(consultantData.work) > 0 &&
                    consultantData.work.map((item, index) => (
                      <ShowModalInfo
                        key={index}
                        icon={<BusinessCenterRoundedIcon />}
                        title={`${item.title} , ${item.companyName} at ${item.employmentType}`}
                        subtitle={`${moment(item.startDate).format(
                          "L"
                        )} to ${moment(item.endDate).format("L")} ${
                          item.location
                        }`}
                        item={item}
                        sectionName="work"
                        setWorkModalOpen={setWorkModalOpen}
                      />
                    ))}

                  <Divider light />
                  {!(workDataLenght > 0) && isSubmit && (
                    <FormHelperText error>
                      {translate("consultant_work_field_required")}
                    </FormHelperText>
                  )}
                </Grid> */}

									<Grid item xs={12}>
										<Typography variant='subtitle1' style={{ marginBottom: '10px' }}>
											{translate('consultant_education')}
										</Typography>

										{/* <Typography
										variant="subtitle2"
										color="text.secondary"
										mb={"10px"}
									>
										{translate("consultant_university")}
									</Typography> */}
										{size(consultantData.educationUniversity) > 0 &&
											consultantData.educationUniversity.map((item, index) => (
												<ShowModalInfo
													key={index}
													icon={<SchoolRoundedIcon fontSize='small' />}
													title={`${item.title} `}
													subtitle={`${item.fieldOfStudy} at ${item.universityName}`}
													item={item}
													sectionName='educationUniversity'
												/>
											))}
										{!(universityDataLenght > 0) && isSubmit && (
											<FormHelperText error>
												{translate('consultant_error_university_field_required')}
											</FormHelperText>
										)}
										<AddUniversityModal showButton={true} />
									</Grid>
									<Grid item xs={12}>
										<Divider light />
									</Grid>

									<Grid item xs={12}>
										<Typography variant='subtitle1' style={{ marginBottom: '10px' }}>
											{translate('consultant_licence_or_certification')}
										</Typography>
										{/* <Typography
										variant="subtitle1"
										component="h4"
										color="text.primary"
										fontWeight="500"
										mb={'10px'}
									>
										{translate("consultant_licence_or_certification")}
									</Typography> */}
										{size(consultantData.licenseCertification) > 0 &&
											consultantData.licenseCertification.map((item, index) => (
												<ShowModalInfo
													key={index}
													icon={<DescriptionIcon fontSize='small' />}
													title={`${item.name} at ${item.issuingOrganization}`}
													subtitle={`${moment(item.issueDate).format('L')}`}
													link={item.credentialUrl}
													item={item}
													sectionName='licenseCertification'
												/>
											))}
										{/* {!(licenseDataLenght > 0) && isSubmit && (
										<FormHelperText error>
											{translate(
												"consultant_error_licence_or_certification_required"
											)}
										</FormHelperText>
									)} */}
										<AddLicenseModal showButton={true} />
									</Grid>

									{/* <Grid item xs={12}>
                  <Typography variant="subtitle2" color="text.secondary">
                    {translate("consultant_high_school")}
                  </Typography>
                  <AddSchoolModal showButton={true} />
                  {size(consultantData.educationHighSchool) > 0 &&
                    consultantData.educationHighSchool.map((item, index) => (
                      <ShowModalInfo
                        key={index}
                        icon={<SchoolRoundedIcon />}
                        title={`${item.schoolName} at ${item.fieldOfStudy}`}
                        subtitle={`${moment(item.startDate).format(
                          "L"
                        )} to ${moment(item.endDate).format("L")} ${
                          item.location
                        }`}
                        item={item}
                        sectionName="educationHighSchool"
                      />
                    ))}
                  {!(schoolDataLenght > 0) && isSubmit && (
                    <FormHelperText error>
                      {translate("consultant_error_high_school_field_required")}
                    </FormHelperText>
                  )}
                </Grid> */}

									<Grid item xs={12}>
										<Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
											{!isEdit && (
												<Button type='button' variant='outlined' color='primary' onClick={handleBack}>
													{translate('common_btn_back')}
												</Button>
											)}
											{!isEdit && (
												<Button type='submit' variant='contained' disabled={props.isSubmitting}>
													{buttonLabel}
												</Button>
											)}
										</Stack>
									</Grid>
								</Grid>
							</form>
						)}
					</Formik>
				</Box>
			)}
		</>
	);
};

export default ProfessionalInfo;
