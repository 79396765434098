// import { replace } from 'lodash';
// import numeral from 'numeral';

// // ----------------------------------------------------------------------

// export function fCurrency(number) {
// 	return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
// }

// export function fPercent(number) {
// 	return numeral(number / 100).format('0.0%');
// }

// export function fNumber(number) {
// 	return numeral(number).format();
// }

// export function fShortenNumber(number) {
// 	return replace(numeral(number).format('0.00a'), '.00', '');
// }

// export function fData(number) {
// 	return numeral(number).format('0.0 b');
// }

/**
 * Convert file size in bytes to human-readable string.
 *
 * @param {number} fileSizeInBytes - File size in bytes.
 * @returns {string} - Human-readable file size string.
 */
export function getReadableFileSizeString(fileSizeInBytes) {
	const i = Math.floor(Math.log(fileSizeInBytes) / Math.log(1024));
	return (fileSizeInBytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}
