import React from "react";

import Grid from "@mui/material/Grid";

// import TopDetails from "./charts/TopDetails";
import Account from "./account";
import TransactionList from "./components/TransactionList";

const BankingSetup = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Account />
        </Grid>
        {/* <Grid item xs={12}>
          <TopDetails />
        </Grid> */}
        <Grid item xs={12}>
          <TransactionList />
        </Grid>
      </Grid>
    </>
  );
};

export default BankingSetup;
