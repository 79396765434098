import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import DialogContent from '@mui/material/DialogContent';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';

import { map } from 'lodash';
import translate from '../../../hooks/translate';
import { useDispatch } from 'react-redux';
import { getServiceTypes } from '../../../../store/slice/dashboard/servicePlanSlice';
// import SetOfflineDetails from './offline/SetOfflineDetails';
import GoogleCalendar from './online/GoogleCalendar';
import SetPhoneDetails from './phone/SetPhoneDetails';
import { getCountryList, getPhoneCode } from '../../../../store/slice/common';
import { getPhoneService } from '../../../../store/slice/dashboard/appointmentSlice';

const btnList = [
	{ label: translate('common_online'), id: 'common_online' },
	// { label: translate('common_offline'), id: 'common_offline' },
	{ label: translate('common_phone'), id: 'common_phone' },
];

const ServiceTypeSetting = ({ disableForm }) => {
	const dispatch = useDispatch();
	const [drawerOpenState, setDrawerOpenState] = useState('');

	useEffect(() => {
		dispatch(getPhoneCode());
		if (disableForm) return;

		dispatch(getCountryList());
		// dispatch(getOfflineService());
		dispatch(getPhoneService());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [disableForm]);

	const refreshData = () => {
		dispatch(getServiceTypes());
	};

	const closeModal = () => setDrawerOpenState('');

	return (
		<Box>
			<Typography variant='body1' className='mb-3'>
				Connectivity options
			</Typography>
			<Stack direction='row' spacing={1}>
				{map(btnList, (item) => (
					<Button
						variant='outlined'
						color='primary'
						onClick={() => setDrawerOpenState(item.id)}
						key={item.id}
						// disabled={disableForm}
					>
						{translate(item.label)}
					</Button>
				))}
			</Stack>

			<Drawer anchor='right' className='custom-drawer' open={Boolean(drawerOpenState)} onClose={closeModal}>
				<div>
					<Stack
						direction='row'
						gap={1}
						py={2}
						px={{ xs: 2, md: 3 }}
						alignItems='center'
						justifyContent='space-between'
					>
						<IconButton size='small' color='primary' onClick={closeModal} sx={{ minWidth: 0 }}>
							<KeyboardArrowLeftRoundedIcon />
						</IconButton>
						<Typography variant='h6'>
							{drawerOpenState === 'common_offline' && translate('consultant_add_your_office_address')}
							{drawerOpenState === 'common_phone' && translate('consultant_add_your_phone_number')}
						</Typography>
					</Stack>
					<Divider sx={{ borderStyle: 'dashed' }} />
				</div>
				{drawerOpenState === 'common_online' && (
					<DialogContent dividers sx={{ px: { xs: 2, md: 3 }, pt: 2 }}>
						<GoogleCalendar refreshData={refreshData} />
					</DialogContent>
				)}
				{/* {drawerOpenState === 'common_offline' && <SetOfflineDetails refreshData={refreshData} />} */}
				{drawerOpenState === 'common_phone' && <SetPhoneDetails refreshData={refreshData} />}
			</Drawer>
		</Box>
	);
};

export default ServiceTypeSetting;
