import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  AddDefaultTemplate,
  CreateQuestionTemplate,
  GetDefaultTemplate,
  GetQuestionList,
  GetQuestionTemplate,
  StatusUpdateQuestionTemplate,
  UpdateQuestionTemplate,
} from "../../../api/dashboard/QAndATemplateAPI";

const initialState = {
  questionsList: [],
  questionsTemplateList: [],
  defaultTemplateList: [],
  loading: false,
};

export const getQuestionsList = createAsyncThunk(
  "QAndATemplate/getQuestionsList",
  async (data) => {
    try {
      const response = await GetQuestionList(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const getQuestionsTemplateList = createAsyncThunk(
  "QAndATemplate/getQuestionsTemplateList",
  async (data) => {
    try {
      const response = await GetQuestionTemplate(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const createQuestionTemplate = createAsyncThunk(
  "QAndATemplate/createQuestionTemplate",
  async (data) => {
    try {
      const response = await CreateQuestionTemplate(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const updateQuestionTemplate = createAsyncThunk(
  "QAndATemplate/updateQuestionTemplate",
  async (data) => {
    try {
      const response = await UpdateQuestionTemplate(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const statusUpdateQuestionTemplate = createAsyncThunk(
  "QAndATemplate/statusUpdateQuestionTemplate",
  async (data) => {
    try {
      const response = await StatusUpdateQuestionTemplate(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const getDefaultTemplate = createAsyncThunk(
  "QAndATemplate/getDefaultTemplate",
  async (data) => {
    try {
      const response = await GetDefaultTemplate(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const addDefaultTemplate = createAsyncThunk(
  "QAndATemplate/addDefaultTemplate",
  async (data) => {
    try {
      const response = await AddDefaultTemplate(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const QAndATemplateSlice = createSlice({
  name: "QAndATemplate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getQuestionsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuestionsList.fulfilled, (state, { payload }) => {
        state.questionsList = get(payload, "data", []);
        state.loading = false;
      })
      .addCase(getQuestionsList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getQuestionsTemplateList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuestionsTemplateList.fulfilled, (state, { payload }) => {
        state.questionsTemplateList = get(payload, "data", []);
        state.loading = false;
      })
      .addCase(getQuestionsTemplateList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createQuestionTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(createQuestionTemplate.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createQuestionTemplate.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateQuestionTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateQuestionTemplate.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateQuestionTemplate.rejected, (state) => {
        state.loading = false;
      })
      .addCase(statusUpdateQuestionTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(statusUpdateQuestionTemplate.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(statusUpdateQuestionTemplate.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getDefaultTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDefaultTemplate.fulfilled, (state, { payload }) => {
        state.defaultTemplateList = get(payload, "data", []);
        state.loading = false;
      })
      .addCase(getDefaultTemplate.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addDefaultTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(addDefaultTemplate.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addDefaultTemplate.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default QAndATemplateSlice.reducer;
