import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { Formik } from 'formik';
import { get, isEmpty, map, omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { getConsultantInfo, postConsultantInfo } from '../../../../../store/slice/auth/signup/consultantInfoSlice';
import MultiSelectSpecialization, {
	getInitSpecialization,
	getInitSubSpecialization,
} from '../../../../common/MultiSelectSpecialization';
import translate from '../../../../hooks/translate';
import { toast } from 'react-toastify';

const Schema = Yup.object().shape({
	specialization: Yup.array()
		.min(1, translate('consultant_error_specialization_are_required'))
		.max(3, translate('consultant_error_max_3_specialization'))
		.nullable(),
	// subSpecialization: Yup.array().min(1, 'Sub specialization is required'),
});

const getSpecializationSubmitData = (values, subSpecializationData, fieldName, subFieldName) => {
	const spc = [];
	const data = [];
	map(values[fieldName], (item) => {
		spc.push({
			foreignId: get(item, '_id', ''),
			foreignData: get(item, '_id', ''),
			child: [],
		});
	});
	if (!isEmpty(subSpecializationData)) {
		map(spc, (item) => {
			const subEl = [];
			map(subSpecializationData, (subItem) => {
				if (get(subItem, 'parentId', '') === get(item, 'foreignId', '')) {
					subEl.push({
						foreignId: subItem.foreignId,
						foreignData: subItem.foreignId,
						parentId: subItem.parentId,
					});
				}
			});
			data.push({
				...item,
				[subFieldName]: !isEmpty(subEl) ? subEl : [],
			});
		});
		return data;
	} else {
		return spc;
	}
};

const ProfessionalDomain = (props) => {
	const { handleNext, handleBack, consultantInfo, buttonLabel, isEdit } = props;
	const consultantData = get(consultantInfo, 'data', {});
	const specializationList = useSelector((state) => state.common.specializationList);

	const dispatch = useDispatch();

	const handleProfessionalDomainInfo = async (values) => {
		const payload = {
			...omit(values, 'subSpecialization'),
			specialization: getSpecializationSubmitData(values, values.subSpecialization, 'specialization', 'child'),
			step: isEdit ? get(consultantData, 'step', 2) : 2,
		};
		const res = await dispatch(postConsultantInfo(payload))
			.unwrap()
			.then((result) => {
				if (result.status) {
					!isEdit && handleNext(2);
					if (isEdit) {
						toast.success(result.message);
					}
					dispatch(getConsultantInfo());
				}
			})
			.catch((err) => {
				console.log(' handleProfessionalDomainInfo err', err);
			});
		return res;
	};

	return (
		<>
			<Box>
				<Formik
					initialValues={{
						specialization: getInitSpecialization(consultantData, specializationList, 'specialization'),
						subSpecialization: getInitSubSpecialization(
							consultantData,
							specializationList,
							'specialization',
							'child'
						),
					}}
					validationSchema={Schema}
					onSubmit={async (values) => {
						handleProfessionalDomainInfo(values);
					}}
				>
					{(props) => (
						<form onSubmit={props.handleSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography variant='body1' className='mb-3'>
										{translate('common_select_specialization')}
									</Typography>
									<MultiSelectSpecialization
										specialization={specializationList}
										fieldName='specialization'
										subFieldName='subSpecialization'
										formProps={props}
										// initialData={consultantData}
										isSubmit={false}
									/>
								</Grid>
								<Grid item xs={12}>
									<Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
										{!isEdit && (
											<Button type='button' variant='outlined' color='primary' onClick={handleBack}>
												{translate('common_btn_back')}
											</Button>
										)}
										<Stack direction='row' spacing={1} sx={{ ml: 'auto' }}>
											<Button type='submit' variant='contained' disabled={props.isSubmitting}>
												{buttonLabel}
											</Button>
											{/* <Button
                        variant="text"
                        onClick={() => {
                          handleNext(3);
                        }}
                      >
                        Skip
                      </Button> */}
										</Stack>
									</Stack>
								</Grid>
							</Grid>
						</form>
					)}
				</Formik>
			</Box>
		</>
	);
};

export default ProfessionalDomain;
