import React from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { alpha, styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

import { Link } from 'react-router-dom';

import Logo from '../../../assets/images/logo/logo.png';
import AccountPopover from '../../components/AccountPopover';
import LanguagePopover from '../components/LanguagePopover';
import NotificationsPopover from '../components/NotificationsPopover';
import { menuOptions } from './HeaderConfig';

const DRAWER_WIDTH = 240;
const openedMixin = {
	width: `calc(100% - ${DRAWER_WIDTH}px)`,
};
const closedMixin = (theme) => ({
	width: `calc(100% - ${theme.spacing(8.2)})`,
});
const RootStyle = styled(AppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	boxShadow: 'none',
	backdropFilter: 'blur(6px)',
	WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
	backgroundColor: alpha(theme.palette.background.default, 0.72),
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.easeInOut,
		duration: theme.transitions.duration.complex,
	}),
	...(open ? openedMixin : closedMixin(theme)),
	[theme.breakpoints.down('sm')]: {
		width: '100%',
	},
}));

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 72;
const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
	minHeight: APPBAR_MOBILE,
	[theme.breakpoints.up('lg')]: {
		minHeight: APPBAR_DESKTOP,
		padding: theme.spacing(0, 5),
	},
}));
const DashboardHeader = (props) => {
	const { drawerOpen, handleDrawerToggle } = props;

	return (
		<RootStyle open={drawerOpen}>
			<ToolbarStyle className='py-1 header-section'>
				<Stack gap={2} direction='row' alignItems='center'>
					<Box display={{ xs: 'block', sm: 'none' }}>
						<Link to='/'>
							<Box component='img' src={Logo} alt='logo' sx={{ maxWidth: '42px', verticalAlign: 'middle' }} />
						</Link>
					</Box>
					<Box>
						{!drawerOpen && (
							<IconButton
								aria-label='open drawer'
								onClick={handleDrawerToggle}
								edge='start'
								sx={{ color: 'text.primary' }}
							>
								<MenuIcon />
							</IconButton>
						)}
					</Box>
				</Stack>
				<Box sx={{ flexGrow: 1 }} />
				<Stack direction='row' alignItems='center' spacing={{ xs: 0.5, sm: 1.5 }}>
					<LanguagePopover />
					<NotificationsPopover />
					<AccountPopover menuOptions={menuOptions} />
				</Stack>
			</ToolbarStyle>
		</RootStyle>
	);
};

export default DashboardHeader;
