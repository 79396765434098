import React from 'react';

import { get } from 'lodash';
import moment from 'moment';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PreviewIcon from '@mui/icons-material/Preview';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import Label from '../../../common/Label';
import translate from '../../../hooks/translate';
import stringToColor from '../../../hooks/stringColor';
import CMToolTip from '../../../ui-components/tooltip/CMToolTip';
import Iconify from '../../../common/Iconify';

export const COLUMNS_DATA = [
	{
		field: 'bookingId',
		headerName: translate('consultant_booking_id'),
		width: 180,
		editable: false,
	},
	{
		field: 'consultationDate',
		headerName: translate('consultant_consultation_date'),
		type: 'date',
		width: 150,
		editable: false,
		valueGetter: ({ row }) => moment(get(row, 'consultationDate', 'NA'), ['MM-DD-YYYY', 'YYYY-MM-DD']).format('MM/DD/YYYY'),
	},
	{
		field: 'userDetail',
		headerName: 'User Detail',
		width: 250,
		editable: false,
		renderCell: ({ row }) => (
			<Stack direction='row' spacing={2} alignItems='center'>
				<Avatar
					sx={{
						backgroundColor: stringToColor(get(row, 'userId.firstName', 'NA')),
						width: 38,
						height: 38,
					}}
					alt={get(row, 'userId.firstName', '')}
					src={get(row, 'userId.profilePictureURL', 'NA')}
				/>

				<Stack direction='column'>
					<Typography variant='subtitle2'>
						{get(row, 'userId.firstName', '') + ' ' + get(row, 'userId.lastName', '')}
					</Typography>
					<Typography variant='caption' color='text.secondary'>
						{get(row, 'primaryBookingId.email', '')}
					</Typography>
				</Stack>
			</Stack>
		),
	},
	{
		field: 'serviceType',
		headerName: 'Appointment Type',
		width: 200,
		renderCell: ({ row }) => (
			<Box width='100%' textTransform='capitalize'>
				{get(row, 'primaryBookingId.serviceType', '').replace('Consultation', '')}
			</Box>
		),
	},
	{
		field: 'deepLink',
		headerName: translate('Deep Link'),
		width: 120,
		editable: false,
		renderCell: ({ row }) => (
			<CMToolTip
				icon={
					<CopyToClipboard text={get(row, 'primaryBookingId.deepLink', '')}>
						<ContentCopyIcon fontSize='small' color='secondary' />
					</CopyToClipboard>
				}
				title='Copied!!!'
			/>
		),
	},
	{
		field: 'preQAStatus',
		headerName: 'Pre Q/A Template',
		type: 'action',
		width: 170,
		renderCell: ({ row }) => (
			<Stack direction='column' spacing={0} alignItems='center'>
				{row.preQuestionFormId?.templateName ? (
					<Box display='flex' flexDirection='row' spacing={1} alignItems='center'>
						<CMToolTip
							icon={
								<Label
									variant='ghost'
									color='success'
									sx={{
										maxWidth: '110px',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										display: 'block',
										height: 'auto',
										lineHeight: 1,
										py: 0.8,
									}}
								>
									{row.preQuestionFormId?.templateName}
								</Label>
							}
							title={row.preQuestionFormId?.templateName}
						/>
						<IconButton color='primary' size='small' component={Link} to='/dashboard/q-and-a-template'>
							<Iconify icon='eva:edit-fill' />
						</IconButton>
					</Box>
				) : (
					<Stack direction='row' spacing={1} alignItems='center'>
						<CMToolTip
							icon={
								<Label variant='ghost' color='warning'>
									Not Created
								</Label>
							}
							title='You are not created default template for user, click side icon and go to create default template first.'
						/>
						<IconButton color='primary' size='small' component={Link} to='/dashboard/q-and-a-template'>
							<Iconify icon='eva:edit-fill' />
						</IconButton>
					</Stack>
				)}
			</Stack>
		),
	},
	{
		field: 'paymentStatus',
		headerName: translate('consultant_payment_status'),
		width: 150,
		editable: false,
		headerAlign: 'center',
		align: 'center',
		renderCell: ({ row }) => {
			const paymentStatus = get(row, 'primaryBookingId.paymentStatus', '');
			return (
				<Label color={paymentStatus === 'PAID' ? 'success' : paymentStatus === 'UNPAID' ? 'warning' : 'error'}>
					{paymentStatus}
				</Label>
			);
		},
	},
	{
		field: 'bookingStatus',
		headerName: translate('consultant_booking_status'),
		type: 'boolean',
		width: 150,
		editable: false,
		renderCell: ({ row }) => (
			<Box className='w-100 text-center'>
				{get(row, 'primaryBookingId.bookingStatus', false) ? (
					<CheckCircleIcon color='success' />
				) : (
					<CancelIcon color='error' />
				)}
			</Box>
		),
	},
	{
		field: 'Meeting',
		headerName: 'Meeting',
		width: 150,
		editable: false,
		headerAlign: 'center',
		renderCell: ({ row }) => (
			<Box className='w-100 text-center'>
				<IconButton
					aria-label={`${row._id}-view`}
					color='primary'
					component='a'
					target='_blank'
					href={`/dashboard/booking/attend-meeting/${row.primaryBookingId?._id}`}
				>
					<PreviewIcon />
				</IconButton>
			</Box>
		),
	},
	{
		field: 'actions',
		headerName: translate('common_actions'),
		width: 120,
		headerAlign: 'center',
		renderCell: ({ row }) => (
			<Box className='w-100 text-center'>
				<Tooltip title='View All Booking Detail' arrow>
					<IconButton
						aria-label={`${row._id}-view`}
						color='primary'
						component={Link}
						to={`/dashboard/booking/detail/${row?.primaryBookingId?._id}/${row._id}`}
					>
						<PreviewIcon />
					</IconButton>
				</Tooltip>
			</Box>
		),
	},
];
