import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  PostServicePlanAdd,
  DeleteServicePlan,
  GetServicePlan,
  ServicePlanStatus,
  GetServiceType,
  PostRefundPolicyAdd,
  GetRefundPolicy,
  ChangeServicePlanTypeStatus,
  ServicePlanPDFDownload,
} from "../../../api/dashboard/servicePlan";

const initialState = {
  details: [],
  serviceTypes: {},
  refundPolicy: [],
  pdfDownload: null,
  loading: false,
  refundPolicyLoading: false,
};

export const postServicePlanAdd = createAsyncThunk(
  "servicePlan/postServicePlanAdd",
  async (data) => {
    try {
      const response = await PostServicePlanAdd(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const getServicePlan = createAsyncThunk(
  "servicePlan/getServicePlan",
  async (data) => {
    try {
      const response = await GetServicePlan(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const getServiceTypes = createAsyncThunk(
  "servicePlan/getServiceTypes",
  async (data) => {
    try {
      const response = await GetServiceType(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const deleteServicePlan = createAsyncThunk(
  "servicePlan/deleteServicePlan",
  async (data) => {
    try {
      const response = await DeleteServicePlan(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const updateServicePlanStatus = createAsyncThunk(
  "servicePlan/updateServicePlanStatus",
  async (data) => {
    try {
      const response = await ServicePlanStatus(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const postRefundPolicyAdd = createAsyncThunk(
  "servicePlan/postRefundPolicyAdd",
  async (data) => {
    try {
      const response = await PostRefundPolicyAdd(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const getRefundPolicy = createAsyncThunk(
  "servicePlan/getRefundPolicy",
  async () => {
    try {
      const response = await GetRefundPolicy();
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const changeServicePlanTypeStatus = createAsyncThunk(
  "servicePlan/changeServicePlanTypeStatus",
  async (data) => {
    try {
      const response = await ChangeServicePlanTypeStatus(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const servicePlanPDFDownload = createAsyncThunk(
  "servicePlan/servicePlanPDFDownload",
  async () => {
    try {
      const response = await ServicePlanPDFDownload();
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);


export const servicePlanSlice = createSlice({
  name: "servicePlan",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postServicePlanAdd.pending, (state) => {
        state.loading = true;
      })
      .addCase(postServicePlanAdd.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postServicePlanAdd.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getServicePlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(getServicePlan.fulfilled, (state, { payload }) => {
        state.details = get(payload, "data", []);
        state.loading = false;
      })
      .addCase(getServicePlan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getServiceTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getServiceTypes.fulfilled, (state, { payload }) => {
        state.serviceTypes = get(payload, "data", {});
        state.loading = false;
      })
      .addCase(getServiceTypes.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteServicePlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteServicePlan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteServicePlan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateServicePlanStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateServicePlanStatus.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateServicePlanStatus.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postRefundPolicyAdd.pending, (state) => {
        state.loading = true;
      })
      .addCase(postRefundPolicyAdd.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postRefundPolicyAdd.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getRefundPolicy.pending, (state) => {
        state.refundPolicyLoading = true;
      })
      .addCase(getRefundPolicy.fulfilled, (state, { payload }) => {
        state.refundPolicy = get(payload, "data", {});
        state.refundPolicyLoading = false;
      })
      .addCase(getRefundPolicy.rejected, (state) => {
        state.refundPolicyLoading = false;
      })  
      .addCase(servicePlanPDFDownload.pending, (state) => {
        state.loading = true;
      })
      .addCase(servicePlanPDFDownload.fulfilled, (state, { payload }) => {
        state.pdfDownload = get(payload, "data", {});
        state.loading = false;
      })
      .addCase(servicePlanPDFDownload.rejected, (state) => {
        state.loading = false;
      });
      
  },
});

export default servicePlanSlice.reducer;
