import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { toast } from 'react-toastify';
import translate from '../../../../hooks/translate';
import CMCardHeader from '../../../../ui-components/cards/CMCardHeader';
import AppointmentAdd from '../../AppointmentAdd';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

export default function BookingListHeader() {
	const [open, setOpen] = useState(false);
	const servicePlanData = useSelector((state) => state.servicePlan.details);
	const serviceTypes = useSelector((state) => state.servicePlan.serviceTypes);
	const serviceTypesWorkStatus = get(serviceTypes, 'workStatus', false);

	const handleDialogOpen = () => {
		setOpen(true);
	};

	const handleDialogClose = () => {
		setOpen(false);
	};

	return (
		<Box mb={2}>
			<Box display='flex' justifyContent='space-between' gap={2} p={1}>
				<CMCardHeader border='0' title={translate('consultant_booking_listing')} />
				<Button
					variant='contained'
					size='medium'
					onClick={() => {
						if (servicePlanData.length <= 0) {
							toast.warning('To add an appointment, please first create a service plan.');
							return;
						}
						handleDialogOpen();
					}}
					disabled={serviceTypesWorkStatus}
				>
					Create appointment
				</Button>
			</Box>
			<Divider />

			<AppointmentAdd open={open} onClose={handleDialogClose} />
		</Box>
	);
}
