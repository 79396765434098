import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';

const MuiAccordion = styled(Accordion)(({ theme, lightBorder }) => ({
	border: `1px solid ${alpha(theme.palette.grey[200], lightBorder ? 0.4 : 1)}`,
	'&.Mui-expanded': {
		boxShadow: 'none',
	},
	marginBottom: 8,
	'&:last-of-type': {
		marginBottom: 0,
	},
	'&': {
		borderRadius: '4px',
	},
	'&:before': {
		display: 'none',
	},
}));

const CMAccordion = (props) => {
	const { children, ...rest } = props;
	return (
		<MuiAccordion elevation={0} {...rest}>
			{children}
		</MuiAccordion>
	);
};

export default CMAccordion;
