import React from "react";
import { filter, get } from "lodash";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import QAndABox from "./QAndABox";

const StaticQAndAFormData = ({ QAndAFormData }) => {
  const getCurrentSituationSubQA = (value) => {
    let options = [];
    switch (get(value, "answer", "")) {
      case "M":
        options.push(...MaleOption);
        break;
      case "F":
        options.push(...FemaleOption);
        break;
      default:
        break;
    }

    const res = filter(
      options,
      (opt) =>
        get(opt, "value", 0) === get(value, "options.0.subQuestions.0.answer")
    );
    return get(res, "0.label", "NA");
  };

  return (
    <>
      <Box className="pb-3">
        <Typography variant="h3" className="mb-3" sx={{ color: "grey.800" }}>
          Basic Information
        </Typography>
        <QAndABox
          number={1}
          question={"Your Current Weight ?"}
          answer={
            get(QAndAFormData, "weight.value", 0) +
            " " +
            get(QAndAFormData, "weight.unit", "NA")
          }
          sx={{ mb: 3 }}
        />
        <QAndABox
          number={2}
          question={"Your Current Height ?"}
          answer={
            get(QAndAFormData, "height.value", 0) +
            " " +
            get(QAndAFormData, "height.unit", "NA")
          }
          sx={{ mb: 3 }}
        />
        <QAndABox
          number={3}
          question={"My overall goal/ What are your long-term health goals ?"}
          answer={get(QAndAFormData, "goalType.title", "NA")}
          sx={{ mb: 3 }}
        />
        <QAndABox
          number={4}
          question={"My current situation ?"}
          answer={
            get(QAndAFormData, "currentSituation.answer", "NA") === "M"
              ? "Male"
              : "Female"
          }
          sx={{ mb: 3 }}
        >
          <QAndABox
            number={1}
            question={"Male ?"}
            answer={getCurrentSituationSubQA(
              get(QAndAFormData, "currentSituation", "NA")
            )}
          />
        </QAndABox>
      </Box>
      <Box className="pb-3">
        <Typography variant="h3" className="mb-3" sx={{ color: "grey.800" }}>
          Food Preference
        </Typography>
        <QAndABox
          number={1}
          question={"What is your dietary Preference ?"}
          answer={get(QAndAFormData, "dietPreference.title", "NA")}
          sx={{ mb: 3 }}
        />
        <QAndABox
          number={2}
          question={"Do you have any allergies ?"}
          answer={get(QAndAFormData, "allergy.title", "NA")}
          sx={{ mb: 3 }}
        />
        <QAndABox
          number={3}
          question={"My DAY/WORK activity level ?"}
          answer={get(QAndAFormData, "workActiveLevel.title", "NA")}
          sx={{ mb: 3 }}
        />
        <QAndABox
          number={4}
          question={"My leisure time activity level ?"}
          answer={get(QAndAFormData, "leisureActiveLevel.title", "NA")}
          sx={{ mb: 3 }}
        />
      </Box>
    </>
  );
};

export default StaticQAndAFormData;

const MaleOption = [
  {
    label: "I have recognized eating disorder",
    value: "1",
  },
  {
    label: "None of this applies",
    value: "2",
  },
];
const FemaleOption = [
  {
    label: "I am pregnant",
    value: "1",
  },
  {
    label: "I am exclusive breastfeeding",
    value: "2",
  },
  {
    label: "I am not exclusive breastfeeding",
    value: "3",
  },
  {
    label: "I have recognized eating disorder",
    value: "4",
  },
  {
    label: "None of this applies",
    value: "5",
  },
];
