import React, { useEffect, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import socket from '../../../../../../context/Socket';
import { getFormattedTimelineItem, getFormattedTimelineList } from './helper';

export default function TimelineContainer({ timelineData }) {
	const [timeline, setTimeline] = useState({
		list: [],
		nextToken: null,
	});

	useEffect(() => {
		setTimeline({
			list: getFormattedTimelineList(timelineData.list),
			nextToken: timelineData.nextToken,
		});
	}, [timelineData]);

	useEffect(() => {
		const cb = (value) => {
			setTimeline((prev) => ({
				list: [getFormattedTimelineItem(value), ...prev.list],
				nextToken: prev.nextToken,
			}));
		};
		socket.on('newTimeline', cb);

		return () => {
			socket.off('newTimeline', cb);
		};
	}, []);

	return (
		<Card sx={{ bgcolor: 'background.paper' }}>
			<Box p={2}>Timeline</Box>
			<Divider />

			<Box maxHeight='500px' overflow='auto'>
				<Timeline
					sx={{
						[`& .${timelineItemClasses.root}:before`]: {
							flex: 0,
							padding: 0,
						},
					}}
				>
					{timeline.list.map((timelineData) => (
						<TimelineItem key={timelineData._id}>
							<TimelineSeparator>
								<TimelineConnector />
								<TimelineDot>
									<FastfoodIcon />
								</TimelineDot>
								<TimelineConnector />
							</TimelineSeparator>
							<TimelineContent sx={{ py: '12px', px: 2 }}>
								<Typography variant='h6' component='span'>
									{timelineData.title}
								</Typography>
								<Typography>Because you need strength</Typography>
								<Typography variant='body2' color='text.secondary'>
									{timelineData.createdAt}
								</Typography>
							</TimelineContent>
						</TimelineItem>
					))}
				</Timeline>
			</Box>
		</Card>
	);
}
