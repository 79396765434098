import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  GetTranslateLanguageMacros,
  GetTranslateLanguageMacrosWOLangID,
  GetTranslateLanguages,
} from "../../../api/AppLanguageConfig";

const initialState = {
  translateLanguages: [],
  translateLanguageMacros: [],
  isEmptyLanguageMacros: false,
  isGetMacrosLoading: false,
  loading: false,
};

export const getTranslateLanguages = createAsyncThunk(
  "appLanguageConfig/getTranslateLanguages",
  async () => {
    try {
      const response = await GetTranslateLanguages();
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const getTranslateLanguageMacros = createAsyncThunk(
  "appLanguageConfig/getTranslateLanguageMacros",
  async (data) => {
    try {
      const response = await GetTranslateLanguageMacros(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const getTranslateLanguageMacrosWOLangID = createAsyncThunk(
  "appLanguageConfig/getTranslateLanguageMacrosWOLangID",
  async (data) => {
    try {
      const response = await GetTranslateLanguageMacrosWOLangID(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const AppLanguageConfig = createSlice({
  name: "appLanguageConfig",
  initialState,
  reducers: {
    setEmptyLanguageMacros: (state, { payload: { status } }) => {
      state.isEmptyLanguageMacros = status;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTranslateLanguages.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTranslateLanguages.fulfilled, (state, { payload }) => {
        state.translateLanguages = get(payload, "data", []);
        state.loading = false;
      })
      .addCase(getTranslateLanguages.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTranslateLanguageMacros.pending, (state) => {
        state.isGetMacrosLoading = true;
        state.loading = true;
      })
      .addCase(getTranslateLanguageMacros.fulfilled, (state, { payload }) => {
        state.translateLanguageMacros = get(payload, "data", []);
        state.isGetMacrosLoading = false;
        state.loading = false;
      })
      .addCase(getTranslateLanguageMacros.rejected, (state) => {
        state.isGetMacrosLoading = false;
        state.loading = false;
      })
      .addCase(getTranslateLanguageMacrosWOLangID.pending, (state) => {
        state.isGetMacrosLoading = true;
        state.loading = true;
      })
      .addCase(
        getTranslateLanguageMacrosWOLangID.fulfilled,
        (state, { payload }) => {
          state.translateLanguageMacros = get(payload, "data", []);
          state.isGetMacrosLoading = false;
          state.loading = false;
        }
      )
      .addCase(getTranslateLanguageMacrosWOLangID.rejected, (state) => {
        state.isGetMacrosLoading = false;
        state.loading = false;
      });
  },
});

export const { setEmptyLanguageMacros } = AppLanguageConfig.actions;

export default AppLanguageConfig.reducer;
