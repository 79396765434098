import React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const QAndABox = (props) => {
  const { number, question, answer, subQuestionStyle, children, ...rest } =
    props;

  return (
    <Stack direction="row" spacing={2} {...rest}>
      <Typography
        variant="body1"
        sx={{
          fontSize: { sm: 20, xs: 18 },
          fontWeight: 500,
          color: "grey.700",
          whiteSpace: "nowrap",
        }}
      >
        {number}&#46; &#8594;
      </Typography>
      <Box>
        <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { sm: 20, xs: 18 },
              fontWeight: 500,
              color: "grey.700",
            }}
          >
            Q.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: { sm: 20, xs: 18 },
              fontWeight: 500,
              color: "grey.700",
              textTransform: "capitalize",
            }}
          >
            {question}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { sm: 20, xs: 18 },
              fontWeight: 500,
              color: "grey.700",
            }}
          >
            A.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: { sm: 20, xs: 18 },
              fontWeight: 500,
              color: "grey.900",
              textTransform: "capitalize",
            }}
          >
            {answer}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          className="mt-2"
          {...subQuestionStyle}
        >
          {children}
        </Stack>
      </Box>
    </Stack>
  );
};

export default QAndABox;
