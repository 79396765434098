import { useEffect } from 'react';

import {
	MeetingStatus,
	useNotificationDispatch,
	Severity,
	ActionType,
	useMeetingStatus,
} from 'amazon-chime-sdk-component-library-react';

const useMeetingEndRedirect = () => {
	const dispatch = useNotificationDispatch();
	const meetingStatus = useMeetingStatus();

	useEffect(() => {
		if (meetingStatus === MeetingStatus.Ended) { 
			dispatch({
				type: ActionType.ADD,
				payload: {
					severity: Severity.INFO,
					message: 'The meeting was ended by another attendee',
					autoClose: true,
					replaceAll: true,
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [meetingStatus]);
};

export default useMeetingEndRedirect;
