import { Cookies } from "react-cookie";
import { get } from "lodash";

export const expireTime = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

const cookie = new Cookies();

export default cookie;

export const getuserDataCookie = () => {
  const userData = cookie.get("consultant_data");
  return userData;
};
export const getToken = () => {
  const userData = cookie.get("consultant_data");
  return get(userData, "token", "");
};

export const setCustomCookie = (name, data, expires) => {
  cookie.set(name, data, {
    expires,
  });
  return true;
};

export const getCustomCookie = (name) => {
  const data = cookie.get(name);
  return data;
};
