import { get, isEmpty, map } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { getQAndAData } from "../../../store/slice/dashboard/bookingSlice";

import NoDataOverlay from "../../common/NoDataOverlay";
import CMCardHeader from "../../ui-components/cards/CMCardHeader";
import QAndASkeleton from "../../ui-components/skeleton/QAndASkeleton";
import QAndABox from "./components/QAndABox";
import StaticQAndAFormData from "./components/StaticQAndAFormData";
import translate from "../../hooks/translate";

const QAndADetail = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.booking.loading);
  const QAndAFormData = useSelector((state) => state.booking.QAndAData);

  const [emptyData, setEmptyData] = useState(false);

  useEffect(() => {
    dispatch(getQAndAData(get(location, "state.id", "")))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false)) {
          console.log("CONDITION :>", isEmpty(get(result, "data", {})));
          if (isEmpty(get(result, "data", {}))) {
            setEmptyData(true);
          }
        } else {
          setEmptyData(true);
        }
      })
      .catch((err) => {
        throw err;
      });
  }, [dispatch, location]);

  return (
    <Paper elevation={0} className="h-100">
      <CMCardHeader title={translate("consultant_filled_q_and_a_detail")} />
      <CardContent>
        {isLoading ? (
          <QAndASkeleton skeletons={5} />
        ) : emptyData ? (
          <NoDataOverlay />
        ) : (
          <Box className="px-1">
            <StaticQAndAFormData QAndAFormData={QAndAFormData} />
            <Box>
              {map(
                get(QAndAFormData, "sections", []),
                (sectionData, sectionIndex) => {
                  return (
                    <Box>
                      <Typography
                        variant="h3"
                        className="mb-3"
                        sx={{ color: "grey.800" }}
                      >
                        Section {sectionIndex + 1}
                      </Typography>
                      {map(
                        get(sectionData, "questions", []),
                        (questionsData, questionsIndex) => {
                          return (
                            <QAndABox
                              number={questionsIndex + 9}
                              question={get(
                                questionsData,
                                "questionId.questionTitle",
                                "NA"
                              )}
                              answer={get(questionsData, "answer", "NA")}
                              sx={{ mb: 3 }}
                            >
                              {map(
                                get(questionsData, "options", []),
                                (optionsData, _optionsIndex) => {
                                  return map(
                                    get(optionsData, "subQuestions", []),
                                    (subQuestionsData, subQuestionsIndex) => {
                                      return (
                                        <QAndABox
                                          number={subQuestionsIndex + 1}
                                          question={get(
                                            subQuestionsData,
                                            "questionId.questionTitle",
                                            "NA"
                                          )}
                                          answer={get(
                                            subQuestionsData,
                                            "answer",
                                            "NA"
                                          )}
                                          sx={{
                                            mb: subQuestionsIndex - 1 ? 0 : 3,
                                          }}
                                        />
                                      );
                                    }
                                  );
                                }
                              )}
                            </QAndABox>
                          );
                        }
                      )}
                    </Box>
                  );
                }
              )}
            </Box>
          </Box>
        )}
      </CardContent>
    </Paper>
  );
};

export default QAndADetail;
