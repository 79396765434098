import React, { useCallback, useEffect, useState } from "react";

import LinearProgress from "@mui/material/LinearProgress";

import { get, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { postConnectStripeAccountStatus } from "../../../../store/slice/dashboard/bankingSlice";
import CheckStatus from "./CheckStatus";
import ConnectStripe from "./ConnectStripe";

const Account = () => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState("");

  const loginUrl = useSelector((state) => state.banking.loginUrl);
  const loading = useSelector((state) => state.banking.loading);

  const handleStatus = useCallback(async () => {
    await dispatch(postConnectStripeAccountStatus())
      .unwrap()
      .then((result) => {
        if (get(result, "status", false)) {
          setUrl(get(result, "data.loginLink", ""));
        } else {
          setUrl("");
        }
      })
      .catch((error) => {
        console.log("connect stripe error", error);
      });
  }, [dispatch]);

  useEffect(() => {
    handleStatus();
  }, [handleStatus]);
  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : !isEmpty(url) && !isEmpty(loginUrl) ? (
        <CheckStatus url={url} />
      ) : (
        <ConnectStripe />
      )}
    </>
  );
};

export default Account;
