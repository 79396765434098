import React, { useCallback, useEffect, useMemo, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";

import { get, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  getQuestionsTemplateList,
  statusUpdateQuestionTemplate,
} from "../../../store/slice/dashboard/QAndATemplateSlice";
import CMCardHeader from "../../ui-components/cards/CMCardHeader";
import CMDataGrid from "../../ui-components/data-grid/CMDataGrid";
import CMDataGridToolbar from "../../ui-components/data-grid/CMDataGridToolbar";
import EmptyTableRowOverlay from "../../ui-components/data-grid/EmptyTableRowOverlay";
import TemplateDetailView from "./components/TemplateDetailView";
import translate from "../../hooks/translate";

const QAndATemplateList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const questionTemplateList = useSelector(
    (state) => state.QAndATemplate.questionsTemplateList

  );

  const isLoading = useSelector((state) => state.QAndATemplate.loading);

  const [dialogData, setDialogData] = useState({
    id: "",
    title: "",
    rowData: [],
  });

  const [templateViewDialog, setTemplateViewDialog] = useState(false);
  
  const handleTemplateViewOpen = useCallback(
    (data) => {
      setDialogData({
        ...dialogData,
        id: data._id,
        title: `${data.templateName} View`,
        rowData: data,
      });
      setTemplateViewDialog(true);
    },
    [dialogData]
  );

  const handleTemplateViewClose = () => {
    setTemplateViewDialog(false);
  };

  const handleStatusUpdate = useCallback(
    (event, row) => {
      setDialogData({ ...dialogData, id: row._id });
      const apiData = {
        id: row._id,
        data: { status: event.target.checked },
      };
      dispatch(statusUpdateQuestionTemplate(apiData))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            dispatch(getQuestionsTemplateList());
            toast.success(
              get(result, "message", "Template status update successfully.")
            );
          } else {
            toast.error(get(result, "message", "Something went wrong."));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [dialogData, dispatch]
  );

  useEffect(() => {
    dispatch(getQuestionsTemplateList());
  }, [dispatch]);

  const columnsData = useMemo(
    () => [
      {
        field: "templateName",
        headerName: translate("consultant_template_name"),
        width: 320,
        editable: false,
      },
      {
        field: "language",
        headerName: translate("common_language"),
        width: 180,
        editable: false,
        valueGetter: ({ row }) => get(row, "language.title", ""),
      },
      // {
      //   field: "goalType",
      //   headerName: translate("consultant_goal_type"),
      //   width: 240,
      //   editable: false,
      //   valueGetter: ({ row }) => get(row, "goalType.title", ""),
      // },
      {
        field: "type",
        headerName: translate("consultant_consultation_type"),
        width: 180,
        editable: false,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row }) => (
          <Box className="text-capitalize MuiDataGrid-cellContent">
            {get(row, "type", "")}
          </Box>
        ),
      },
      {
        field: "createdBy",
        headerName: translate("consultant_created_by"),
        width: 180,
        editable: false,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "actions",
        headerName: translate("common_actions"),
        width: 220,
        headerAlign: "center",
        renderCell: ({ row }) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              columnGap: "16px",
              width: "100%",
              px: 2,
            }}
          >
            <IconButton
              aria-label={`${row._id}-edit`}
              color="primary"
              onClick={() => {
                handleTemplateViewOpen(row);
              }}
            >
              <VisibilityIcon />
            </IconButton>
            {get(row, "createdBy", "") !== "MASTER_ADMIN" ? (
              <>
                <IconButton
                  aria-label={`${row._id}-edit`}
                  color="primary"
                  onClick={() => {
                    navigate("/dashboard/q-and-a-template/update", {
                      state: {
                        formType: "update",
                        rowData: row,
                      },
                    });
                  }}
                >
                  <EditIcon />
                </IconButton>
                <Switch
                  checked={row.status}
                  onChange={(event) => {
                    handleStatusUpdate(event, row);
                  }}
                  inputProps={{
                    "aria-label": `${row._id}-status`,
                    role: "switch",
                  }}
                  disabled={get(dialogData, "id", "") === row._id && isLoading}
                />
              </>
            ) : null}
          </Box>
        ),
      },
    ],
    [
      dialogData,
      handleStatusUpdate,
      handleTemplateViewOpen,
      isLoading,
      navigate,
    ]
  );

  const CustomToolbar = () => {
    return (
      <CMDataGridToolbar align="right" divider={true}>
        <Button
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          fontSize="14px"
          onClick={() => {
            navigate("/dashboard/q-and-a-template/create", {
              state: {
                formType: "create",
                rowData: [],
              },
            });
          }}
        >
          {translate("consultant_create_new")}
        </Button>
      </CMDataGridToolbar>
    );
  };

  return (
    <Paper variant="outlined" className="h-100">
      <CMCardHeader title="Question Template Listing" />
      <CardContent>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <CMDataGrid
              rows={!isEmpty(questionTemplateList) ? questionTemplateList : []}
              columns={columnsData}
              getRowId={(row) => row._id}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick={true}
              loading={isLoading}
              components={{
                Toolbar: CustomToolbar,
                NoRowsOverlay: EmptyTableRowOverlay,
                LoadingOverlay: LinearProgress,
              }}
              className="min-vh-100"
            />
            <TemplateDetailView
              open={templateViewDialog}
              onClose={handleTemplateViewClose}
              dialogData={dialogData}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Paper>
  );
};

export default QAndATemplateList;
