import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import truncateString from "../../../../hooks/truncateString";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import Iconify from "../../../../common/Iconify";
import moment from "moment";
import DescriptionIcon from "@mui/icons-material/Description";
import Zoom from "@mui/material/Zoom";

const chatBox = (props) => {
  const { chatData } = props;
  return (
    <Stack>
      <Stack className="px-2 py-2 border rounded overflow-scroll vh-50">
        {chatData.map((item) => {
          const { documentStatus, message, senderType, documentURL } = item;

          return (
            <>
              {senderType === "CUSTOMER" ? (
                <Box
                  component="div"
                  className="my-2 d-flex justify-content-start"
                >
                  <Stack className="text-break max-w-75 bg-secondary text-white rounded-top rounded-right p-2">
                    {documentStatus === "true" ? (
                      <Stack
                        direction="row"
                        alignItems="center"
                        className="gap-1"
                      >
                        <DescriptionIcon className="text-secondary-light" />
                        <Link
                          href={documentURL}
                          variant="caption"
                          target="_blank"
                          className="text-secondary-light text-decoration-none"
                        >
                          {truncateString(documentURL, 28)}
                        </Link>
                        <CopyToClipboard
                          options={{ message: "Whoa!" }}
                          onCopy={() => toast.success("Copied Successfully")}
                          text={documentURL}
                        >
                          <Tooltip
                            arrow
                            TransitionComponent={Zoom}
                            title="Copy To Clipboard"
                            placement="left"
                          >
                            <IconButton
                              className="text-secondary-light"
                              size="small"
                            >
                              <Iconify icon="eva:copy-fill" />
                            </IconButton>
                          </Tooltip>
                        </CopyToClipboard>
                      </Stack>
                    ) : (
                      <Typography variant="subtitle2" className="py-1">
                        {message}
                      </Typography>
                    )}
                    <Typography
                      variant="caption"
                      component="p"
                      className="text-right position-relative"
                      top={2}
                    >
                      {`${moment(item.dateTime).format("hh:mm a")}`}
                    </Typography>
                  </Stack>
                </Box>
              ) : (
                senderType === "CONSULTANT" && (
                  <Box
                    component="div"
                    className="my-2 d-flex justify-content-end"
                  >
                    <Stack className="text-break max-w-75 bg-primary text-white rounded-left rounded-top p-2">
                      {documentStatus === "true" ? (
                        <Stack
                          direction="row"
                          alignItems="center"
                          className="gap-1"
                        >
                          <DescriptionIcon className="text-primary-light" />
                          <Link
                            href={documentURL}
                            variant="caption"
                            target="_blank"
                            className="text-primary-light text-decoration-none"
                          >
                            {truncateString(documentURL, 28)}
                          </Link>
                          <CopyToClipboard
                            options={{ message: "Whoa!" }}
                            onCopy={() => toast.success("Copied Successfully")}
                            text={documentURL}
                          >
                            <Tooltip
                              arrow
                              TransitionComponent={Zoom}
                              title="Copy To Clipboard"
                              placement="left"
                            >
                              <IconButton
                                className="text-primary-light"
                                size="small"
                              >
                                <Iconify icon="eva:copy-fill" />
                              </IconButton>
                            </Tooltip>
                          </CopyToClipboard>
                        </Stack>
                      ) : (
                        <Typography variant="subtitle2" className="py-1">
                          {message}
                        </Typography>
                      )}
                      <Typography
                        variant="caption"
                        component="p"
                        className="text-right position-relative"
                        top={2}
                      >
                        {`${moment(item.dateTime).format("hh:mm a")}`}
                      </Typography>
                    </Stack>
                  </Box>
                )
              )}
            </>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default chatBox;
