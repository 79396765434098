import moment from 'moment';

export function getFormattedTimelineItem(item) {
	return {
		metaData: item.metaData,
		_id: item._id,
		time: item.time,
		userId: item.userId,
		consultantId: item.consultantId,
		iconType: item.iconType,
		title: item.title,
		createdAt: moment(item.createdAt).format('h:mm a'),
	};
}

export function getFormattedTimelineList(timelines) {
	const list = Array.isArray(timelines) ? timelines.flat() : [];
	return list.map(getFormattedTimelineItem);
}
