export function list(theme) {
	return {
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: 'inherit',
					minWidth: 'auto',
					marginRight: theme.spacing(2),
				},
			},
		},
		MuiListItemAvatar: {
			styleOverrides: {
				root: {
					minWidth: 'auto',
					marginRight: theme.spacing(2),
				},
			},
		},
		MuiListItemText: {
			styleOverrides: {
				root: {
					margin: 0,
				},
				multiline: {
					margin: 0,
				},
			},
		},
	};
}
