import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import cookie, { getuserDataCookie } from "../../../../components/hooks/cookie";
import { GetMeInfo } from "../../../../api/common";

const user_data = getuserDataCookie();
const initialState = {
  authData: {
    token: get(user_data, "token", "") !== "" ? user_data.token : "",
  },
  meInfo: [],
  isLoggedIn: get(user_data, "token", "") !== "" ? true : false,
  isSignupCompleted: cookie.get("isSignupCompleted") === "true" ? true : false,
  loading: false,
};
export const getMeInfo = createAsyncThunk("auth/getMeInfo", async () => {
  try {
    const response = await GetMeInfo();
    return response.data;
  } catch (error) {
    return { status: false, message: error.response.data.message };
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoggedIn: (state, { payload }) => {
      state.isLoggedIn = payload;
    },
    setAuthToken: (state, { payload }) => {
      state.authData.token = payload;
    },
    Logout: (state) => {
      cookie.remove("consultant_data", { path: "/" });
      cookie.remove("isSignupCompleted", { path: "/" });
      localStorage.removeItem('revokeGoogleToken')
      state.authData.token = "";
      state.isLoggedIn = false;
      state.isSignupCompleted = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMeInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMeInfo.fulfilled, (state, { payload }) => {
        state.meInfo = get(payload, "data", []);
        state.isSignupCompleted = get(payload, "data.termsAndCondition", false);
        state.loading = false;
      })
      .addCase(getMeInfo.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const getMeData = (state) => state.auth.meInfo;
export const { setIsLoggedIn, Logout, setAuthToken } = authSlice.actions;
export default authSlice.reducer;
