import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  PostForgetPassword,
  PostForgetPasswordOtpVerify,
  PostForgetPasswordReset
} from "../../../../api/signin";

const initialState = {
  data: {
    email: "",
    otpCode: "",
  },
  loading: false,
};

export const postForgetPassword = createAsyncThunk(
  "forgetPassword/postForgetPassword",
  async (data) => {
    try {
      const response = await PostForgetPassword(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const postForgetPasswordOtpVerify = createAsyncThunk(
  "forgetPassword/postForgetPasswordOtpVerify",
  async (data) => {
    try {
      const response = await PostForgetPasswordOtpVerify(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const postForgetPasswordReset = createAsyncThunk(
  "forgetPassword/postForgetPasswordReset",
  async (data) => {
    try {
      const response = await PostForgetPasswordReset(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const forgetPasswordSlice = createSlice({
  name: "forgetPassword",
  initialState,
  reducers: {
    handleOtpCode: (state, { payload }) => {
      state.data.otpCode = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postForgetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(postForgetPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        let otp = state.data.otpCode;
        state.data = payload.data;
        state.data.otpCode = otp;
      })
      .addCase(postForgetPassword.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postForgetPasswordOtpVerify.pending, (state) => {
        state.loading = true;
      })
      .addCase(postForgetPasswordOtpVerify.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postForgetPasswordOtpVerify.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postForgetPasswordReset.pending, (state) => {
        state.loading = true;
      })
      .addCase(postForgetPasswordReset.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postForgetPasswordReset.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const getData = (state) => state.forgetPassword;
export const { handleOtpCode } = forgetPasswordSlice.actions;
export default forgetPasswordSlice.reducer;
