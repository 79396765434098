import SvgIcon from "@mui/material/SvgIcon";
export const AppleIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="40"
      height="40"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5685 0.513997C19.509 0.447497 17.3652 0.540247 15.4997 2.565C13.6342 4.588 13.9212 6.9085 13.9632 6.968C14.0052 7.0275 16.6232 7.12025 18.2945 4.7665C19.9657 2.41275 19.628 0.582247 19.5685 0.513997ZM25.368 21.0467C25.284 20.8787 21.2992 18.8872 21.6702 15.0582C22.0412 11.2275 24.6015 10.1775 24.6417 10.0637C24.682 9.95 23.597 8.68125 22.4472 8.039C21.6031 7.58619 20.6688 7.32676 19.712 7.2795C19.523 7.27425 18.8667 7.11325 17.5175 7.4825C16.6285 7.72575 14.6247 8.51325 14.0735 8.54475C13.5205 8.57625 11.8755 7.63125 10.1062 7.381C8.97399 7.16225 7.77349 7.61025 6.91424 7.955C6.05674 8.298 4.42574 9.2745 3.28474 11.8697C2.14374 14.4632 2.74049 18.5722 3.16749 19.8497C3.59449 21.1255 4.26124 23.2167 5.39524 24.7427C6.40324 26.4647 7.74024 27.66 8.29849 28.066C8.85674 28.472 10.4317 28.7415 11.5237 28.1832C12.4022 27.6442 13.9877 27.3345 14.6142 27.3572C15.239 27.38 16.471 27.6267 17.7327 28.3005C18.732 28.6452 19.677 28.5017 20.6237 28.1167C21.5705 27.73 22.9407 26.2635 24.5402 23.2902C25.1475 21.9077 25.424 21.1605 25.368 21.0467Z"
        fill="black"
      />
      <path
        d="M19.5685 0.513997C19.509 0.447497 17.3652 0.540247 15.4997 2.565C13.6342 4.588 13.9212 6.9085 13.9632 6.968C14.0052 7.0275 16.6232 7.12025 18.2945 4.7665C19.9657 2.41275 19.628 0.582247 19.5685 0.513997ZM25.368 21.0467C25.284 20.8787 21.2992 18.8872 21.6702 15.0582C22.0412 11.2275 24.6015 10.1775 24.6417 10.0637C24.682 9.95 23.597 8.68125 22.4472 8.039C21.6031 7.58619 20.6688 7.32676 19.712 7.2795C19.523 7.27425 18.8667 7.11325 17.5175 7.4825C16.6285 7.72575 14.6247 8.51325 14.0735 8.54475C13.5205 8.57625 11.8755 7.63125 10.1062 7.381C8.97399 7.16225 7.77349 7.61025 6.91424 7.955C6.05674 8.298 4.42574 9.2745 3.28474 11.8697C2.14374 14.4632 2.74049 18.5722 3.16749 19.8497C3.59449 21.1255 4.26124 23.2167 5.39524 24.7427C6.40324 26.4647 7.74024 27.66 8.29849 28.066C8.85674 28.472 10.4317 28.7415 11.5237 28.1832C12.4022 27.6442 13.9877 27.3345 14.6142 27.3572C15.239 27.38 16.471 27.6267 17.7327 28.3005C18.732 28.6452 19.677 28.5017 20.6237 28.1167C21.5705 27.73 22.9407 26.2635 24.5402 23.2902C25.1475 21.9077 25.424 21.1605 25.368 21.0467Z"
        fill="black"
      />
    </SvgIcon>
  );
};
export const GoogleIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="40"
      height="40"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.4398 12.2151H24.5V12.1667H14V16.8333H20.5935C19.6315 19.5499 17.0468 21.5 14 21.5C10.1343 21.5 7.00004 18.3657 7.00004 14.5C7.00004 10.6342 10.1343 7.49999 14 7.49999C15.7845 7.49999 17.4079 8.17316 18.644 9.27274L21.9439 5.97283C19.8602 4.03091 17.073 2.83333 14 2.83333C7.55712 2.83333 2.33337 8.05708 2.33337 14.5C2.33337 20.9429 7.55712 26.1667 14 26.1667C20.443 26.1667 25.6667 20.9429 25.6667 14.5C25.6667 13.7177 25.5862 12.9542 25.4398 12.2151Z"
        fill="#FFC107"
      />
      <path
        d="M3.67847 9.06975L7.51155 11.8808C8.54872 9.313 11.0606 7.5 14 7.5C15.7844 7.5 17.4078 8.17316 18.6439 9.27275L21.9438 5.97283C19.8601 4.03091 17.073 2.83333 14 2.83333C9.5188 2.83333 5.63263 5.36325 3.67847 9.06975Z"
        fill="#FF3D00"
      />
      <path
        d="M14 26.1667C17.0135 26.1667 19.7517 25.0134 21.8219 23.138L18.2111 20.0825C17.0004 21.0032 15.521 21.5012 14 21.5C10.9655 21.5 8.38894 19.5651 7.41827 16.8648L3.61377 19.7961C5.5446 23.5743 9.46577 26.1667 14 26.1667Z"
        fill="#4CAF50"
      />
      <path
        d="M25.4397 12.2151H24.5V12.1667H14V16.8333H20.5934C20.1333 18.1262 19.3045 19.256 18.2093 20.0831L18.2111 20.0819L21.8219 23.1374C21.5664 23.3696 25.6667 20.3333 25.6667 14.5C25.6667 13.7178 25.5862 12.9542 25.4397 12.2151Z"
        fill="#1976D2"
      />
    </SvgIcon>
  );
};

export const FaceBookIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="40"
      height="40"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.25 14.5738C26.25 7.80898 20.7648 2.32382 14 2.32382C7.23516 2.32382 1.75 7.80898 1.75 14.5738C1.75 20.6879 6.22891 25.7558 12.0859 26.6756V18.1159H8.97477V14.5738H12.0859V11.875C12.0859 8.80538 13.9152 7.10843 16.713 7.10843C18.0534 7.10843 19.4556 7.34796 19.4556 7.34796V10.3629H17.9102C16.3893 10.3629 15.9135 11.3068 15.9135 12.2769V14.5738H19.3107L18.7682 18.1159H15.9141V26.6767C21.7711 25.7574 26.25 20.6895 26.25 14.5738Z"
        fill="#4267B2"
      />
    </SvgIcon>
  );
};

export const FemaleIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="17"
      height="18"
      viewBox="0 0 17 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_200_732)">
        <path d="M11.3648 12.5122C11.3517 12.3325 11.343 12.1524 11.3386 11.9722C13.2465 11.7765 14.5855 11.3182 14.5855 10.7835C14.5803 10.7835 14.581 10.761 14.581 10.7527C13.1544 9.46425 15.8175 0.312745 10.8603 0.497245C10.3881 0.142223 9.80596 -0.0341643 9.2166 -0.000754541H9.22259C2.50788 0.507745 5.47714 9.1515 3.94648 10.7827H3.94348C3.94947 11.3062 5.24286 11.7562 7.09538 11.9572C7.09014 12.0847 7.07891 12.243 7.05496 12.5077C6.28626 14.5852 1.09547 14.0032 0.855957 18H17.5428C17.304 14.0025 12.135 14.5845 11.3655 12.5122H11.3648Z" />
      </g>
      <defs>
        <clipPath id="clip0_200_732">
          <rect
            width="16.3939"
            height="18"
            fill="white"
            transform="translate(0.537109)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
export const MaleIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_200_725)">
        <path d="M11.5635 12.4718C11.5172 11.8416 11.5042 11.2095 11.5246 10.578L11.5238 10.6058C12.087 10.0207 12.4383 9.26336 12.5215 8.45475L12.523 8.439C12.7768 8.418 13.1772 8.1705 13.294 7.1895C13.3292 7.02055 13.3159 6.84505 13.2557 6.68336C13.1954 6.52167 13.0906 6.38042 12.9534 6.276L12.9512 6.2745C13.3643 5.0295 14.2221 1.1775 11.3636 0.78C11.0695 0.2625 10.3165 0 9.33747 0C5.42061 0.072 4.94832 2.96325 5.80459 6.2745C5.66633 6.37988 5.56088 6.52253 5.50056 6.68576C5.44025 6.84899 5.42758 7.02606 5.46403 7.19625L5.46328 7.19025C5.58304 8.16975 5.98048 8.41875 6.23422 8.43975C6.32323 9.25737 6.68569 10.0208 7.26265 10.6058C7.28162 11.2483 7.26713 11.8914 7.21924 12.5325L7.22298 12.4718C6.44605 14.5597 1.21559 13.9733 0.976074 18H17.7857C17.5447 13.9733 12.3382 14.5597 11.5627 12.4718H11.5635Z" />
      </g>
      <defs>
        <clipPath id="clip0_200_725">
          <rect
            width="16.3939"
            height="18"
            fill="white"
            transform="translate(0.922852)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
export const DoubleCheckIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="256"
      height="256"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M208 40H48C43.5817 40 40 43.5817 40 48V208C40 212.418 43.5817 216 48 216H208C212.418 216 216 212.418 216 208V48C216 43.5817 212.418 40 208 40Z"
      />
      <path d="M162.741 74.9718L109.541 125.672L85.7407 102.972C84.9895 102.198 84.0896 101.584 83.0951 101.167C82.1006 100.749 81.032 100.537 79.9535 100.544C78.8751 100.55 77.809 100.774 76.8194 101.203C75.8298 101.632 74.9371 102.256 74.195 103.039C73.4529 103.821 72.8767 104.746 72.501 105.757C72.1253 106.768 71.958 107.844 72.0089 108.922C72.0599 109.999 72.3282 111.055 72.7976 112.026C73.2671 112.997 73.928 113.863 74.7407 114.572L104.041 142.572C105.519 143.991 107.491 144.78 109.541 144.772C111.621 144.785 113.626 143.997 115.141 142.572L173.741 86.5718C175.178 85.0905 175.988 83.1107 176 81.0465C176.012 78.9822 175.225 76.9932 173.805 75.4953C172.385 73.9974 170.44 73.1065 168.378 73.0089C166.316 72.9114 164.296 73.6147 162.741 74.9718V74.9718Z" />
      <path d="M170.088 110.805L113.308 163.079L87.9057 139.674C87.104 138.876 86.1436 138.243 85.0822 137.813C84.0207 137.383 82.8802 137.164 81.7291 137.171C80.578 137.177 79.4402 137.408 78.384 137.85C77.3278 138.292 75.583 139.743 75.583 139.743C75.583 139.743 74.1759 141.503 73.775 142.546C73.374 143.588 73.1954 144.698 73.2498 145.809C73.3042 146.919 73.5905 148.008 74.0915 149.009C74.5926 150.01 75.298 150.903 76.1654 151.634L107.437 180.503C109.016 181.966 111.12 182.78 113.308 182.772C115.528 182.785 117.668 181.973 119.285 180.503L181.829 122.765C183.363 121.238 184.227 119.196 184.24 117.068C184.253 114.94 183.414 112.889 181.898 111.345C180.381 109.8 178.306 108.882 176.105 108.781C173.905 108.68 171.749 109.406 170.088 110.805Z" />
      <path d="M208 32H48C43.7565 32 39.6869 33.6857 36.6863 36.6863C33.6857 39.6869 32 43.7565 32 48V208C32 212.243 33.6857 216.313 36.6863 219.314C39.6869 222.314 43.7565 224 48 224H208C212.243 224 216.313 222.314 219.314 219.314C222.314 216.313 224 212.243 224 208V48C224 43.7565 222.314 39.6869 219.314 36.6863C216.313 33.6857 212.243 32 208 32V32ZM208 208H48V48H208V208Z" />
    </SvgIcon>
  );
};
export const FileUpload = (props) => {
  return (
    <SvgIcon
      {...props}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.7918 13.5417V15.875C19.7918 18.7034 19.7918 20.1176 18.9131 20.9963C18.0345 21.875 16.6203 21.875 13.7918 21.875H11.2085C8.38007 21.875 6.96586 21.875 6.08718 20.9963C5.2085 20.1176 5.2085 18.7034 5.2085 15.875V9.125C5.2085 6.29657 5.2085 4.88236 6.08718 4.00368C6.96586 3.125 8.38007 3.125 11.2085 3.125H12.5002" />
      <path
        d="M19.25 3.125C19.25 2.84886 19.0261 2.625 18.75 2.625C18.4739 2.625 18.25 2.84886 18.25 3.125L19.25 3.125ZM18.25 9.375C18.25 9.65114 18.4739 9.875 18.75 9.875C19.0261 9.875 19.25 9.65114 19.25 9.375L18.25 9.375ZM18.25 3.125L18.25 9.375L19.25 9.375L19.25 3.125L18.25 3.125Z"
        fill="#777E89"
      />
      <path
        d="M21.875 6.75C22.1511 6.75 22.375 6.52614 22.375 6.25C22.375 5.97386 22.1511 5.75 21.875 5.75L21.875 6.75ZM15.625 5.75C15.3489 5.75 15.125 5.97386 15.125 6.25C15.125 6.52614 15.3489 6.75 15.625 6.75L15.625 5.75ZM21.875 5.75L15.625 5.75L15.625 6.75L21.875 6.75L21.875 5.75Z"
        // fill="#777E89"
      />
      <path d="M9.375 13.5416L15.625 13.5416" />
      <path d="M9.375 9.375L13.5417 9.375" />
      <path d="M9.375 17.7084L13.5417 17.7084" />
    </SvgIcon>
  );
};

export const InstagramIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="40"
      height="40"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.19 8H13.81C10.17 8 8 10.17 8 13.81V22.18C8 25.83 10.17 28 13.81 28H22.18C25.82 28 27.99 25.83 27.99 22.19V13.81C28 10.17 25.83 8 22.19 8ZM18 21.88C15.86 21.88 14.12 20.14 14.12 18C14.12 15.86 15.86 14.12 18 14.12C20.14 14.12 21.88 15.86 21.88 18C21.88 20.14 20.14 21.88 18 21.88ZM23.92 12.88C23.87 13 23.8 13.11 23.71 13.21C23.61 13.3 23.5 13.37 23.38 13.42C23.26 13.47 23.13 13.5 23 13.5C22.73 13.5 22.48 13.4 22.29 13.21C22.2 13.11 22.13 13 22.08 12.88C22.03 12.76 22 12.63 22 12.5C22 12.37 22.03 12.24 22.08 12.12C22.13 11.99 22.2 11.89 22.29 11.79C22.52 11.56 22.87 11.45 23.19 11.52C23.26 11.53 23.32 11.55 23.38 11.58C23.44 11.6 23.5 11.63 23.56 11.67C23.61 11.7 23.66 11.75 23.71 11.79C23.8 11.89 23.87 11.99 23.92 12.12C23.97 12.24 24 12.37 24 12.5C24 12.63 23.97 12.76 23.92 12.88Z"
        fill="#777E89"
      />
    </SvgIcon>
  );
};

export const Eye = (props) => {
  return (
    <SvgIcon
      {...props}
      width="40"
      height="40"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1666 4.09995C11.6266 1.67995 9.37325 0.286621 6.99992 0.286621C5.81325 0.286621 4.65992 0.633288 3.60659 1.27995C2.55325 1.93329 1.60659 2.88662 0.833252 4.09995C0.166585 5.14662 0.166585 6.84662 0.833252 7.89329C2.37325 10.32 4.62659 11.7066 6.99992 11.7066C8.18659 11.7066 9.33992 11.36 10.3933 10.7133C11.4466 10.06 12.3933 9.10662 13.1666 7.89329C13.8333 6.85329 13.8333 5.14662 13.1666 4.09995ZM6.99992 8.69329C5.50659 8.69329 4.30659 7.48662 4.30659 5.99995C4.30659 4.51329 5.50659 3.30662 6.99992 3.30662C8.49325 3.30662 9.69325 4.51329 9.69325 5.99995C9.69325 7.48662 8.49325 8.69329 6.99992 8.69329Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export const CategoryDesign = (props) => {
  return (
    <SvgIcon
      {...props}
      width="40"
      height="40"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6699 2.5H16.7699C14.5899 2.5 13.4399 3.65 13.4399 5.83V7.73C13.4399 9.91 14.5899 11.06 16.7699 11.06H18.6699C20.8499 11.06 21.9999 9.91 21.9999 7.73V5.83C21.9999 3.65 20.8499 2.5 18.6699 2.5Z"
        fill="#8dc0e9"
      />
      <path
        d="M7.24 13.9299H5.34C3.15 13.9299 2 15.0799 2 17.2599V19.1599C2 21.3499 3.15 22.4999 5.33 22.4999H7.23C9.41 22.4999 10.56 21.3499 10.56 19.1699V17.2699C10.57 15.0799 9.42 13.9299 7.24 13.9299Z"
        fill="#8dc0e9"
      />
      <path
        d="M6.29 11.08C8.6593 11.08 10.58 9.1593 10.58 6.79C10.58 4.4207 8.6593 2.5 6.29 2.5C3.9207 2.5 2 4.4207 2 6.79C2 9.1593 3.9207 11.08 6.29 11.08Z"
        fill="#8dc0e9"
      />
      <path
        d="M17.7099 22.4999C20.0792 22.4999 21.9999 20.5792 21.9999 18.2099C21.9999 15.8406 20.0792 13.9199 17.7099 13.9199C15.3406 13.9199 13.4199 15.8406 13.4199 18.2099C13.4199 20.5792 15.3406 22.4999 17.7099 22.4999Z"
        fill="#8dc0e9"
      />
    </SvgIcon>
  );
};

export const DashboardIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(2 4)">
        <path d="m18.38 4.57-1.23 1.85c1.2049 2.40314304 1.1222508 5.2507848-.22 7.58h-13.86c-1.76350331-3.059309-1.31261601-6.91298595 1.10947843-9.48257235s6.24242627-3.24722187 9.40052157-1.66742765l1.85-1.23c-3.8761922-2.48556317-8.94860517-2.00294347-12.28650726 1.16901179-3.3379021 3.17195526-4.07833512 8.21319061-1.79349274 12.21098821.35510459.6150891 1.00977788.9957131 1.72 1.0000158h13.85c.7173695.0028322 1.3813181-.3787474 1.74-1.0000158 1.8786438-3.25433 1.7743473-7.28712667-.27-10.44z" />
        <path
          d="m8.59 11.41c.37513651.3755541.8841815.5865733 1.415.5865733s1.0398635-.2110192 1.415-.5865733l5.66-8.49-8.49 5.66c-.37555409.37513651-.58657331.8841815-.58657331 1.415s.21101922 1.0398635.58657331 1.415z"
          opacity=".48"
        />
      </g>
    </SvgIcon>
  );
};
