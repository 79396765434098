import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import PublishIcon from '@mui/icons-material/Publish';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';

import { get, isEmpty, map } from 'lodash';
import CMDataGrid from '../../ui-components/data-grid/CMDataGrid';
import EmptyTableRowOverlay from '../../ui-components/data-grid/EmptyTableRowOverlay';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import translate from '../../hooks/translate';
import moment from 'moment';
import DefaultThumbnail from '../../../assets/images/default-healthcare.webp';
import BlogCard from './blog-card/BlogCard';
import {
	articleBlogArchive,
	articleBlogDelete,
	articleBlogpublish,
	getArticleBlog,
} from '../../../store/slice/dashboard/articleAndBlogSlice';
import MDDataGridToolbar from '../../ui-components/data-grid/CMDataGridToolbar';

export default function BlogListRender({ viewType, list }) {
	const dispatch = useDispatch();

	const handleArchive = (id, checked) => {
		const data = { id, payload: { status: checked } };

		dispatch(articleBlogArchive(data))
			.unwrap()
			.then((result) => {
				if (result.status) {
					dispatch(getArticleBlog({ pageNo: 0, type: 'ALL' }));
					toast.success(result.message);
				} else {
					toast.error(result.message);
				}
			})
			.catch((error) => {});
	};

	const handlePublish = async (id, checked) => {
		const data = { id, payload: { status: checked } };

		const res = await dispatch(articleBlogpublish(data))
			.unwrap()
			.then((result) => {
				if (result.status) {
					dispatch(getArticleBlog({ pageNo: 0, type: 'ALL' }));
					toast.success(result.message);
				} else {
					toast.error(result.message);
				}
			})
			.catch((error) => {});
		return res;
	};

	const handleDelete = async (id) => {
		const res = await dispatch(articleBlogDelete(id))
			.unwrap()
			.then((result) => {
				if (result.status) {
					toast.success(result.message);
					dispatch(getArticleBlog({ pageNo: 0, type: 'ALL' }));
				}
			})
			.catch((error) => {});
		return res;
	};

	const tableColumns = useMemo(
		() => [
			{
				field: 'planName',
				headerName: 'Thumbnail',
				width: 150,
				minWidth: 160,
				renderCell: (params) => {
					return (
						<Box>
							<img src={get(params, 'row.thumbnail[0].imageUrl[0].url', DefaultThumbnail)} alt='' loading='lazy' />
						</Box>
					);
				},
			},
			{
				field: 'title',
				headerName: 'Title',
				width: 130,
				minWidth: 200,
				flex: 1,
			},
			{
				field: 'category',
				headerName: 'Category',
				width: 150,
				renderCell: ({ row }) => {
					return (
						<Box display='flex' gap={1}>
							{get(row, 'categories', []).map((item) => (
								<Chip
									key={item._id}
									label={translate(get(item, 'foreignData.macroName', ''))}
									size='small'
									sx={{
										height: '18px',
										color: 'text.primary',
										fontSize: '0.55rem',
										lineHeight: 3,
										bgcolor: (theme) =>
											theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[700],
										borderRadius: '4px',
										textTransform: 'capitalize',
										fontWeight: 500,
									}}
								/>
							))}
						</Box>
					);
				},
			},
			{
				field: 'language',
				headerName: 'Language',
				width: 130,
				minWidth: 120,
				valueGetter: (value) =>
					get(value, 'row.language', [])
						.map((e) => e.title)
						.join(', '),
			},
			{
				field: 'createdAt',
				headerName: 'Date',
				width: 130,
				minWidth: 145,
				valueFormatter: (v) => moment(v).format('LL'),
			},
			{
				field: 'Actions',
				headerName: translate('common_actions'),
				headerAlign: 'center',
				width: 155,
				sortable: false,
				renderCell: ({ row }) => {
					return (
						<Box display='flex' alignItems='center' justifyContent='center'>
							<Tooltip title={translate('common_view')} arrow>
								<IconButton
									component={Link}
									to='/dashboard/article-blog/blog-details'
									state={{ ArticleData: row }}
									size='small'
								>
									<RemoveRedEyeIcon fontSize='inherit' />
								</IconButton>
							</Tooltip>
							<Tooltip title={translate('common_edit')} arrow>
								<IconButton size='small' component={Link} to='add-blog' state={{ item: row, type: 'update' }}>
									<EditIcon fontSize='inherit' />
								</IconButton>
							</Tooltip>
							<Tooltip title={translate('common_archived')} arrow>
								<IconButton
									size='small'
									color='success.dark'
									onClick={() => handleArchive(row._id, !row.isArchived)}
								>
									{row.isArchived ? (
										<BookmarkIcon color='primary' fontSize='inherit' />
									) : (
										<BookmarkBorderIcon fontSize='inherit' />
									)}
								</IconButton>
							</Tooltip>
							<Tooltip title={translate('common_publish')} arrow>
								<IconButton
									size='small'
									color={row.isPublished ? 'primary' : 'success.dark'}
									onClick={() => handlePublish(row._id, !row.isPublished)}
								>
									<PublishIcon fontSize='inherit' />
								</IconButton>
							</Tooltip>
							<Tooltip title={translate('common_delete')} arrow>
								<IconButton size='small' color='error' onClick={() => handleDelete(row._id)}>
									<DeleteIcon fontSize='inherit' />
								</IconButton>
							</Tooltip>
						</Box>
					);
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	if (viewType === 'gallery') {
		return map(list, (data, index) => (
			<Grid item xs={12} md={6} sm={6} lg={4} xl={3} key={index}>
				<BlogCard item={data} />
			</Grid>
		));
	}

	return (
		<Grid item xs={12}>
			<CMDataGrid
				getRowSpacing={() => ({ top: 2, bottom: 2 })}
				rows={!isEmpty(list) ? list : []}
				columns={tableColumns}
				getRowId={(row) => row._id}
				disableSelectionOnClick={true}
				autoHeight={true}
				getRowHeight={() => 'auto'}
				components={{
					Toolbar: MDDataGridToolbar,
					NoRowsOverlay: EmptyTableRowOverlay,
					LoadingOverlay: LinearProgress,
				}}
			/>
		</Grid>
	);
}
