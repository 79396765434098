import React from 'react';

// import { getConsultantData } from "../../../../../store/slice/auth/signup/consultantInfoSlice";
// import LicenseAndPublish from "../../../../auth/signup/consultant-info/license-and-publish/LicenseAndPublish";

const ProfileLicenseCertificate = () => {
	// const consultantData = useSelector(getConsultantData);
	// const loading = useSelector((state) => state.consultantInfo.loading);

	return (
		<>
			{/* <Paper variant="outlined">
        <CardContent>
          {loading ? (
            <LinearProgress />
          ) : (
            <LicenseAndPublish
              consultantInfo={consultantData}
              buttonLabel="Submit"
              isEdit={true}
            />
          )}
        </CardContent>
      </Paper> */}
		</>
	);
};

export default ProfileLicenseCertificate;
