import React from "react";
import { Typography, Box } from "@mui/material";
import CircularProgress, {
  circularProgressClasses
} from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

const LoaderWrapper = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 1301,
  width: "100%",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column"
});

function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 300 : 800]
        }}
        size={50}
        thickness={2}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) => theme.palette.primary.main,
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round"
          }
        }}
        size={50}
        thickness={2}
        {...props}
      />
    </Box>
  );
}
const Loader = () => (
  <LoaderWrapper>
    <Typography gutterBottom variant="h5">
      Loading Please Wait...
    </Typography>
    <FacebookCircularProgress color="primary" thickness={4.5} />
  </LoaderWrapper>
);

export default Loader;
