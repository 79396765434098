import React, { useCallback, useEffect, useState } from 'react';

import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';

import { getBookingList } from '../../../../store/slice/dashboard/bookingSlice';
import CMDataGrid from '../../../ui-components/data-grid/CMDataGrid';
import CMDataGridToolbar from '../../../ui-components/data-grid/CMDataGridToolbar';
import EmptyTableRowOverlay from '../../../ui-components/data-grid/EmptyTableRowOverlay';
import { getServicePlan, getServiceTypes } from '../../../../store/slice/dashboard/servicePlanSlice';
import BookingListHeader from '../components/BookingList/BookingListHeader';
import BookingTab from './BookingTab';
import { COLUMNS_DATA } from './TableColumnProvider';
import { TAB_TYPE } from './data';

const BookingList = () => {
	const dispatch = useDispatch();

	const isLoading = useSelector((state) => state.booking.loading);
	const bookingList = useSelector((state) => state.booking.bookingList);

	const [filterOptions, setFilterOptions] = useState({
		pageNo: 0,
		type: 'ALL',
	});
	const [pageSize, setPageSize] = useState(10);
	const [rowPerPage, setRowPerPage] = useState([10]);
	const [rowCountState, setRowCountState] = useState(0);

	const getBookingsData = useCallback(
		(data) => {
			const payload = {
				pageNo: get(data, 'pageNo', 0),
				type: get(data, 'type', TAB_TYPE.UPCOMING),
			};
			dispatch(getBookingList(payload))
				.unwrap()
				.then((result) => {
					if (get(result, 'status', false)) {
						const totalBooking = get(result, 'data.totalBookings', 0);
						const bookingPerPage = get(result, 'data.OnThisPage', 0);
						setRowCountState(totalBooking);
						setRowPerPage([bookingPerPage]);
					}
				})
				.catch((err) => {
					throw err;
				});
		},
		[dispatch]
	);

	useEffect(() => {
		dispatch(getServicePlan());
		dispatch(getServiceTypes());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handlePageChange = (newPageNo) => {
		setFilterOptions({ ...filterOptions, pageNo: newPageNo });
		getBookingsData({
			pageNo: newPageNo,
			type: filterOptions.type,
		});
	};

	return (
		<Paper elevation={0} className='h-100'>
			<BookingListHeader />
			<CardContent className='p-2 h-100'>
				<Grid container spacing={0} className='h-100 align-content-start'>
					<Grid item xs={12}>
						<BookingTab
							filterOptions={filterOptions}
							setFilterOptions={setFilterOptions}
							getBookingsData={getBookingsData}
						/>
					</Grid>
					<Grid item xs={12}>
						<Box>
							<CMDataGrid
								rows={bookingList ?? []}
								rowCount={rowCountState}
								columns={COLUMNS_DATA}
								getRowId={(row) => row._id}
								pagination
								page={filterOptions.pageNo}
								pageSize={pageSize}
								paginationMode='server'
								rowsPerPageOptions={rowPerPage}
								onPageChange={handlePageChange}
								onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
								disableSelectionOnClick={true}
								loading={isLoading}
								components={{
									Toolbar: CMDataGridToolbar,
									NoRowsOverlay: EmptyTableRowOverlay,
									LoadingOverlay: LinearProgress,
								}}
								autoHeight
								sx={{ border: 'none' }}
							/>
						</Box>
					</Grid>
				</Grid>
			</CardContent>
		</Paper>
	);
};

export default BookingList;
