import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import {
	CancelAllBookings,
	GetBookingDetail,
	GetBookingList,
	GetQAndAData,
	UpdateBookingStatus,
} from '../../../api/dashboard/BookingAPI';

const initialState = {
	bookingList: [],
	bookingDetail: {},
	QAndAData: [],
	loading: false,
};

export const getBookingList = createAsyncThunk('booking/getBookingList', async (data) => {
	try {
		const response = await GetBookingList(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const getBookingDetail = createAsyncThunk('booking/getBookingDetail', async (data) => {
	try {
		const response = await GetBookingDetail(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const getQAndAData = createAsyncThunk('booking/getQAndAData', async (data) => {
	try {
		const response = await GetQAndAData(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const updateBookingStatus = createAsyncThunk('booking/updateBookingStatus', async (data) => {
	try {
		const response = await UpdateBookingStatus(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const cancelAllBookings = createAsyncThunk('booking/cancelAllBookings', async (data) => {
	try {
		const response = await CancelAllBookings(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const bookingSlice = createSlice({
	name: 'booking',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getBookingList.pending, (state) => {
				state.loading = true;
			})
			.addCase(getBookingList.fulfilled, (state, { payload }) => {
				state.bookingList = get(payload, 'data.list', []);
				state.loading = false;
			})
			.addCase(getBookingList.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getBookingDetail.pending, (state) => {
				state.loading = true;
			})
			.addCase(getBookingDetail.fulfilled, (state, { payload }) => {
				state.bookingDetail = get(payload, 'data', []);
				state.loading = false;
			})
			.addCase(getBookingDetail.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getQAndAData.pending, (state) => {
				state.loading = true;
			})
			.addCase(getQAndAData.fulfilled, (state, { payload }) => {
				state.QAndAData = get(payload, 'data', []);
				state.loading = false;
			})
			.addCase(getQAndAData.rejected, (state) => {
				state.loading = false;
			})
			.addCase(updateBookingStatus.pending, (state) => {
				state.loading = true;
			})
			.addCase(updateBookingStatus.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(updateBookingStatus.rejected, (state) => {
				state.loading = false;
			})
			.addCase(cancelAllBookings.pending, (state) => {
				state.loading = true;
			})
			.addCase(cancelAllBookings.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(cancelAllBookings.rejected, (state) => {
				state.loading = false;
			});
	},
});

export default bookingSlice.reducer;
