import styles from './style.module.css';
import classNames from 'classnames';

const TextMessageUI = ({ message, messageClass, time }) => {
	return (
		<div className={classNames(styles.messageContainer, styles[messageClass])}>
			<div>
				<span>{message}</span>
				<span className={styles.timeSpan}></span>
			</div>
			<div className={classNames(styles.timeWrapper, styles.time)}>
				<div className={styles.timeContainer}>
					<span style={{ verticalAlign: 'top' }}>{time}</span>
				</div>
			</div>
		</div>
	);
};

export default TextMessageUI;
