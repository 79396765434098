import React from 'react';
import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';

import Page from '../../components/common/Page';
import PageHeader from '../../components/common/PageHeader';

const Booking = () => {
	return (
		<Page title='Booking | InspireMind' display='flex'>
			<Box component='section' className='w-100' display='flex' flexDirection='column' flex={1}>
				<PageHeader title='consultant_booking' variant='outlined' elevation={0} />
				<Box mt={2} flex={1}>
					<Outlet />
				</Box>
			</Box>
		</Page>
	);
};

export default Booking;
