import React from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";

import { get, map } from "lodash";
import translate from "../../../hooks/translate";

const ArticleFilter = (props) => {
  const { setFilterOptions, filterOptions, getArticleData } = props;

  const [order, setOrder] = React.useState("");

  const option = [
    { title: "consultant_ascending_by_title", sort: "titleASC" },
    { title: "consultant_descending_by_title", sort: "titleDESC" },
    {
      title: "consultant_ascending_by_created_date",
      sort: "createdASC",
    },
    {
      title: "consultant_descending_by_created_date",
      sort: "createdDESC",
    },
  ];

  const handleChange = (event) => {
    setOrder(event.target.value);
    let obj = option.find((o) => o.title === event.target.value);
    setFilterOptions({
      ...filterOptions,
      pageNo: 0,
      sortBy: get(obj, "sort", ""),
    });
    getArticleData({
      ...filterOptions,
      pageNo: 0,
      sortBy: get(obj, "sort", ""),
    });
  };

  return (
    <>
      <Box sx={{ width: { xs: "100%", sm: "auto" } }}>
        <FormControl
          sx={{
            minWidth: { sm: 250, xs: "100%" },
            marginLeft: { sm: "auto", xs: "none" },
          }}
          size="small"
        >
          <InputLabel id="demo-select-small">
            {translate("consultant_sort_by")}
          </InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={order}
            label="Order By"
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>{translate("common_none")}</em>
            </MenuItem>
            {map(option, (item, index) => (
              <MenuItem key={index} value={item.title}>
                {translate(item?.title)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default ArticleFilter;
