import { GREY, createGradient } from './common';

export const PRIMARY_LIGHT = {
	lighter: '#EFF6FF',
	light: '#93c5fd', // 0.5
	main: '#3b82f6',
	dark: '#1D4ED8',
	darker: '#0F172A',
	contrastText: '#fff',
};
export const SECONDARY_LIGHT = {
	lighter: '#EFD6FF',
	light: '#C684FF',
	main: '#8E33FF',
	dark: '#5119B7',
	darker: '#27097A',
	contrastText: '#FFFFFF',
};
export const INFO_LIGHT = {
	lighter: '#DEEAFF',
	light: '#9EBBFF',
	main: '#5E86FF',
	dark: '#2F4AB7',
	darker: '#12217A',
	contrastText: '#fff',
};
export const SUCCESS_LIGHT = {
	lighter: '#ECFDF5',
	light: '#6EE7B7',
	main: '#10B981',
	dark: '#047857',
	darker: '#064E3B',
	contrastText: GREY[800],
};
export const WARNING_LIGHT = {
	lighter: '#FFFBEB',
	light: '#FCD34D',
	main: '#F59E0B',
	dark: '#B45309',
	darker: '#78350F',
	contrastText: GREY[800],
};
export const ERROR_LIGHT = {
	lighter: '#FEF2F2',
	light: '#d37b82',
	main: '#EF4444',
	dark: '#B91C1C',
	darker: '#7F1D1D',
	contrastText: '#fff',
};
export const GRADIENTS_LIGHT = {
	primary: createGradient(PRIMARY_LIGHT.light, PRIMARY_LIGHT.main),
	info: createGradient(INFO_LIGHT.light, INFO_LIGHT.main),
	success: createGradient(SUCCESS_LIGHT.light, SUCCESS_LIGHT.main),
	warning: createGradient(WARNING_LIGHT.light, WARNING_LIGHT.main),
	error: createGradient(ERROR_LIGHT.light, ERROR_LIGHT.main),
};
