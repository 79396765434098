import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FieldArray, Formik, getIn } from 'formik';
import { capitalize, get, isEmpty, map } from 'lodash';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import RemoveIcon from '@mui/icons-material/Remove';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import translate from '../../hooks/translate';
import CMLoadingButton from '../../ui-components/buttons/CMLoadingButton';
import QuestionDetailAccordion from './components/QuestionDetailAccordion';
import SelectQuestion from './components/SelectQuestion';
import DefaultSegmentSection from './default-sections/DefaultSegmentSection';

import { getSpecialization } from '../../../store/slice/common';
import {
	createQuestionTemplate,
	getQuestionsList,
	updateQuestionTemplate,
} from '../../../store/slice/dashboard/QAndATemplateSlice';
import { getTranslateLanguages } from '../../../store/slice/language/AppLanguageConfigSlice';
import { getInitSpecialization, getOnlySpecializationSubmitData } from '../../common/MultiSelectSpecialization';
import { Checkbox, FormControlLabel } from '@mui/material';

const ValidationSchema = Yup.object().shape({
	templateName: Yup.string().required(translate('consultant_error_template_name_required')),
	description: Yup.string().required(translate('consultant_error_description_required')),
	language: Yup.object().nullable().required(translate('consultant_error_languages_required')),
	categories: Yup.array().min(1, 'Min 1 Category').max(3, 'Max 3 Category').nullable(),
	type: Yup.string().required(translate('consultant_error_consultation_type_required')),
	sections: Yup.array().of(
		Yup.object().shape({
			sectionName: Yup.string().required(translate('consultant_error_section_name_required')),
			questions: Yup.array().of(Yup.object().required(translate('consultant_error_questions_required'))),
		})
	),
});

const QAndATemplateForm = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const formType = location.state.formType;
	const rowData = location.state.rowData;

	const dispatch = useDispatch();

	const isLoading = useSelector((state) => state.QAndATemplate.loading);
	const translateLanguages = useSelector((state) => state.appLanguageConfig.translateLanguages);

	const specializationList = useSelector((state) => state.common.specializationList);

	const [selectLanguageAlert, setSelectLanguageAlert] = useState(false);
	const [questionDialogName, setQuestionDialogName] = useState('');
	const handleSelectQuestionOpen = (fieldName) => {
		setQuestionDialogName(fieldName);
	};
	const handleSelectQuestionClose = () => {
		setQuestionDialogName(false);
	};

	const consultantTypeData = [
		{
			label: 'Pre',
			value: 'pre',
		},
		{
			label: 'Post',
			value: 'post',
		},
	];

	const handleSubmit = (values) => {
		// console.log('values :>> ', values);
		let payloadData = {
			...values,
			language: get(values, 'language._id', ''),
			categories: getOnlySpecializationSubmitData(values, 'categories'),
			sections: map(values.sections, (sec) => {
				return {
					sectionName: sec.sectionName,
					questions: map(sec.questions, (que) => {
						return { questionId: que.questionId._id };
					}),
				};
			}),
		};

		console.log('payloadData :>> ', payloadData);

		if (formType === 'create') {
			dispatch(createQuestionTemplate(payloadData))
				.unwrap()
				.then((result) => {
					if (get(result, 'status', false)) {
						toast.success(get(result, 'message', 'Template created successfully.'));
						navigate('/dashboard/q-and-a-template/list');
					} else {
						toast.error(get(result, 'message', 'Something went wrong.'));
					}
				})
				.catch((err) => {
					throw err;
				});
		} else if (formType === 'update') {
			payloadData = { id: get(rowData, '_id', ''), data: payloadData };

			dispatch(updateQuestionTemplate(payloadData))
				.unwrap()
				.then((result) => {
					if (get(result, 'status', false)) {
						toast.success(get(result, 'message', 'Template updated successfully.'));
						navigate('/dashboard/q-and-a-template/list');
					} else {
						toast.error(get(result, 'message', 'Something went wrong.'));
					}
				})
				.catch((err) => {
					throw err;
				});
		}
	};

	useEffect(() => {
		dispatch(getQuestionsList());
		dispatch(getTranslateLanguages());
		dispatch(getSpecialization({ sectionName: 'SPECIALIZATION' }));
	}, [dispatch]);

	return (
		<Paper variant='outlined' className='h-100'>
			<CardContent className='h-100'>
				<Grid container spacing={3} justifyContent='center'>
					<Grid item xs={12}>
						<Box sx={{ mb: 1 }}>
							<Typography variant='h5'>
								{translate('consultant_question_template')}{' '}
								{translate(formType === 'create' ? 'common_create' : 'common_update')}
							</Typography>
						</Box>
						<Divider light />
					</Grid>
					<Grid item xs={12}>
						{specializationList?.length > 0 && (
							<Formik
								initialValues={{
									templateName: get(rowData, 'templateName', ''),
									description: get(rowData, 'description', ''),
									language: get(rowData, 'language', null),
									categories: getInitSpecialization(rowData, specializationList, 'categories'),

									type: get(rowData, 'type', 'pre'),
									sections: get(rowData, 'sections', []),
									additionalComments: get(rowData, 'additionalComments', false),
								}}
								validationSchema={ValidationSchema}
								onSubmit={(values) => {
									handleSubmit(values);
								}}
							>
								{(props) => (
									<form onSubmit={props.handleSubmit}>
										<Grid container spacing={3}>
											<Grid item xs={12}>
												<TextField
													label={translate('consultant_template_name')}
													name='templateName'
													value={props.values.templateName}
													onChange={props.handleChange}
													onBlur={props.handleBlur}
													error={props.errors.templateName && props.touched.templateName ? true : false}
													helperText={
														props.errors.templateName && props.touched.templateName
															? props.errors.templateName
															: null
													}
													fullWidth={true}
													multiline={true}
													size='small'
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													label={translate('consultant_description')}
													name='description'
													value={props.values.description}
													onChange={props.handleChange}
													onBlur={props.handleBlur}
													error={props.errors.description && props.touched.description ? true : false}
													helperText={
														props.errors.description && props.touched.description
															? props.errors.description
															: null
													}
													fullWidth={true}
													multiline={true}
													size='small'
													minRows={3}
												/>
											</Grid>
											<Grid item md={6} xs={12}>
												<Autocomplete
													options={translateLanguages || []}
													name='language'
													value={props.values.language}
													getOptionLabel={(option) => option?.title ?? ''}
													isOptionEqualToValue={(option, value) => {
														if (option?._id === value?._id) {
															return true;
														}
													}}
													onChange={(_e, value) => {
														props.setFieldValue('language', value);
													}}
													onBlur={props.handleBlur}
													autoHighlight={true}
													size='small'
													noOptionsText={translate('common_no_data')}
													renderInput={(params) => (
														<TextField
															{...params}
															label={translate('consultant_select_language')}
															name='language'
															error={props.errors.language && props.touched.language ? true : false}
															helperText={
																props.errors.language && props.touched.language
																	? props.errors.language
																	: null
															}
														/>
													)}
												/>
											</Grid>
											<Grid item md={6} xs={12}>
												<Autocomplete
													options={specializationList || []}
													size='small'
													getOptionLabel={(option) => translate(get(option, 'macroName', '')) ?? ''}
													isOptionEqualToValue={(option, value) => {
														if (get(option, '_id', '') === get(value, '_id', '')) {
															return true;
														}
													}}
													id='specialization'
													name={'categories'}
													value={props.values.categories}
													onChange={(_event, value) => {
														props.setFieldValue('categories', value);
													}}
													fullWidth={true}
													autoHighlight={true}
													multiple={true}
													renderInput={(params) => (
														<TextField
															{...params}
															label={capitalize('specialization')}
															error={
																props.errors.categories && props.touched.categories ? true : false
															}
															helperText={
																props.errors.categories && props.touched.categories
																	? props.errors.categories
																	: null
															}
															onBlur={props.handleBlur}
															inputProps={{
																...params.inputProps,
																autoComplete: 'categories',
															}}
															sx={{ '& .MuiFormHelperText-root': { mx: 1 } }}
														/>
													)}
												/>
											</Grid>
											<Grid item md={6} xs={12}>
												<TextField
													label={translate('consultant_consultation_type')}
													name='type'
													value={props.values.type}
													onChange={props.handleChange}
													onBlur={props.handleBlur}
													error={props.errors.type && props.touched.type ? true : false}
													helperText={
														props.errors.type && props.touched.type ? props.errors.type : null
													}
													fullWidth={true}
													size='small'
													select={true}
												>
													{map(consultantTypeData, (option, index) => (
														<MenuItem key={index} value={option.value}>
															{option.label}
														</MenuItem>
													))}
												</TextField>
											</Grid>
											{props.values.type === 'pre' ? (
												<Grid item xs={12}>
													<Box>
														<DefaultSegmentSection />
													</Box>
												</Grid>
											) : null}
											<Grid item xs={12}>
												<FieldArray name='sections'>
													{({ remove, push }) => (
														<Box>
															{map(props.values.sections, (customSection, customSectionsIndex) => {
																const sectionName = `sections[${customSectionsIndex}].sectionName`;
																const errorSectionName = getIn(props.errors, sectionName);
																const touchedSectionName = getIn(props.touched, sectionName);
																const questionsField = `sections[${customSectionsIndex}].questions`;
																const questionsFieldError = getIn(props.errors, questionsField);
																const questionsFieldTouched = getIn(
																	props.touched,
																	questionsField
																);

																return (
																	<Paper
																		key={customSectionsIndex}
																		variant='outlined'
																		className={
																			customSectionsIndex ===
																			props.values.sections.length - 1
																				? 'mb-0'
																				: 'mb-4'
																		}
																		sx={{ borderColor: '#c0c0c0' }}
																	>
																		<Stack
																			direction='row'
																			justifyContent='space-between'
																			spacing={2}
																			className='px-3 py-2 border-bottom'
																		>
																			<Typography variant='body1' fontWeight={500}>
																				{customSection.sectionName}
																			</Typography>
																			<Button
																				variant='outlined'
																				type='button'
																				color='error'
																				onClick={() => remove(customSectionsIndex)}
																				className='pt-0 pb-0 px-0'
																			>
																				<RemoveIcon />
																			</Button>
																		</Stack>
																		<CardContent>
																			<Grid container spacing={2}>
																				<Grid item xs={12} container spacing={2}>
																					<Grid item lg={10} md={9} sm={8} xs={12}>
																						<TextField
																							label={translate(
																								'consultant_section_name'
																							)}
																							name={sectionName}
																							value={customSection.sectionName}
																							onChange={props.handleChange}
																							onBlur={props.handleBlur}
																							error={
																								errorSectionName &&
																								touchedSectionName
																									? true
																									: false
																							}
																							helperText={
																								errorSectionName &&
																								touchedSectionName
																									? errorSectionName
																									: null
																							}
																							fullWidth={true}
																							size='small'
																						/>
																					</Grid>
																					<Grid item lg={2} md={3} sm={4} xs={12}>
																						<Button
																							variant='contained'
																							padding='4px 16px'
																							fullWidth={true}
																							onClick={() => {
																								if (
																									isEmpty(props.values.language)
																								) {
																									setSelectLanguageAlert(true);
																								} else {
																									handleSelectQuestionOpen(
																										questionsField
																									);
																								}
																							}}
																							className='text-nowrap'
																						>
																							{translate(
																								'consultant_select_question'
																							)}
																						</Button>
																					</Grid>
																					{selectLanguageAlert &&
																					isEmpty(props.values.language) ? (
																						<Grid item xs={12}>
																							<Alert
																								severity='error'
																								onClose={() => {
																									setSelectLanguageAlert(false);
																								}}
																							>
																								{translate(
																									'consultant_error_first_choose_language'
																								)}
																								.
																							</Alert>
																						</Grid>
																					) : null}
																					<SelectQuestion
																						name={questionsField}
																						value={customSection.questions}
																						setFieldValue={props.setFieldValue}
																						open={Boolean(
																							questionDialogName === questionsField
																						)}
																						onClose={handleSelectQuestionClose}
																						id={`select-question-dialog-${customSectionsIndex}`}
																						// languageId={
																						//   props.values.language?._id
																						// }
																					/>
																				</Grid>
																				<Grid item xs={12}>
																					<Box>
																						{map(
																							customSection.questions,
																							(
																								customSectionQuestion,
																								customSectionQuestionIndex
																							) => (
																								<Box
																									key={`custom-section-${customSectionQuestionIndex}`}
																								>
																									<QuestionDetailAccordion
																										sectionType='custom'
																										customSectionsIndex={
																											customSectionsIndex
																										}
																										questionIndex={
																											customSectionQuestionIndex
																										}
																										questionValue={
																											customSectionQuestion.questionId
																										}
																										setFieldValue={
																											props.setFieldValue
																										}
																										fieldName={questionsField}
																										fieldValue={
																											customSection.questions
																										}
																									/>
																								</Box>
																							)
																						)}
																						{Boolean(
																							questionsFieldError &&
																								questionsFieldTouched
																						) ? (
																							<FormHelperText error={true}>
																								{questionsFieldError}
																							</FormHelperText>
																						) : null}
																					</Box>
																				</Grid>
																			</Grid>
																		</CardContent>
																	</Paper>
																);
															})}
															<Box sx={{ mt: 1 }}>
																<Button
																	onClick={() => push({ sectionName: '', questions: [] })}
																	variant='contained'
																	padding='4px 24px'
																>
																	{translate('consultant_add_section')}
																</Button>
															</Box>
														</Box>
													)}
												</FieldArray>
											</Grid>
											<Grid item xs={12}>
												<FormControlLabel
													control={
														<Checkbox
															name='additionalComments'
															checked={props.values.additionalComments}
															value={props.values.additionalComments}
															onChange={(e) => {
																props.setFieldValue('additionalComments', e.target.checked);
															}}
															inputProps={{ 'aria-label': 'Status' }}
														/>
													}
													label='Additional Comments'
													sx={{ mr: 0 }}
												/>
											</Grid>
											<Grid item xs={12}>
												<Box mt={2} className='d-flex gap-3'>
													<Button
														type='reset'
														variant='outlined'
														padding='6px 24px'
														fullWidth={true}
														onClick={props.resetForm}
													>
														{translate('common_reset')}
													</Button>
													<CMLoadingButton
														type='submit'
														variant='contained'
														padding='6px 24px'
														fullWidth={true}
														loading={props.isSubmitting && isLoading}
													>
														{translate('common_btn_submit')}
													</CMLoadingButton>
												</Box>
											</Grid>
										</Grid>
									</form>
								)}
							</Formik>
						)}
					</Grid>
				</Grid>
			</CardContent>
		</Paper>
	);
};

export default QAndATemplateForm;
