import axios from 'axios';
import { isEmpty } from 'lodash';
import cookie from '../components/hooks/cookie';
import { get } from 'lodash';

const AxiosDefaultSetting = async ({ method, data, url, contentType, ...props }) => {
	const APIENDPOINT = process.env.REACT_APP_API_END_POINT;
	// console.log('API endPoint ====>', APIENDPOINT)
	const AxiosDefault = axios.create({
		baseURL: APIENDPOINT,
		// timeout: 6000,
		headers: {
			'Content-Type': isEmpty(contentType) ? 'application/json' : contentType,
			Accept: 'application/json',
			'ngrok-skip-browser-warning': '69420',
		},
	});

	AxiosDefault.interceptors.request.use(
		async function (config) {
			try {
				const userData = cookie.get('consultant_data');
				config.headers.authorization = get(userData, 'token', null);
				// const token = get(userData, "token","");
				// if (isString(token) && !isEmpty(token)) {
				//   config.headers.authorization = token;
				// }
			} catch (error) {
				console.log(error);
			}
			return config;
		},
		function (error) {
			return Promise.reject(error);
		}
	);

	AxiosDefault.interceptors.response.use(
		function (response) {
			return response;
		},
		async function (error) {
			if (error.response.status === 401) {
				try {
					const expiryTime = new Date(new Date().getTime() - 100000);
					cookie.remove('consultant_data');
					cookie.remove('isSignupCompleted');
					cookie.set(
						'consultant_data',
						{},
						{
							expires: expiryTime,
						}
					);
					cookie.set('isSignupCompleted', false, {
						expires: expiryTime,
					});
					window.location.replace('/');
					window.location = '/';
				} catch (e) {
					return e;
				}
			}
			return Promise.reject(error);
		}
	);

	return await AxiosDefault({
		method,
		data,
		url,
		contentType,
		...props,
	});
};
export default AxiosDefaultSetting;
