import React, { useEffect } from 'react';

import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';

import { useDispatch, useSelector } from 'react-redux';

import { getConsultantData } from '../../../../../store/slice/auth/signup/consultantInfoSlice';
import { getCountryList } from '../../../../../store/slice/common';
import ResidentialAddress from '../../../../auth/signup/consultant-info/residential-address/ResidentialAddress';

const ProfileBasicInfo = () => {
	const dispatch = useDispatch();
	const consultantData = useSelector(getConsultantData);

	const loading = useSelector((state) => state.common.countryLoading);
	const infoLoading = useSelector((state) => state.consultantInfo.infoLoading);

	useEffect(() => {
		dispatch(getCountryList());
	}, [dispatch]);

	return (
		<>
			<Card>
				<CardContent>
					{loading || infoLoading ? (
						<LinearProgress />
					) : (
						<ResidentialAddress consultantInfo={consultantData} buttonLabel='Submit' isEdit={true} />
					)}
				</CardContent>
			</Card>
		</>
	);
};

export default ProfileBasicInfo;
