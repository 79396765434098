import React from 'react';

import CardHeader from '@mui/material/CardHeader';
import { styled } from '@mui/material/styles';

const CustomCardHeader = styled(CardHeader)(({ theme, other }) => ({
	borderBottomWidth: other?.border ? other?.border : '1px',
	borderBottomStyle: 'solid',
	borderBottomColor: '#e9ebec',
	padding: '12px 24px',
	backgroundColor:
		other?.bgcolor || other?.bgColorVariant
			? theme.palette[other?.bgcolor][other?.bgColorVariant]
			: theme.palette.background.paper,
	'.MuiCardHeader-title': {
		fontSize: other.titleSize ?? '1rem',
		fontWeight: other.titleWeight ?? 700,
		color:
			other?.titleColor && other?.titleColorVariant
				? theme.palette[other?.titleColor][other?.titleColorVariant]
				: theme.palette.text.primary,
		[theme.breakpoints.down('sm')]: {
			fontSize: other.titleSize ?? '0.875rem',
		},
	},
}));

const CMCardHeader = (props) => {
	const { ...rest } = props;
	return (
		<>
			<CustomCardHeader other={props} {...rest} />
		</>
	);
};

export default CMCardHeader;
