import { useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Avatar, Box, Button, Divider, IconButton, MenuItem, MenuList, Stack, Switch, Typography } from '@mui/material';
// components
import Iconify from '../../components/common/Iconify';
import MenuPopover from '../../components/common/MenuPopover';
//

import { get, upperCase } from 'lodash';
import { Logout } from '../../store/slice/auth/authentication/authSlice';
import { useLocalThemeContext, THEME_NAMES } from '../../theme/config';

// ----------------------------------------------------------------------

const ThemeToggleButton = () => {
	const { themeMode, updateTheme } = useLocalThemeContext();

	const toggleTheme = (_, checked) => {
		updateTheme(checked ? THEME_NAMES.DARK_MODE : THEME_NAMES.LIGHT_MODE);
	};

	return (
		<Box p={1}>
			<MenuList>
				<MenuItem>
					<Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
						<Box display='flex' alignItems='center' gap={0.5}>
							Dark Mode
							<Typography
								bgcolor='warning.light'
								color='warning.contrastText'
								py={0.25}
								px={0.5}
								borderRadius={1}
								variant='caption'
							>
								beta
							</Typography>
						</Box>
						<Switch size='small' checked={themeMode === THEME_NAMES.DARK_MODE} onChange={toggleTheme} />
					</Stack>
				</MenuItem>
			</MenuList>
		</Box>
	);
};

const AccountPopover = ({ menuOptions }) => {
	const anchorRef = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const meInfo = useSelector((state) => state.auth.meInfo);
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleLogout = () => {
		dispatch(Logout());
		navigate('/');
	};

	return (
		<>
			<IconButton
				ref={anchorRef}
				onClick={handleOpen}
				sx={{
					padding: 0,
					width: 44,
					height: 44,
				}}
			>
				<Avatar alt={upperCase(get(meInfo, 'name', 'U'))} src={get(meInfo, 'profilePictureURL', 'NA')} />
			</IconButton>

			<MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220, p: 0, mt: 0 }}>
				<Box sx={{ p: 1.5 }}>
					<Typography variant='subtitle2' textTransform='capitalize' noWrap>
						{get(meInfo, 'firstName', '')} {get(meInfo, 'lastName', '')}
					</Typography>
					<Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
						{get(meInfo, 'email', '')}
					</Typography>
				</Box>

				<Divider sx={{ borderBottomStyle: 'dashed' }} />

				<Box p={1}>
					<MenuList>
						{menuOptions.map((option) => (
							<MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
								<Iconify icon={option.icon} sx={{ mr: 1, width: 20, height: 20 }} />

								{option.label}
							</MenuItem>
						))}
					</MenuList>
				</Box>

				<Divider sx={{ borderBottomStyle: 'dashed' }} />

				<ThemeToggleButton />

				<Divider sx={{ borderBottomStyle: 'dashed' }} />

				<Box sx={{ p: 1.5 }}>
					<Button fullWidth color='error' variant='outlined' onClick={handleLogout}>
						Logout
					</Button>
				</Box>
			</MenuPopover>
		</>
	);
};
export default AccountPopover;
