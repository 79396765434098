import React from 'react';

import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

import moment from 'moment';
import ComponentStyle from '../../../styles/ComponentStyle.module.css';
import translate from '../../hooks/translate';
import CMCardHeader from '../../ui-components/cards/CMCardHeader';

let startDate = moment('2022-09-17');
let endDate = moment('2022-09-25');
let newValue = [];

for (var m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
	newValue.push(m.format('YYYY/MM/DD'));
}

const isWeekend = (date) => {
	const currentDate = moment(date).format('YYYY/MM/DD');
	const result = newValue.includes(currentDate);
	return result;
};

const Calendar = (props) => {
	const { setAvailableDate, formikProps, planId } = props;
	return (
		<>
			<Paper mb={'1px'} variant='outlined' className={`${ComponentStyle['custom-picker']} max-w-fit`}>
				<CMCardHeader title={translate('front_set_booking_date')}></CMCardHeader>
				<Divider light />
				<StaticDatePicker
					openTo='day'
					shouldDisableDate={isWeekend}
					value={formikProps.values.consultationDate}
					onChange={(newValue) => {
						planId && formikProps.setFieldValue('consultationDate', moment(newValue?._d).format('L'));
						setAvailableDate(newValue?._d);
					}}
					onBlur={formikProps.handleBlur}
					disablePast
					maxDate={moment().add(60, 'days')}
					toolbarTitle={translate('front_set_booking_date')}
					displayStaticWrapperAs='desktop'
					sx={{
						'.PrivatePickersSlideTransition-root': {
							minHeight: 'auto',
						},
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							error={formikProps.errors.consultationDate && formikProps.touched.consultationDate ? true : false}
							helperText={
								formikProps.errors.consultationDate && formikProps.touched.consultationDate
									? formikProps.errors.consultationDate
									: null
							}
						/>
					)}
				/>
			</Paper>
		</>
	);
};

export default Calendar;
