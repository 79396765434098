import moment from 'moment';
import { uniqueId } from 'lodash';

class Store {
	constructor() {
		this._counter = 0;
	}

	removeJob;
	removeAllJob;

	_add;
	_counter;

	_incrementCounter = () => (this._counter += 1);

	_createJob(newJob) {
		return {
			...newJob,
			id: uniqueId(),
			createdAt: moment().format('MMM D, YYYY [at] hh:mm A'),
			createdTime: new Date().getTime(),
		};
	}

	addJob(newJob) {
		this._incrementCounter();
		return this._add(this._createJob(newJob));
	}

	getCounter = () => this._counter;

	register(parameters) {
		const { addJob, removeAllJob, removeJob } = parameters;

		this._add = addJob;
		this.removeJob = removeJob;
		this.removeAllJob = removeAllJob;
	}
}

const store = new Store();
export default store;
