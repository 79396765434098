import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ProfileSectionChildStatus,
  ProfileSectionStatus,
  ProfileStatusUpdate,
} from "../../../api/dashboard/inspiremindProfile";

const initialState = {
  loading: false,
};

export const profileStatusUpdate = createAsyncThunk(
  "inspiremindProfile/profileStatusUpdate",
  async (data) => {
    try {
      const response = await ProfileStatusUpdate(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const profileSectionStatus = createAsyncThunk(
  "inspiremindProfile/profileSectionStatus",
  async (data) => {
    try {
      const response = await ProfileSectionStatus(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const profileSectionChildStatus = createAsyncThunk(
  "inspiremindProfile/profileSectionChildStatus",
  async (data) => {
    try {
      const response = await ProfileSectionChildStatus(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const inspiremindProfileSlice = createSlice({
  name: "inspiremindProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(profileStatusUpdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(profileStatusUpdate.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(profileStatusUpdate.rejected, (state) => {
        state.loading = false;
      })
      .addCase(profileSectionStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(profileSectionStatus.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(profileSectionStatus.rejected, (state) => {
        state.loading = false;
      })
      .addCase(profileSectionChildStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(profileSectionChildStatus.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(profileSectionChildStatus.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default inspiremindProfileSlice.reducer;
