import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CustomTypography from '../ui-components/typography/CMTypography';

const DetailWrapper = ({ fullWidth, children, sx, ...rest }) => {
	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: {
					sm: fullWidth ? '240px calc(100% - 240px)' : '240px 240px',
				},
				...sx,
			}}
			{...rest}
		>
			{children}
		</Box>
	);
};

const DetailTitle = (props) => {
	const { title, colon, children, highlight, fontWeight, ...rest } = props;

	return (
		<Stack direction='row' justifyContent={{ sm: 'space-between', xs: 'flex-start' }} spacing={2} className='me-3'>
			{highlight ? (
				<CustomTypography variant='body1' component='div' fontWeight={fontWeight ?? 500} {...props}>
					{title || children}
				</CustomTypography>
			) : (
				<Typography variant='body1' component='div' fontWeight={fontWeight ?? 500} color='grey.700' {...rest}>
					{title || children}
				</Typography>
			)}
			{colon ? (
				<Typography variant='body1' fontWeight={700}>
					:
				</Typography>
			) : null}
		</Stack>
	);
};

const DetailValue = (props) => {
	const { value, children, highlight, fontWeight, ...rest } = props;

	return (
		<>
			{highlight ? (
				<CustomTypography variant='body1' component='div' fontWeight={fontWeight ?? 500} {...props}>
					{value || children}
				</CustomTypography>
			) : (
				<Typography variant='body1' component='div' fontWeight={fontWeight ?? 500} color='grey.900' {...rest}>
					{value || children}
				</Typography>
			)}
		</>
	);
};

export default DetailWrapper;
export { DetailTitle, DetailValue };
