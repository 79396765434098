import { get } from 'lodash';
import moment from 'moment';

export const findEndTime = (existingTimeslots, st) => {
	let newArray = [];
	for (let index = 0; index < existingTimeslots.length; index++) {
		const element = existingTimeslots[index];
		const startTime = get(element, 'startTime', false);
		const st_moment = moment(st, 'LT');
		const startTime_moment = moment(startTime, 'LT');
		if (startTime && startTime_moment.isAfter(st_moment)) {
			newArray.push(element);
		}
	}
	const minDate = new Date(
		Math.min(
			...newArray.map((e) => {
				return new Date(moment(get(e, 'startTime', false), 'LT').format());
			})
		)
	);
	if (minDate instanceof Date && !isNaN(minDate)) {
		return minDate;
	}
	return false;
};

export const selectedSlotsArray = (existingTimeslots, interval, subIndex) => {
	let slotArray = [];
	for (let index = 0; index < existingTimeslots.length; index++) {
		const element = existingTimeslots[index];
		if (get(element, 'startTime', false) && subIndex !== index) {
			const st = moment(element.startTime, 'LT');
			const et = moment(element.endTime, 'LT');
			const totalMinutes = moment(et).diff(st, 'minutes') + 1;
			const lengthOfSlots = totalMinutes / interval;
			let minutes = 0;
			slotArray.push(st.format('LT'));
			for (let i = 0; i < lengthOfSlots - 1; i++) {
				minutes = minutes + interval;
				const slotValue = moment(element.startTime, 'LT').add(minutes, 'minutes');
				const id = slotValue.format('LT');
				slotArray.push(id);
			}
		}
	}
	return slotArray;
};

export const generateSlots = (interval, timeType, existingTimeslots, subIndex) => {
	let selectedSlots = [];
	if (timeType === 'startTime') {
		selectedSlots = selectedSlotsArray(existingTimeslots, interval, subIndex);
	}
	const st = existingTimeslots[subIndex].startTime;
	const dateToday = timeType === 'startTime' ? moment().startOf('day') : moment(st, 'LT').add(15, 'minutes');
	const slots = [];
	const et = findEndTime(existingTimeslots, st);
	const minutesInDay =
		timeType === 'endTime' && et
			? moment(et).diff(dateToday, 'minutes') + 1
			: moment().endOf('day').diff(dateToday, 'minutes') + 1;
	const lengthOfSlots = minutesInDay / interval;

	let minutes = 0;
	const found = selectedSlots.find((element) => {
		return element === dateToday.format('LT');
	});
	if (!found) {
		slots.push({
			id: dateToday.format('LT'),
		});
	}

	for (let i = 0; i < lengthOfSlots - 1; i++) {
		const dateToday = timeType === 'startTime' ? moment().startOf('day') : moment(st, 'LT').add(15, 'minutes');
		minutes = minutes + interval;
		const slotValue = dateToday.add(minutes, 'minutes');
		const id = slotValue.format('LT');
		const found = selectedSlots.find((element) => {
			return element === slotValue.format('LT');
		});
		if (!found) {
			slots.push({
				id,
			});
		}
	}

	if (slots[0] && slots[0].id === 'Invalid date') {
		return [];
	}
	return slots;
};

export const validateStartTime = (existingTimeslots, value, isStartDate, subIndex) => {
	let validate = true;
	for (let index = 0; index < existingTimeslots.length; index++) {
		const st = get(existingTimeslots[index], 'startTime.id', false);
		const et = get(existingTimeslots[index], 'endTime.id', false);
		if (!isStartDate && st === undefined) {
			validate = false;
			return validate;
		}
		if (st && et) {
			const startDate = moment(st, 'LT').format();
			const endDate = moment(et, 'LT').format();
			const selectedTime = moment(value.id, 'LT').format();
			const isBetween = moment(selectedTime).isBetween(startDate, endDate);
			if (isBetween) {
				validate = !isBetween;
				return validate;
			}
		}
	}
	return validate;
};

export const DAYS_LIST = [
	{
		name: 'consultant_weekday_sunday',
		dayPosition: 0,
		time: [{ startTime: '', endTime: '' }],
		checked: false,
	},
	{
		name: 'consultant_weekday_monday',
		dayPosition: 1,
		time: [{ startTime: '', endTime: '' }],
		checked: false,
	},
	{
		name: 'consultant_weekday_tuesday',
		dayPosition: 2,
		time: [{ startTime: '', endTime: '' }],
		checked: false,
	},
	{
		name: 'consultant_weekday_wednesday',
		dayPosition: 3,
		time: [{ startTime: '', endTime: '' }],
		checked: false,
	},
	{
		name: 'consultant_weekday_thursday',
		dayPosition: 4,
		time: [{ startTime: '', endTime: '' }],
		checked: false,
	},
	{
		name: 'consultant_weekday_friday',
		dayPosition: 5,
		time: [{ startTime: '', endTime: '' }],
		checked: false,
	},
	{
		name: 'consultant_weekday_saturday',
		dayPosition: 6,
		time: [{ startTime: '', endTime: '' }],
		checked: false,
	},
];

export function getDefaultTimeZone(availabilityData, consultantData) {
	const timeZone = get(availabilityData, 'timezone', false);
	if (timeZone) return timeZone;

	return get(consultantData, 'timeZoneProfile', '');
}
