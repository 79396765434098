import React, { useRef, useState } from 'react';

import Button from '@mui/material/Button';

import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { getConsultantInfo } from '../../../../../store/slice/auth/signup/consultantInfoSlice';
import { profileImageUpload } from '../../../../../store/slice/common';
import translate from '../../../../hooks/translate';
import urlToFile from '../../../../hooks/urlToFile';
import AvatarPicker from '../../../../ui-components/avatar-picker';
import CMDialog from '../../../../ui-components/dialog/CMDialog';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';

const AddProfileImage = (props) => {
	const dispatch = useDispatch();
	const consultantInfo = useSelector((state) => state.consultantInfo.data);
	const { open, setOpen, handleClose, setIsRefresh } = props;
	const [editor, setEditor] = useState(null);
	const [loading, setLoading] = React.useState(false);

	let avatarPicker = useRef();
	avatarPicker = (editor) => {
		setEditor(editor);
	};

	const getCanvas = () => {
		try {
			const canvas = editor.getImage().toDataURL();
			return canvas;
		} catch (error) {
			toast.error('Please select an image.');
			return null;
		}
	};

	const handleImageSave = async () => {
		if (loading) {
			return;
		}

		const canvas = getCanvas();
		if (!canvas) {
			return;
		}

		setLoading(true);
		urlToFile(canvas, get(editor, 'props.image.name', ''), get(editor, 'props.image.type', '')).then(function (file) {
			dispatch(profileImageUpload({ file: file }))
				.unwrap()
				.then(async (result) => {
					setIsRefresh(false);
					if (get(result, 'status', false)) {
						toast.success(result?.message);
						// setLoading(false);
						await dispatch(getConsultantInfo())
							.unwrap()
							.then((response) => {
								if (get(response, 'status', false)) {
									setIsRefresh(true);
									setOpen(false);
									setLoading(false);
								}
							})
							.catch((error) => {
								console.log('error', error);
								setLoading(false);
								// toast.error("Uploaded file is too large")
							});
					}
				})
				.catch((error) => {
					setLoading(false);
					toast.error('Uploaded file is too large');
				});
		});
	};
	return (
		<>
			<CMDialog
				dialogTitle={translate('common_add_profile_image')}
				dividers={true}
				dialogOpen={open}
				maxWidth='md'
				dialogClose={handleClose}
				dialogContent={<AvatarPicker ref={avatarPicker} selectedImage={get(consultantInfo, 'profilePictureURL', 'NA')} />}
				dialogAction={
					<Button variant='contained' disabled={loading} onClick={handleImageSave}>
						{!loading ? (
							translate('common_upload')
						) : (
							<>
								&nbsp;
								<CircularProgress
									size={24}
									sx={{
										// color: green[500],
										position: 'absolute',
										top: '50%',
										left: '50%',
										marginTop: '-12px',
										marginLeft: '-12px',
									}}
								/>
							</>
						)}
					</Button>
				}
			></CMDialog>
		</>
	);
};

export default AddProfileImage;
