import { get, map } from "lodash";
import React from "react";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import QuestionDetailAccordion from "./QuestionDetailAccordion";
import { Drawer } from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const TemplateDetailView = (props) => {
  const { open, onClose, dialogData } = props;
  const { title, rowData } = dialogData;

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      className="university-details-design"
    >
      <div className="drawer-title">
        <div className="back-icon-common">
          <Button onClick={onClose}>
            <ArrowBackIosIcon />
          </Button>
          <h2>{title}</h2>
        </div>
      </div>
      <DialogContent dividers={true}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { sm: "1fr 3fr", xs: "1fr" },
                }}
              >
                <Stack
                  direction="row"
                  justifyContent={{ sm: "space-between", xs: "flex-start" }}
                  spacing={2}
                  className="me-3"
                >
                  <Typography variant="body1" fontWeight={500}>
                    Template Name
                  </Typography>
                  <Typography variant="body1" fontWeight={500}>
                    :
                  </Typography>
                </Stack>
                <Typography variant="body1" fontWeight={500} color="grey.800">
                  {get(rowData, "templateName", "")}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { sm: "1fr 3fr", xs: "1fr" },
                }}
              >
                <Stack
                  direction="row"
                  justifyContent={{ sm: "space-between", xs: "flex-start" }}
                  spacing={2}
                  className="me-3"
                >
                  <Typography variant="body1" fontWeight={500}>
                    Description
                  </Typography>
                  <Typography variant="body1" fontWeight={500}>
                    :
                  </Typography>
                </Stack>
                <Typography variant="body1" fontWeight={500} color="grey.800">
                  {get(rowData, "description", "")}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { sm: "1fr 3fr", xs: "1fr" },
                }}
              >
                <Stack
                  direction="row"
                  justifyContent={{ sm: "space-between", xs: "flex-start" }}
                  spacing={2}
                  className="me-3"
                >
                  <Typography variant="body1" fontWeight={500}>
                    Goal Type
                  </Typography>
                  <Typography variant="body1" fontWeight={500}>
                    :
                  </Typography>
                </Stack>
                <Typography variant="body1" fontWeight={500} color="grey.800">
                  {get(rowData, "goalType.title", "")}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { sm: "1fr 3fr", xs: "1fr" },
                }}
              >
                <Stack
                  direction="row"
                  justifyContent={{ sm: "space-between", xs: "flex-start" }}
                  spacing={2}
                  className="me-3"
                >
                  <Typography variant="body1" fontWeight={500}>
                    Consultant Type
                  </Typography>
                  <Typography variant="body1" fontWeight={500}>
                    :
                  </Typography>
                </Stack>
                <Typography
                  variant="body1"
                  fontWeight={500}
                  color="grey.800"
                  className="text-capitalize"
                >
                  {get(rowData, "type", "")}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { sm: "1fr 3fr", xs: "1fr" },
                }}
              >
                <Stack
                  direction="row"
                  justifyContent={{ sm: "space-between", xs: "flex-start" }}
                  spacing={2}
                  className="me-3"
                >
                  <Typography variant="body1" fontWeight={500}>
                    Sections
                  </Typography>
                  <Typography variant="body1" fontWeight={500}>
                    :
                  </Typography>
                </Stack>
                <Box>
                  {map(
                    get(rowData, "sections", []),
                    (section, sectionIndex) => {
                      return (
                        <Paper
                          key={sectionIndex}
                          variant="outlined"
                          className={
                            sectionIndex ===
                            get(rowData, "sections", []).length - 1
                              ? "mb-0"
                              : "mb-3"
                          }
                          sx={{ borderColor: "#c0c0c0" }}
                        >
                          <Box className="px-3 py-2 border-bottom">
                            <Box
                              direction={{ sm: "row", xs: "column" }}
                              alignItems={{ sm: "center" }}
                              spacing={1}
                            >
                              <Typography variant="body1" fontWeight={500}>
                                {section.sectionName}
                              </Typography>
                            </Box>
                          </Box>
                          <CardContent>
                            <Box className="mb-2">
                              <Typography
                                variant="body1"
                                className="fw-medium"
                                sx={{ color: "grey.800" }}
                              >
                                Questions :
                              </Typography>
                            </Box>
                            {map(
                              section.questions,
                              (question, questionIndex) => (
                                <Box key={`default-section-${questionIndex}`}>
                                  <QuestionDetailAccordion
                                    sectionType="default"
                                    sectionIndex={sectionIndex}
                                    questionIndex={questionIndex}
                                    questionValue={question.questionId}
                                  />
                                </Box>
                              )
                            )}
                          </CardContent>
                        </Paper>
                      );
                    }
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Drawer>
  );
};

export default TemplateDetailView;
