import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Formik } from 'formik';
import { filter, get, isEmpty, map } from 'lodash';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import CMDialog from '../../../ui-components/dialog/CMDialog';
import translate from '../../../hooks/translate';

const DetailCardLabel = ({ label, sx, className, ...rest }) => {
	return (
		<Stack
			direction='row'
			justifyContent={{
				sm: 'space-between',
				xs: 'flex-start',
			}}
			spacing={2}
			className={`me-3 ${className}`}
			sx={{
				width: { md: '25%', sm: '30%', xs: 'auto' },
				...sx,
			}}
			{...rest}
		>
			<Typography variant='body1' fontWeight={500}>
				{label}
			</Typography>
			<Typography variant='body1' fontWeight={500}>
				:
			</Typography>
		</Stack>
	);
};

const SelectQuestion = (props) => {
	const { open, onClose, name, value, setFieldValue } = props;
	const getSelectQuestions = value;

	const questionsList = useSelector((state) => state.QAndATemplate.questionsList);
	// const filterQuestionsList = filter(questionsList, (item) => {
	//   return item?.language?._id === languageId;
	// });

	const ValidationSchema = Yup.object().shape({
		questionId: Yup.object()
			.shape({})
			.required('Please select question.')
			.test('duplicateCheck', 'You already select this question. Please select another question.', (value) => {
				const questionExist = filter(getSelectQuestions, (item) => {
					return get(item, 'questionId._id', '') === get(value, '_id', '');
				})[0];
				return isEmpty(questionExist) ? true : false;
			})
			.nullable(),
	});

	return (
		<CMDialog
			dialogTitle='Select Questions'
			dialogOpen={open}
			dialogClose={onClose}
			maxWidth='md'
			fullWidth={true}
			scroll='paper'
			aria-labelledby='select-template-question'
			aria-describedby='select-template-sections-question'
		>
			<Formik
				initialValues={{
					questionId: null,
				}}
				validationSchema={ValidationSchema}
				onSubmit={(values) => {
					setFieldValue(name, value.concat(values));
					onClose();
				}}
			>
				{(props) => (
					<form onSubmit={props.handleSubmit}>
						<DialogContent dividers={true} className='d-flex flex-column overflow-x-hidden'>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Box className='mb-2'>
										<Autocomplete
											fullWidth={true}
											autoHighlight={true}
											disableClearable={true}
											size='small'
											id='questionId'
											options={
												// !isEmpty(filterQuestionsList) ? filterQuestionsList : []
												!isEmpty(questionsList) ? questionsList : []
											}
											getOptionLabel={(option) => option.questionTitle ?? ''}
											isOptionEqualToValue={(option, value) => option.questionTitle === value.questionTitle}
											name='questionId'
											value={props.values.questionId}
											onChange={(_event, value) => {
												props.setFieldValue('questionId', value);
											}}
											onBlur={props.handleBlur}
											renderInput={(params) => (
												<TextField
													{...params}
													label='Questions'
													error={props.errors.questionId && props.touched.questionId ? true : false}
													helperText={
														props.errors.questionId && props.touched.questionId
															? props.errors.questionId
															: null
													}
													inputProps={{
														...params.inputProps,
														autoComplete: 'new-password',
													}}
												/>
											)}
										/>
									</Box>
								</Grid>
								{!isEmpty(props.values.questionId) ? (
									<Grid item xs={12}>
										<Box className='px-2'>
											<Stack direction='row' flexWrap='wrap' spacing={1}>
												<DetailCardLabel label={translate('common_qa_input_type')} />
												<Typography variant='body1' className='text-capitalize fw-regular' color='black'>
													{get(props.values.questionId, 'inputType', '')}
												</Typography>
											</Stack>
										</Box>
									</Grid>
								) : null}
								{get(props.values.questionId, 'inputType', '') === 'scale' ? (
									<Grid item xs={12}>
										<Box className='px-2'>
											<Stack direction='column' spacing={1}>
												<Box>
													<Stack direction='row' flexWrap='wrap' spacing={1}>
														<DetailCardLabel label='Minimum Value' />
														<Typography
															variant='body1'
															className='text-capitalize fw-regular'
															color='black'
														>
															{get(props.values.questionId, 'minValue', '')}
														</Typography>
													</Stack>
												</Box>
												<Box>
													<Stack direction='row' spacing={1}>
														<DetailCardLabel label='Maximum Value' />
														<Typography
															variant='body1'
															className='text-capitalize fw-regular'
															color='black'
														>
															{get(props.values.questionId, 'maxValue', '')}
														</Typography>
													</Stack>
												</Box>
												<Box>
													<Stack direction='row' spacing={1}>
														<DetailCardLabel label='Step Value' />
														<Typography
															variant='body1'
															className='text-capitalize fw-regular'
															color='black'
														>
															{get(props.values.questionId, 'stepValue', '')}
														</Typography>
													</Stack>
												</Box>
											</Stack>
										</Box>
									</Grid>
								) : null}
								{get(props.values.questionId, 'inputType', '') === 'radio' ||
								get(props.values.questionId, 'inputType', '') === 'checkbox' ? (
									<Grid item xs={12}>
										<Box className='px-2'>
											<Stack direction='row' flexWrap='wrap' spacing={1}>
												<DetailCardLabel label={translate('common_qa_options')} />
												<Box>
													<Table
														sx={{ minWidth: 280 }}
														size='small'
														stickyHeader={true}
														aria-label='Role Assign Table'
													>
														<TableHead>
															<TableRow
																sx={{
																	'& .MuiTableCell-root': { padding: 1 },
																}}
															>
																<TableCell>Label</TableCell>
																<TableCell>Value</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{map(get(props.values.questionId, 'options', []), (item, index) => (
																<TableRow
																	key={index}
																	sx={{
																		'&:last-child td, &:last-child th': {
																			border: 0,
																		},
																		'& .MuiTableCell-root': {
																			py: 0.5,
																			px: 1,
																		},
																	}}
																>
																	<TableCell component='th' scope='row'>
																		{item.label}
																	</TableCell>
																	<TableCell component='th' scope='row'>
																		{item.value}
																	</TableCell>
																</TableRow>
															))}
														</TableBody>
													</Table>
												</Box>
											</Stack>
										</Box>
									</Grid>
								) : null}
								{map(get(props.values.questionId, 'options', []), (option) => {
									if (get(option, 'subQuestionStatus', false)) {
										return map(get(option, 'subQuestions', []), (subQuestion, subQuestionIndex) => {
											return (
												<Stack direction='column' spacing={1.5} key={subQuestionIndex} className='w-100'>
													<Box className='px-2 mt-2'>
														<Stack direction='row' flexWrap='wrap' spacing={1}>
															<Typography
																variant='body1'
																className='text-capitalize fw-medium'
																color='grey.800'
															>
																Option {get(option, 'label', '')} Sub Question
															</Typography>
														</Stack>
													</Box>
													{!isEmpty(subQuestion) ? (
														<Box className='px-2'>
															<Stack direction='row' flexWrap='wrap' spacing={1}>
																<DetailCardLabel label='common_qa_question_title' />
																<Typography
																	variant='body1'
																	className='text-capitalize fw-regular'
																	color='black'
																>
																	{get(subQuestion, 'questionTitle', '')}
																</Typography>
															</Stack>
														</Box>
													) : null}
													{!isEmpty(subQuestion) ? (
														<Box className='px-2'>
															<Stack direction='row' flexWrap='wrap' spacing={1}>
																<DetailCardLabel label={translate('common_qa_input_type')} />
																<Typography
																	variant='body1'
																	className='text-capitalize fw-regular'
																	color='black'
																>
																	{get(subQuestion, 'inputType', '')}
																</Typography>
															</Stack>
														</Box>
													) : null}
													{get(subQuestion, 'inputType', '') === 'scale' ? (
														<Box className='px-2'>
															<Stack direction='column' spacing={1}>
																<Box>
																	<Stack direction='row' flexWrap='wrap' spacing={1}>
																		<DetailCardLabel label='Minimum Value' />
																		<Typography
																			variant='body1'
																			className='text-capitalize fw-regular'
																			color='black'
																		>
																			{get(subQuestion, 'minValue', '')}
																		</Typography>
																	</Stack>
																</Box>
																<Box>
																	<Stack direction='row' flexWrap='wrap' spacing={1}>
																		<DetailCardLabel label='Maximum Value' />
																		<Typography
																			variant='body1'
																			className='text-capitalize fw-regular'
																			color='black'
																		>
																			{get(subQuestion, 'maxValue', '')}
																		</Typography>
																	</Stack>
																</Box>
																<Box>
																	<Stack direction='row' flexWrap='wrap' spacing={1}>
																		<DetailCardLabel label='Step Value' />
																		<Typography
																			variant='body1'
																			className='text-capitalize fw-regular'
																			color='black'
																		>
																			{get(subQuestion, 'stepValue', '')}
																		</Typography>
																	</Stack>
																</Box>
															</Stack>
														</Box>
													) : null}
													{get(subQuestion, 'inputType', '') === 'radio' ||
													get(subQuestion, 'inputType', '') === 'checkbox' ? (
														<Box className='px-2'>
															<Stack direction='row' flexWrap='wrap' spacing={1}>
																<DetailCardLabel label={translate('common_qa_options')} />
																<Box>
																	<Table
																		sx={{
																			minWidth: 240,
																		}}
																		size='small'
																		stickyHeader={true}
																		aria-label={`question-option-table-${subQuestionIndex}`}
																	>
																		<TableHead>
																			<TableRow
																				sx={{
																					'& .MuiTableCell-root': {
																						padding: 1,
																					},
																				}}
																			>
																				<TableCell>Label</TableCell>
																				<TableCell>Value</TableCell>
																			</TableRow>
																		</TableHead>
																		<TableBody>
																			{map(
																				get(subQuestion, 'options', []),
																				(subQuestionOption, subQuestionOptionIndex) => (
																					<TableRow
																						key={subQuestionOptionIndex}
																						sx={{
																							'&:last-child td, &:last-child th': {
																								border: 0,
																							},
																							'& .MuiTableCell-root': {
																								py: 0.5,
																								px: 1,
																							},
																						}}
																					>
																						<TableCell component='th' scope='row'>
																							{subQuestionOption.label}
																						</TableCell>
																						<TableCell component='th' scope='row'>
																							{subQuestionOption.value}
																						</TableCell>
																					</TableRow>
																				)
																			)}
																		</TableBody>
																	</Table>
																</Box>
															</Stack>
														</Box>
													) : null}
												</Stack>
											);
										});
									}
								})}
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button type='submit' variant='contained' onClick={() => {}}>
								Select
							</Button>
						</DialogActions>
					</form>
				)}
			</Formik>
		</CMDialog>
	);
};

export default SelectQuestion;
