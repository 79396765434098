import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { get, isEmpty, map, size, startCase, upperCase } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddUniversityModal from '../../../components/auth/signup/consultant-info/professional-info/AddUniversityModal';
import AddSocialMediaLinksModal from '../../../components/auth/signup/consultant-info/social-media/AddSocialMediaLinksModal';
import AddLicenseModal from '../../auth/signup/consultant-info/license-and-publish/AddLicenseModal';
import { EventEmitter } from '../../../services/EventEmitter';
import { getConsultantData } from '../../../store/slice/auth/signup/consultantInfoSlice';
import Iconify from '../../common/Iconify';
// import ProgressBar from '../../ui-components/progress-bar/ProgressBar';
import translate from '../../hooks/translate';
import ProfileSkelton from '../../ui-components/skeleton/ProfileSkelton';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import DescriptionIcon from '@mui/icons-material/Description';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import ShowModalInfo from '../../auth/signup/consultant-info/ShowModalInfo';

const ProfileDetails = () => {
	const consultantData = useSelector(getConsultantData);
	const consultantInfo = consultantData.data;
	const loading = useSelector((state) => state.consultantInfo.infoLoading);
	const navigate = useNavigate();

	const handleAddData = async (eventName, data) => {
		EventEmitter.dispatch(eventName, data);
	};

	const getIconComponent = (socialMediaChanel) => {
		switch (socialMediaChanel) {
			case 'Twitter':
				return <TwitterIcon fontSize='small' />;
			case 'Facebook':
				return <FacebookIcon fontSize='small' />;
			case 'LinkedIn':
				return <LinkedInIcon fontSize='small' />;
			case 'Instagram':
				return <InstagramIcon fontSize='small' />;
			case 'YouTube':
				return <YouTubeIcon fontSize='small' />;
			default:
				return <BusinessCenterOutlinedIcon fontSize='small' />;
		}
	};

	return (
		<>
			{loading ? (
				<ProfileSkelton />
			) : isEmpty(consultantInfo) ? (
				'No Data Available'
			) : (
				<Grid item xs={12}>
					<Box className='d-flex flex-column gap-2'>
						<Card>
							<CardContent>
								<Stack
									direction={{ xs: 'column', md: 'row' }}
									justifyContent={{ xs: 'center', md: 'space-between' }}
									alignItems={{ xs: 'end', md: 'flex-start' }}
								>
									<div className='w-auto mx-auto position-relative'>
										<Avatar
											sx={{
												height: { xs: '150px', md: '100px' },
												width: { xs: '150px', md: '100px' },
												bgcolor: 'primary.main',
												margin: { xs: 'auto', md: '0' },
											}}
											alt={upperCase(get(consultantInfo, 'firstName', 'U'))}
											src={get(consultantInfo, 'profilePictureURL', 'NA')}
										/>
										<IconButton
											onClick={() => navigate('professional-info')}
											className='position-absolute right-0 bottom-0'
											sx={{
												backgroundColor: 'grey.200',
												'&:hover': { backgroundColor: 'grey.300' },
											}}
										>
											<EditIcon fontSize='small' />
										</IconButton>
									</div>

									<Box className='w-100' sx={{ marginLeft: { xs: '0px', md: '10px' } }}>
										<Stack direction='row' justifyContent='space-between'>
											{consultantInfo.firstName && (
												<Typography variant='subtitle1' gutterBottom>
													{startCase(consultantInfo.firstName) +
														' ' +
														startCase(consultantInfo.lastName)}
												</Typography>
											)}
											<IconButton size='small' onClick={() => navigate('basicinfo')}>
												<EditIcon fontSize='small' />
											</IconButton>
										</Stack>
										{!isEmpty(consultantInfo.email) ? (
											<Stack direction='row' alignItems='center' spacing={1} mb={0.5}>
												<Iconify
													icon='eva:email-fill'
													width='18px'
													height='18px'
													color='text.secondary'
												/>
												{consultantInfo?.email && (
													<Typography variant='body2' color='text.secondary'>
														{get(consultantInfo, 'email', '')}
													</Typography>
												)}
											</Stack>
										) : null}

										<Stack direction='row' alignItems='center' spacing={1} mb={0.5}>
											<Iconify icon='ic:round-phone' width='18px' height='18px' color='text.secondary' />
											<Typography variant='body2' color='text.secondary'>
												{consultantInfo.code} {consultantInfo.mobile}
											</Typography>
										</Stack>

										<Stack direction='row' alignItems='center' spacing={1}>
											<Iconify
												icon='material-symbols:location-on'
												width='18px'
												height='18px'
												color='text.secondary'
											/>
											<Typography variant='body2' color='text.secondary'>
												{consultantInfo.city && `${consultantInfo.city} |`}
												{consultantInfo.state && ` ${consultantInfo.state} |`}
												{consultantInfo.country.name && `${consultantInfo.country.name} |`}
												{consultantInfo.zipCode && `${consultantInfo.zipCode}`}
											</Typography>
										</Stack>
									</Box>
								</Stack>
							</CardContent>
						</Card>

						{/* <Paper variant="outlined">
              <CardHeader
                title={translate("consultant_suggested_for_you")}
                subheader="Intermediate"
              />
              <CardContent className="py-3">
                <ProgressBar />
              </CardContent>
            </Paper> */}

						<Card>
							<CardContent>
								<Stack direction='row' alignItems='center' justifyContent='space-between'>
									<Typography variant='h6'>{translate('consultant_about_me')}</Typography>

									<IconButton size='small' onClick={() => navigate('professional-info')}>
										<EditIcon fontSize='small' />
									</IconButton>
								</Stack>
								<Typography variant='body2' color='text.secondary'>
									{consultantInfo.bio}
								</Typography>
							</CardContent>
						</Card>

						<Card>
							<CardContent>
								<Stack direction='row' alignItems='center' justifyContent='space-between'>
									<Typography variant='h6'>{translate('common_language')}</Typography>

									<IconButton size='small' onClick={() => navigate('professional-info')}>
										<EditIcon fontSize='small' />
									</IconButton>
								</Stack>
								<Stack pt={2} direction='row' flexWrap='wrap' gap={0.5}>
									{map(get(consultantInfo, 'language', []), (item, index) => (
										<Chip color='primary' key={get(item, 'name', index)} label={get(item, 'name', '')} />
									))}
								</Stack>
							</CardContent>
						</Card>

						<Card>
							<CardContent>
								<Stack direction='row' justifyContent='space-between' alignItems='center'>
									<Typography variant='h6'>{translate('consultant_skills')}</Typography>
									<Box>
										<IconButton size='small' onClick={() => navigate('professional-domain')}>
											<EditIcon fontSize='small' />
										</IconButton>
									</Box>
								</Stack>
								<Stack pt={2} direction='row' flexWrap='wrap' gap={1}>
									{map(get(consultantInfo, 'specialization', []), (item, index) => (
										<React.Fragment key={get(item, '_id', index)}>
											<Chip color='primary' label={translate(get(item, 'foreignData.macroName', ''))} />
											{map(get(item, 'child', []), (item, subIndex) => (
												<Chip
													color='primary'
													key={get(item, '_id', subIndex)}
													label={translate(get(item, 'foreignData.macroName', ''))}
												/>
											))}
										</React.Fragment>
									))}
								</Stack>
							</CardContent>
						</Card>

						<Card>
							<CardContent>
								<Stack direction='row' justifyContent='space-between' alignItems='center'>
									<Typography variant='h6'>{translate('consultant_education')}</Typography>
									<Box>
										<IconButton size='small' onClick={() => handleAddData('addProfileUniversity')}>
											<AddIcon fontSize='small' />
										</IconButton>
										<IconButton size='small' onClick={() => navigate('professional-info')}>
											<EditIcon fontSize='small' />
										</IconButton>
										<AddUniversityModal showButton={false} />
									</Box>
								</Stack>

								<Box mt={2} mb={-2}>
									{map(get(consultantInfo, 'educationUniversity', []), (item) => (
										<ShowModalInfo
											key={item._id}
											icon={<SchoolRoundedIcon fontSize='small' />}
											title={`${item.title} `}
											subtitle={`${item.fieldOfStudy} at ${item.universityName}`}
											item={item}
											sectionName='educationUniversity'
										/>
									))}
								</Box>
							</CardContent>
						</Card>

						<Card>
							<CardContent>
								<Stack direction='row' justifyContent='space-between' alignItems='center'>
									<Typography variant='h6'>{translate('consultant_licence_or_certification')}</Typography>
									<Box>
										<IconButton size='small' onClick={() => handleAddData('addLicenseCertification')}>
											<AddIcon fontSize='small' />
										</IconButton>
										<IconButton size='small' onClick={() => navigate('professional-info')}>
											<EditIcon fontSize='small' />
										</IconButton>
										<AddLicenseModal showButton={false} />
									</Box>
								</Stack>

								<Box mt={2} mb={-2}>
									{map(get(consultantInfo, 'licenseCertification', []), (item) => (
										<ShowModalInfo
											key={item._id}
											icon={<DescriptionIcon fontSize='small' />}
											title={`${item.name} at ${item.issuingOrganization}`}
											subtitle={`${moment(item.issueDate).format('L')}`}
											link={item.credentialUrl}
											item={item}
											sectionName='licenseCertification'
										/>
									))}
								</Box>
							</CardContent>
						</Card>

						<Card>
							<CardContent>
								<Stack direction='row' justifyContent='space-between' alignItems='center'>
									<Typography variant='h6'>{translate('consultant_links')}</Typography>
									<Box>
										<IconButton size='small' onClick={() => handleAddData('addProfileSocialLinks')}>
											<AddIcon fontSize='small' />
										</IconButton>
										<IconButton size='small' onClick={() => navigate('social-media')}>
											<EditIcon fontSize='small' />
										</IconButton>
										<AddSocialMediaLinksModal />
									</Box>
								</Stack>

								{size(consultantInfo.socialMediaInfo) > 0 &&
									consultantInfo.socialMediaInfo.map((items, index) => (
										<Box key={index}>
											<Stack direction='row' alignItems='center' spacing={2} pt={2}>
												<Box component='span'>
													<Avatar
														sx={{
															backgroundColor: 'primary.lighter',
															color: 'primary.main',
														}}
													>
														{getIconComponent(items.socialMediaChanel)}
													</Avatar>
												</Box>
												<Stack direction='column'>
													<Typography variant='body2' color='text.primary' className='fw-bold'>
														{translate('common_follow_me_on') + ' ' + items.socialMediaChanel + ' - '}
													</Typography>
													<Typography variant='caption' color='primary.main' className='text-break'>
														{items.chanelLink}
													</Typography>
												</Stack>
											</Stack>
										</Box>
									))}
							</CardContent>
						</Card>
					</Box>
				</Grid>
			)}
		</>
	);
};

export default ProfileDetails;
