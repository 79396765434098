import React from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PublishIcon from '@mui/icons-material/Publish';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { Formik } from 'formik';
import { get } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DefaultThumbnail from '../../../../assets/images/default-healthcare.webp';
import translate from '../../../hooks/translate';
import { alpha } from '@mui/material';
import Text from '../../../common/Text';
import { useContentAction } from './useConentAction';

const getProgress = (processCompleted, totalProcessCount) => {
	if (!processCompleted || !totalProcessCount) return 0;
	return parseInt((processCompleted / totalProcessCount) * 100);
};
const LoadingOverlay = ({ inBackgroundJob, isPending, processCompleted, totalProcessCount }) => {
	const progress = getProgress(processCompleted, totalProcessCount);
	if (!inBackgroundJob && !isPending) return <></>;
	return (
		<Box
			position='absolute'
			top={0}
			left={0}
			right={0}
			bottom={0}
			display='flex'
			justifyContent='center'
			alignItems='center'
			sx={{ backdropFilter: 'blur(1px)', bgcolor: ({ palette }) => alpha(palette.primary.main, 0.3) }}
			zIndex={99999}
		>
			<Box
				width='100%'
				maxWidth='650px'
				bgcolor='white'
				padding='10px'
				display='flex'
				justifyContent='center'
				alignItems='center'
				flexDirection='column'
				gap={1}
			>
				<Typography variant='h5' fontWeight='bold'>
					{inBackgroundJob && 'Saving...'}
					{!inBackgroundJob && `Processing... ${progress}%`}
				</Typography>
			</Box>
		</Box>
	);
};

const ContentBlogCard = ({ item }) => {
	const navigate = useNavigate();
	const isPending = item?.status === 'pending';
	const backgroundJobIdMap = useSelector((state) => state.common.backgroundJobIdMap);
	const { handleArchive, handleDelete, handleEdit, handlePublish } = useContentAction(item);

	return (
		<Card sx={{ boxShadow: (theme) => theme.shadows.z8 }} className='h-100'>
			<LoadingOverlay
				inBackgroundJob={backgroundJobIdMap?.[item._id]}
				isPending={isPending}
				processCompleted={item?.processCompleted}
				totalProcessCount={item?.totalProcessCount}
			/>
			<Formik
				initialValues={{
					isArchived: get(item, 'isArchived', false),
					isPublished: get(item, 'isPublished', false),
				}}
			>
				{(props) => (
					<form onSubmit={props.handleSubmit} className='d-flex flex-column h-100'>
						<CardMedia sx={{ height: '250px' }}>
							<Box
								component='img'
								src={get(item, 'thumbnail[0].imageUrl[0].url', DefaultThumbnail)}
								alt='content main image'
								className='img-fluid img-cover w-100 h-100'
								onClick={() => {
									navigate('/dashboard/content/content-details', {
										state: {
											contentData: item,
										},
									});
								}}
							/>
						</CardMedia>
						<CardContent className='p-3 pb-2'>
							<Stack
								direction='row'
								justifyContent='space-between'
								alignItems='center'
								className='gap-2'
								sx={{ flexWrap: 'wrap' }}
							>
								<Box sx={{ display: 'inline-flex' }}>
									<CalendarMonthTwoToneIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
									<Typography variant='caption' ml={1} color='text.secondary'>
										{moment(item.createdAt).format('LL')}
									</Typography>
								</Box>
								<Box className='d-flex align-items-center flex-wrap'>
									<Tooltip title={translate('common_edit')} arrow>
										<Checkbox
											sx={{ '&.MuiCheckbox-root': { padding: '9px 3px' } }}
											icon={<EditIcon />}
											checkedIcon={<EditIcon />}
											onClick={() => handleEdit()}
										/>
									</Tooltip>
									<Tooltip title={translate('common_archived')} arrow>
										<Checkbox
											sx={{ '&.MuiCheckbox-root': { padding: '9px 3px' } }}
											icon={<BookmarkBorderIcon />}
											checkedIcon={<BookmarkIcon />}
											onChange={(e) => props.setFieldValue('isArchived', e.target.checked)}
											checked={props.values.isArchived}
											onClick={(e) => handleArchive(item._id, e.target.checked)}
										/>
									</Tooltip>

									<Tooltip title={translate('common_publish')} arrow>
										<Checkbox
											sx={{ '&.MuiCheckbox-root': { padding: '9px 3px' } }}
											icon={<PublishIcon />}
											checkedIcon={<PublishIcon />}
											onChange={(e) => props.setFieldValue('isPublished', e.target.checked)}
											checked={props.values.isPublished}
											onClick={(e) => handlePublish(item._id, e.target.checked)}
										/>
									</Tooltip>

									<Tooltip title={translate('common_delete')} arrow>
										<Checkbox
											sx={{ '&.MuiCheckbox-root': { padding: '9px 3px' } }}
											icon={<DeleteIcon />}
											checkedIcon={<DeleteIcon />}
											onClick={() => handleDelete(item._id)}
										/>
									</Tooltip>
								</Box>
							</Stack>

							<Text variant='subtitle1' color='text.primary' line={2}>
								{item?.title}
							</Text>

							<Box display='flex' gap={1}>
								{get(item, 'categories', []).map((item) => (
									<Chip
										key={item._id}
										label={translate(get(item, 'foreignData.macroName', ''))}
										size='small'
										sx={{
											height: '18px',
											color: 'text.primary',
											fontSize: '0.55rem',
											lineHeight: 3,
											bgcolor: (theme) =>
												theme.palette.mode === 'light'
													? theme.palette.grey[100]
													: theme.palette.grey[700],
											borderRadius: '4px',
											textTransform: 'capitalize',
											fontWeight: 500,
										}}
									/>
								))}
							</Box>
						</CardContent>
						<CardContent className='p-2 mt-auto border-top'>
							{get(item, 'categories.title', '') || get(item, 'subCategories.title', '') ? (
								<Stack direction='row' sx={{ flexWrap: 'wrap' }} className='gap-1'>
									{get(item, 'categories.title', '') ? (
										<Chip label={get(item, 'categories.title', '')} variant='body2' />
									) : null}
									{get(item, 'subCategories.title', '') ? (
										<Chip label={get(item, 'subCategories.title', '')} variant='body2' />
									) : null}
								</Stack>
							) : null}
							<Button
								onClick={() => {
									navigate('/dashboard/content/content-details', {
										state: {
											contentData: item,
										},
									});
								}}
								variant='text'
								endIcon={<ArrowForwardIcon />}
							>
								{translate('common_read_more')}
							</Button>
						</CardContent>
					</form>
				)}
			</Formik>
		</Card>
	);
};

export default ContentBlogCard;
