import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, omit } from 'lodash';

import { GetArticleBlog, ArticleBlogArchive, ArticleBlogPublish, ArticleBlogDelete } from '../../../api/dashboard/articleAndBlog';

const initialState = {
	articleBlogList: [],
	totalArticle: 0,
	loading: false,
};

export const getArticleBlog = createAsyncThunk('articleAndBlog/getArticleBlog', async (data) => {
	try {
		const response = await GetArticleBlog(omit(data, 'silentFetch'));
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const articleBlogArchive = createAsyncThunk('articleAndBlog/articleBlogArchive', async (data) => {
	try {
		const response = await ArticleBlogArchive(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const articleBlogpublish = createAsyncThunk('articleAndBlog/articleBlogpublish', async (data) => {
	try {
		const response = await ArticleBlogPublish(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const articleBlogDelete = createAsyncThunk('articleAndBlog/articleBlogDelete', async (id) => {
	try {
		const response = await ArticleBlogDelete(id);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const articleAndBlogSlice = createSlice({
	name: 'articleAndBlog',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getArticleBlog.pending, (state, action) => {
				const updateLoadingStatus = get(action, 'meta.arg.silentFetch', false);
				if (!updateLoadingStatus) {
					state.loading = true;
				}
			})
			.addCase(getArticleBlog.fulfilled, (state, { payload }) => {
				state.totalArticle = get(payload, 'data.totalArticle', 0);
				state.articleBlogList = get(payload, 'data.list', []);
				state.loading = false;
			})
			.addCase(getArticleBlog.rejected, (state) => {
				state.loading = false;
			})
			.addCase(articleBlogDelete.pending, (state) => {
				state.loading = true;
			})
			.addCase(articleBlogDelete.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(articleBlogDelete.rejected, (state) => {
				state.loading = false;
			});
	},
});

export default articleAndBlogSlice.reducer;
