import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PostOtpVerification, PostResendOtp } from "../../../../api/signup";

const initialState = {
  loading: false,
};

export const postOtpVerification = createAsyncThunk(
  "otpVerification/postOtpVerification",
  async (data) => {
    try {
      const response = await PostOtpVerification(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const postResendOtp = createAsyncThunk(
  "otpResendVerification/postResendOtp",
  async (data) => {
    try {
      const response = await PostResendOtp(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const otpVerificationSlice = createSlice({
  name: "otpVerification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postOtpVerification.pending, (state) => {
        state.loading = true;
      })
      .addCase(postOtpVerification.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postOtpVerification.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postResendOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(postResendOtp.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postResendOtp.rejected, (state) => {
        state.loading = false;
      });
  },
});
export default otpVerificationSlice.reducer;
