import React, { useEffect, useState } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Formik } from 'formik';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { EventEmitter } from '../../../../../services/EventEmitter';
import { getConsultantInfo, postAddLicenseCertificate } from '../../../../../store/slice/auth/signup/consultantInfoSlice';
import translate from '../../../../hooks/translate';
import CMTextButton from '../../../../ui-components/buttons/CMTextButton';
import { Drawer } from '@mui/material';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';

const specialCharacter = /^[aA-zZ\s]+$/;
const schemaObj = Yup.object().shape({
	name: Yup.string()
		.matches(specialCharacter, translate('common_error_special_characters_not_allowed'))
		.required(translate('consultant_error_name_required')),
	issuingOrganization: Yup.string()
		.matches(specialCharacter, translate('common_error_special_characters_not_allowed'))
		.required(translate('consultant_error_issuing_organization_required')),
	// credentialId: Yup.string().required(
	//   translate("consultant_error_credential_id_required")
	// ),
	credentialUrl: Yup.string()
		.url(translate('consultant_error_enter_valid_url'))
		.required(translate('consultant_error_credential_url_required')),
	// issueDate: Yup.date()
	//   .nullable()
	//   .required(translate("consultant_error_issue_date_required")),
});

const AddLicenseModal = (props) => {
	const { showButton } = props;
	const [open, setOpen] = useState(false);
	const [data, setData] = useState();
	const dispatch = useDispatch();

	useEffect(() => {
		EventEmitter.subscribe('licenseCertification', async (data) => {
			setData(data);
			setOpen(true);
		});
		EventEmitter.subscribe('addLicenseCertification', () => {
			setOpen(true);
		});
	}, []);

	const handleClickOpen = () => {
		setData({});
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleAddLicense = async (payload) => {
		data && (payload._id = data._id);
		const res = await dispatch(postAddLicenseCertificate(payload))
			.unwrap()
			.then((result) => {
				if (result.status) {
					dispatch(getConsultantInfo());
					handleClose();
				}
			})
			.catch((err) => {
				console.log(' handleAddLicense err', err);
			});
		return res;
	};

	return (
		<>
			{showButton && (
				<CMTextButton
					onClick={handleClickOpen}
					startIcon={<AddCircleOutlineOutlinedIcon fontSize='small' />}
					color='primary'
				>
					{translate('consultant_add_licence_or_certification')}
				</CMTextButton>
			)}
			<Drawer anchor='right' open={open} onClose={handleClose} className='university-details-design'>
				<Box>
					<Stack
						direction='row'
						gap={1}
						py={2}
						px={{ xs: 2, md: 3 }}
						alignItems='center'
						justifyContent='space-between'
					>
						<IconButton size='small' color='primary' onClick={handleClose} sx={{ minWidth: 0 }}>
							<KeyboardArrowLeftRoundedIcon />
						</IconButton>

						<Typography variant='h6'>{translate('consultant_add_licence_or_certification')}</Typography>
					</Stack>
					<Divider sx={{ borderStyle: 'dashed' }} />
				</Box>
				<Formik
					initialValues={{
						name: get(data, 'name', ''),
						issuingOrganization: get(data, 'issuingOrganization', ''),
						// credentialId: get(data, "credentialId", ""),
						credentialUrl: get(data, 'credentialUrl', ''),
						issueDate: get(data, 'issueDate', ''),
					}}
					validationSchema={schemaObj}
					onSubmit={(values, { resetForm }) => {
						const payload = {
							name: get(values, 'name', ''),
							issuingOrganization: get(values, 'issuingOrganization', ''),
							// credentialId: get(values, "credentialId", ""),
							credentialUrl: get(values, 'credentialUrl', ''),
							// credentialUrl: 'abc',
							// credentialId: 'abc',
							issueDate: get(values, 'issueDate', ''),
						};
						handleAddLicense(payload);
						resetForm();
					}}
				>
					{(props) => (
						<form onSubmit={props.handleSubmit} className='d-flex flex-column overflow-auto'>
							<DialogContent dividers sx={{ px: { xs: 2, md: 3 }, pt: 2 }}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6}>
										<TextField
											label={translate('consultant_name')}
											name='name'
											value={props.values.name}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											error={props.errors.name && props.touched.name ? true : false}
											helperText={props.errors.name && props.touched.name ? props.errors.name : null}
										/>
									</Grid>

									<Grid item xs={12} sm={6}>
										<TextField
											label={translate('consultant_issuing_organization')}
											name='issuingOrganization'
											value={props.values.issuingOrganization}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											error={
												props.errors.issuingOrganization && props.touched.issuingOrganization
													? true
													: false
											}
											helperText={
												props.errors.issuingOrganization && props.touched.issuingOrganization
													? props.errors.issuingOrganization
													: null
											}
										/>
									</Grid>

									{/* <Grid item xs={12} sm={6}>
                    <TextField
                      label={translate("consultant_credential_id")}
                      name="credentialId"
                      value={props.values.credentialId}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={
                        props.errors.credentialId && props.touched.credentialId
                          ? true
                          : false
                      }
                      helperText={
                        props.errors.credentialId && props.touched.credentialId
                          ? props.errors.credentialId
                          : null
                      }
                    />
                  </Grid>*/}

									<Grid item xs={12}>
										<TextField
											label={translate('consultant_credential_url')}
											name='credentialUrl'
											value={props.values.credentialUrl}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											error={props.errors.credentialUrl && props.touched.credentialUrl ? true : false}
											helperText={
												props.errors.credentialUrl && props.touched.credentialUrl
													? props.errors.credentialUrl
													: null
											}
										/>
									</Grid>

									<Grid item xs={12}>
										<FormControl fullWidth>
											<Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
												<MobileDatePicker
													views={['month', 'year']}
													label={translate('consultant_issue_date')}
													name='issueDate'
													value={props.values.issueDate}
													onChange={(newValue) => {
														const dateValue = newValue.format('YYYY/MM');
														props.setFieldValue('issueDate', dateValue);
													}}
													onBlur={props.handleBlur}
													inputFormat='MM/YYYY'
													toolbarTitle='Select Issue Date'
													toolbarPlaceholder=''
													closeOnSelect={true}
													disableFuture
													renderInput={(params) => (
														<TextField
															{...params}
															fullWidth
															name='issueDate'
															error={
																props.errors.issueDate && props.touched.issueDate ? true : false
															}
															helperText={
																props.errors.issueDate && props.touched.issueDate
																	? props.errors.issueDate
																	: null
															}
														/>
													)}
												/>
											</Stack>
										</FormControl>
									</Grid>
								</Grid>
							</DialogContent>

							<DialogActions style={{ marginBottom: '10px', marginRight: '20px' }}>
								<Button variant='outlined' onClick={handleClose}>
									{translate('common_cancel')}
								</Button>
								<Button variant='contained' type='submit'>
									{translate('common_btn_submit')}
								</Button>
							</DialogActions>
						</form>
					)}
				</Formik>
				{/* </CMDialog> */}
			</Drawer>
		</>
	);
};

export default AddLicenseModal;
