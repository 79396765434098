import AxiosDefault from '../../services/AxiosDefault';

export const GetBookingList = async (data) => {
	const response = await AxiosDefault({
		method: 'POST',
		url: `/consultant/consultation/booking/list`,
		data: data,
	});
	return response;
};

export const GetBookingDetail = async (id) => {
	const response = await AxiosDefault({
		method: 'GET',
		url: `/consultant/consultation/booking/details/${id}`,
	});
	return response;
};

export const GetQAndAData = async (id) => {
	const response = await AxiosDefault({
		method: 'GET',
		url: `/consultant/consultation/pre-question-answers/get/${id}`,
	});
	return response;
};

export const UpdateBookingStatus = async ({ status, id }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/consultant/consultation/booking/status-update/${id}`,
		data: { consultationStatus: status },
	});
	return response;
};

export const CancelAllBookings = async (id) => {
	const response = await AxiosDefault({
		method: 'POST',
		url: `/consultant/consultation/booking/cancel-refund/${id}`,
	});
	return response;
};
