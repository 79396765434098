import { FileUploader } from '../../../../../utils/fileUpload';

const initiateUrl = '/consultant/upload/initiate-upload';
const uploadUrl = '/consultant/upload/upload-part';
const completeUrl = '/consultant/upload/complete-upload';

const uploadVideo = async (data, id, updateJob, totalSize, completedSize) => {
	const fileUploader = new FileUploader(data, initiateUrl, uploadUrl, completeUrl, id, updateJob, totalSize, completedSize);
	await fileUploader.initiateUpload();

	const status = fileUploader.getStatus();
	return { status };
};

export default uploadVideo;
