import React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import PathBreadcrumbs from '../../layout/private/PathBreadcrumbs';
import translate from '../hooks/translate';

const PageHeader = (props) => {
	const { title, titleStyle, ...rest } = props;

	return (
		<Paper variant='outlined' {...rest}>
			<Box className='px-3 py-2'>
				<Stack
					direction={{ md: 'row', xs: 'column' }}
					alignItems={{ md: 'center', xs: 'flex-start' }}
					justifyContent='space-between'
					spacing={1}
				>
					<Box>
						<Typography variant='body1' fontWeight={600} sx={{ ...titleStyle }}>
							{translate(title)}
						</Typography>
					</Box>
					<Box>
						<PathBreadcrumbs />
					</Box>
				</Stack>
			</Box>
		</Paper>
	);
};

export default PageHeader;
