import React, { useEffect } from 'react';

import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';

import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { getConsultantData } from '../../../../../store/slice/auth/signup/consultantInfoSlice';
import { getSpecialization } from '../../../../../store/slice/common';
import ProfessionalDomain from '../../../../auth/signup/consultant-info/professional-domain/ProfessionalDomain';

const ProfileProfessionalDomain = () => {
	const dispatch = useDispatch();
	const consultantData = useSelector(getConsultantData);
	const loading = useSelector((state) => state.common.loading);

	const specializationList = useSelector((state) => state.common.specializationList);

	useEffect(() => {
		dispatch(getSpecialization({ sectionName: 'SPECIALIZATION' }));
	}, [dispatch]);

	return (
		<>
			<Card>
				<CardContent>
					{isEmpty(specializationList) || loading || consultantData?.infoLoading ? (
						<LinearProgress />
					) : (
						<ProfessionalDomain consultantInfo={consultantData} buttonLabel='Submit' isEdit={true} />
					)}
				</CardContent>
			</Card>
		</>
	);
};

export default ProfileProfessionalDomain;
