import React from 'react';
import { styled } from '@mui/material/styles';
import AccordionDetails from '@mui/material/AccordionDetails';

const MuiAccordionDetails = styled(AccordionDetails)(() => ({
	padding: '8px 16px',
	textAlign: 'start',
	borderRadius: '0 0 3px 3px',
}));

const CMAccordionDetails = ({ children, ...rest }) => {
	return <MuiAccordionDetails {...rest}>{children}</MuiAccordionDetails>;
};

export default CMAccordionDetails;
