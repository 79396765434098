import moment from 'moment';

export function getCurrentMeetingFromList(list) {
	if (!Array.isArray(list)) {
		return {};
	}

	const currentMeeting = list?.find((e) => e.consultationStatus === 'IN_PROGRESS');
	if (currentMeeting) {
		return currentMeeting;
	}
	const nextMeeting = list?.find((e) => e.consultationStatus === 'UPCOMING');
	return nextMeeting || {};
}

export function checkCurrentlyHasMeeting(startTime, endTime) {
	return moment().isBetween(moment(startTime), moment(endTime));
}
export function getMeetingMetaData(startTime, endTime) {
	const nextMeeting = moment(startTime).diff(moment());
	const hasMeeting = checkCurrentlyHasMeeting(startTime, endTime);

	return {
		hasMeeting,
		nextMeeting: nextMeeting > 0 ? nextMeeting : 0,
	};
}
