import { find, get, map } from "lodash";
import { useRef, useState } from "react";
// material
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { alpha } from "@mui/material/styles";
import TranslateIcon from "@mui/icons-material/Translate";
// components
import MenuPopover from "../../../components/ui-components/popover/MenuPopover";
import Iconify from "../../../components/common/Iconify";
// store
import useContextData from "../../../components/hooks/useContextData";

const LANGS_FLAG = [
  {
    label: "English",
    icon: "flag:gb-4x3",
  },
  {
    label: "Hindi",
    icon: "flag:in-4x3",
  },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const anchorRef = useRef(null);
  const { LanguageSelected, setLanguage, GetLanguageList } = useContextData();
  const LanguageList = GetLanguageList();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  LanguageSelected &&
    localStorage.setItem(
      "selectedHeaderLanguage",
      JSON.stringify(LanguageSelected)
    );

  const handleChangeLanguage = (value) => {
    setLanguage(value);
    localStorage.setItem("selectedHeaderLanguage", JSON.stringify(value));
    handleClose();
  };

  const getLangFlagIcon = (languageTitle) => {
    const result = find(LANGS_FLAG, (localLang) => {
      return localLang?.label === languageTitle;
    });
    return get(result, "icon", null);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 40,
          height: 40,
          overflow: "hidden",
          borderRadius: 0,
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}
      >
        <TranslateIcon />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ py: 1 }}>
          {map(LanguageList, (option, index) => (
            <MenuItem
              key={index}
              selected={option?.title === get(LanguageSelected, "title", "")}
              onClick={() => handleChangeLanguage(option)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <Iconify icon={getLangFlagIcon(option?.title)} width="100%" />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                {option?.title}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
