import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import { VscSend } from 'react-icons/vsc';
import { IconButton } from '@mui/material';
import UploadAttachment from './UploadAttachment';

export default function ChatFooter({ disabled, onMessageSend, onFileUpload }) {
	const [text, setText] = useState('');
	const isSendBtnDisable = disabled || text?.length === 0;

	const onSendBtnClick = () => {
		onMessageSend(text);
		setText('');
	};

	return (
		<Box px={2} py={1} display='flex'>
			<UploadAttachment disabled={disabled} onFileUpload={onFileUpload} />
			<Box flex={1}>
				<Input
					placeholder='Type a message'
					fullWidth
					disableUnderline
					multiline
					maxRows={5}
					sx={{ p: 1 }}
					disabled={disabled}
					value={text}
					onChange={(e) => setText(e.target.value)}
				/>
			</Box>
			<Box display='flex' alignItems='flex-end'>
				<IconButton disabled={isSendBtnDisable} onClick={onSendBtnClick}>
					<VscSend fontSize={20} />
				</IconButton>
			</Box>
		</Box>
	);
}
