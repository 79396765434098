import { blog, chat, content, event } from './workers';

export const jobTypes = {
	BLOG_CREATE: 'blogCreate',
	CONTENT_CREATE: 'contentCreate',
	EVENT_CREATE: 'eventCreate',
	CHAT_MESSAGE_CREATE: 'eventChatMessage',
};

export const getJobWorkerFn = (jobType) => {
	if (jobType === jobTypes.BLOG_CREATE) {
		return blog;
	}
	if (jobType === jobTypes.CONTENT_CREATE) {
		return content;
	}
	if (jobType === jobTypes.EVENT_CREATE) {
		return event;
	}
	if (jobType === jobTypes.CHAT_MESSAGE_CREATE) {
		return chat;
	}
	return () => {};
};
