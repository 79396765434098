import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  AddAvailability,
  AddOfflineService,
  AddPhoneService,
  OtpSendService,
  OtpVerifyService,
  AddUnavailability,
  GetAvailability,
  GetOfflineService,
  GetPhoneService,
  GetUnavailability,
  PauseAvailability,
  PostOnlineServiceAdd,
  RemoveUnavailability,
  GetCelenderEvent,
  PostAppointmentAdd,
  GetCelenderFilteredEvent,
} from "../../../api/dashboard/appointment";

const initialState = {
  offlineServiceData: {},
  phoneServiceData: {},
  unavailabilityList: [],
  availabilityList: [],
  celenderEvent: [],
  celenderFilteredEvent:[],
  loading: false,
};

export const addOfflineService = createAsyncThunk(
  "appointment/addOfflineService",
  async (data) => {
    try {
      const response = await AddOfflineService(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const getOfflineService = createAsyncThunk(
  "appointment/getOfflineService",
  async (data) => {
    try {
      const response = await GetOfflineService(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const addOnlineService = createAsyncThunk(
  "appointment/addOnlineService",
  async (data) => {
    try {
      const response = await PostOnlineServiceAdd(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const addPhoneService = createAsyncThunk(
  "appointment/addPhoneService",
  async (data) => {
    try {
      const response = await AddPhoneService(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const otpSendService = createAsyncThunk(
  "appointment/otpSendService",
  async (data) => {
    try {
      const response = await OtpSendService(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const otpVerifyService = createAsyncThunk(
  "appointment/otpVerifyService",
  async (data) => {
    try {
      const response = await OtpVerifyService(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const getPhoneService = createAsyncThunk(
  "appointment/getPhoneService",
  async (data) => {
    try {
      const response = await GetPhoneService(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const addUnavailability = createAsyncThunk(
  "appointment/addUnavailability",
  async (data) => {
    try {
      const response = await AddUnavailability(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const getUnavailability = createAsyncThunk(
  "appointment/getUnavailability",
  async (data) => {
    try {
      const response = await GetUnavailability(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const removeUnavailability = createAsyncThunk(
  "appointment/removeUnavailability",
  async (id) => {
    try {
      const response = await RemoveUnavailability(id);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const addAvailability = createAsyncThunk(
  "appointment/addAvailability",
  async (data) => {
    try {
      const response = await AddAvailability(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const getAvailability = createAsyncThunk(
  "appointment/getAvailability",
  async (data) => {
    try {
      const response = await GetAvailability(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const pauseAvailability = createAsyncThunk(
  "appointment/pauseAvailability",
  async (data) => {
    try {
      const response = await PauseAvailability(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const postAppointmentAdd = createAsyncThunk(
  "appointment/postAppointmentAdd",
  async (data) => {
    try {
      const response = await PostAppointmentAdd(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const getCelenderEvent = createAsyncThunk(
  "appointment/getCelenderEvent",
  async (data) => {
    try {
      const response = await GetCelenderEvent(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const getCelenderFilterdEvent = createAsyncThunk(
  "appointment/getCelenderFilterdEvent",
  async (data) => {
    try {
      const response = await GetCelenderFilteredEvent(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);



export const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // .addCase(otpSendService.pending, (state) => {
      //   state.loading = true;
      // })
      .addCase(otpSendService.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(otpSendService.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addOfflineService.pending, (state) => {
        state.loading = true;
      })
      .addCase(addOfflineService.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(addOfflineService.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getOfflineService.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOfflineService.fulfilled, (state, { payload }) => {
        state.offlineServiceData = get(payload, "data", {});
        state.loading = false;
      })
      .addCase(getOfflineService.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addOnlineService.pending, (state) => {
        state.loading = true;
      })
      .addCase(addOnlineService.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addOnlineService.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addPhoneService.pending, (state) => {
        state.loading = true;
      })
      .addCase(addPhoneService.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addPhoneService.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getPhoneService.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPhoneService.fulfilled, (state, { payload }) => {
        state.phoneServiceData = get(payload, "data", {});
        state.loading = false;
      })
      .addCase(getPhoneService.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addUnavailability.pending, (state) => {
        state.loading = true;
      })
      .addCase(addUnavailability.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addUnavailability.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getUnavailability.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUnavailability.fulfilled, (state, { payload }) => {
        state.unavailabilityList = get(payload, "data", []);
        state.loading = false;
      })
      .addCase(getUnavailability.rejected, (state) => {
        state.loading = false;
      })
      .addCase(removeUnavailability.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeUnavailability.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(removeUnavailability.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addAvailability.pending, (state) => {
        state.loading = true;
      })
      .addCase(addAvailability.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addAvailability.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAvailability.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAvailability.fulfilled, (state, { payload }) => {
        state.availabilityList = get(payload, "data", []);
        state.loading = false;
      })
      .addCase(getAvailability.rejected, (state) => {
        state.loading = false;
      })
      .addCase(pauseAvailability.pending, (state) => {
        state.loading = true;
      })
      .addCase(pauseAvailability.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(pauseAvailability.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCelenderEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCelenderEvent.fulfilled, (state, { payload }) => {
        state.celenderEvent = get(payload, "data", []);
        state.loading = false;
      })
      .addCase(getCelenderEvent.rejected, (state) => {
        state.loading = false;
      })   
      .addCase(getCelenderFilterdEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCelenderFilterdEvent.fulfilled, (state, { payload }) => {
        state.celenderFilteredEvent = get(payload, "data", []);
        state.loading = false;
      })
      .addCase(getCelenderFilterdEvent.rejected, (state) => {
        state.loading = false;
      })


      

      .addCase(postAppointmentAdd.pending, (state) => {
        state.loading = true;
      })
      .addCase(postAppointmentAdd.fulfilled, (state, { payload }) => {
        state.appointmentData = get(payload, "data", []);
        state.loading = false;
      })
      .addCase(postAppointmentAdd.rejected, (state) => {
        state.loading = false;
      });
  },
});



export default appointmentSlice.reducer;
