import { useEffect, useState } from 'react';
import styles from './style.module.css';
import classNames from 'classnames';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import EventSubject from '../../../../../../../services/EventSubject';
import Iconify from '../../../../../../common/Iconify';
import { styled } from '@mui/material';
import { downloadFileFromUrl } from '../../../../../../../utils/file';
import { get } from 'lodash';

const ProgressBar = ({ id }) => {
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		const subscription = EventSubject.subscribe((data) => {
			if (data.messageId !== id) return;
			setProgress(data.progress);
		});

		return () => {
			subscription.unsubscribe();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.uploadIndicatorContainer}>
			<Box color='white'>
				<CircularProgressWithLabel color='inherit' value={progress} />
			</Box>
		</div>
	);
};

const StyledButton = styled(Button)`
	border-radius: 4px;
	font-weight: 500;
	box-shadow: none;
	background: #cacaca94;
	color: black;

	&:hover {
		background: #cacaca;
		color: black;
		box-shadow: none;
	}
`;

const FileMessageUI = ({ messageClass, data }) => {
	const [isDownloading, setIsDownloading] = useState(false);

	const saveFile = async () => {
		setIsDownloading(true);
		await downloadFileFromUrl(data.url, data.fileName);
		setIsDownloading(false);
	};

	return (
		<div className={classNames(styles.messageContainer, styles[messageClass])}>
			<div style={{ position: 'relative', height: 'calc(100% - 15px)' }}>
				{data.isReport && (
					<Typography variant='h6' gutterBottom>
						{data.title}
					</Typography>
				)}
				<Box display='flex' gap={1}>
					<Box>
						<Iconify
							icon={get(data, 'fileMetaData.fileIconName', 'bi:file-pdf')}
							width={40}
							sx={{ height: 40, color: get(data, 'fileMetaData.fileIconColor', 'red') }}
						/>
					</Box>
					<Box flex={1} display='flex' flexDirection='column'>
						<Typography>{data.fileName}</Typography>
						{data.fileMetaData && (
							<Typography lineHeight={1.3} variant='caption'>
								{data.fileMetaData.size}
							</Typography>
						)}
					</Box>
				</Box>
				{data.status !== 'uploading' && data.status !== 'error' && (
					<>
						<Divider sx={{ my: 1 }} />
						<Box display='flex' gap={1}>
							<StyledButton variant='contained' size='small' fullWidth onClick={saveFile} disabled={isDownloading}>
								{isDownloading ? 'Downloading...' : 'Save'}
							</StyledButton>
						</Box>
					</>
				)}

				{data.status === 'uploading' && <ProgressBar id={data.id} />}
			</div>
			<div className={classNames(styles.timeWrapper, styles.time)}>
				<div className={styles.timeContainer}>
					<span style={{ verticalAlign: 'bottom' }}>{data.createdAt}</span>
				</div>
			</div>
		</div>
	);
};

export default FileMessageUI;
