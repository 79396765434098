import React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import ScrollToTop from './components/common/ScrollToTop';
import reportWebVitals from './reportWebVitals';
import store from './store/Store';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import { DataProvider } from './context/DataProvider';
import { StyledEngineProvider } from '@mui/material/styles';
import { JobQueueContainer } from './components/common/JobQueue';
import axiosInit from './libs/axios';

axiosInit();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<HelmetProvider>
		<CookiesProvider>
			<ReduxProvider store={store}>
				<JobQueueContainer />
				<BrowserRouter>
					<DataProvider>
						<StyledEngineProvider injectFirst>
							<ThemeConfig>
								<GlobalStyles />
								<ScrollToTop />
								<CssBaseline enableColorScheme>
									<LocalizationProvider dateAdapter={AdapterMoment}>
										<App />
									</LocalizationProvider>
								</CssBaseline>
							</ThemeConfig>
						</StyledEngineProvider>
					</DataProvider>
				</BrowserRouter>
			</ReduxProvider>
		</CookiesProvider>
	</HelmetProvider>
);

reportWebVitals();
