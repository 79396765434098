import AxiosDefault from '../../services/AxiosDefault';

export const GetArticleBlog = async (data) => {
	const response = await AxiosDefault({
		url: '/consultant/article-blog/get',
		method: 'POST',
		data: data,
	});
	return response;
};

export const ArticleBlogArchive = async ({ payload, id }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/consultant/article-blog/archive/${id}`,
		data: payload,
	});
	return response;
};

export const ArticleBlogPublish = async ({ payload, id }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/consultant/article-blog/publish/${id}`,
		data: payload,
	});
	return response;
};

export const ArticleBlogDelete = async (id) => {
	const response = await AxiosDefault({
		method: 'DELETE',
		url: `/consultant/article-blog/delete/${id}`,
	});
	return response;
};
