import React from 'react';

import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { get, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';

import { postConnectStripeAccount } from '../../../../store/slice/dashboard/bankingSlice';
import translate from '../../../hooks/translate';

const ConnectStripe = () => {
	const dispatch = useDispatch();

	const handleConnectAccount = async () => {
		await dispatch(postConnectStripeAccount())
			.unwrap()
			.then((result) => {
				if (get(result, 'status', false) && !isEmpty(get(result, 'data.URL', ''))) {
					window.open(get(result, 'data.URL', ''), '_blank', 'noopener,noreferrer');
				}
			})
			.catch((error) => {
				console.log('connect stripe error', error);
			});
	};

	return (
		<Card>
			<CardContent>
				<Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center'>
					<Typography variant='h6'>{translate('consultant_payment_method')}</Typography>
					<Button
						variant='outlined'
						color='primary'
						startIcon={<PersonAddAltIcon />}
						onClick={() => handleConnectAccount()}
					>
						{translate('consultant_connect_to_stripe')}
					</Button>
				</Stack>
			</CardContent>
		</Card>
	);
};

export default ConnectStripe;
