import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import translate from '../../../hooks/translate';

const data = [
	{ macroName: 'common_all', name: 'ALL' },
	{ macroName: 'common_publish', name: 'PUBLISH' },
	{ macroName: 'common_saved', name: 'SAVED' },
	{ macroName: 'common_archived', name: 'ARCHIVED' },
];

const ArticleTypes = (props) => {
	const { setFilterOptions, filterOptions, getArticleData } = props;

	const [add, setAdd] = useState('common_all');

	const handleType = (values) => {
		setAdd(values);
		setFilterOptions({ ...filterOptions, pageNo: 0, ...values });
		getArticleData({ ...filterOptions, pageNo: 0, ...values });
	};

	return (
		<>
			<Stack direction='row' spacing={1} justifyContent='center'>
				{data.map((items, index) => (
					<Button
						variant='outlined'
						key={index}
						sx={{
							borderRadius: '10px',
							borderColor: grey[400],
							color: grey[500],
						}}
						className={add === items.name ? 'type_active' : null}
						onClick={() => handleType({ type: items.name })}
					>
						{translate(items.macroName)}
					</Button>
				))}
			</Stack>
		</>
	);
};

export default ArticleTypes;
