import AxiosDefault from "../services/AxiosDefault";
import { isEmpty } from "lodash";

export const GetTranslateLanguages = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/public/translate/language`,
  });
  return response;
};

export const GetTranslateLanguageMacros = async (langId) => {
  const queryPath = (isEmpty(langId) ?"":`&languageId=${langId}`);
  const response = await AxiosDefault({
    method: "GET",
    url: `/public/translate/language-macros?userType=CONSULTANT${queryPath}`,
  });
  return response;
};

export const GetTranslateLanguageMacrosWOLangID = async (langId) => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/public/translate/language-macros?userType=CONSULTANT`,
  });
  return response;
};
