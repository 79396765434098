import { combineReducers } from '@reduxjs/toolkit';
import authSliceReducer from './slice/auth/authentication/authSlice';
import forgetPasswordReducer from './slice/auth/signin/forgetPasswordSlice';
import signinSliceReducer from './slice/auth/signin/signinSlice';
import consultantInfoReducer from './slice/auth/signup/consultantInfoSlice';
import faqSliceReducer from './slice/auth/signup/faqSlice';
import otpVerificationSliceReducer from './slice/auth/signup/otpVerificationSlice';
import signupSliceReducer from './slice/auth/signup/signupSlice';
import commonReducer from './slice/common';
import appointmentSliceReducer from './slice/dashboard/appointmentSlice';
import articleAndBlogSliceReducer from './slice/dashboard/articleAndBlogSlice';
import contentSlice from './slice/dashboard/contentSlice';
import bankingReducer from './slice/dashboard/bankingSlice';
import bookingReducer from './slice/dashboard/bookingSlice';
import inspiremindProfileReducer from './slice/dashboard/inspiremindProfileSlice';
import QAndATemplateReducer from './slice/dashboard/QAndATemplateSlice';
import servicePlanSliceReducer from './slice/dashboard/servicePlanSlice';
import appLanguageConfigReducer from './slice/language/AppLanguageConfigSlice';
import NotificationSliceReducer from './slice/notification/NotificationSlice';
import ChatReducer from './slice/dashboard/chatSlice';
import eventReducer from './slice/dashboard/eventSlice';
// import eventReducer from "./slice/chat/chatSlice"

const combinedReducer = combineReducers({
	auth: authSliceReducer,
	appLanguageConfig: appLanguageConfigReducer,
	common: commonReducer,
	signup: signupSliceReducer,
	otpVerification: otpVerificationSliceReducer,
	consultantInfo: consultantInfoReducer,
	faq: faqSliceReducer,
	signin: signinSliceReducer,
	forgetPassword: forgetPasswordReducer,
	servicePlan: servicePlanSliceReducer,
	inspiremindProfile: inspiremindProfileReducer,
	QAndATemplate: QAndATemplateReducer,
	appointment: appointmentSliceReducer,
	articleAndBlog: articleAndBlogSliceReducer,
	booking: bookingReducer,
	chat: ChatReducer,
	banking: bankingReducer,
	notification: NotificationSliceReducer,
	contentData: contentSlice,
	eventData: eventReducer,
});

export const rootReducer = (state, action) => {
	if (action.type === 'auth/Logout') {
		state = undefined;
	}
	return combinedReducer(state, action);
};
