import React from "react";

import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

import { map } from "lodash";

const ProfileSkelton = () => {
  return (
    <>
      <Stack spacing={1}>
        <Paper variant="outlined" className="w-100 mb-3">
          <CardContent>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <Skeleton variant="circular" width={150} height={150} />
              <Box className="d-flex flex-column gap-2 flex-grow-1">
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  sx={{ width: "200px" }}
                  height={10}
                />
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  sx={{ width: "300px" }}
                  height={10}
                />
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  sx={{ width: "100%" }}
                  height={2}
                  className="my-2"
                />
                {map([1, 2, 3], (item, index) => (
                  <Box className="d-flex align-items-center gap-1" key={index}>
                    <Skeleton
                      variant="circular"
                      animation="wave"
                      width={15}
                      height={15}
                    />
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      sx={{ width: "150px" }}
                      height={8}
                      className="my-2"
                    />
                  </Box>
                ))}
              </Box>
            </Stack>
          </CardContent>
        </Paper>
        {map([1, 2, 3, 4, 5], (item, index) => (
          <Paper variant="outlined" key={index} className="mb-1">
            <Skeleton
              variant="rounded"
              animation="wave"
              height={10}
              width={150}
              className="my-3 ms-4"
            />
            <Skeleton variant="rectangular" animation="wave" height={3} />
            <CardContent className="d-flex flex-column gap-2">
              <Skeleton
                variant="rounded"
                animation="wave"
                height={8}
                width="50%"
              />
              <Skeleton
                variant="rounded"
                animation="wave"
                height={8}
                width="60%"
              />
              <Skeleton
                variant="rounded"
                animation="wave"
                height={8}
                width="80%"
              />
              <Skeleton
                variant="rounded"
                animation="wave"
                height={8}
                width="100%"
              />
            </CardContent>
          </Paper>
        ))}
      </Stack>
    </>
  );
};

export default ProfileSkelton;
