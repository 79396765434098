import React, { useState } from "react";
import { filter, get, isEmpty, map } from "lodash";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import CMAccordion from "../../../ui-components/accordion/CMAccordion";
import CMAccordionDetails from "../../../ui-components/accordion/CMAccordionDetails";
import CMAccordionSummary from "../../../ui-components/accordion/CMAccordionSummary";
import translate from "../../../hooks/translate";

const DetailCardLabel = ({ label, sx, className, ...rest }) => {
  return (
    <Stack
      direction="row"
      justifyContent={{
        sm: "space-between",
        xs: "flex-start",
      }}
      spacing={2}
      className={`me-3 ${className}`}
      sx={{
        width: { md: "25%", sm: "30%", xs: "auto" },
        ...sx,
      }}
      {...rest}
    >
      <Typography variant="body1" fontWeight={500}>
        {label}
      </Typography>
      <Typography variant="body1" fontWeight={500}>
        :
      </Typography>
    </Stack>
  );
};

const QuestionDetailAccordion = (props) => {
  const {
    sectionType,
    sectionIndex,
    questionIndex,
    questionValue,
    setFieldValue,
    fieldName,
    fieldValue,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <CMAccordion
      key={sectionType + "_" + questionIndex}
      expanded={
        expanded ===
        `${sectionType}-sections-${sectionIndex}-panel-${questionIndex}`
      }
      onChange={handleChange(
        `${sectionType}-sections-${sectionIndex}-panel-${questionIndex}`
      )}
      className="mb-1"
    >
      <CMAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${sectionType}-sections-${sectionIndex}-panel-${questionIndex}-content`}
        id={`${sectionType}-sections-${sectionIndex}-panel-${questionIndex}-header`}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          className="w-100"
        >
          <Stack direction="row" spacing={1}>
            <Typography variant="body1" fontWeight={500}>
              {questionIndex + 1}.
            </Typography>
            <Typography variant="body1" className="text-break">
              {translate(get(questionValue, "questionTitle", ""))}
            </Typography>
          </Stack>
          {sectionType !== "default" ? (
            <IconButton
              color="error"
              size="small"
              onClick={() => {
                setFieldValue(
                  fieldName,
                  filter(fieldValue, (data) => {
                    return data.questionId._id !== questionValue._id;
                  })
                );
              }}
              className="me-1"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          ) : null}
        </Stack>
      </CMAccordionSummary>
      <CMAccordionDetails>
        <Stack direction="column" spacing={1.5}>
          {!isEmpty(questionValue) ? (
            <Box className="px-2">
              <Stack direction="row" flexWrap="wrap" spacing={1}>
                <DetailCardLabel label={translate("common_qa_input_type")} />
                <Typography
                  variant="body1"
                  className="text-capitalize fw-regular"
                  color="black"
                >
                  {get(questionValue, "inputType", "")}
                </Typography>
              </Stack>
            </Box>
          ) : null}
          {get(questionValue, "inputType", "") === "scale" ? (
            <Box className="px-2">
              <Stack direction="column" spacing={1}>
                <Box>
                  <Stack direction="row" flexWrap="wrap" spacing={1}>
                    <DetailCardLabel label="Minimum Value" />
                    <Typography
                      variant="body1"
                      className="text-capitalize fw-regular"
                      color="black"
                    >
                      {get(questionValue, "minValue", "")}
                    </Typography>
                  </Stack>
                </Box>
                <Box>
                  <Stack direction="row" flexWrap="wrap" spacing={1}>
                    <DetailCardLabel label="Maximum Value" />
                    <Typography
                      variant="body1"
                      className="text-capitalize fw-regular"
                      color="black"
                    >
                      {get(questionValue, "maxValue", "")}
                    </Typography>
                  </Stack>
                </Box>
                <Box>
                  <Stack direction="row" flexWrap="wrap" spacing={1}>
                    <DetailCardLabel label="Step Value" />
                    <Typography
                      variant="body1"
                      className="text-capitalize fw-regular"
                      color="black"
                    >
                      {get(questionValue, "stepValue", "")}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          ) : null}
          {get(questionValue, "inputType", "") === "radio" ||
          get(questionValue, "inputType", "") === "checkbox" ? (
            <Box className="px-2">
              <Stack direction={{ sm: "row", xs: "column" }} spacing={1}>
                <DetailCardLabel label={translate("common_qa_options")} />
                <Box overflow="auto">
                  <Table
                    sx={{
                      minWidth: 240,
                    }}
                    size="small"
                    stickyHeader={true}
                    aria-label={`question-option-table-${questionIndex}`}
                    className="border"
                  >
                    <TableHead>
                      <TableRow
                        sx={{
                          "& .MuiTableCell-root": {
                            padding: 1,
                          },
                        }}
                      >
                        <TableCell>{translate("common_qa_option")}</TableCell>
                        <TableCell align="right">
                          {translate("common_qa_value")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {map(
                        get(questionValue, "options", []),
                        (option, optionIndex) => (
                          <TableRow
                            key={optionIndex}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              "& .MuiTableCell-root": {
                                py: 0.5,
                                px: 1,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {translate(option?.label)}
                            </TableCell>
                            <TableCell component="th" scope="row" align="right">
                              {option?.value}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </Stack>
            </Box>
          ) : null}
          {map(get(questionValue, "options", []), (option) => {
            if (get(option, "subQuestionStatus", false)) {
              return map(
                get(option, "subQuestions", []),
                (subQuestion, subQuestionIndex) => {
                  return (
                    <Stack
                      direction="column"
                      spacing={1.5}
                      key={subQuestionIndex}
                    >
                      <Box className="px-2">
                        <Stack direction="row" flexWrap="wrap" spacing={1}>
                          <Typography
                            variant="body1"
                            className="text-capitalize fw-medium"
                            color="grey.800"
                          >
                            {translate("common_qa_option")}{" "}
                            {translate(get(option, "label", ""))}{" "}
                            {translate("common_qa_sub_question")}
                          </Typography>
                        </Stack>
                      </Box>
                      {!isEmpty(subQuestion) ? (
                        <Box className="px-2">
                          <Stack direction="row" flexWrap="wrap" spacing={1}>
                            <DetailCardLabel
                              label={translate("common_qa_question_title")}
                            />
                            <Typography
                              variant="body1"
                              className="text-capitalize fw-regular"
                              color="black"
                            >
                              {get(subQuestion, "questionTitle", "")}
                            </Typography>
                          </Stack>
                        </Box>
                      ) : null}
                      {!isEmpty(subQuestion) ? (
                        <Box className="px-2">
                          <Stack direction="row" flexWrap="wrap" spacing={1}>
                            <DetailCardLabel
                              label={translate("common_qa_input_type")}
                            />
                            <Typography
                              variant="body1"
                              className="text-capitalize fw-regular"
                              color="black"
                            >
                              {get(subQuestion, "inputType", "")}
                            </Typography>
                          </Stack>
                        </Box>
                      ) : null}
                      {get(subQuestion, "inputType", "") === "scale" ? (
                        <Box className="px-2">
                          <Stack direction="column" spacing={1}>
                            <Stack direction="row" flexWrap="wrap" spacing={1}>
                              <DetailCardLabel label="Minimum Value" />
                              <Typography
                                variant="body1"
                                className="text-capitalize fw-regular"
                                color="black"
                              >
                                {get(subQuestion, "minValue", "")}
                              </Typography>
                            </Stack>
                            <Stack direction="row" flexWrap="wrap" spacing={1}>
                              <DetailCardLabel label="Maximum Value" />
                              <Typography
                                variant="body1"
                                className="text-capitalize fw-regular"
                                color="black"
                              >
                                {get(subQuestion, "maxValue", "")}
                              </Typography>
                            </Stack>
                            <Stack direction="row" flexWrap="wrap" spacing={1}>
                              <DetailCardLabel label="Step Value" />
                              <Typography
                                variant="body1"
                                className="text-capitalize fw-regular"
                                color="black"
                              >
                                {get(subQuestion, "stepValue", "")}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Box>
                      ) : null}
                      {get(subQuestion, "inputType", "") === "radio" ||
                      get(subQuestion, "inputType", "") === "checkbox" ? (
                        <Box className="px-2">
                          <Stack
                            direction={{ sm: "row", xs: "column" }}
                            spacing={1}
                          >
                            <DetailCardLabel
                              label={translate("common_qa_options")}
                            />
                            <Box overflow="auto">
                              <Table
                                sx={{
                                  minWidth: 240,
                                }}
                                size="small"
                                stickyHeader={true}
                                aria-label={`question-option-table-${subQuestionIndex}`}
                              >
                                <TableHead>
                                  <TableRow
                                    sx={{
                                      "& .MuiTableCell-root": {
                                        padding: 1,
                                      },
                                    }}
                                  >
                                    <TableCell>
                                      {translate("common_qa_option")}
                                    </TableCell>
                                    <TableCell>
                                      {translate("common_qa_value")}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {map(
                                    get(subQuestion, "options", []),
                                    (
                                      subQuestionOption,
                                      subQuestionOptionIndex
                                    ) => (
                                      <TableRow
                                        key={subQuestionOptionIndex}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                          "& .MuiTableCell-root": {
                                            py: 0.5,
                                            px: 1,
                                          },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          {translate(subQuestionOption?.label)}
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          align="right"
                                        >
                                          {subQuestionOption.value}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Stack>
                        </Box>
                      ) : null}
                    </Stack>
                  );
                }
              );
            }
          })}
        </Stack>
      </CMAccordionDetails>
    </CMAccordion>
  );
};

export default QuestionDetailAccordion;
