import React from 'react';
import { get } from 'lodash';
import Avatar from '@mui/material/Avatar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { renderFileStyle } from '../FilePickerAppointmentPreview.styles';
import { AvatarInnerButtonsWrapper } from '../../component/FilePickerComponents';

export default function ImageViewRenderer({ file, filePreviewSize, previewImageStyle, theme, fileLabel }) {
	return (
		<>
			<ListItemIcon className='position-relative' sx={renderFileStyle.ListItemIcon}>
				<Avatar
					src={get(file, 'preview')}
					alt={get(file, 'name')}
					variant='rounded'
					sx={{
						width: filePreviewSize ? filePreviewSize : 128,
						height: filePreviewSize ? filePreviewSize : 96,
						aspectRatio: '4/3',
						border: `2px solid ${theme.palette.grey[200]}`,
						cursor: 'pointer',
						...previewImageStyle,
					}}
				/>

				<AvatarInnerButtonsWrapper className='avatar-hover-buttons'>
					<Button
						aria-label='view-file'
						size='small'
						className='min-w-auto p-1'
						component={'a'}
						href={get(file, 'preview')}
						target='_blank'
					>
						<VisibilityIcon sx={{ color: 'grey.200' }} />
					</Button>
				</AvatarInnerButtonsWrapper>
			</ListItemIcon>
			{fileLabel ? (
				<ListItemText
					primary={get(file, 'path', '')}
					secondary={(get(file, 'size', '') / 1024).toFixed(2) + ' kb'}
					sx={{ wordWrap: 'break-word' }}
				/>
			) : null}
		</>
	);
}
