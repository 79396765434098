import Radio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const CustomRadio = styled(Radio)(({ other, theme }) => ({
	display: 'none',
	'& + .MuiFormControlLabel-label': {
		backgroundColor: other.varianttype === 'option' ? `${theme.palette.grey[200]}` : theme.palette.primary.lighter,
		color: other.varianttype === 'option' ? theme.palette.text.primary : theme.palette.primary.main,
		textAlign: other.align && other.align,
		padding: other.padding ? other.padding : other.varianttype === 'option' ? '8px 16px' : '6px 12px',
		borderRadius: 8,
		outline: 'none',
		fontSize: other.fontSize ? other.fontSize : other.varianttype === 'option' ? '16px' : '14px',
		fontWeight: other.fontWeight ? other.fontWeight : '500',
		width: other.width ? other.width : other.varianttype === 'option' && '100%',
		border: other.varianttype === 'option' && `1px solid ${theme.palette.grey[500]}}`,
		transition: 'all 300ms ease-in-out',
		'& + :hover': {
			backgroundColor: other.varianttype === 'option' ? theme.palette.primary[50] : theme.palette.primary[100],
			color: other.varianttype === 'option' ? theme.palette.primary.main : theme.palette.primary[600],
			borderColor: theme.palette.primary.main,
		},
	},
	'&.Mui-checked': {
		'& + .MuiFormControlLabel-label': {
			color: other.varianttype === 'option' ? theme.palette.primary.main : theme.palette.primary.contrastText,
			backgroundColor: other.varianttype === 'option' ? theme.palette.primary.lighter : theme.palette.primary.main,
			borderColor: other.varianttype === 'option' && theme.palette.primary.main,

			'& + :hover': {
				backgroundColor: other.varianttype === 'option' ? theme.palette.primary.lighter : theme.palette.primary.lighter,
			},
		},
	},
	'&.MuiRadio-root.Mui-checked': {
		'& + .MuiFormControlLabel-label': {
			animation: other.animated === 'true' ? 'flash 300ms ease alternate' : 'none',
		},
	},
}));

const CMRadio = (props) => {
	const { name, value, sx, ...rest } = props;

	return <CustomRadio name={name} value={value} sx={{ ...sx }} other={props} {...rest} />;
};

CMRadio.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	sx: PropTypes.any,
	value: PropTypes.any,
};

export default CMRadio;
