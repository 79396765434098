import React from 'react';

import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// import PauseAvailablity from './availablity/PauseAvailablity';
import SetAvailability from './availablity/SetAvailability';
import ServiceTypeSetting from './service-type/ServiceTypeSetting';
import SetUnavailability from './unavailablity/SetUnavailability';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

const Appointment = () => {
	const serviceTypes = useSelector((state) => state.servicePlan.serviceTypes);
	const disableForm = get(serviceTypes, 'workStatus', false);

	return (
		<Grid className='fdf' container spacing={2}>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<ServiceTypeSetting disableForm={disableForm} />
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} container spacing={2}>
				<Grid item xs={12} lg={8} order={{ xs: 2, lg: 1 }}>
					<SetAvailability disableForm={disableForm} />
				</Grid>
				<Grid item xs={12} lg={4} order={{ xs: 1, lg: 2 }}>
					{/* <PauseAvailablity /> */}
					<SetUnavailability disableForm={disableForm} />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Appointment;
