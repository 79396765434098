import { toast } from 'react-toastify';

/**
 * Download file from URL.
 *
 * @param {string} url - URL of the file to download.
 * @param {string} filename - Name of the downloaded file.
 */
export function downloadFileFromUrl(url, filename) {
	return new Promise((resolve) => {
		fetch(url)
			.then((response) => response.blob())
			.then((blob) => {
				const link = document.createElement('a');
				link.href = URL.createObjectURL(blob);
				link.download = filename;
				link.click();
			})
			.catch((error) => {
				toast.error(error.message);
			})
			.finally(() => {
				resolve();
			});
	});
}
