import AxiosDefault from "../../services/AxiosDefault";

export const PostConnectStripeAccount = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/consultant/consultation/stripe/connect-account`,
    data: data,
  });
  return response;
};

export const PostConnectStripeAccountStatus = async (data) => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/consultant/consultation/stripe/connect-account/status`,
    data: data,
  });
  return response;
};

export const GetTransactionsList = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/consultant/consultation/transaction/list`,
    data: data,
  });
  return response;
};
