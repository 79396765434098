import React from "react";

import NotInterestedRoundedIcon from "@mui/icons-material/NotInterestedRounded";
import UpcomingRoundedIcon from "@mui/icons-material/UpcomingRounded";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Label from "../../../common/Label";
import InProgressCard from "./InProgressCard";
import SuccessCard from "./SuccessCard";

const ConsultationDetailCard = ({ data }) => {
  const consultationStatus = data.consultationStatus;
  if (consultationStatus === "COMPLETE") {
    return <SuccessCard data={data} />;
  } else if (consultationStatus === "IN_PROGRESS") {
    return <InProgressCard data={data} />;
  } else {
    return (
      <>
        <Paper
          className="p-2 px-3 rounded h-100"
          sx={{
            boxShadow: (theme) => theme.shadows[3],
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="gap-3 mb-2"
          >
            <Label
              variant="ghost"
              color={consultationStatus === "UPCOMING" ? "info" : "error"}
            >
              {consultationStatus}
            </Label>
            {consultationStatus === "UPCOMING" ? (
              <UpcomingRoundedIcon color="info" />
            ) : (
              <NotInterestedRoundedIcon color="error" />
            )}
          </Stack>
          <Typography variant="subtitle1" gutterBottom>
            {data.consultationNumber} Consultation
          </Typography>
        </Paper>
      </>
    );
  }
};

export default ConsultationDetailCard;
