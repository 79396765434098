import React, { useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import {
	lightTheme,
	MeetingProvider,
	NotificationProvider,
	GlobalStyles,
	LoggerProvider,
} from 'amazon-chime-sdk-component-library-react';

import { MeetingStateProvider } from './providers/MeetingStateProvider';
import ErrorProvider from './providers/ErrorProvider';
import { NavigationProvider } from './providers/NavigationProvider.jsx';
import Notifications from './containers/Notifications.jsx';
import MeetingView from './views/meeting';
import { meetingConfig } from './meetingConfig';
import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import AxiosDefaultSetting from '../../../../../services/AxiosDefault.jsx';

const Theme = ({ children }) => {
	return (
		<ThemeProvider theme={lightTheme}>
			<GlobalStyles />
			{children}
		</ThemeProvider>
	);
};

const MeetingContainer = ({ appointmentData }) => {
	const [meetingData, setMeetingData] = useState();
	const [showMeeting, setShowMeeting] = useState(false);
	const [loading, setLoading] = useState(false);

	const meetingDetails = useMemo(() => {
		if (!appointmentData?.startTime) {
			return {};
		}

		return {
			_id: appointmentData._id,
			startTime: moment.utc(appointmentData.startTime).local().format('hh:mm A'),
		};
	}, [appointmentData]);

	const handleMeetingJoin = async () => {
		setLoading(true);
		try {
			const response = await AxiosDefaultSetting({
				method: 'GET',
				url: `/consultant/consultation/booking/meeting/${meetingDetails._id}`,
			});

			if (response?.status === 200) {
				setMeetingData(response.data);
				setShowMeeting(true);
			}

			console.log(response);
		} catch (error) {}
		setLoading(false);
	};

	if (!showMeeting) {
		return (
			<Box display='flex' justifyContent='center' alignItems='center' mt={4}>
				<Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
					<Typography mb={2}> Meeting time: {meetingDetails.startTime}</Typography>
					<Button onClick={handleMeetingJoin} disabled={!meetingDetails?._id}>
						{loading ? 'Loading...' : 'Join Meeting'}
					</Button>
				</Box>
			</Box>
		);
	}

	return (
		<LoggerProvider logger={meetingConfig.logger}>
			<MeetingStateProvider>
				<Theme>
					<NotificationProvider>
						<Notifications />
						<ErrorProvider>
							<MeetingProvider>
								<NavigationProvider>
									<MeetingView meetingData={meetingData} />
								</NavigationProvider>
							</MeetingProvider>
						</ErrorProvider>
					</NotificationProvider>
				</Theme>
			</MeetingStateProvider>
		</LoggerProvider>
	);
};

export default MeetingContainer;
