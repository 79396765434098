import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import {
	GetPhoneCode,
	GetCountyList,
	GetLanguageList,
	GetTimeZone,
	GetGeneralConfiguration,
	GetCurrencyList,
	ProfileImageUpload,
	GetCountryPolicies,
	GetBenefits,
} from '../../api/common';

const initialState = {
	phoneCode: [],
	languageList: [],
	countryList: [],
	timeZoneList: [],
	currencyList: [],
	specializationList: [],
	benefitsList: [],
	socialMediaChanelList: [],
	cusineType: [],
	goalType: [],
	dietaryPreference: [],
	workActivityLevel: [],
	leisureTimeActivityLevel: [],
	foodAllergies: [],
	countryPolicies: [],
	countryLoading: false,
	dietaryPreferenceLoading: false,
	headerHeight: 50,
	footerHeight: 40,
	loading: false,
	contentConfigList: [],
	backgroundJobIdMap: {},
};

export const getPhoneCode = createAsyncThunk('common/getPhoneCode', async () => {
	try {
		const response = await GetPhoneCode();
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const getCountryList = createAsyncThunk('common/getCountryList', async () => {
	try {
		const response = await GetCountyList();
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const getLanguageList = createAsyncThunk('common/getLanguageList', async () => {
	try {
		const response = await GetLanguageList();
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const getTimeZone = createAsyncThunk('common/getTimeZone', async () => {
	try {
		const response = await GetTimeZone();
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const getCurrencyList = createAsyncThunk('common/getCurrencyList', async (data) => {
	try {
		const response = await GetCurrencyList(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const getSpecialization = createAsyncThunk('common/getSpecialization', async (data) => {
	try {
		const response = await GetGeneralConfiguration(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const getSocialMediaChanel = createAsyncThunk('common/getSocialMediaChanel', async (data) => {
	try {
		const response = await GetGeneralConfiguration(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const getGoalType = createAsyncThunk('common/getGoalType', async (data) => {
	try {
		const response = await GetGeneralConfiguration(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const getDietaryPreference = createAsyncThunk('common/getDietaryPreference', async (data) => {
	try {
		const response = await GetGeneralConfiguration(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const getWorkActivityLevel = createAsyncThunk('common/getWorkActivityLevel', async (data) => {
	try {
		const response = await GetGeneralConfiguration(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});
export const getLeisureTimeActivityLevel = createAsyncThunk('common/getLeisureTimeActivityLevel', async (data) => {
	try {
		const response = await GetGeneralConfiguration(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const getCusineType = createAsyncThunk('common/getCusineType', async (data) => {
	try {
		const response = await GetGeneralConfiguration(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const getFoodAllergies = createAsyncThunk('common/getFoodAllergies', async (data) => {
	try {
		const response = await GetGeneralConfiguration(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const profileImageUpload = createAsyncThunk('common/profileImageUpload', async (data) => {
	try {
		const response = await ProfileImageUpload(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const getCountryPolicies = createAsyncThunk('common/getCountryPolicies', async (data) => {
	try {
		const response = await GetCountryPolicies(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const getBenefits = createAsyncThunk('common/getBenefits', async (data) => {
	try {
		const response = await GetBenefits(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const getContentconfig = createAsyncThunk('common/getContentconfig', async (data) => {
	try {
		const response = await GetGeneralConfiguration(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const common = createSlice({
	name: 'common',
	initialState,
	reducers: {
		setHeaderFooterHeight: (state, { payload }) => {
			state.headerHeight = payload.headerheight;
			state.footerHeight = payload.footerheight;
		},
		addBackgroundJobId: (state, { payload }) => {
			state.backgroundJobIdMap[payload.id] = payload.value;
		},
		removeBackgroundJobId: (state, { payload }) => {
			delete state.backgroundJobIdMap[payload.id];
		},
		removeBackgroundAllJob: (state) => {
			state.backgroundJobIdMap = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getPhoneCode.pending, (state) => {
				state.loading = true;
			})
			.addCase(getPhoneCode.fulfilled, (state, { payload }) => {
				state.phoneCode = get(payload, 'data', []);
				state.loading = false;
			})
			.addCase(getPhoneCode.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getCountryList.pending, (state) => {
				state.countryLoading = true;
			})
			.addCase(getCountryList.fulfilled, (state, { payload }) => {
				state.countryList = get(payload, 'data', []);
				state.countryLoading = false;
			})
			.addCase(getCountryList.rejected, (state) => {
				state.countryLoading = false;
			})
			.addCase(getLanguageList.pending, (state) => {
				state.loading = true;
			})
			.addCase(getLanguageList.fulfilled, (state, { payload }) => {
				state.languageList = get(payload, 'data', []);
				state.loading = false;
			})
			.addCase(getLanguageList.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getTimeZone.pending, (state) => {
				state.loading = true;
			})
			.addCase(getTimeZone.fulfilled, (state, { payload }) => {
				state.timeZoneList = get(payload, 'data', []);
				state.loading = false;
			})
			.addCase(getTimeZone.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getCurrencyList.pending, (state) => {
				state.loading = true;
			})
			.addCase(getCurrencyList.fulfilled, (state, { payload }) => {
				state.currencyList = get(payload, 'data', []);
				state.loading = false;
			})
			.addCase(getCurrencyList.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getSpecialization.pending, (state) => {
				state.loading = true;
			})
			.addCase(getSpecialization.fulfilled, (state, { payload }) => {
				state.specializationList = get(payload, 'data', []);
				state.loading = false;
			})
			.addCase(getSpecialization.rejected, (state) => {
				state.loading = false;
			})

			.addCase(getBenefits.pending, (state) => {
				state.loading = true;
			})
			.addCase(getBenefits.fulfilled, (state, { payload }) => {
				state.benefitsList = get(payload, 'data', []);
				state.loading = false;
			})
			.addCase(getBenefits.rejected, (state) => {
				state.loading = false;
			})

			.addCase(getSocialMediaChanel.pending, (state) => {
				state.loading = true;
			})
			.addCase(getSocialMediaChanel.fulfilled, (state, { payload }) => {
				state.socialMediaChanelList = get(payload, 'data', []);
				state.loading = false;
			})
			.addCase(getSocialMediaChanel.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getGoalType.pending, (state) => {
				state.loading = true;
			})
			.addCase(getGoalType.fulfilled, (state, { payload }) => {
				state.goalType = get(payload, 'data', []);
				state.loading = false;
			})
			.addCase(getGoalType.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getDietaryPreference.pending, (state) => {
				state.loading = true;
			})
			.addCase(getDietaryPreference.fulfilled, (state, { payload }) => {
				state.dietaryPreference = get(payload, 'data', []);
				state.loading = false;
			})
			.addCase(getDietaryPreference.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getWorkActivityLevel.pending, (state) => {
				state.loading = true;
			})
			.addCase(getWorkActivityLevel.fulfilled, (state, { payload }) => {
				state.workActivityLevel = get(payload, 'data', []);
				state.loading = false;
			})
			.addCase(getWorkActivityLevel.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getLeisureTimeActivityLevel.pending, (state) => {
				state.loading = true;
			})
			.addCase(getLeisureTimeActivityLevel.fulfilled, (state, { payload }) => {
				state.leisureTimeActivityLevel = get(payload, 'data', []);
				state.loading = false;
			})
			.addCase(getLeisureTimeActivityLevel.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getCusineType.pending, (state) => {
				state.loading = true;
			})
			.addCase(getCusineType.fulfilled, (state, { payload }) => {
				state.cusineType = get(payload, 'data', []);
				state.loading = false;
			})
			.addCase(getCusineType.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getFoodAllergies.pending, (state) => {
				state.loading = true;
			})
			.addCase(getFoodAllergies.fulfilled, (state, { payload }) => {
				state.foodAllergies = get(payload, 'data', []);
				state.loading = false;
			})
			.addCase(getFoodAllergies.rejected, (state) => {
				state.loading = false;
			})
			.addCase(profileImageUpload.pending, (state) => {
				state.loading = true;
			})
			.addCase(profileImageUpload.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(profileImageUpload.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getCountryPolicies.pending, (state) => {
				state.loading = true;
			})
			.addCase(getCountryPolicies.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.countryPolicies = get(payload, 'data', '');
				localStorage.setItem('system_2_country_policies', JSON.stringify(get(payload, 'data', '')));
			})
			.addCase(getCountryPolicies.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getContentconfig.pending, (state) => {
				state.loading = true;
			})
			.addCase(getContentconfig.fulfilled, (state, { payload }) => {
				state.contentConfigList = get(payload, 'data', []);
				state.loading = false;
			})
			.addCase(getContentconfig.rejected, (state) => {
				state.loading = false;
			});
	},
});

export const { setHeaderFooterHeight, addBackgroundJobId, removeBackgroundJobId, removeBackgroundAllJob } = common.actions;

export default common.reducer;
