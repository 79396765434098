import React, { useRef, useState } from 'react';

import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';

import OtpInput from 'react-otp-input';
import { Formik } from 'formik';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import {
	addPhoneService,
	getPhoneService,
	otpSendService,
	otpVerifyService,
} from '../../../../../store/slice/dashboard/appointmentSlice';
import translate from '../../../../hooks/translate';
import { changeServicePlanTypeStatus } from '../../../../../store/slice/dashboard/servicePlanSlice';
import getAddDetailsStatus from './helpers';
import { OtpInputStyle } from './SetPhoneDetails.style';
import Timer from '../../../../common/Timer';

const PHONE_REG_EXP = /^\d{10}$/;
const Schema = Yup.object().shape({
	code: Yup.string().required(translate('consultant_error_dialling_code_required')),
	mobile: Yup.string()
		.max(10, translate('consultant_error_phone_number_too_long'))
		.length(10, translate('consultant_error_please_enter_a_valid_phone_number'))
		.matches(PHONE_REG_EXP, translate('consultant_error_please_enter_a_valid_phone_number'))
		.required(translate('consultant_error_phone_number_required')),
});

const SetPhoneDetails = ({ refreshData }) => {
	const [showOTP, setShowOTP] = useState(false);
	const [canResendOtp, setCanResendOtp] = useState(false);
	const [otpCode, setOtpCode] = useState('');
	const [phoneData, setPhoneData] = useState({});
	const mobileOTPTimer = useRef();

	const serviceTypes = useSelector((state) => state.servicePlan.serviceTypes);
	const phoneCode = useSelector((state) => state.common.phoneCode);
	const phoneServiceData = useSelector((state) => state.appointment.phoneServiceData);
	const loading = useSelector((state) => state.appointment.loading);
	const dispatch = useDispatch();

	const handleChange = (code) => {
		setOtpCode(code);
	};

	const handleSendOtpServie = async (payload) => {
		const res = await dispatch(otpSendService(payload))
			.unwrap()
			.then((result) => {
				if (result.status) {
					toast.success(result.message);
					setPhoneData(payload);
					setShowOTP(true);
					setCanResendOtp(false);
					// dispatch(getPhoneService());

					mobileOTPTimer.current = setTimeout(() => {
						setCanResendOtp(true);
					}, 30000);
				} else {
					toast.error(result.message);
				}
			})
			.catch((error) => {
				console.log('Phone service error========>', error);
			});
		return res;
	};

	const handleOtpVerifyServie = async () => {
		const payload = {
			code: otpCode,
		};
		const res = await dispatch(otpVerifyService(payload))
			.unwrap()
			.then((result) => {
				if (result.status) {
					setOtpCode('');
					if (result.message === 'Verify sucessfully') {
						toast.success(result.message);
						setShowOTP(false);
						handlePhoneServie();
						if (mobileOTPTimer.current) {
							clearTimeout(mobileOTPTimer.current);
						}
						// dispatch(getPhoneService());
					} else {
						toast.error('Invalid Otp try again');
					}
				} else {
					toast.error(result.message);
				}
			})
			.catch((error) => {
				console.log('Phone service error========>', error);
			});
		return res;
	};

	const handlePhoneServie = async () => {
		if (phoneData) {
			const res = await dispatch(addPhoneService(phoneData))
				.unwrap()
				.then((result) => {
					if (result.status) {
						toast.success(result.message);
						dispatch(getPhoneService());
					} else {
						toast.error(result.message);
					}
				})
				.catch((error) => {
					console.log('Phone service error========>', error);
				});
			return res;
		}
	};

	const _serviceTypePhone = serviceTypes?.serviceTypePhone;

	const handlePhoneStatus = () => {
		const payload = {
			serviceTypePhone: !_serviceTypePhone,
		};
		if (payload) {
			dispatch(changeServicePlanTypeStatus(payload))
				.unwrap()
				.then((result) => {
					if (result.status) {
						toast.success(result.message);
						refreshData();
					}
				})
				.catch((error) => {
					console.log('Service plan update error=======>', error);
				});
		}
	};

	if (loading) {
		return <LinearProgress />;
	}
	return (
		<Formik
			initialValues={{
				mobile: get(phoneServiceData, 'mobile', ''),
				code: get(phoneServiceData, 'code', ''),
			}}
			validationSchema={Schema}
			onSubmit={async (values) => {
				if (otpCode === '') {
					handleSendOtpServie(values);
				} else {
					handleOtpVerifyServie();
				}
			}}
		>
			{(props) => (
				<form onSubmit={props.handleSubmit}>
					<DialogContent sx={{ px: { xs: 2, md: 3 }, py: 2 }}>
						<Grid item container spacing={2} xs={12}>
							<Grid item xs={12} sm={4}>
								<Autocomplete
									disabled={
										showOTP || phoneServiceData?._id ? (_serviceTypePhone === true ? false : true) : false
									}
									getOptionLabel={(option) => option.dialingcode ?? option}
									options={phoneCode}
									isOptionEqualToValue={(option, value) => option.dialingcode === value}
									onChange={(event, value) => {
										props.setFieldValue('code', value.dialingcode);
									}}
									name='code'
									value={props.values.code}
									autoHighlight
									disableClearable
									noOptionsText={translate('common_no_data')}
									popupIcon={<KeyboardArrowDownSharpIcon />}
									renderOption={(props, option) => (
										<Box component='li' sx={{ '& > img': { mr: 1, flexShrink: 0 } }} {...props}>
											<Box component='span' mr={1}>
												{option.flag}
											</Box>
											{option.dialingcode}
										</Box>
									)}
									renderInput={(params) => (
										<TextField
											name='code'
											{...params}
											disabled={showOTP}
											label={translate('common_code')}
											error={props.errors.code && props.touched.code ? true : false}
											helperText={props.errors.code && props.touched.code ? props.errors.code : null}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} sm={8}>
								<TextField
									label={translate('consultant_phone_number')}
									name='mobile'
									disabled={
										showOTP || phoneServiceData?._id ? (_serviceTypePhone === true ? false : true) : false
									}
									value={props.values.mobile}
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									inputProps={{ maxLength: 10 }}
									error={props.errors.mobile && props.touched.mobile ? true : false}
									helperText={props.errors.mobile && props.touched.mobile ? props.errors.mobile : null}
								/>
							</Grid>

							{showOTP ? (
								<>
									<Grid item sm={4} display={{ xs: 'none', sm: 'block' }} />
									<Grid item xs={12} sm={8}>
										<Grid container spacing={2}>
											<Grid item xs={12} xl={6}>
												<OtpInput
													value={otpCode}
													onChange={handleChange}
													numInputs={4}
													separator={<span style={{ width: '8px' }} />}
													isInputNum={true}
													shouldAutoFocus={true}
													errorStyle={OtpInputStyle.errorStyle}
													inputStyle={OtpInputStyle.inputStyle}
													focusStyle={OtpInputStyle.focusStyle}
												/>
											</Grid>
											<Grid item xs={12} xl={6} display='flex' gap={1}>
												<Button variant='contained' fullWidth type='submit'>
													{translate('common_verify_now')}
												</Button>

												{canResendOtp ? (
													<Button
														variant='outlined'
														fullWidth
														onClick={() =>
															handleSendOtpServie({
																mobile: props.values.mobile,
																code: props.values.code,
															})
														}
														disabled={!canResendOtp}
													>
														Resend OTP
													</Button>
												) : (
													<Timer time={30} />
												)}
											</Grid>
										</Grid>
									</Grid>
								</>
							) : (
								<Grid item xs={12} display='flex' justifyContent='flex-end' gap={1}>
									<Button
										variant='contained'
										type='submit'
										disabled={getAddDetailsStatus(showOTP, phoneServiceData?._id, _serviceTypePhone)}
									>
										{phoneServiceData?._id
											? translate('consulant_update_details')
											: translate('consultant_add_details')}
									</Button>

									{phoneServiceData?._id && (
										<Button variant='contained' color='error' onClick={handlePhoneStatus}>
											{_serviceTypePhone
												? translate('consultant_offline_disable')
												: translate('consultant_offline_enable')}
										</Button>
									)}
								</Grid>
							)}
						</Grid>
					</DialogContent>
				</form>
			)}
		</Formik>
	);
};

export default SetPhoneDetails;
