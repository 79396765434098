import { useEffect, useState } from 'react';
import styles from './style.module.css';
import classNames from 'classnames';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import Box from '@mui/material/Box';
import EventSubject from '../../../../../../../services/EventSubject';

const ProgressBar = ({ id }) => {
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		const subscription = EventSubject.subscribe((data) => {
			if (data.messageId !== id) return;
			setProgress(data.progress);
		});

		return () => {
			subscription.unsubscribe();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.uploadIndicatorContainer}>
			<Box color='white'>
				<CircularProgressWithLabel color='inherit' value={progress} />
			</Box>
		</div>
	);
};

const TextMessageUI = ({ messageClass, data }) => {
	return (
		<div className={classNames(styles.messageContainer, styles[messageClass])}>
			<div style={{ position: 'relative', height: 'calc(100% - 15px)' }}>
				<img src={data.url} className={styles.img} alt='attach document' />

				{data.status === 'uploading' && <ProgressBar id={data.id} />}
			</div>
			<div className={classNames(styles.timeWrapper, styles.time)}>
				<div className={styles.timeContainer}>
					<span style={{ verticalAlign: 'bottom' }}>{data.createdAt}</span>
				</div>
			</div>
		</div>
	);
};

export default TextMessageUI;
