import { useLayoutEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';

import { useDispatch } from 'react-redux';

import { setHeaderFooterHeight } from '../../store/slice/common';
import Footer from './footer';
import HeaderMain from './header';

const PublicLayout = ({ children }) => {
	const headerRef = useRef(null);
	const footerRef = useRef(null);
	const dispatch = useDispatch();

	const [headerHeight, setHeaderHeight] = useState(0);
	const [footerHeight, setFooterHeight] = useState(0);

	useLayoutEffect(() => {
		setHeaderHeight(headerRef.current.clientHeight);
		setFooterHeight(footerRef.current.clientHeight);
		dispatch(
			setHeaderFooterHeight({
				headerHeight: headerHeight,
				footerHeight: footerHeight,
			})
		);
	}, [dispatch, footerHeight, headerHeight]);

	return (
		<Box display='flex' flexDirection='column'>
			<HeaderMain headerRef={headerRef} />
			<Box
				component='main'
				// sx={{
				// 	width: '100%',
				// 	minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
				// 	display: 'flex',
				// 	mt: `${headerHeight}px`,
				// 	backgroundColor: 'primary.contrastText',
				// }}
				paddingTop={`${headerHeight}px`}
				flex={1}
			>
				{children}
			</Box>
			<Footer footerRef={footerRef} />
		</Box>
	);
};

export default PublicLayout;
