import React, { useEffect } from "react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteIcon from "@mui/icons-material/Delete";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { get, isEmpty, map } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getUnavailability,
  removeUnavailability,
} from "../../../../store/slice/dashboard/appointmentSlice";
import unavailability from "../../../../assets/images/unavailability.svg";
import { toast } from "react-toastify";
import translate from "../../../hooks/translate";

const LeaveList = () => {
  const dispatch = useDispatch();
  const unavailabilityList = useSelector(
    (state) => state.appointment.unavailabilityList
  );

  const loading = useSelector((state) => state.appointment.loading);

  const CMTimeLine = styled(Timeline)(() => ({
    "&.MuiTimeline-root": {
      padding: 0,
    },
  }));

  const CMTimeLineItem = styled(TimelineItem)(() => ({
    "&.MuiTimelineItem-root:before": {
      display: "none",
    },
  }));

  useEffect(() => {
    dispatch(getUnavailability());
  }, [dispatch]);

  const handleRemove = async (id) => {
    const res = await dispatch(removeUnavailability(id))
      .unwrap()
      .then((result) => {
        if (result.status) {
          toast.success(result.message);
          dispatch(getUnavailability());
        }
      })
      .catch((error) => {
        throw error;
      });
    return res;
  };

  return (
    <>
      <Box className="mt-3">
        <Typography variant="h6">
          {translate("consultant_your_holidays")}
        </Typography>
        {isEmpty(unavailabilityList) && !loading ? (
          <Box className="p-1 w-100 text-center">
            <Box
              component="img"
              src={unavailability}
              alt="no unavailability"
              className="img-fluid"
            />
          </Box>
        ) : loading && isEmpty(unavailabilityList) ? (
          <Box className="py-2 text-center">
            <CircularProgress />
          </Box>
        ) : (
          map(unavailabilityList, (item, index) => (
            <CMTimeLine key={index}>
              <CMTimeLineItem>
                <TimelineSeparator>
                  <TimelineDot color="secondary">
                    <AccessTimeIcon />
                  </TimelineDot>
                  {unavailabilityList.length - 1 === index ? null : (
                    <TimelineConnector />
                  )}
                </TimelineSeparator>

                <TimelineContent sx={{ py: "12px" }}>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack
                      direction={{ xl: "row", lg: "column" }}
                      spacing={1}
                      alignItems="center"
                    >
                      <Typography variant="body1" color="text.secondary">
                        {moment(get(item, "startDate", "")).format(
                          "DD/MM/YYYY , hh:mm A"
                        )}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="primary"
                        className="fw-bold mt-0"
                      >
                        {translate("common_to")}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        {moment(get(item, "endDate", "")).format(
                          "DD/MM/YYYY , hh:mm A"
                        )}
                      </Typography>
                    </Stack>
                    <IconButton
                      color="error"
                      onClick={() => handleRemove(item._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                  {unavailabilityList.length - 1 === index ? null : (
                    <Divider light className="mt-2" />
                  )}
                </TimelineContent>
              </CMTimeLineItem>
            </CMTimeLine>
          ))
        )}
      </Box>
    </>
  );
};

export default LeaveList;
