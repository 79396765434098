import axios from 'axios';
import { errorInterceptor } from './error-interceptor';
import { jwtInterceptor } from './jwt-interceptor';

const BASE_URL = process.env.REACT_APP_API_END_POINT;

export default function axiosInit() {
	axios.defaults.baseURL = BASE_URL;
	errorInterceptor();
	jwtInterceptor();
}
