import React from 'react';

import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';

import { useSelector } from 'react-redux';

import { getConsultantData } from '../../../../../store/slice/auth/signup/consultantInfoSlice';
import ProfessionalInfo from '../../../../auth/signup/consultant-info/professional-info/ProfessionalInfo';

const ProfileProfessionalInfo = () => {
	const loading = useSelector((state) => state.consultantInfo.loading);
	const consultantData = useSelector(getConsultantData);

	return (
		<Card>
			<CardContent>
				{loading ? (
					<LinearProgress />
				) : (
					<ProfessionalInfo consultantInfo={consultantData} buttonLabel='Submit' isEdit={true} />
				)}
			</CardContent>
		</Card>
	);
};

export default ProfileProfessionalInfo;
