import React, { useEffect, useState } from 'react';

export default function Timer({ time }) {
	const [seconds, setSeconds] = useState(time);

	useEffect(() => {
		const interval = setInterval(() => {
			setSeconds((seconds) => seconds - 1);
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	return (
		<div>
			{Math.floor(seconds / 60)}:{seconds % 60}
		</div>
	);
}
