import React, { useEffect, useState } from 'react';
import { get, map } from 'lodash';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import QuestionDetailAccordion from '../components/QuestionDetailAccordion';
import translate from '../../../hooks/translate';
import AxiosDefaultSetting from '../../../../services/AxiosDefault';

const DefaultSegmentSection = () => {
	const [defaultSegment, setDefaultSegment] = useState([]);

	const getDefaultSegmentQuestions = async () => {
		try {
			const res = await AxiosDefaultSetting({
				method: 'GET',
				url: '/consultant/questions/template/get?type=default',
			});
			setDefaultSegment(
				get(res, 'data.data', []).map((e) => ({
					...e,
					questions: e.questions.map((question) => ({ ...question.questionId })),
				}))
			);
		} catch (error) {}
	};

	useEffect(() => {
		getDefaultSegmentQuestions();
	}, []);

	return (
		<Stack direction='column' spacing={3}>
			{map(defaultSegment, (item) => (
				<Paper variant='outlined' sx={{ borderColor: '#c0c0c0' }} key={item._id}>
					<Box className='px-3 py-2 border-bottom'>
						<Stack direction={{ sm: 'row', xs: 'column' }} alignItems={{ sm: 'center' }} spacing={1}>
							<Typography variant='body1' fontWeight={500}>
								{item.sectionName}
							</Typography>
						</Stack>
					</Box>
					<CardContent>
						<Box className='mb-2'>
							<Typography variant='body1' className='fw-medium' sx={{ color: 'grey.800' }}>
								{translate('common_questions')} :
							</Typography>
						</Box>

						{map(item.questions, (defaultSectionQuestion, index) => {
							return (
								<Box key={`default-section-${index}`}>
									<QuestionDetailAccordion
										sectionType='default'
										customSectionsIndex={index}
										questionIndex={index}
										questionValue={defaultSectionQuestion}
									/>
								</Box>
							);
						})}
					</CardContent>
				</Paper>
			))}
		</Stack>
	);
};

export default DefaultSegmentSection;
