import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export default function StatusViewerScreen({ status }) {
	if (status === 0 || status === 4 || status === 7) {
		return (
			<Box
				position='absolute'
				width='100%'
				height='100%'
				bgcolor='rgb(0, 0, 0 , 0.5)'
				zIndex='99'
				display='flex'
				justifyContent='center'
				alignItems='center'
				sx={{ pointerEvents: 'none' }}
			>
				<Box display='flex' flexDirection='column' alignItems='center'>
					{status === 0 && (
						<>
							<CircularProgress color='inherit' />
							<Typography mt={2}>Loading...</Typography>
						</>
					)}
					{status === 4 && <Typography>Joined from another device.</Typography>}
					{status === 7 && <Typography>Reconnecting...</Typography>}
				</Box>
			</Box>
		);
	}

	return <></>;
}
