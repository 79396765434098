import React from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { Formik } from 'formik';
import { get } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import DefaultThumbnail from '../../../../assets/images/default-healthcare.webp';
import {
	articleBlogArchive,
	articleBlogDelete,
	articleBlogpublish,
	getArticleBlog,
} from '../../../../store/slice/dashboard/articleAndBlogSlice';
import translate from '../../../hooks/translate';
import truncateString from '../../../hooks/truncateString';
import Iconify from '../../../common/Iconify';
import CardProgressLoadingOverlay from '../../../common/CardProgressLoadingOverlay';
import Text from '../../../common/Text';

const BlogCard = ({ item }) => {
	const backgroundJobIdMap = useSelector((state) => state.common.backgroundJobIdMap);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isPending = item?.status === 'pending';

	const handleArchive = (id, checked) => {
		const data = {
			id: id,
			payload: {
				status: checked,
			},
		};
		dispatch(articleBlogArchive(data))
			.unwrap()
			.then((result) => {
				if (result.status) {
					toast.success(result.message);
				} else {
					toast.error(result.message);
				}
			})
			.catch((error) => {
				console.log('Article blog error========>', error);
			});
	};

	const handlePublish = async (id, checked) => {
		const data = {
			id: id,
			payload: {
				status: checked,
			},
		};
		const res = await dispatch(articleBlogpublish(data))
			.unwrap()
			.then((result) => {
				if (result.status) {
					toast.success(result.message);
				} else {
					toast.error(result.message);
				}
			})
			.catch((error) => {
				console.log('Article blog error========>', error);
			});
		return res;
	};

	const handleDelete = async (id) => {
		const res = await dispatch(articleBlogDelete(id))
			.unwrap()
			.then((result) => {
				if (result.status) {
					toast.success(result.message);
					dispatch(getArticleBlog({ pageNo: 0, type: 'ALL' }));
				}
			})
			.catch((error) => {});
		return res;
	};

	const handleEdit = () => {
		navigate('add-blog', { state: { item: item, type: 'update' } });
	};

	return (
		<Card sx={{ boxShadow: (theme) => theme.shadows.z8 }} className='h-100 blog-card-details'>
			<CardProgressLoadingOverlay
				inBackgroundJob={backgroundJobIdMap?.[item._id]}
				isPending={isPending}
				processCompleted={item?.processCompleted}
				totalProcessCount={item?.totalProcessCount}
			/>
			<Formik
				initialValues={{
					isArchived: get(item, 'isArchived', false),
					isPublished: get(item, 'isPublished', false),
				}}
			>
				{(props) => (
					<form onSubmit={props.handleSubmit} className='d-flex flex-column h-100'>
						<Box
							sx={{ height: '250px' }}
							component='img'
							src={get(item, 'thumbnail[0].imageUrl[0].url', DefaultThumbnail)}
							alt='article main image'
							className='img-fluid img-contain w-100 h-100'
							onClick={() => {
								navigate('/dashboard/article-blog/blog-details', {
									state: {
										ArticleData: item,
									},
								});
							}}
						/>
						<CardContent className='p-3'>
							<Stack
								direction='row'
								justifyContent='space-between'
								alignItems='center'
								className='gap-2'
								sx={{ flexWrap: 'wrap' }}
							>
								<Box className='calender-icon' display='inline-flex'>
									<CalendarMonthTwoToneIcon sx={{ fontSize: 16, color: 'text.secondary', ml: '-6px' }} />
									<Typography variant='caption' ml={0.5} color='text.secondary'>
										{moment(item.createdAt).format('LL')}
									</Typography>
								</Box>
								{!isPending && (
									<Box className='d-flex align-items-center flex-wrap' gap={0.25}>
										{/* <Tooltip
											title={`Likes: ${item?.likeCount === undefined || 0 ? 0 : item?.likeCount}`}
											arrow
											sx={{ cursor: 'pointer' }}
										>
											<Iconify icon='mdi:like' height='22px' />
										</Tooltip>

										<Tooltip
											title={`Comments: ${item?.commentCount === undefined || 0 ? 0 : item?.commentCount}`}
											arrow
											sx={{ cursor: 'pointer' }}
										>
											<Iconify icon='ic:round-comment' height='22px' />
										</Tooltip> */}

										<Tooltip title={translate('common_edit')} arrow>
											<IconButton size='small' onClick={() => handleEdit()} sx={{ p: 0 }}>
												<Iconify icon='solar:pen-bold' />
											</IconButton>
										</Tooltip>
										<Tooltip title={translate('common_archived')} arrow>
											<IconButton
												size='small'
												onClick={() => {
													handleArchive(item._id, !props.values.isArchived);
													props.setFieldValue('isArchived', !props.values.isArchived);
												}}
												sx={{ p: 0 }}
											>
												<Iconify
													icon={
														props.values.isArchived
															? 'solar:bookmark-bold-duotone'
															: 'solar:bookmark-line-duotone'
													}
												/>
											</IconButton>
										</Tooltip>

										<Tooltip title={translate('common_publish')} arrow>
											<IconButton
												size='small'
												color={props.values.isPublished ? 'primary' : ''}
												onClick={() => {
													handlePublish(item._id, !props.values.isPublished);
													props.setFieldValue('isPublished', !props.values.isPublished);
												}}
												sx={{ p: 0 }}
											>
												<Iconify height='24px' width='24px' icon='ic:round-publish' />
											</IconButton>
										</Tooltip>

										<Tooltip title={translate('common_delete')} arrow>
											<IconButton
												size='small'
												color='error'
												onClick={() => handleDelete(item._id)}
												sx={{ p: 0 }}
											>
												<Iconify icon='solar:trash-bin-minimalistic-bold' />
											</IconButton>
										</Tooltip>
									</Box>
								)}
							</Stack>

							<Text mt={0.25}>{item.title}</Text>
							<Typography variant='body2' color='text.secondary'>
								{truncateString(item.subTitle, 45)}
							</Typography>
						</CardContent>
						<CardContent className='p-2 mt-auto border-top'>
							{get(item, 'categories.title', '') || get(item, 'subCategories.title', '') ? (
								<Stack direction='row' sx={{ flexWrap: 'wrap' }} className='gap-1'>
									{get(item, 'categories.title', '') ? (
										<Chip label={get(item, 'categories.title', '')} variant='body2' />
									) : null}
									{get(item, 'subCategories.title', '') ? (
										<Chip label={get(item, 'subCategories.title', '')} variant='body2' />
									) : null}
								</Stack>
							) : null}
							<Button
								onClick={() => {
									navigate('/dashboard/article-blog/blog-details', {
										state: {
											ArticleData: item,
										},
									});
								}}
								variant='text'
								endIcon={<ArrowForwardIcon />}
								disabled={isPending}
							>
								{translate('common_read_more')}
							</Button>
						</CardContent>
					</form>
				)}
			</Formik>
		</Card>
	);
};

export default BlogCard;
