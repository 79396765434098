import LoadingButton from "@mui/lab/LoadingButton";
import { alpha, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";

const CMLoadingButton = (props) => {
  const { variant, color, label, children, sx, ...rest } = props;
  const theme = useTheme();
  const { palette } = theme;
  const shadowColor = color ? color : "primary";
  const boxShadow = `0 2px 8px 0 ${alpha(palette[shadowColor].light, 0.5)}`;

  const colorSX = props.variant === "custom" && {
    boxShadow: "none",
    color: `${color}.main`,
    backgroundColor: `${color}.light`,
    "&:hover,&:active,&:focus": {
      color: theme.palette.primary.contrastText,
      backgroundColor: `${color}.main`,
      boxShadow: boxShadow,
    },
  };

  return (
    <>
      <LoadingButton
        variant={variant}
        sx={{ ...colorSX, ...sx }}
        color={color}
        other={props}
        {...rest}
      >
        {label}
        {children}
      </LoadingButton>
    </>
  );
};

CMLoadingButton.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
  sx: PropTypes.any,
  children: PropTypes.any,
};
export default CMLoadingButton;
