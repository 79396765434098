import { get, isEmpty } from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';
import translate from '../../../hooks/translate';

export const Schema = Yup.object().shape({
	availability: Yup.array().of(
		Yup.object().shape({
			time: Yup.array().when('checked', {
				is: true,
				then: Yup.array().of(
					Yup.object().shape({
						startTime: Yup.string().when(['endTime'], (value, _, { parent }) => {
							const st = moment(get(parent, 'startTime', ''), 'LT');
							const et = moment(get(parent, 'endTime', ''), 'LT');
							const isEndTimeValid = et.isAfter(st);
							if (isEmpty(value)) {
								return Yup.string().required(translate('common_required'));
							}
							if (!isEndTimeValid) {
								return Yup.string().required(
									translate('consultant_error_end_time_should_be_greater_than_start_time')
								);
							}
						}),
						endTime: Yup.string().required(translate('common_required')),
					})
				),
				otherwise: Yup.array().of(Yup.object().notRequired()),
			}),
		})
	),
	timezone: Yup.string().required(translate('consultant_error_time_zone_required')),
});
