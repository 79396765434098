import AxiosDefault from "../../services/AxiosDefault";

export const GetChatMessages = async (id) => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/consultant/consultation/chat/message/get/${id}`,
  });
  return response;
};
export const PostMessageAdd = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/consultant/consultation/chat/message/add`,
    data: data,
  });
  return response;
};
export const PostFileUpload = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/consultant/consultation/chat/document/add`,
    data: data,
    contentType: "multipart/form-data",
  });
  return response;
};
