import MuiDrawer from '@mui/material/Drawer';
import { alpha, styled } from '@mui/material/styles';

const DRAWER_WIDTH = 240;
const openedMixin = (theme) => ({
	overflowX: 'hidden',
	width: DRAWER_WIDTH,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.easeInOut,
		duration: theme.transitions.duration.complex,
	}),
	[theme.breakpoints.down('sm')]: {
		width: '100%',
		backgroundColor: theme.palette.primary.lighter,
	},
});
const closedMixin = (theme) => ({
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.easeInOut,
		duration: theme.transitions.duration.complex,
	}),
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8.2)} + 1px)`,
	},
	[theme.breakpoints.down('sm')]: {
		width: 'calc(0px + 1px)',
		backgroundColor: theme.palette.primary.lighter,
	},
});

const getResizeStyle = (open, theme) => {
	const style = open ? openedMixin(theme) : closedMixin(theme);

	return {
		...style,
		'& .MuiDrawer-paper': {
			...style,
			backgroundColor: 'initial',
			border: 'none',
		},
	};
};
const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	backgroundColor: alpha(theme.palette.primary.light, 0.1),
	width: DRAWER_WIDTH,
	minHeight: '100vh',
	flexShrink: 0,
	whiteSpace: 'nowrap',
	...getResizeStyle(open, theme),
	[theme.breakpoints.down('sm')]: {
		position: 'absolute',
		width: 'calc(0px + 1px)',
	},
}));

export default Drawer;
