import React, { useState } from 'react';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import { get, size } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { getConsultantInfo, postConsultantInfo } from '../../../../../store/slice/auth/signup/consultantInfoSlice';
import translate from '../../../../hooks/translate';
import ShowModalInfo from '../ShowModalInfo';
import AddSocialMediaLinksModal from './AddSocialMediaLinksModal';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { toast } from 'react-toastify';

const SocialMediaInfo = (props) => {
	const { handleNext, handleBack, consultantInfo, buttonLabel, isEdit } = props;
	const [isSubmit, setIsSubmit] = useState(false);
	const dispatch = useDispatch();
	const store = consultantInfo;
	const consultantData = store.data;
	const socialMediaDataLenght = size(useSelector((state) => state.common.socialMediaChanelList));
	const Schema = Yup.object().shape({});

	const handleSocialMediaInfo = async (payload) => {
		const res = await dispatch(postConsultantInfo(payload))
			.unwrap()
			.then((result) => {
				if (result.status) {
					!isEdit && handleNext(4);
					dispatch(getConsultantInfo());
					if (isEdit) {
						toast.success(result.message);
					}
				}
			})
			.catch((err) => {
				console.log(' basic err', err);
			});
		return res;
	};

	return (
		<>
			<Box>
				<Formik
					initialValues={{}}
					validationSchema={Schema}
					onSubmit={async (values) => {
						setIsSubmit(true);
						const payload = {
							step: isEdit ? get(consultantData, 'step', 4) : 4,
						};
						socialMediaDataLenght > 0 && (await handleSocialMediaInfo(payload));
					}}
				>
					{(props) => (
						<form onSubmit={props.handleSubmit}>
							{/* {console.log("SocialMediaInfo props", props)} */}
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography component='h4' color='text.primary' fontWeight='500' mb='10px'>
										{translate('consultant_social_media_links')}
									</Typography>
									{size(consultantData.socialMediaInfo) > 0 &&
										consultantData.socialMediaInfo.map((item, index) => {
											let iconComponent;
											if (item.socialMediaChanel === 'Twitter') {
												iconComponent = <TwitterIcon fontSize='small' />;
											} else if (item.socialMediaChanel === 'Facebook') {
												iconComponent = <FacebookIcon fontSize='small' />;
											} else if (item.socialMediaChanel === 'LinkedIn') {
												iconComponent = <LinkedInIcon fontSize='small' />;
											} else if (item.socialMediaChanel === 'Instagram') {
												iconComponent = <InstagramIcon fontSize='small' />;
											} else if (item.socialMediaChanel === 'YouTube') {
												iconComponent = <YouTubeIcon fontSize='small' />;
											} else {
												iconComponent = <BusinessCenterOutlinedIcon fontSize='small' />;
											}
											return (
												<ShowModalInfo
													key={index}
													icon={iconComponent}
													title={`${translate('common_follow_me_on')} ${item.socialMediaChanel}`}
													link={item.chanelLink}
													item={item}
													sectionName='socialMediaInfo'
												/>
											);
										})}
									<AddSocialMediaLinksModal
										showButton={true}
										socialMediaInfo={consultantData.socialMediaInfo}
									/>
									{/* <Divider light /> */}
									{!(socialMediaDataLenght > 0) && isSubmit && (
										<FormHelperText error>
											{translate('consultant_error_social_media_links_required')}
										</FormHelperText>
									)}
								</Grid>

								{!isEdit && (
									<Grid item xs={12}>
										<Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
											<Button type='button' variant='outlined' color='primary' onClick={handleBack}>
												{translate('common_btn_back')}
											</Button>
											<Stack direction='row' spacing={1} sx={{ ml: 'auto' }}>
												<Button type='submit' variant='contained' disabled={props.isSubmitting}>
													{buttonLabel}
												</Button>
												{/* <Button variant='text'onClick={() => {handleNext(6);}}>
												Skip
											</Button> */}
											</Stack>
										</Stack>
									</Grid>
								)}
							</Grid>
						</form>
					)}
				</Formik>
			</Box>
		</>
	);
};

export default SocialMediaInfo;
