import React from 'react';
import Page from '../../components/common/Page';
import PageHeader from '../../components/common/PageHeader';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';

const CalendarPage = () => {
	return (
		<>
			<Page title='Calendar | InspireMind'>
				<Box component='section' className='w-100'>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<PageHeader title='common_calendar' variant='outlined' elevation={0} />
						</Grid>
						<Grid item xs={12}>
							<Outlet />
						</Grid>
					</Grid>
				</Box>
			</Page>
		</>
	);
};

export default CalendarPage;
