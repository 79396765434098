import React, { useEffect } from 'react';

import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';

import { useDispatch, useSelector } from 'react-redux';

import { getConsultantData } from '../../../../../store/slice/auth/signup/consultantInfoSlice';
import { getSocialMediaChanel } from '../../../../../store/slice/common';
import SocialMediaInfo from '../../../../auth/signup/consultant-info/social-media/SocialMediaInfo';

const ProfileSocialMedia = () => {
	const dispatch = useDispatch();
	const consultantData = useSelector(getConsultantData);
	const loading = useSelector((state) => state.common.loading);

	useEffect(() => {
		dispatch(getSocialMediaChanel({ sectionName: 'SOCIAL_MEDIA_CHANNELS' }));
	}, [dispatch]);

	return (
		<Card>
			<CardContent>
				{loading ? (
					<LinearProgress />
				) : (
					<SocialMediaInfo consultantInfo={consultantData} buttonLabel='Submit' isEdit={true} />
				)}
			</CardContent>
		</Card>
	);
};

export default ProfileSocialMedia;
