import React, { useEffect, useRef, useState } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import socket from '../../../../../../context/Socket';
import Typography from '@mui/material/Typography';

const InputProps = {
	sx: { p: 2, borderRadius: 0, '&:after': { display: 'none' }, '&:before': { display: 'none' } },
};

export default function NoteContainer({ note, chatId }) {
	const [state, setState] = useState('');
	const [noteStatus, setNoteStatus] = useState('');
	const timer = useRef();

	useEffect(() => {
		return () => {
			if (timer.current) {
				clearTimeout(timer.current);
			}
		};
	}, []);

	useEffect(() => {
		if (!note) return;
		setState(note);
	}, [note]);

	const handleChange = (e) => {
		const value = e.target.value;
		setState(value);
		setNoteStatus('Typing...');

		if (timer.current) {
			timer.current = clearTimeout(timer.current);
		}
		timer.current = setTimeout(() => {
			setNoteStatus('Saving...');
			const payload = { chatId, note: value };

			socket.send('note', payload, (value) => {
				setNoteStatus('');
			});

			timer.current = null;
		}, 1000);
	};

	return (
		<Card sx={{ bgcolor: 'background.paper' }}>
			<Box p={2} display='flex' justifyContent='space-between' alignItems='center'>
				<Typography>Note</Typography>
				<Typography variant='caption' color='text.secondary'>
					{noteStatus}
				</Typography>
			</Box>
			<Divider />
			<TextField
				multiline
				rows={8}
				placeholder='Notes...'
				variant='filled'
				InputProps={InputProps}
				value={state}
				onChange={handleChange}
			/>
		</Card>
	);
}
