import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import ListIcon from '@mui/icons-material/List';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Iconify from '../../common/Iconify';

import { debounce, get, isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getContent } from '../../../store/slice/dashboard/contentSlice';
import DataNotFound from '../../common/DataNotFound';
import ArticleCardSkeleton from '../../ui-components/skeleton/ArticleCardSkeleton';
import ContentFilter from './components/ContentFilter';
import ContentSearch from './components/ContentSearch';
import ContentTypes from './components/ContentTypes';
import ContentCustomPagination from './components/ContentCustomPagination';
import translate from '../../hooks/translate';
import ContentBlogListRender from './ContentBlogListRender';
import TableSkeleton from '../../ui-components/skeleton/TableSkeleton';
import EventSubject from '../../../services/EventSubject';

const ContentBlogPage = () => {
	const [viewType, setViewType] = useState('gallery');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [filterOptions, setFilterOptions] = useState({
		pageNo: 0,
		type: 'ALL',
		search: '',
		sortBy: '',
	});

	const contentList = useSelector((state) => state.contentData.contentList);
	const contentLoading = useSelector((state) => state.contentData.loading);
	const userId = useSelector((state) => state.auth.meInfo._id);

	const getContentData = useCallback(
		async (data) => {
			const payload = {
				pageNo: get(data, 'pageNo', 0),
				type: get(data, 'type', ''),
				sortBy: get(data, 'sortBy', ''),
				search: get(data, 'search', ''),
				silentFetch: get(data, 'silentFetch', false),
			};
			dispatch(getContent(payload));
		},
		[dispatch]
	);
	const getContentDataDebounce = useMemo(() => debounce(getContentData, 500), [getContentData]);
	useEffect(() => {
		const payload = {
			pageNo: 0,
			type: 'ALL',
			search: '',
			sortBy: '',
		};
		getContentData(payload);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const subscription = EventSubject.subscribe((data) => {
			if (data.tag !== userId) return;
			if (data.target !== 'content') return;
			if (data.action === 'update-list') {
				getContentDataDebounce({
					...filterOptions,
					silentFetch: true,
				});
			}
		});

		return () => {
			subscription.unsubscribe();
		};
	}, [userId, getContentDataDebounce, filterOptions]);

	const handleChangeView = (_, newView) => {
		if (newView !== null) {
			setViewType(newView);
		}
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<ContentSearch
					getContentData={getContentData}
					setFilterOptions={setFilterOptions}
					filterOptions={filterOptions}
				/>
			</Grid>

			<Grid item xs={12}>
				<ContentTypes getContentData={getContentData} setFilterOptions={setFilterOptions} filterOptions={filterOptions} />
			</Grid>

			<Grid item xs={12}>
				<Stack
					direction={{ sm: 'row', xs: 'column' }}
					spacing={1}
					sx={{ justifyContent: { xs: 'end', sm: 'space-between' }, alignItems: { xs: 'end', sm: 'center' } }}
				>
					<Button
						variant='contained'
						color='primary'
						startIcon={<AddCircleIcon />}
						onClick={() => navigate('/dashboard/content/add-content')}
					>
						{translate('consultant_add_content')}
					</Button>
					<Stack
						width={{ xs: '100%', sm: 'auto' }}
						gap={1}
						flexDirection='row'
						alignItems='center'
						justifyContent='flex-end'
					>
						<ContentFilter
							getContentData={getContentData}
							setFilterOptions={setFilterOptions}
							filterOptions={filterOptions}
						/>
						<ToggleButtonGroup size='small' value={viewType} exclusive onChange={handleChangeView}>
							<ToggleButton value='list'>
								<ListIcon fontSize='small' />
							</ToggleButton>

							<ToggleButton value='gallery'>
								<Iconify icon='mingcute:dot-grid-fill' width={18} sx={{ height: 18 }} />
							</ToggleButton>
						</ToggleButtonGroup>
					</Stack>
				</Stack>
			</Grid>

			{contentLoading ? (
				viewType === 'list' ? (
					<Grid item xs={12}>
						<TableSkeleton />
					</Grid>
				) : (
					map([1, 2, 3, 4], (data, index) => (
						<Grid item xs={12} md={6} sm={6} lg={4} xl={3} key={index}>
							<ArticleCardSkeleton />
						</Grid>
					))
				)
			) : null}
			{!contentLoading && isEmpty(contentList) && <DataNotFound />}
			{!contentLoading && <ContentBlogListRender contentList={contentList} viewType={viewType} />}

			{viewType === 'gallery' && (
				<Grid item xs={12}>
					{isEmpty(contentList) && !contentLoading ? null : (
						<ContentCustomPagination
							getContentData={getContentData}
							setFilterOptions={setFilterOptions}
							filterOptions={filterOptions}
						/>
					)}
				</Grid>
			)}
		</Grid>
	);
};

export default ContentBlogPage;
