import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetFaqInfo } from "../../../../api/signup";
import { get } from "lodash";

const initialState = {
  faqData: [],
  faqStep: 0,
  loading: false,
};

export const getFaqInfo = createAsyncThunk("faq/getFaqInfo", async (type) => {
  try {
    const response = await GetFaqInfo(type);
    return response.data;
  } catch (error) {
    return { status: false, message: error.response.data.message };
  }
});

export const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    incrementFaqStep: (state) => {
      state.faqStep += 1;
    },
    decrementFaqStep: (state) => {
      state.faqStep -= 1;
    },
    incrementStepByAmount: (state, action) => {
      state.faqStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFaqInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFaqInfo.fulfilled, (state, { payload }) => {
        state.faqData = get(payload, "data", []);
        state.loading = false;
      })
      .addCase(getFaqInfo.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const getFaqData = (state) => state.faq;
export const { incrementFaqStep, decrementFaqStep, incrementStepByAmount } =
  faqSlice.actions;
export default faqSlice.reducer;
