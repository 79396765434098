import { Suspense, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';

import AppRoutes from './Routes';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

import 'swiper/css';
import 'swiper/css/bundle';
import 'react-lazy-load-image-component/src/effects/blur.css';

import './styles/App.css';
import './styles/utility.css';
import './styles/CustomAnimation.css';
import useContextData from './components/hooks/useContextData';
import socket from './context/Socket';
import cookie from './components/hooks/cookie';

function App() {
	const LanguageMacro = cookie.get('LanguageMacro');
	const { LanguageLoader, SetLanguageMacros, SetLanguageList } = useContextData();
	const translateLanguageMacros = localStorage.getItem('translateLanguageMacros');

	const LanguageList = localStorage.getItem('LanguageList');

	useEffect(() => {
		const userData = cookie.get('consultant_data');
		const token = get(userData, 'token', null);
		if (token) {
			socket.connect(token);
		}
	}, []);

	useEffect(() => {
		if (isEmpty(translateLanguageMacros) || isEmpty(LanguageMacro)) {
			SetLanguageMacros('');
			// getAppLanguageMacros("");
		}
		// setLanguageLoader(false);
	}, [SetLanguageMacros, translateLanguageMacros, LanguageMacro]);

	useEffect(() => {
		if (isEmpty(LanguageList)) {
			SetLanguageList();
		}
	}, [SetLanguageList, LanguageList]);

	return (
		<Suspense
			fallback={
				<Backdrop
					sx={{
						color: 'primary.main',
						backgroundColor: 'rgba(0, 0, 0, 0.2)',
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={LanguageLoader}
				>
					<CircularProgress color='inherit' />
				</Backdrop>
			}
		>
			<ToastContainer position='top-right' autoClose={5000} pauseOnFocusLoss draggable pauseOnHover newestOnTop limit={2} />
			<CssBaseline />
			<AppRoutes />
		</Suspense>
	);
}

export default App;
