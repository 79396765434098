import * as React from 'react';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HouseIcon from '@mui/icons-material/House';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { Link as RouterLink, useLocation } from 'react-router-dom';
import translate from '../../components/hooks/translate';

const breadcrumbNameMap = {
	'/dashboard': 'common_dashboard',

	'/dashboard/profile': 'common_profile',
	'/dashboard/profile/basicinfo': 'common_basic_information',
	'/dashboard/profile/residential-address': 'consultant_residential_address',
	'/dashboard/profile/professional-domain': 'consultant_professional_domain',
	'/dashboard/profile/professional-info': 'consultant_professional_information',
	'/dashboard/profile/license-or-certification': 'consultant_licence_or_certification',
	'/dashboard/profile/social-media': 'consultant_social_media_information',

	'/dashboard/inspiremind-profile': 'consultant_inspiremind_profile',

	'/dashboard/service-plans': 'consultant_service_plan',

	'/dashboard/article-blog': 'common_blogs',
	'/dashboard/article-blog/add-blog': 'consultant_add_article_or_blog',
	'/dashboard/article-blog/blog-details': 'common_blogs_details',

	'/dashboard/content': 'common_content',
	'/dashboard/content/add-content': 'consultant_add_content',
	'/dashboard/content/content-details': 'common_content_details',

	// '/dashboard/event': 'common_event',
	// '/dashboard/event/add-event': 'common_add_event',

	'/dashboard/health-food/recipe': 'Health Recipe',
	'/dashboard/health-food/create-recipe': 'Create Recipe',

	'/dashboard/appointment': 'common_appointment',
	'/dashboard/appointment/service-type': 'consultant_service_type',

	'/dashboard/banking': 'consultant_banking',
	'/dashboard/banking/account-status': 'consultant_account_status',

	'/dashboard/q-and-a-template': 'consultant_q_and_a_template',
	'/dashboard/q-and-a-template/list': 'List',
	'/dashboard/q-and-a-template/create': 'Create',
	'/dashboard/q-and-a-template/update': 'Update',

	'/dashboard/booking': 'consultant_booking',
	'/dashboard/booking/detail/:bookingId': 'consultant_booking_details',
	'/dashboard/booking/q-and-a-detail': 'consultant_q_and_a_detail',

	'/dashboard/settings': 'common_settings',
};

const LinkRouter = (props) => <Link variant='body2' {...props} component={RouterLink} />;

const PathBreadcrumbs = () => {
	const location = useLocation();
	const pathnames = location.pathname.split('/').filter((x) => x);

	return (
		<Breadcrumbs
			aria-label='breadcrumb'
			separator={<FiberManualRecordIcon sx={{ fontSize: '5px', color: 'text.primary' }} />}
			maxItems={2}
		>
			{/* <LinkRouter
        underline="hover"
        color="secondary"
        to="/"
        className="d-inline-block"
      > */}
			<HouseIcon className='align-top' sx={{ fontSize: '20px' }} />
			{/* </LinkRouter> */}
			{pathnames.map((value, index) => {
				const last = index === pathnames.length - 1;
				const to = `/${pathnames.slice(0, index + 1).join('/')}`;
				return last ? (
					<Typography variant='body2' color='primary' key={to} className='fw-bold'>
						{translate(breadcrumbNameMap[to])}
					</Typography>
				) : (
					<LinkRouter underline='hover' color='inherit' to={to} key={to}>
						{translate(breadcrumbNameMap[to])}
					</LinkRouter>
				);
			})}
		</Breadcrumbs>
	);
};

export default PathBreadcrumbs;
