import axios from 'axios';
import cookie from '../../components/hooks/cookie';

export function errorInterceptor() {
	axios.interceptors.response.use(null, (error) => {
		const { response } = error;
		if (!response) {
			return {};
		}

		if (response.status === 401) {
			try {
				const expiryTime = new Date(new Date().getTime() - 100000);
				cookie.remove('consultant_data');
				cookie.remove('isSignupCompleted');
				cookie.set('consultant_data', {}, { expires: expiryTime });
				cookie.set('isSignupCompleted', false, { expires: expiryTime });
				window.location.replace('/');
				window.location = '/';
			} catch (e) {
				return e;
			}
		}

		return response;
	});
}
