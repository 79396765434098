/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, Typography } from '@mui/material';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { jobTypes } from '../config';

export default function JobListItem({ data, removeJob }) {
	return (
		<Box
			width='100%'
			maxWidth='500px'
			minWidth='400px'
			display='flex'
			gap={1}
			alignItems='flex-start'
			padding={1}
			position='relative'
			sx={{
				'&:hover': {
					'& .close-icon': {
						display: 'block',
					},
				},
			}}
		>
			<Box
				position='absolute'
				top={0}
				bottom={0}
				left={0}
				right={`${100 - data.value}%`}
				bgcolor='rgba(110, 231, 183,0.4)'
				zIndex={-1}
			/>
			<div>
				<Box
					width={{ xs: 20, md: 40, lg: 50 }}
					height={{ xs: 20, md: 40, lg: 50 }}
					borderRadius='50%'
					padding='10px'
					sx={{ bgcolor: 'rgb(147 197 253 / 37%)', color: 'primary.main' }}
					display='flex'
					alignItems='center'
					justifyContent='center'
				>
					{data.jobType === jobTypes.BLOG_CREATE && <ArticleTwoToneIcon />}
				</Box>
			</div>
			<Box flex={1} py={0.5} overflow='hidden'>
				<Typography
					variant='body1'
					fontWeight='600'
					display='-webkit-box'
					overflow='hidden'
					sx={{ WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}
				>
					{data.title}
				</Typography>
				<Typography variant='caption' component='p' pl='2px'>
					{data.content}
				</Typography>
			</Box>
			<Box
				position='absolute'
				top='50%'
				right='10px'
				height='20px'
				sx={{ transform: 'translateY(-50%)' }}
				onClick={() => removeJob(data.id)}
				display='none'
				className='close-icon'
			>
				<AiOutlineCloseCircle fontSize='20px' />
			</Box>
		</Box>
	);
}
