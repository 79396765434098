/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';

import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { Formik } from 'formik';
import { cloneDeep, get, groupBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { getCurrencyList, getGoalType, getTimeZone } from '../../../store/slice/common';
import { getServicePlan } from '../../../store/slice/dashboard/servicePlanSlice';
import translate from '../../hooks/translate';
import RichTextEditor from '../../ui-components/editor/RichTextEditor';
import CMRadio from '../../ui-components/form-components/CMRadio';
import { Alert, Box } from '@mui/material';
import { Drawer } from '@mui/material';
import '../service-plans/plans/RightModal.css';
import Calendar from './Calendar';
import moment from 'moment-timezone';
// import moment from "moment";
import { getMeInfo } from '../../../store/slice/auth/authentication/authSlice';
import { postAppointmentAdd } from '../../../store/slice/dashboard/appointmentSlice';
import AxiosDefaultSetting from '../../../services/AxiosDefault';
import { isDateToday } from '../../../utils/day';

const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';

const AppointmentAdd = (props) => {
	const { open, onClose } = props;
	const dispatch = useDispatch();
	const [slotList, setSlotList] = useState({});
	const consultantInfo = useSelector((state) => state.consultantInfo.data);
	const [selectedPlanData, setSelectedPlanData] = useState([]);
	const [servicePlanName, setServicePlanName] = useState('');
	const [planId, setPlanId] = useState(null);
	const [timeZone, setTimeZone] = useState('');
	const [availableDate, setAvailableDate] = useState(null);

	const serviceTypes = useSelector((state) => state.servicePlan.serviceTypes);
	const goalTypeList = useSelector((state) => state.common.goalType);

	const serviceTypeOnline = get(serviceTypes, 'serviceTypeOnline', false);
	const serviceTypeOffline = get(serviceTypes, 'serviceTypeOffline', false);
	const serviceTypePhone = get(serviceTypes, 'serviceTypePhone', false);

	const timeZoneList = useSelector((state) => state.common.timeZoneList);
	const meInfo = useSelector((state) => state.auth.meInfo);

	useEffect(() => {
		dispatch(getGoalType({ sectionName: 'GOAL_TYPE' }));
		dispatch(getCurrencyList());
		dispatch(getTimeZone());
		dispatch(getMeInfo());
	}, []);

	const addAppointmentSchema = useMemo(
		() =>
			Yup.object().shape({
				email: Yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
				planName: Yup.string().required('Please select service plan'),
				userTimezone: Yup.string().required('Please select time zone'),
				consultationDate:
					!planId && Yup.string('Please select service plan to get appointment').required('please select date'),
				description: Yup.string().required(translate('consultant_error_description_required')),
				goalType: Yup.object()
					.shape({
						macroName: Yup.string().required('GoalType is Required'),
						_id: Yup.string().required('GoalType is Required'),
					})
					.required('GoalType is Required!'),
			}),
		[planId]
	);

	const handleAddServicePlan = async (payload, resetForm) => {
		const res = await dispatch(postAppointmentAdd(payload))
			.unwrap()
			.then((result) => {
				if (result.status) {
					dispatch(getServicePlan());
					onClose();
					toast.success(result.message);
				} else {
					toast.error(result.message);
				}
			})
			.catch((err) => {});
		return res;
	};

	const handleConsultationType = (value) => {
		if (!value) return;

		setServicePlanName(value);

		//for get service plan data
		dispatch(getServicePlan())
			.then((result) => {
				const servicePlan = result?.payload?.data;
				setSelectedPlanData(servicePlan?.filter((plan) => Boolean(plan[value])));
			})
			.catch(() => {});
	};

	useEffect(() => {
		async function getList(data) {
			try {
				const response = await AxiosDefaultSetting({
					method: 'POST',
					url: `/customer/consultation/booking/slots`,
					data,
				});
				if (Array.isArray(response.data)) {
					const groupList = groupBy(response.data, (e) => moment(e.startTime).format('DD/MM/YYYY'));
					setSlotList(groupList);
				}
			} catch (error) {}
		}

		setSlotList({});

		let queryDate = moment(availableDate);
		let queryEndDate = moment(availableDate);
		if (isDateToday(availableDate)) {
			queryDate = moment().utc().format(dateFormat);
			queryEndDate = moment().endOf('day').utc().format(dateFormat);
		} else {
			queryDate = moment(availableDate).utc().format(dateFormat);
			queryEndDate = moment(availableDate).endOf('day').utc().format(dateFormat);
		}

		console.log(planId, meInfo?._id, availableDate, timeZone);
		console.log(planId && meInfo?._id && availableDate && timeZone);
		if (planId && meInfo?._id && availableDate && timeZone) {
			const data = {
				date: queryDate,
				endDate: queryEndDate,
				userTimezone: timeZone,
				consultantId: meInfo?._id,
				planId: planId,
			};
			getList(data);
		}
	}, [availableDate, planId, meInfo?._id, timeZone]);

	return (
		<Drawer anchor='right' className='service-plan-add-container' open={open} onClose={onClose}>
			<div>
				<Stack direction='row' gap={1} py={2} px={{ xs: 2, md: 3 }} alignItems='center' justifyContent='space-between'>
					<IconButton size='small' color='primary' onClick={onClose} sx={{ minWidth: 0 }}>
						<KeyboardArrowLeftRoundedIcon />
					</IconButton>

					<Typography variant='h6'>Create Appointment</Typography>
				</Stack>
				<Divider sx={{ borderStyle: 'dashed' }} />
			</div>

			{!isEmpty(consultantInfo) && (
				<Formik
					initialValues={{
						eventName: `InspireMind appointment with ${meInfo?.firstName} ${meInfo?.lastName}`,
						email: '',
						serviceType: '',
						planName: '',
						description: '',
						userTimezone: consultantInfo?.timezone,
					}}
					validationSchema={addAppointmentSchema}
					onSubmit={(values, { resetForm }) => {
						const data = cloneDeep(values);
						data['startTime'] = data?.slot?.startTime;
						data['endTime'] = data?.slot?.endTime;
						data['planId'] = data?.planName;
						data['consultantId'] = meInfo?._id;
						data['goalType'] = data?.goalType?._id;
						delete data?.slot;
						delete data?.planName;
						delete data?.description_content_len;

						if (data) {
							handleAddServicePlan(data);
							// resetForm(data);
							setServicePlanName('');
						}
					}}
				>
					{(props) => (
						<form onSubmit={props.handleSubmit} className='d-flex flex-column overflow-auto'>
							<DialogContent dividers sx={{ px: { xs: 2, md: 3 }, pt: 2 }}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<TextField
											disabled
											label={translate('consultant_event_name')}
											variant='outlined'
											name='eventName'
											value={props.values.eventName}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
										/>
									</Grid>
									<Grid item sm={6} xs={12}>
										<TextField
											label={translate('common_email')}
											name='email'
											value={props.values.email}
											onChange={props.handleChange}
											onBlur={(e) => {
												props.handleBlur(e);
											}}
											error={props.errors.email && props.touched.email ? true : false}
											helperText={props.errors.email && props.touched.email ? props.errors.email : null}
										/>
									</Grid>

									<Grid item sm={6} xs={12}>
										<FormControl component='fieldset' variant='standard'>
											<FormLabel>{translate('consultant_consultation_type')}</FormLabel>
											<FormGroup row>
												<FormControlLabel
													value='online'
													control={
														<Checkbox
															name='serviceType'
															value='onlineConsultation'
															checked={servicePlanName === 'onlineConsultation' ? true : false}
															onChange={(e) => {
																props.setFieldValue('serviceType', e.target.value);
																handleConsultationType('onlineConsultation');
															}}
														/>
													}
													disabled={!serviceTypeOnline}
													label={translate('common_online')}
												/>
												<FormControlLabel
													control={
														<Checkbox
															name='serviceType'
															value='offlineConsultation'
															checked={servicePlanName === 'offlineConsultation' ? true : false}
															onChange={(e) => {
																props.setFieldValue('serviceType', e.target.value);
																handleConsultationType('offlineConsultation');
															}}
														/>
													}
													disabled={!serviceTypeOffline}
													label={translate('common_offline')}
												/>
												<FormControlLabel
													control={
														<Checkbox
															name='serviceType'
															value='phoneConsultation'
															checked={servicePlanName === 'phoneConsultation' ? true : false}
															onChange={(e) => {
																props.setFieldValue('serviceType', e.target.value);
																handleConsultationType('phoneConsultation');
															}}
														/>
													}
													disabled={!serviceTypePhone}
													label={translate('common_phone')}
												/>
											</FormGroup>
											{props.errors.onlineConsultation && props.touched.onlineConsultation && (
												<FormHelperText error>Type is Required</FormHelperText>
											)}
										</FormControl>
									</Grid>

									<Grid item sm={6} xs={12}>
										<Autocomplete
											getOptionLabel={(option) => option.planName ?? option}
											disabled={servicePlanName ? false : true}
											options={selectedPlanData || []}
											name='planName'
											isOptionEqualToValue={(option, value) => option._id === value._id}
											value={props?.values?._id}
											onChange={(_event, value) => {
												props.setFieldValue('planName', value?._id);
												setPlanId(value?._id);
											}}
											autoHighlight={true}
											disableClearable={false}
											noOptionsText={translate('common_no_data')}
											popupIcon={<KeyboardArrowDownSharpIcon />}
											renderOption={(props, option) => (
												<Box
													key={option.planName}
													component='li'
													sx={{ '& > img': { mr: 1, flexShrink: 0 } }}
													{...props}
												>
													<Box component='span' mr={1}>
														{option.planName}
													</Box>
												</Box>
											)}
											renderInput={(params) => (
												<TextField
													{...params}
													label={translate('consultant_service_plan')}
													name='planName'
													value={props.values.planName}
													onBlur={props.handleBlur}
													error={props.errors.planName && props.touched.planName ? true : false}
													helperText={
														props.errors.planName && props.touched.planName
															? props.errors.planName
															: null
													}
												/>
											)}
										/>
									</Grid>

									<Grid item sm={6} xs={12}>
										<Autocomplete
											options={timeZoneList || []}
											name='userTimezone'
											value={props.values.userTimezone}
											getOptionLabel={(option) => {
												return option.zoneName ?? option;
											}}
											isOptionEqualToValue={(option, value) => {
												if (value === '' || option.zoneName === (value?.zoneName || value)) {
													setTimeZone(value?.zoneName || value);
													return true;
												}
											}}
											onChange={(e, value) => {
												props.setFieldValue('userTimezone', value?.zoneName);
												// setTimeZone(value?.zoneName || value);
											}}
											autoHighlight={true}
											disableClearable={false}
											noOptionsText={translate('common_no_data')}
											popupIcon={<KeyboardArrowDownSharpIcon />}
											renderOption={(props, option) => (
												<Box
													key={option.zoneName}
													component='li'
													sx={{ '& > img': { mr: 1, flexShrink: 0 } }}
													{...props}
												>
													<Box component='span' mr={1}>
														{option.zoneName}
													</Box>
												</Box>
											)}
											renderInput={(params) => (
												<TextField
													{...params}
													name='userTimezone'
													label={translate('consultant_select_time_zone')}
													value={props.values.userTimezone}
													onBlur={props.handleBlur}
													error={props.errors.userTimezone && props.touched.userTimezone ? true : false}
													helperText={
														props.errors.userTimezone && props.touched.userTimezone
															? props.errors.userTimezone
															: null
													}
												/>
											)}
										/>
									</Grid>
									<Grid item sm={6} xs={12}>
										<Autocomplete
											options={goalTypeList || []}
											name='goalType'
											value={props.values.goalType}
											getOptionLabel={(option) => translate(option.macroName)}
											isOptionEqualToValue={(option, value) => option._id === value._id}
											onChange={(e, value) => {
												props.setFieldValue('goalType', {
													_id: value?._id,
													macroName: value?.macroName,
												});
											}}
											autoHighlight={true}
											disableClearable={false}
											noOptionsText={translate('common_no_data')}
											popupIcon={<KeyboardArrowDownSharpIcon />}
											renderOption={(props, option) => (
												<Box
													key={option._id}
													component='li'
													sx={{ '& > img': { mr: 1, flexShrink: 0 } }}
													{...props}
												>
													<Box component='span' mr={1}>
														{translate(option.macroName)}
													</Box>
												</Box>
											)}
											renderInput={(params) => (
												<TextField
													{...params}
													name='goalType'
													label={translate('consultant_select_goal_type')}
													value={props.values.goalType}
													onBlur={props.handleBlur}
													error={props.errors.goalType && props.touched.goalType ? true : false}
													helperText={
														props.errors.goalType && props.touched.goalType
															? props.errors.goalType.macroName
															: null
													}
												/>
											)}
										/>
									</Grid>
									<Grid item sm={6} xs={12} />

									<Grid item sm={6} md={5} xs={12}>
										<Calendar
											setAvailableDate={setAvailableDate}
											formikProps={props}
											planId={planId}
											availableDate={availableDate}
										/>
									</Grid>

									<Grid item sm={6} md={7} xs={12}>
										{!planId && availableDate !== null && (
											<Alert severity='warning'>
												To reserve an appointment slot, please first select a service plan.
											</Alert>
										)}
										{planId && slotList && availableDate && (
											<RadioGroup
												row
												sx={{
													marginLeft: '10px',
													marginTop: '10px',
													justifyContent: 'start',
												}}
											>
												{Object.keys(slotList).map((key) => (
													<Box key={key} mb={2}>
														<Typography variant='subtitle1'>{key}</Typography>
														<Divider sx={{ mb: 1 }} />
														<Box display='flex' gap={1} flexWrap='wrap'>
															{slotList[key].map((slot, index) => (
																<FormControlLabel
																	key={index}
																	value={JSON.stringify(slot)}
																	name='slot'
																	onChange={() => props.setFieldValue('slot', slot)}
																	control={<CMRadio fontWeight='bold' align='center' />}
																	label={slot.title}
																	sx={{ mx: 0 }}
																/>
															))}
														</Box>
													</Box>
												))}
											</RadioGroup>
										)}
									</Grid>

									<Grid item xs={12}>
										<RichTextEditor
											name='description'
											value={props.values.description}
											setFieldValue={props.setFieldValue}
											error={props.errors.description && props.touched.description ? true : false}
											helperText={
												props.errors.description && props.touched.description
													? props.errors.description
													: null
											}
										/>
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions sx={{ px: { xs: 2, md: 3 }, py: 2 }}>
								<Button variant='contained' type='submit'>
									{translate('common_create_appoinment')}
								</Button>
							</DialogActions>
						</form>
					)}
				</Formik>
			)}
		</Drawer>
	);
};

export default AppointmentAdd;
