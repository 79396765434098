/** @format */

import React, { useEffect, useState } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Button from '@mui/material/Button';
import { gapi } from 'gapi-script';
import { GoogleLogin } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addOnlineService } from '../../../../../store/slice/dashboard/appointmentSlice';
import translate from '../../../../hooks/translate';
import { getMeInfo } from '../../../../../store/slice/auth/authentication/authSlice';
import { Box, CircularProgress, Typography } from '@mui/material';
import { changeServicePlanTypeStatus } from '../../../../../store/slice/dashboard/servicePlanSlice';
import AxiosDefaultSetting from '../../../../../services/AxiosDefault';
import { getConsultantInfo } from '../../../../../store/slice/auth/signup/consultantInfoSlice';

const GoogleCalendar = ({ refreshData }) => {
	const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
	const meInfo = useSelector((state) => state.auth.meInfo);
	const contentedEmail = useSelector((state) => state.consultantInfo?.data?.contentedEmail);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const isConnected = Boolean(meInfo?.calenderStatus);

	useEffect(() => {
		function start() {
			gapi.client.init({
				clientId: CLIENT_ID,
				scope: 'openid profile email https://www.googleapis.com/auth/calendar',
			});
		}
		gapi.load('client:auth2', start);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleModeChange = (serviceTypeOnline) => {
		const payload = {
			serviceTypeOnline: serviceTypeOnline,
		};
		dispatch(changeServicePlanTypeStatus(payload))
			.unwrap()
			.then((result) => {
				if (!result.status) return;
				toast.success(result.message);
				refreshData();
				dispatch(getMeInfo());
			})
			.catch((error) => {
				console.log('error :>> ', error);
			});
	};

	const onLoginSuccess = async (response) => {
		const { code } = response;
		const payload = { code: code };

		if (!code) return;

		setLoading(true);
		await dispatch(addOnlineService(payload))
			.unwrap()
			.then(async (result) => {
				if (result.status) {
					toast.success(result.message);
					handleModeChange(true);
				} else {
					toast.error(result.message);
				}
			});
		await dispatch(getConsultantInfo())
			.unwrap()
			.then(() => {
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const onFailureSuccess = (response) => {
		toast.error(response);
	};

	const handleDisconnectCalendar = async () => {
		const response = await AxiosDefaultSetting({
			url: `/consultant/auth/revoke-google-token`,
			method: 'POST',
		});
		if (response?.status === 200) {
			window.location.reload();
		} else {
			toast.error('Failed to revoke access token.');
		}
	};

	return (
		<Box>
			<GoogleLogin
				clientId={CLIENT_ID}
				onSuccess={onLoginSuccess}
				onFailure={onFailureSuccess}
				isSignedIn={true}
				render={(renderProps) => (
					<>
						<Button
							onClick={renderProps.onClick}
							variant='contained'
							startIcon={<CalendarMonthIcon />}
							disabled={isConnected || loading}
							type='button'
						>
							{isConnected ? translate('common_already_connected') : translate('common_connect')}
							{loading ? <CircularProgress size={14} sx={{ ml: 2 }} /> : null}
						</Button>
						{isConnected && (
							<Button
								onClick={handleDisconnectCalendar}
								variant='contained'
								color='error'
								style={{ marginLeft: '10px' }}
								startIcon={<CalendarMonthIcon />}
							>
								{translate('common_disconnect')}
							</Button>
						)}
					</>
				)}
				cookiePolicy={'single_host_origin'}
				responseType='code'
				scope='openid email profile https://www.googleapis.com/auth/calendar'
				accessType='offline'
			/>
			<Box mt={4}>
				<Typography variant='body2' fontWeight='500'>
					{isConnected ? (
						<>
							Your
							<Typography variant='span' fontWeight='bold'>
								&nbsp;{contentedEmail}&nbsp;
							</Typography>
							email account is linked to the InspireMind calendar. Click "disconnect" to update or remove the
							account
						</>
					) : (
						'Your email account is not linked to sync with the InspireMind calendar. Click "Connect" to add an account. Please note: Your profile will only become active once you set up your account.'
					)}
				</Typography>
			</Box>
		</Box>
	);
};

export default GoogleCalendar;
