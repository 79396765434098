export const Best_suitedArrList = [
  "Everyone",
  "Complete beginner",
  "Some prior experience is necessary (2 months or more)",
  "Extensive experience necessary (12 months or more)",
];

export const COPYRIGHT_OWN = [
  "Yes",
  "No - someone else does, but they have authorized me to publish it",
  "No - this file does not have restrictions/copyright, so I am free to upload it",
  //   "No - this file does not have restrictions/copyright, so I am free to upload it",
];

export const BEST_SUITED_EXTENSIVEEXPERIENCENECESSARY = [
  "Young Adult",
  "Teenagers",
  "Young children",
  "Infants",
];
