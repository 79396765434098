export const renderFileStyle = {
	ListItem: {
		columnGap: 2,
		padding: '4px',
		borderRadius: '6px',
		flexWrap: 'wrap',
	},
	IconButton: {
		borderRadius: '4px',
		'& :hover': { borderRadius: '4px' },
	},
	ListItemIcon: {
		'&:hover > .avatar-hover-buttons': {
			display: 'inline-flex',
		},
	},
};
