import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import PrivateLayout from './PrivateLayout';
// import AxiosDefaultSetting from '../../services/AxiosDefault';

const Private = () => {
	const location = useLocation();

	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
	const isSignupCompleted = useSelector((state) => state.auth.isSignupCompleted);
	const token = useSelector((state) => state.auth.authData?.token);

	useEffect(() => {
		async function getMessageData() {
			// try {
			// 	const response = await AxiosDefaultSetting({
			// 		url: 'http://localhost:3002/conversation-list',
			// 		method: 'GET',
			// 	});
			// 	if (response.status !== 200) return;
			// 	console.log(response.data);
			// } catch (error) {}
		}

		if (isLoggedIn && isSignupCompleted && token) {
			getMessageData();
		}
	}, [isLoggedIn, isSignupCompleted, token]);

	return isLoggedIn ? (
		!isSignupCompleted ? (
			<Navigate to='/consultant-info' state={{ from: location }} replace />
		) : (
			<PrivateLayout>
				<Outlet />
			</PrivateLayout>
		)
	) : (
		<Navigate to='/login' state={{ from: location }} replace />
	);
};

export default Private;
