import React from 'react';

import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

import { Formik } from 'formik';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { addUnavailability, getUnavailability } from '../../../../store/slice/dashboard/appointmentSlice';
import LeaveList from './LeaveList';
import translate from '../../../hooks/translate';

const schemaObj = Yup.object().shape({
	startDate: Yup.date().nullable().required(translate('consultant_error_start_date_required')),
	endDate: Yup.date().nullable().required(translate('consultant_error_end_date_required')),
});
const initialValues = {
	startDate: '',
	endDate: '',
};
const SetUnavailability = ({ disableForm }) => {
	const dispatch = useDispatch();

	const handleUnavailability = async (data) => {
		const payload = {
			startDate: moment(data.startDate).format(),
			endDate: moment(data.endDate).format(),
			status: true,
		};
		const res = await dispatch(addUnavailability(payload))
			.unwrap()
			.then((result) => {
				if (result.status) {
					toast.success(result.message);
					dispatch(getUnavailability());
				}
			})
			.catch((_) => {});
		return res;
	};

	return (
		<Card className='mt-3'>
			<Formik
				initialValues={initialValues}
				validationSchema={schemaObj}
				onSubmit={(values, { resetForm }) => {
					handleUnavailability(values);
					resetForm();
				}}
			>
				{(props) => (
					<form onSubmit={props.handleSubmit} className='d-flex flex-column overflow-auto'>
						<CardContent>
							<Typography variant='h6' className='mb-3'>
								{translate('consultant_set_unavailability')}
							</Typography>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<MobileDateTimePicker
										label={translate('consultant_start_date_and_time')}
										name='startDate'
										value={props.values.startDate}
										onChange={(newValue) => {
											props.setFieldValue('startDate', newValue);
										}}
										onBlur={props.handleBlur}
										inputFormat='DD/MM/YYYY hh:mm A'
										mask='____/__/__ __:__ _M'
										disablePast
										disabled={disableForm}
										renderInput={(params) => (
											<TextField
												{...params}
												fullWidth
												name='startDate'
												error={props.errors.startDate && props.touched.startDate ? true : false}
												helperText={
													props.errors.startDate && props.touched.startDate
														? props.errors.startDate
														: null
												}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} p={0}>
									<Divider light />
								</Grid>
								<Grid item xs={12}>
									<MobileDateTimePicker
										label={translate('consultant_end_date_and_time')}
										name='endDate'
										value={props.values.endDate}
										onChange={(newValue) => {
											props.setFieldValue('endDate', newValue);
										}}
										onBlur={props.handleBlur}
										disabled={disableForm || isEmpty(props.values.startDate)}
										minDateTime={get(props, 'values.startDate', moment())}
										inputFormat='DD/MM/YYYY hh:mm A'
										mask='____/__/__ __:__ _M'
										disablePast
										renderInput={(params) => (
											<TextField
												{...params}
												fullWidth
												name='endDate'
												error={props.errors.endDate && props.touched.endDate ? true : false}
												helperText={
													props.errors.endDate && props.touched.endDate ? props.errors.endDate : null
												}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12}>
									<Button fullWidth variant='contained' type='submit' disabled={disableForm}>
										{translate('common_add')}
									</Button>
								</Grid>
							</Grid>
							<LeaveList />
						</CardContent>
					</form>
				)}
			</Formik>
		</Card>
	);
};

export default SetUnavailability;
