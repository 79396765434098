import React from 'react';
import Calendar from 'react-awesome-calendar';

const EventCalendar = ({ events, handleDateClick }) => {
	console.log(events);
	return (
		<Calendar
			events={events}
			mode='monthlyMode'
			// onClickEvent={handleDateClick1()}
		/>
	);
};

export default EventCalendar;
