import AxiosDefault from '../../services/AxiosDefault';

export const GetContent = async (data) => {
	const response = await AxiosDefault({
		url: 'consultant/content/get',
		method: 'POST',
		data: data,
	});
	return response;
};

export const ContentArchive = async ({ payload, id }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/consultant/content/archive/${id}`,
		data: payload,
	});
	return response;
};

export const ContentPublish = async ({ payload, id }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/consultant/content/publish/${id}`,
		data: payload,
	});
	return response;
};

export const ContentDelete = async (id) => {
	const response = await AxiosDefault({
		method: 'DELETE',
		url: `/consultant/content/delete/${id}`,
	});
	return response;
};
