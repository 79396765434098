import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(
	({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
			color: theme.palette.common.black,
		},
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.black,
			maxWidth: 300,
			whiteSpace: 'break-spaces',
		},
	})
);

const CMToolTip = ({ icon, title, sx, ...rest }) => {
	const [open, setOpen] = React.useState(false);

	const handleTooltipClose = useCallback(() => {
		setOpen(false);
	}, []);

	const handleTooltipOpen = useCallback(() => {
		setOpen(true);
	}, []);

	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<Box>
				<BootstrapTooltip
					PopperProps={{ disablePortal: true }}
					onClose={handleTooltipClose}
					open={open}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					onMouseLeave={handleTooltipClose}
					title={title}
					arrow
					sx={{ ...sx }}
					{...rest}
				>
					<Box>
						<IconButton onClick={handleTooltipOpen}>{icon}</IconButton>
					</Box>
				</BootstrapTooltip>
			</Box>
		</ClickAwayListener>
	);
};

CMToolTip.propTypes = {
	icon: PropTypes.any.isRequired,
};
export default CMToolTip;
