import React, { useEffect, useMemo } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import { get, map, upperCase } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';

import Logo from '../../../assets/images/logo/logo.png';
import stringToColor from '../../../components/hooks/stringColor';
import translate from '../../../components/hooks/translate';
import { getConsultantData } from '../../../store/slice/auth/signup/consultantInfoSlice';
import MenuItemsList from './MenuItemsList';
import SidebarMenuItem from './SidebarMenuItem';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Drawer from './Drawer';

const AccountStyle = styled('div')(({ theme }) => ({
	display: 'flex',
	gap: 10,
	padding: theme.spacing(2, 2.5),
	borderRadius: Number(theme.shape.borderRadius) * 1.5,
	backgroundColor: theme.palette.grey[200],
}));

const DashboardSidebar = (props) => {
	const { drawerOpen, setDrawerOpen, handleDrawerToggle } = props;
	const meInfo = useSelector((state) => state.auth.meInfo);
	const theme = useTheme();
	const matchUpSm = useMediaQuery(theme.breakpoints.up('md'));

	const consultantData = useSelector(getConsultantData);
	const consultantInfo = consultantData.data;

	useEffect(() => {
		matchUpSm ? setDrawerOpen(true) : setDrawerOpen(false);
	}, [setDrawerOpen, matchUpSm]);

	//add calendar object to sidebar menu
	const isCalendar = meInfo?.calenderStatus;
	const formattedMenuList = useMemo(() => {
		if (isCalendar) {
			return [
				...MenuItemsList,
				{
					label: 'common_calendar',
					icon: <CalendarMonthOutlinedIcon />,
					path: 'calendar',
					selected: '',
					type: 'main-menu',
				},
			];
		}

		return MenuItemsList;
	}, [isCalendar]);

	return (
		<Drawer variant='permanent' open={drawerOpen} className='sidebar-section'>
			<Box display='flex' alignItems='center' justifyContent='space-between' gap={1} p={2}>
				<Link to='/'>
					<Box component='img' src={Logo} alt='logo' sx={{ maxWidth: '30px', verticalAlign: 'middle' }} />
				</Link>
				{drawerOpen && (
					<div>
						<Box
							bgcolor='grey.200'
							display='flex'
							onClick={handleDrawerToggle}
							p={0.5}
							borderRadius={1}
							sx={{ cursor: 'pointer' }}
						>
							<MenuOpenIcon />
						</Box>
					</div>
				)}
			</Box>
			<List sx={{ padding: '8px 12px' }}>
				{drawerOpen ? (
					<Box>
						<AccountStyle>
							<Avatar
								sx={{
									backgroundColor: stringToColor(get(consultantInfo, 'profilePictureURL', 'NA')),
								}}
								alt={upperCase(get(consultantInfo, 'name', 'U'))}
								src={get(consultantInfo, 'profilePictureURL', 'NA')}
							/>
							<Typography
								textOverflow='ellipsis'
								whiteSpace='nowrap'
								overflow='hidden'
								variant='subtitle2'
								color='text.primary'
							>
								{get(consultantInfo, 'firstName', '') + ' ' + get(consultantInfo, 'lastName', '')}
							</Typography>
						</AccountStyle>
					</Box>
				) : null}
				<PerfectScrollbar>
					{map(formattedMenuList, (item, index) => {
						return (
							<React.Fragment key={index}>
								<NavLink to={item.path} end={true}>
									{({ isActive }) => (
										<SidebarMenuItem
											isActive={isActive}
											setDrawerOpen={setDrawerOpen}
											drawerOpen={drawerOpen}
											icon={item?.icon}
											label={translate(item?.label)}
											props={consultantInfo}
										/>
									)}
								</NavLink>
							</React.Fragment>
						);
					})}
				</PerfectScrollbar>
			</List>
		</Drawer>
	);
};

export default DashboardSidebar;
