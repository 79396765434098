import React from 'react';

import AddBoxIcon from '@mui/icons-material/AddBox';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { FieldArray } from 'formik';
import { size } from 'lodash';
import translate from '../../../../hooks/translate';
import AvailabilityTimeZoneForm from './AvailabilityTimeZoneForm';

export default function AvailabilityForm({ item, index, props, slots, setSlots, disableForm }) {
	return (
		<Grid item xs={12} container spacing={1}>
			<Grid item xs={6} md={2}>
				<Stack direction='row' spacing={1} alignItems='center'>
					<Checkbox
						name={`availability.${[index]}.checked`}
						color='primary'
						checked={item.checked}
						onChange={props.handleChange}
						inputProps={{ 'aria-label': 'week-Active' }}
						disabled={disableForm}
					/>
					<Typography variant='body1' sx={{ fontWeight: '500', color: 'text.primary' }}>
						{translate(item?.name)}
					</Typography>
				</Stack>
			</Grid>
			<FieldArray name={`availability.${[index]}.time`}>
				{({ remove, push }) => (
					<>
						<Grid item sx={{ flexGrow: 1, margin: 'auto' }} order={{ xs: 1, md: 0 }}>
							{item.checked &&
								size(item.time) > 0 &&
								item.time.map((time, subIndex) => (
									<AvailabilityTimeZoneForm
										index={index}
										key={subIndex}
										subIndex={subIndex}
										props={props}
										slots={slots}
										setSlots={setSlots}
										time={time}
										remove={remove}
										item={item}
										disableForm={disableForm}
									/>
								))}
						</Grid>
						<Grid item xs={6} md='auto' order={{ xs: 0, md: 1 }} sx={{ textAlign: 'end' }}>
							<IconButton
								aria-label='add'
								disabled={!item.checked || disableForm}
								sx={{
									color: 'primary.main',
									'&.Mui-disabled': {
										color: 'text.primary',
									},
								}}
								onClick={() => {
									push({ startTime: '', endTime: '' });
								}}
							>
								<AddBoxIcon sx={{ color: 'inherit' }} />
							</IconButton>
						</Grid>
					</>
				)}
			</FieldArray>

			<Grid item xs={12} order={3} className='pt-0'>
				<Divider light />
			</Grid>
		</Grid>
	);
}
